import React from "react";
import StockDetailRecipeQualityCard from "../../cards/StockDetailRecipeQualityCard";
import moment from "moment";
import OperationDetailLegacyCard from "../../cards/OperationDetailLegacyCard";
import NoAvatar from "../../../../components/avatar/NoAvatar";
import generateFileUrl from "../../../../utils/generateFileUrl";
import { convertSeconds } from "../../../../utils/secondToHoursAndMinute";

const LegacyOperationDetailModal = ({ data }) => {
  const groupedJunctions = data?.operation?.operations[0]?.junctions?.reduce((groups, junction) => {
    const date = new Date(junction.startDate).toLocaleDateString("tr-TR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(junction);
    return groups;
  }, {});

  const sortedDates = Object.keys(groupedJunctions).sort((a, b) => {
    const dateA = new Date(a.split(".").reverse().join("-"));
    const dateB = new Date(b.split(".").reverse().join("-"));
    return dateA - dateB;
  });

  moment.locale("tr");

  return (
    <div className="flex flex-col w-full pt-5">
      <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] py-3 gap-x-2">
        {data?.product?.product?.image ? (
          <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden mr-3">
            <img src={generateFileUrl(data?.product?.product?.image)} />
          </div>
        ) : (
          <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden mr-3">
            <NoAvatar name={data?.product?.product?.name || "--"} rounded={4} theme={"product"} />
          </div>
        )}
        <span className="flex flex-col">
          <p className="ml-1 text-[#101828] font-semibold text-md">{data?.product?.product?.name || "Product Name (default)"}</p>
          <span className="flex w-fit bg-[#FFFAEB] px-1 py-[3px] text-[#B54708] font-medium text-xs">Stok: {data?.product?.product?.stockCode || "--"}</span>
        </span>
      </div>
      <div className="flex w-full pt-5 gap-x-6">
        <div className="flex flex-col gap-y-8 w-[368px]">
          <div className="flex flex-col gap-y-4 w-full">
            <div className="flex gap-x-3 w-full">
              <span className="flex min-w-10 max-w-10 w-10 min-h-10 max-h-10 h-10 border border-[#E4E7EC] shadow-xs px-[10px] items-center justify-center rounded-lg">
                <QuantityIcon />
              </span>
              <span className="flex flex-col">
                <p className="text-[#475467] text-xs">Yapılan Adet</p>
                <p className="text-[#344054] font-medium text-sm">{data?.operation?.operations?.totalMadeWorkCount || 0} ad.</p>
              </span>
            </div>
            <span className="flex w-full min-h-px max-h-px h-px bg-[#E4E7EC]"></span>
            <div className="flex gap-x-3 w-full">
              <span className="flex min-w-10 max-w-10 w-10 min-h-10 max-h-10 h-10 border border-[#E4E7EC] shadow-xs px-[10px] items-center justify-center rounded-lg">
                <QuantityAcceptedIcon />
              </span>
              <span className="flex flex-col">
                <p className="text-[#475467] text-xs">Onaylanan Adet</p>
                <p className="text-[#079455] font-medium text-sm">{data?.operation?.operations?.totalAcceptedWorkCount || 0} ad.</p>
              </span>
            </div>
            <span className="flex w-full min-h-px max-h-px h-px bg-[#E4E7EC]"></span>
            <div className="flex gap-x-3 w-full">
              <span className="flex min-w-10 max-w-10 w-10 min-h-10 max-h-10 h-10 border border-[#E4E7EC] shadow-xs px-[10px] items-center justify-center rounded-lg">
                <QuantityDeclinedIcon />
              </span>
              <span className="flex flex-col">
                <p className="text-[#475467] text-xs">Reddedilen Adet</p>
                <p className="text-[#D92D20] font-medium text-sm">{data?.operation?.operations?.totalRejectedWorkCount || 0} ad.</p>
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-y-5">
            <div className="flex gap-x-3 w-full border border-[#EAECF0] p-3 rounded-[10px] shadow-xs min-h-[70px] h-[70px] max-h-[70px]">
              <span className="flex min-w-8 max-w-8 w-8 min-h-8 max-h-8 h-8 rounded bg-[#DCFAE6] p-2">
                <ClockStart />
              </span>
              <div className="flex flex-col w-full">
                <p className="text-[#475467] text-xs">Tahmini İş Süresi</p>
                <span className="flex gap-x-1 items-end">
                  <span className="text-[#475467] font-semibold text-lg">{convertSeconds(data?.operation?.times?.[0]?.estimatedTime) || "--"}</span>
                  <span className="text-[#667085] text-md mb-[1px]"></span>
                </span>
              </div>
              <div className="flex flex-col w-full">
                <p className="text-[#475467] text-xs">Uygulanan İş Süresi</p>
                <span className="flex gap-x-1 items-end">
                  <span className="text-[#475467] font-semibold text-lg">{convertSeconds(data?.operation?.times?.[0]?.avgEstimatedTime) || "--"}</span>
                  <span className="text-[#667085] text-md mb-[1px]"></span>
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-5">
            <div className="flex gap-x-3 w-full border border-[#EAECF0] p-3 rounded-[10px] shadow-xs min-h-[70px] h-[70px] max-h-[70px]">
              <span className="flex min-w-8 max-w-8 w-8 min-h-8 max-h-8 h-8 rounded bg-[#FEF0C7] p-2">
                <SettingStart />
              </span>
              <div className="flex flex-col w-full">
                <p className="text-[#475467] text-xs">Tahmini Ayar Süresi</p>
                <span className="flex gap-x-1 items-end">
                  <span className="text-[#475467] font-semibold text-lg">--</span>
                  <span className="text-[#667085] text-md mb-[1px]"></span>
                </span>
              </div>
              <div className="flex flex-col w-full">
                <p className="text-[#475467] text-xs">Uygulanan Ayar Süresi</p>
                <span className="flex gap-x-1 items-end">
                  <span className="text-[#475467] font-semibold text-lg">--</span>
                  <span className="text-[#667085] text-md mb-[1px]"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <span className="w-px min-w-px max-w-px min-h-full bg-[#E4E7EC]"></span>
        <div className="flex flex-col h-[400px] overflow-y-auto overflow-x-hidden scrollbar-hide w-[368px] gap-y-5">
          {sortedDates.map((date) => {
            const dayName = moment(date.split(".").reverse().join("-")).format("dddd");
            return (
              <div key={date} className="flex flex-col w-full gap-y-3">
                <div className="flex items-center justify-between pb-2 border-b border-[#E4E7EC]">
                  <span className="text-sm font-medium text-gray-700">{date}</span>
                  <span className="text-xs text-gray-500">{dayName}</span>
                </div>
                <div className="flex flex-col gap-y-2">
                  {groupedJunctions[date].map((junction, index) => (
                    <div key={`${junction.id}-${index}`} className="flex">
                      <OperationDetailLegacyCard data={junction} operationType={data?.operation?.operations?.[0]?.type} />
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LegacyOperationDetailModal;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.1875 2.5H5C3.61929 2.5 2.5 3.61929 2.5 5V15C2.5 16.3807 3.61929 17.5 5 17.5H15C16.3807 17.5 17.5 16.3807 17.5 15V5C17.5 3.61929 16.3807 2.5 15 2.5H12.8125M7.1875 2.5V6.78571C7.1875 6.88277 7.29093 6.94484 7.37658 6.89916L10 5.5L12.6234 6.89916C12.7091 6.94484 12.8125 6.88278 12.8125 6.78571V2.5M7.1875 2.5H12.8125"
        stroke="#475467"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const QuantityAcceptedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_22200_10666)">
        <path
          d="M6.87337 1.66675H4.16504C2.78433 1.66675 1.66504 2.78604 1.66504 4.16675V15.8334C1.66504 17.2141 2.78433 18.3334 4.16504 18.3334H8.14652M6.87337 1.66675V6.45246C6.87337 6.54952 6.97681 6.61158 7.06245 6.56591L9.99837 5.00008L12.9343 6.56591C13.0199 6.61158 13.1234 6.54952 13.1234 6.45246V1.66675M6.87337 1.66675H13.1234M13.1234 1.66675H15.8317C17.2124 1.66675 18.3317 2.78604 18.3317 4.16675V9.01214M10.7239 15.1532L13.0876 17.5033L18.3348 12.2628"
          stroke="#17B26A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22200_10666">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const QuantityDeclinedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M6.87484 1.66675H3.74984C2.59924 1.66675 1.6665 2.59949 1.6665 3.75008V16.2501C1.6665 17.4007 2.59925 18.3334 3.74984 18.3334H8.14799M6.87484 1.66675V6.48818C6.87484 6.56906 6.96103 6.62078 7.0324 6.58271L9.99984 5.00008L12.9673 6.58271C13.0386 6.62078 13.1248 6.56906 13.1248 6.48818V1.66675M6.87484 1.66675H13.1248M13.1248 1.66675H16.2498C17.4004 1.66675 18.3332 2.59949 18.3332 3.75008V9.01214M12.2722 12.2353L14.8947 14.8907M17.5292 17.5023L14.8947 14.8907M14.8947 14.8907L17.5292 12.2353M14.8947 14.8907L12.2722 17.5023"
        stroke="#F04438"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ClockStart = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_22200_3306)">
        <path
          d="M14.6569 8.3665C14.6668 7.99992 14.6668 8.12292 14.6668 7.99992C14.6668 4.31802 11.6821 1.33325 8.00016 1.33325C4.31826 1.33325 1.3335 4.31802 1.3335 7.99992C1.3335 11.6235 4.2245 14.5719 7.82582 14.6644M8.00016 3.99992V7.99992L5.50791 9.24604M13.7592 11.0607L11.2785 9.40801C10.915 9.1658 10.7332 9.04469 10.5824 9.05372C10.4511 9.06159 10.3298 9.12654 10.2504 9.23146C10.1594 9.3519 10.1594 9.57034 10.1594 10.0072V13.3189C10.1594 13.7566 10.1594 13.9755 10.2506 14.096C10.3301 14.201 10.4515 14.2659 10.583 14.2736C10.7339 14.2824 10.9158 14.1608 11.2796 13.9174L13.7603 12.2583C14.0601 12.0578 14.2101 11.9575 14.2622 11.8313C14.3079 11.721 14.3077 11.597 14.2619 11.4868C14.2095 11.3606 14.0594 11.2606 13.7592 11.0607Z"
          stroke="#079455"
          stroke-width="1.33"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22200_3306">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const SettingStart = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_22200_14389)">
        <path
          d="M8.00016 10.2222C6.73032 10.2222 5.70088 9.22725 5.70088 7.99996C5.70088 6.77264 6.73032 5.77773 8.00016 5.77773C8.81456 5.77773 9.53007 6.18695 9.93857 6.80424M7.83277 14.6666H6.77089C6.48516 14.6666 6.24128 14.4669 6.19431 14.1945L6.06173 13.4258C5.91053 12.5489 4.96883 12.0235 4.10751 12.3354L3.35244 12.6087C3.08482 12.7056 2.78398 12.6013 2.64108 12.3622L1.41184 10.3044C1.26897 10.0652 1.32594 9.76126 1.54655 9.58572L2.16911 9.09041C2.8792 8.52541 2.8792 7.47448 2.16911 6.90948L1.54655 6.41417C1.32594 6.23863 1.26897 5.93468 1.41184 5.6955L2.64108 3.63773C2.78398 3.39855 3.08482 3.29425 3.35244 3.39115L4.10751 3.66454C4.96883 3.9764 5.91053 3.45095 6.06173 2.57408L6.19431 1.80535C6.24128 1.53295 6.48516 1.33329 6.77089 1.33329H9.22944C9.51517 1.33329 9.75905 1.53295 9.80602 1.80535L9.93857 2.57408C10.0898 3.45095 11.0315 3.9764 11.8928 3.66454L12.6479 3.39115C12.9155 3.29425 13.2163 3.39855 13.3592 3.63773L14.5885 5.6955C14.7314 5.93468 14.6744 6.23863 14.4538 6.41417L13.8312 6.90948C13.4762 7.19198 13.2986 7.59597 13.2987 7.99996M14.0245 12.0264L10.8534 14.1873C10.5547 14.3909 10.15 14.1769 10.15 13.8154V9.51252C10.15 9.15187 10.553 8.93775 10.8519 9.13962L14.0229 11.2816C14.2864 11.4596 14.2872 11.8473 14.0245 12.0264Z"
          stroke="#B54708"
          stroke-width="1.33"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22200_14389">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
