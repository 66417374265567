import React from "react";
import { NewDotsIcon, TargetIcon } from "../../../../components/new-cards/card-icons";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { formatSeconds, legacyFormatSeconds, secondToHoursAndMinute, secondToHoursAndMinute2 } from "../../../../utils/secondToHoursAndMinute";
import Badgets from "../../../../components/buttons/Badgets";

const OperationCard = ({ item, times }) => {
  const { t } = useTranslation();

  const operationTypes = (type) => {
    switch (type) {
      case "conversion_operation":
        return "DÖN";
      case "supporter_operation":
        return "YAR";
      case "assembly_operation":
        return "MON";
      case "decomposition_operation":
        return "AYR";
      default:
        return "DÖN";
    }
  };
  const statusColor = () => {
    if (item?.status?.includes("finished")) {
      return "#ECFDF3";
    } else if (item?.status?.includes("started")) {
      return "#FFFAEB";
    } else if (item?.status?.includes("rejected")) {
      return "#D92D20";
    } else {
      return "#F2F4F7";
    }
  };

  return (
    <div className={`flex w-[312px] h-[112px] flex-row border items-start cursor-pointer rounded-lg border-secondary-300  bg-white relative group `}>
      {/* <div style={{ backgroundColor: statusColor() }} className="flex w-full min-h-[4px] max-h-[4px] h-1 rounded-t-[8px]"></div> */}
      <div className="flex flex-col w-[93%]">
        <div className="flex flex-row items-center w-[100%]  justify-between pr-1 pt-2 pl-3">
          <div className="flex flex-row items-center gap-x-0.5 h-5 ">
            {item[0]?.type == "external" ? (
              <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
              </div>
            ) : (
              <div className="flex flex-row items-center gap-x-1">
                <div className="px-1  border border-[#475467] h-full w-fit rounded bg-[#fff]">
                  <p className="text-xs font-semibold text-secondary-700">{item[0]?.step || "-"}.Op</p>
                </div>
                <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                  <p className="text-xs font-semibold text-secondary-700">
                    {item[0]?.internalOperation?.process?.type ? operationTypes(item[0]?.internalOperation?.process?.type) : "DÖN"}
                  </p>
                </div>
              </div>
            )}
            <div className=" bg-white max-w-[230px] ml-1 rounded-r-[8px]">
              <p className="font-semibold text-sm text-secondary-700  truncate">
                {item[0]?.internalOperation?.process?.name || item[0]?.externalOperation?.process?.name || "Process Name"}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center h-[25px] justify-between w-full  border-t border-b border-t-[#E4E7EC] bg-[#F9FAFB] mt-1">
          <div className="w-[33%] pl-3 pr-1 border-r h-full flex items-center">
            <p className="text-xxs font-medium text-secondary-700">{item[0]?.type === "external" ? t("product:cycleTimes") : t("product:cycleTime")}</p>
          </div>

          <div className="flex flex-row pl-2 pr-1 items-center w-[33%]">
            <TargetIcon />
            <p className="text-xs font-medium ml-1 text-secondary-700">{legacyFormatSeconds(times?.avgEstimatedTime)}</p>
            <p className="text-xxs text-secondary-600 ml-0.5">{t("product:target")}</p>
          </div>
          <div className="flex flex-row items-center w-[33%]">
            <PlayIcon
              color={`${
                (item[0]?.type == "internal" ? times.estimatedTime < times?.avgEstimatedTime : times.estimatedTime * 86400 < times?.avgEstimatedTime)
                  ? "#F04438"
                  : "#079455"
              }`}
            />
            <p
              className={`text-xs font-medium ml-1 ${
                (item[0]?.type == "internal" ? times.estimatedTime < times?.avgEstimatedTime : times.estimatedTime * 86400 < times?.avgEstimatedTime)
                  ? "text-[#F04438]"
                  : "text-[#079455] "
              }`}
            >
              {times?.avgEstimatedTime
                ? item[0]?.type == "internal"
                  ? legacyFormatSeconds(times?.avgEstimatedTime?.toFixed(0))
                  : `${Math.ceil(times?.avgEstimatedTime / 60 / 60 / 24)} `
                : "--"}
            </p>
            <p
              className={`text-xxs ${
                (item[0]?.type == "internal" ? times.estimatedTime < times?.avgEstimatedTime : times.estimatedTime * 86400 < times?.avgEstimatedTime)
                  ? "text-[#F04438]"
                  : "text-[#079455] "
              }  ml-0.5`}
            >
              {t("product:applieds")}
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-x-2 w-[100%] pl-3 pr-3 mt-[5px]">
          <div className="flex flex-col h-[42px] w-1/3 mb-1.5 border border-[#E4E7EC] rounded">
            <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
              <p className="text-[10px] font-medium text-secondary-600">Eylem S.</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 px-0.5 py-[2px] justify-center">
              <p className="text-xs truncate font-medium text-secondary-700">{item?.eventLength}</p>
            </div>
          </div>
          <div className="flex flex-col h-[42px]  w-1/3 mb-1.5 border border-[#E4E7EC] rounded">
            <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
              <p className="text-[10px] font-medium text-secondary-600">Uygulanan</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 px-0.5 py-[2px] justify-center">
              <p className="text-xs truncate font-medium text-secondary-700">{item?.totalMadeWorkCount}</p>
            </div>
          </div>
          <div className="w-[44%] h-[42px] flex items-center mb-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
            <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
              <span className="text-xxs truncate text-[#475467] font-medium ">Kalite</span>
            </div>
            <div className="flex flex-col w-full items-start">
              <div className="flex flex-row items-center w-full border-b px-1">
                <p className="text-xxs font-normal text-secondary-600">Onay</p>
                <p className="text-xs font-medium  truncate ml-auto text-secondary-600">{item?.totalAcceptedWorkCount}</p>
              </div>
              <div className="flex flex-row items-center w-full px-1">
                <p className="text-xxs font-normal text-secondary-600">Red</p>
                <p className="text-xs font-medium max-w-[40px] truncate ml-auto text-secondary-600">{item?.totalRejectedWorkCount}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: statusColor() }}
        className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0  border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
      >
        <p
          className={`text-xxs font-semibold whitespace-nowrap ${
            item[0]?.status?.includes("started")
              ? "text-[#CA8504]"
              : item[0]?.status?.includes("finished")
              ? "text-[#079455]"
              : item[0]?.status?.includes("rejected")
              ? "text-white"
              : "#475467"
          } -rotate-90`}
        >
          {t("product:semiProduct")}({t("product:pcs")})
        </p>
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
      >
        <NewDotsIcon />
      </div>
      <Tooltip className="z-50" id={`${item[0]?.id}`} />
    </div>
  );
};

export default OperationCard;

export const PlayIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M2.91699 2.91065C2.91699 2.34414 2.91699 2.06088 3.03511 1.90474C3.13801 1.76871 3.2953 1.68454 3.46556 1.67437C3.661 1.6627 3.89668 1.81983 4.36805 2.13407L10.5021 6.22345C10.8916 6.48311 11.0863 6.61294 11.1542 6.77658C11.2135 6.91964 11.2135 7.08043 11.1542 7.22349C11.0863 7.38713 10.8916 7.51696 10.5021 7.77661L4.36805 11.866C3.89668 12.1802 3.661 12.3374 3.46556 12.3257C3.2953 12.3155 3.13801 12.2314 3.03511 12.0953C2.91699 11.9392 2.91699 11.6559 2.91699 11.0894V2.91065Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
