import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DetailHeader from "./components/DetailHeader";
import { useQuery } from "@tanstack/react-query";
import { reportService } from "../../services/report.service";
import moment from "moment";
import MonthlyChart from "./Twin/chart/MonthlyChart";
import DetailPageDateSelector from "./Twin/components/DetailPageDateSelector";
import WorkCountCard from "./components/WorkCountCard";
import { useTranslation } from "react-i18next";
import { Loading } from "./embeddedServer/svg/EmbeddedServerIcons";
import EquipmentJunctions from "./Twin/components/EquipmentJunctions";
import ExternalLoading from "../../components/loading/ExternalLoading";
import { timeFormatter } from "../../utils/timezoneFormatter";
import { authStore } from "../../stores/auth.store";
import EquipmentLosses from "./Twin/components/EquipmentLosses";
import { EquipmentDetailTourSteps } from "../../assets/TourSteps";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import EquipmentUpuTimeline from "../../components/upuTimeline/EquipmentUpuTimeline";
import { useStore } from "../../hooks/useStores";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import EquipmentDetailInfo from "./Twin/components/EquipmentDetailInfo";
import BottomDrawer from "../../components/drawer/AddManuelJunctionDrawer";
import { consoleStore } from "../../stores/console.store";
import AddManuelJunctionChildren from "../../components/drawer/subpages/AddManuelJunctionChildren";
import AddManuelJunctionDrawer from "../../components/drawer/AddManuelJunctionDrawer";
import ManuelJunction from "./manuelJunctionModal/ManuelJunction";
const EquipmentDetail = () => {
  const { auth } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlData = useParams();
  let { dates } = useParams();
  const pattern = /^(.*?)-(.+)$/;
  const match = urlData.deviceId?.match(pattern);
  //bottom console
  const [isOpen, setIsOpen] = useState(false);
  const [defaultStartForm, setDefaultStartForm] = useState();
  const [defaultEndForm, setDefaultEndForm] = useState();

  const [workCount, setWorkCount] = useState();
  const [gaugeData, setGaugeData] = useState([]);
  const [lossesData, setLossesData] = useState();
  const [barChartData, setBarChartData] = useState();
  const [junctionData, setJunctionData] = useState();
  const [defaultJunctionData, setDefaultJunctionData] = useState();
  const [isOpenQr, setIsOpenQr] = useState(false);
  const [energyUsageData, setEnergyUsageData] = useState();
  const [suggestedCycleTime, setSuggestedCycleTime] = useState("");

  const [customLoading, setCustomLoading] = useState(false);
  const [dateChangeCount, setDateChangeCount] = useState(0);

  //bottom console data

  const [bottomOpen, setBottomOpen] = useState(false);

  //New Timeline
  const [startTime, setStartTime] = useState(null);
  const [defaultStartTime, setDefaultStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [defaultEndTime, setDefaultEndTime] = useState(null);
  const [selectedJunction, setSelectedJunction] = useState("");
  const [groupedDatas, setGroupedDatas] = useState([]);

  //values
  const [shiftValues, setShiftValues] = useState([]);
  const [timelineValues, setTimelineValues] = useState([]);

  const [date, setDate] = useState(
    timeFormatter(moment(), authStore?.user?.company?.timeZone).formatted
  );
  const [warning, setWarning] = useState(false);
  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["daily"],
    queryFn: async () =>
      reportService.getDailyNodeData({
        nodeId: match[2],
        date: moment(
          timeFormatter(dates, authStore?.user?.company?.timeZone).formatted
        ).format("YYYY-MM-DD"),
      }),
  });

  const handleDateChange = (newDate) => {
    navigate(
      `/app/machine/digital-twin/equipment-details/${urlData.deviceId}/${newDate}`
    );
  };

  const PastJunctionData = async (event, onClickDate) => {
    setCustomLoading(true);
    setSelectedJunction(event?.id || event?.junctionId || event?.shiftId);
    let endDateToSend;
    if (event?.endDate) {
      endDateToSend = moment(event?.endDate).format("YYYY-MM-DD HH:mm:ss");
    } else {
      endDateToSend = moment(onClickDate).format("YYYY-MM-DD HH:mm:ss");
    }
    await reportService
      .getCustomDateReport({
        nodeId: event?.station?.id || event?.nodeId,
        id: data?._id,
        deviceId: data?.equipmentData?.deviceId,
        startDate: moment(event?.startDate).format("YYYY-MM-DD HH:mm:ss"),
        endDate: endDateToSend,
        junctionId: event?.junctionId ? event.junctionId : null,
      })
      .then(async (res) => {
        await setSuggestedCycleTime(res?.data?.suggestedCycleTime || "");
        await setBarChartData(res?.data?.barChartData || []);
        await setWorkCount(res?.data?.workCountData || []);
        await setEnergyUsageData(
          {
            kwh: res?.data?.kwh,
            kwhCostT1: res?.data?.kwhCostForStandard,
            kwhCostForT3: res?.data?.kwhCostForThreeTime,
            cCount: res?.data?.cCount,
            kw: res?.data?.kw,
            kwhRatioByOperation: res?.data?.kwhRatioByOperation,
            kwhRatioByCompany: res?.data?.kwhRatioByCompany,
          } || []
        );

        setGaugeData(
          [
            { ...res?.data?.measurable, type: "measurable" },
            { ...res?.data?.utilization, type: "Utilization" },
            { ...res?.data?.availability, type: "Availability" },
            { ...res?.data?.performance, type: "Performance" },
            { ...res?.data?.quality, type: "qualityRate" },
            { ...res?.data?.oee, type: "OEE" },
          ] || [{}, {}, {}, {}, {}, {}]
        );
        await setCustomLoading(false);
      });
    // setDataZoom({
    //   start: normalizeAndScaleTimestamp(
    //     defaultStart,
    //     defaultEnd,
    //     moment(event?.startDate).unix()
    //   ),
    //   end: normalizeAndScaleTimestamp(
    //     defaultStart,
    //     defaultEnd,
    //     moment(endDateToSend).unix()
    //   ),
    // });
    // setDataZoom({
    //   start: normalizeAndScaleTimestamp(
    //     defaultStart,
    //     defaultEnd,
    //     moment(event?.startDate).unix()
    //   ),
    //   end: normalizeAndScaleTimestamp(
    //     defaultStart,
    //     defaultEnd,
    //     moment(endDateToSend).unix()
    //   ),
    // });
  };

  useEffect(() => {
    setWarning(data?.equipmentData?.warning);
    setWorkCount(data?.workCountData || []);
    setBarChartData(data?.barChartData || []);
    setJunctionData(data?.junctionData);
    setDefaultJunctionData(data?.junctionData);
    setLossesData(data?.lossesData);
    setGaugeData(
      [
        { ...data?.measurable, type: "measurable" },
        { ...data?.utilization, type: "Utilization" },
        { ...data?.availability, type: "Availability" },
        { ...data?.performance, type: "Performance" },
        { ...data?.quality, type: "qualityRate" },
        { ...data?.oee, type: "OEE" },
      ] || [{}, {}, {}, {}, {}, {}]
    );
    setEnergyUsageData(
      {
        kw: data?.kw,
        kwh: data?.kwh,
        cCount: data?.cCount,
        kwhCostT1: data?.kwhCostForStandard,
        kwhCostForT3: data?.kwhCostForThreeTime,
        kwhRatioByCompany: data?.kwhRatioByCompany,
        kwhRatioByOperation: data?.kwhRatioByOperation,
      } || []
    );
  }, [data]);

  useEffect(() => {
    setDate(dates);
  }, []);

  useEffect(() => {
    if (!selectedJunction) {
      setWorkCount(data?.workCountData || []);
    }
  }, [selectedJunction]);

  useEffect(() => {
    refetch();
  }, [dates]);

  const [stepIndexState, setStepIndexState] = useState(0);
  const [runState, setRunState] = useState(true);

  useEffect(() => {
    if (isOpenQr) {
      const timer = setTimeout(() => {
        setStepIndexState(1);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [isOpenQr, setStepIndexState]);

  useEffect(() => {
    if (stepIndexState === 4) {
      setIsOpenQr(false);
    } else if (stepIndexState === 1) {
      setIsOpenQr(true);
    }
  }, [stepIndexState, setIsOpenQr]);

  const getAllShiftDatas = async () => {
    await setGroupedDatas(
      data?.shiftlyData
        ? data?.shiftlyData
        : data?.shiftData
        ? data?.shiftData
        : []
    );
  };
  const companyID = auth?.user?.company?.id;
  const channel = useChannel(companyID);
  useEvent(channel, "update-node", (socketEvent) => {
    if (socketEvent?.node?.deviceId == match[1]) {
      setWarning(socketEvent?.status == 11 ? true : false);
    }
  });

  const defaultTimer = async () => {
    await getAllShiftDatas();
    (await data) &&
      setStartTime(
        moment(timeFormatter(data?.startDate)?.formatted)?.valueOf()
      );
    await setDefaultStartTime(
      moment(timeFormatter(data?.startDate)?.formatted)?.valueOf()
    );
    await setEndTime(
      moment(timeFormatter(data?.startDate)?.formatted)?.valueOf() + 86400000
    );
    await setDefaultEndTime(
      moment(timeFormatter(data?.startDate)?.formatted)?.valueOf() + 86400000
    );
  };

  useEffect(() => {
    defaultTimer();
  }, [date, data]);

  useEffect(() => {
    if (
      moment(date).format("YYYY-MM-DD") === moment(dates).format("YYYY-MM-DD")
    ) {
    } else {
      handleDateChange(moment(date).format("YYYY-MM-DD"));
    }
  }, [date]);

  useEffect(() => {
    setDateChangeCount((prevCount) => prevCount + 1);
  }, [dates]);

  return (
    <>
      <div className="w-full h-full flex flex-col mt-2 relative">
        <DetailHeader
          dateChangeCount={dateChangeCount}
          t={t}
          match={match}
          warning={warning}
          refetch={refetch}
          isLoading={isLoading || isFetching}
          setWarning={setWarning}
          junctionData={defaultJunctionData}
          equipmentData={data?.equipmentData}
          isWarning={data?.equipmentData?.warning}
          name={data?.equipmentData?.equipmentName}
          process={data?.equipmentData?.processName}
          image={data?.equipmentData?.equipmentImage}
          setRunState={setRunState}
          setIsOpenQr={setIsOpenQr}
          isOpenQr={isOpenQr}
          setStepIndexState={setStepIndexState}
          runState={runState}
        />

        <div className="flex-1 mt-3 w-full flex flex-col overflow-y-auto scrollbar-hide pb-8 pt-2 overflow-x-hidden">
          <div className="flex flex-col w-full gap-3">
            {isLoading || isFetching ? (
              <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[275px]" />
            ) : (
              <div className="flex-1 border rounded-lg shadow border-secondary-200 step-7">
                <MonthlyChart
                  equipmentData={data?.equipmentData}
                  setDate={setDate}
                  defaultLegends={data?.legends}
                  data={data?.monthlyChart}
                  end={data?.monthlyChart?.endDate}
                  start={data?.monthlyChart?.startDate}
                  selectedDate={dates}
                  selectedEquipmentId={data?.id || data?.nodeId}
                  refetch={refetch}
                />
              </div>
            )}

            {isLoading || isFetching ? (
              <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[256px]" />
            ) : (
              <EquipmentDetailInfo
                gaugeData={gaugeData}
                energyUsageData={energyUsageData}
                data={data}
                isLoading={isLoading}
                selectedDate={dates}
                isFetching={isFetching}
              />
            )}
          </div>
          <DetailPageDateSelector
            dates={dates}
            date={date}
            setDate={setDate}
            isLoading={isLoading || isFetching}
            shiftSelecter={(r) => setDate(r)}
          />
          {isLoading || isFetching ? (
            <div className="w-full min-h-[270px] rounded-lg shadow border border-secondary-300 bg-gray-200 animate-pulse flex items-center justify-center">
              <Loading size={38} color={"#475467"} secondary={"#EAECF0"} />
            </div>
          ) : (
            <span className="step-12">
              <EquipmentUpuTimeline
                refetch={refetch}
                timelineChart={data?.timelineData}
                junctionData={junctionData}
                defaultStartTime={defaultStartTime}
                defaultEndTime={defaultEndTime}
                startTime={startTime}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                equipmentData={data?.equipmentData}
                endTime={endTime}
                selectedJunction={selectedJunction}
                setSelectedJunction={setSelectedJunction}
                shiftValues={shiftValues}
                setShiftValues={setShiftValues}
                timelineValues={timelineValues}
                setTimelineValues={setTimelineValues}
                groupedDatas={groupedDatas}
                date={date}
                barChartData={barChartData}
                PastJunctionData={PastJunctionData}
                //bottom console
                setIsOpen={setIsOpen}
                setDefaultEndForm={setDefaultEndForm}
                setDefaultStartForm={setDefaultStartForm}
                defaultEndForm={defaultEndForm}
                defaultStartForm={defaultStartForm}
              />
            </span>
          )}

          {isLoading || isFetching ? (
            <div className="w-full h-[52.81px] rounded-lg bg-gray-200 animate-pulse mt-4" />
          ) : (
            <span className="step-15">
              <WorkCountCard
                workCount={workCount}
                selectedJunction={selectedJunction}
                metadataId={data?.equipmentData?.metadataId}
                equipmentData={data?.equipmentData}
                junctionData={junctionData}
                refetch={refetch}
                suggestedCycleTime={suggestedCycleTime}
              />
            </span>
          )}
          <div className="flex flex-col w-full">
            {isLoading || isFetching ? (
              <div className="w-full h-[72px] rounded-lg bg-gray-200 animate-pulse " />
            ) : (
              <span className="step-16">
                <EquipmentJunctions
                  data={data}
                  junctionData={junctionData}
                  date={date}
                  refetch={refetch}
                  defaultEndTime={defaultEndTime}
                  defaultStartTime={defaultStartTime}
                  PastJunctionData={PastJunctionData}
                  selectedJunction={selectedJunction}
                  setSelectedJunction={setSelectedJunction}
                  equipmentData={data?.equipmentData}
                  activeLength={data?.activeJunctionLength}
                  setStartTime={setStartTime}
                  setEndTime={setEndTime}
                />
              </span>
            )}
            <div className="w-full h-px bg-gray-300 mt-4" />
            {isLoading || isFetching ? (
              <div className="w-full h-[72px] rounded-lg bg-gray-200 animate-pulse " />
            ) : (
              <span className="step-17">
                <EquipmentLosses
                  date={date}
                  lossesData={lossesData}
                  refetch={refetch}
                />
              </span>
            )}
          </div>
        </div>
        {!isLoading ||
          (isFetching && (
            <ExternalLoading first={isFetching} second={customLoading} />
          ))}
        {/* <ReactJoyride
        steps={EquipmentDetailTourSteps}
        continuous={true}
        disableOverlayClose={true}
        scrollToFirstStep={true}
        spotlightClicks={true}
        showProgress={true}
        stepIndex={stepIndexState}
        showSkipButton={true}
        hideCloseButton={true}
        run={runState}
        callback={handleJoyrideCallback}
        locale={{
          next: t("buttons:next"),
          back: t("buttons:back"),
          skip: t("buttons:skip"),
          last: t("buttons:last"),
          close: t("buttons:close"),
        }}
        styles={{
          buttonNext: {
            backgroundColor: "#7F56D9",
            borderRadius: 8,
            paddingRight: 14,
            paddingLeft: 14,
            paddingBottom: 10,
            paddingTop: 10,
          },
          tooltipTitle: {
            color: "#101828",
            fontSize: 20,
            fontStyle: "normal",
            fontWeight: 600,
            fontFamily: "Inter",
          },
          tooltipContent: {
            color: "#667085",
          },
          options: {
            beaconSize: 36,
            overlayColor: "#000",
            primaryColor: "#6941C6",
            textColor: "#000",
            width: 410,
            zIndex: 1000,
          },
          buttonSkip: {
            fontSize: 14,
            fontFamily: "Inter",
            fontWeight: 600,
            fontStyle: "normal",
            color: "#475467",
            paddingLeft: 14,
            paddingRight: 14,
            paddingBottom: 10,
            paddingTop: 10,
          },
          buttonBack: {
            color: "#6941C6",
            fontSize: 14,
            fontWeight: 600,
            fontFamily: "Inter",
            paddingLeft: 14,
            paddingRight: 14,
            paddingBottom: 10,
            paddingTop: 10,
            marginRight: 12,
          },
          overlay: {
            // Arka plan stilleri
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Arka plan rengini burada değiştirebilirsiniz
          },
          spotlight: {
            // Işık halkası stilleri
          },
          tooltip: {
            // Tooltip stilleri
            backgroundColor: "white", // Tooltip arka plan rengini burada değiştirebilirsiniz
            borderRadius: 12,
            color: "#667085",
            fontSize: 14,
            fontFamily: "Inter",
            fontWeight: 400,
            fontStyle: "normal",
            padding: 24,
          },
          tooltipContent: {
            // Tooltip içeriği stilleri
            marginBottom: 32,
            padding: 0,
            marginTop: 4,
          },
          beacon: {
            // Rehber ışığı stilleri
          },
        }}
      /> */}
      </div>

      <AddManuelJunctionDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        children={
          <ManuelJunction
            process={data?.processId}
            setIsOpen={setIsOpen}
            equipmentData={data?.equipmentData}
            setDefaultEndForm={setDefaultEndForm}
            setDefaultStartForm={setDefaultStartForm}
            defaultStartForm={defaultStartForm}
            defaultEndForm={defaultEndForm}
            isOpen={isOpen}
          />
        }
        // children={
        //   <AddManuelJunctionChildren
        //     process={data?.processId}
        //     setIsOpen={setIsOpen}
        //     setConsoleStart={setConsoleStart}
        //     setConsoleEnd={setConsoleEnd}
        //     consoleStart={consoleStart}
        //     consoleEnd={consoleEnd}
        //     stationId={data?.equipmentData?.id}
        //   />
        // }
      />
    </>
  );
};

export default EquipmentDetail;

// endDate
// :
// "2024-07-11T21:24:32Z"
// header
// :
// "220419a2-37e0-438d-8875-d9a02d35381d"
// internalOperation
// :
// "9f2f9e86-4bf0-49ae-a352-a58ef57de675"
// job
// :
// "f6e7692b-1712-44bc-8cdd-36e20e4f099e"
// madeWorkCount
// :
// 100
// startDate
// :
// "2024-07-11T20:43:58Z"
// station
// :
// "40173112-5a01-4693-95db-ce4c65370478"
// subEvents
// :
// []
// type
// :
// "setup"
// user
// :
// "3791ec0d-3bcf-4a08-8c54-ad7388f6521c"
