import React from "react";
import DetailGaugeChart from "./chart/DetailGaugeChart";
import { useTranslation } from "react-i18next";
import EquipmentDetailGaugeChart from "./chart/EquipmentDetailGaugeChart";
import PersonelDetailGaugeChart from "./chart/PersonelDetailGaugeChart";
import EquipmentAndOperationDetailKpiCard from "./chart/EquipmentAndOperationDetailKpiCard";
import PersonnelDetailKpiCard from "./chart/PersonnelDetailKpiCard";

const PersonelDetailCharts = ({ gaugeData,selectedDate }) => {
  const { t } = useTranslation();
  const deneme = [
    { actual: 0.99, average: 0.89, kpi: 0.99, timeMass: 8371, type: "Performance" },
    { actual: 0.99, average: 0.89, kpi: 0.99, timeMass: 8371, type: "Kalite K. Oranı" },
  ] || [{}, {}];
  return (
    <div className="flex w-full flex-col h-full gap-y-[14px]">
      {gaugeData.map((item, index) => (
        <PersonnelDetailKpiCard
        key={index}
        gaugeData={gaugeData}
        name={t(`addProcess:${item?.type}`)}
        value={item?.actual ? item?.actual : 0}
        timeMass={item?.timeMass || 0}
        kpi1={item?.average?.toFixed(2) || 0}
        kpi2={item?.kpi || 0}
        item={item}
        selectedDate={selectedDate}
        // selectedProcess={data?.id}
        />
        // <PersonelDetailGaugeChart
        //   key={index}
        //   gaugeData={gaugeData}
        //   name={t(`addProcess:${item.type}`)}
        //   value={item.actual ? item.actual : 0}
        //   timeMass={item.timeMass || 0}
        //   kpi1={item.average?.toFixed(2) || 0}
        //   kpi2={item.kpi || 0}
        //   // selectedProcess={data?.id}
        // />
      ))}
    </div>
  );
};

export default PersonelDetailCharts;
