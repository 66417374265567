import React, { useEffect, useMemo, useState } from "react";
import { Button, CustomModal, PaginationComponent, TableOptions } from "../../components";
import { useStore } from "../../hooks/useStores";
import { PlusIcon } from "../../assets/icons/stepsIcons";
import { useTranslation } from "react-i18next";
import NewRawMaterialsModal from "./modals/NewRawMaterialsModal";
import { useQuery } from "@tanstack/react-query";
import { rawMaterialService } from "../../services/raw-material.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TableArrowLeft } from "../../assets/icons/lossIcon";
import SearchInput from "../../components/inputs/SearchInput";
import { SearchIcon } from "../../assets/icons/PersonIcons";
import { ScrollChevronIcon } from "../../assets/icons/productIcons";
import TypeRawPurchaseRequestForm from "./modals/TypeRawPurchaseRequestForm";
import RawMaterialListMaterialCard from "../../components/junction/RawMaterialListMaterialCard";
import RawMaterialListSort from "./sort/RawMaterialListSort";
import RawMaterialListFilter from "./filter/RawMaterialListFilter";
import NoData from "../../steps/step-navigation/components/NoData";

const RawMaterialList = ({ }) => {
  const { auth } = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [onEdit, setOnEdit] = useState(null);
  const [isNewRawMaterialModal, setIsNewRawMaterialModal] = useState(false);
  const [responsiveSearch, setResponsiveSearch] = useState(false);
  const [purchaseForm, setPurchaseForm] = useState(false);
  const [purchase, setPurchase] = useState(null);
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const [windowSize, setWindowSize] = useState(window?.innerWidth);
  const [pageNo, setPageNo] = useState(Number(searchParams.get("pageNo")) || 1);
  const [searchVal, setSearchVal] = useState(searchParams.get("search") || "");
  const [sortValue, setSortValue] = useState(searchParams.get("sort") || "");
  const [ascDesc, setAscDesc] = useState(searchParams.get("ascDesc") === "true" ? true : false);
  const [pageLimit, setPageLimit] = useState(Number(searchParams.get("take")) || 10);
  const [filterOptions, setFilterOptions] = useState(searchParams.get("filter") ? JSON.parse(decodeURIComponent(searchParams.get("filter"))) : {});
  const [debouncedQuery, setDebouncedQuery] = useState(searchVal);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [sortOption, setSortOption] = useState();

  const filterString = filterOptions && Object.keys(filterOptions).length > 0 ? encodeURIComponent(JSON.stringify(filterOptions)) : "";

  const { isLoading, refetch, isFetching } = useQuery({
    queryKey: ["listMaterial"],
    retry: 0,
    enabled: false,
    queryFn: async () => {
      try {
        const res = await rawMaterialService.getRawMaterialList(
          Number(pageNo),
          searchVal,
          sortValue,
          ascDesc ? "DESC" : "ASC",
          pageLimit,
          filterString ? [filterString] : []
        );
        setTotalCount(res?.meta?.itemCount);
        setPageCount(res?.meta?.pageCount);
        setPageData(res?.data);

        const queryParams = new URLSearchParams();
        if (pageNo) queryParams.set("pageNo", pageNo.toString());
        if (searchVal) queryParams.set("search", searchVal);
        if (sortValue) queryParams.set("sort", sortValue);
        if (ascDesc !== undefined) queryParams.set("ascDesc", ascDesc.toString());
        if (pageLimit != 10) queryParams.set("take", pageLimit.toString());
        if (filterString) queryParams.set("filter", filterString);

        setSearchParams(queryParams);

        return res;
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
        throw error;
      }
    },
  });

  const changePage = (newPageNo) => {
    setPageNo(newPageNo);
    searchParams.set("pageNo", newPageNo.toString());
    setSearchParams(searchParams);
  };

  const tableHeadItems = [
    { name: t("product:rawMaterialss"), value: "image", type: "" },
    { name: t("product:rawMaterialType"), value: "name", type: "string" },
    { name: t("product:associatedGroup"), value: "lastName", type: "string" },
    { name: t("product:unitPriceAndUnit"), value: "rolesKey", type: "string" },
    { name: t("product:description"), value: "talents", type: "number" },
    { name: "", value: "detail", type: "string" },
  ];

  const handleSearch = () => {
    setResponsiveSearch(!responsiveSearch);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window?.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const memoizedFilterOptions = useMemo(() => filterOptions, [filterOptions]);

  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };

  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchVal);
    }, 200);

    return () => {
      clearTimeout(handler);
    };
  }, [searchVal]);

  useEffect(() => {
    const params = {};
    if (debouncedQuery) {
      params.search = debouncedQuery;
    }
    if (sortOption) {
      params.sort = sortOption;
    }
    setSearchParams(new URLSearchParams(params));
  }, [debouncedQuery, sortOption]);

  useEffect(() => {
    refetch();
  }, [pageNo, searchVal, sortValue, ascDesc, pageLimit, filterOptions]);

  useEffect(() => {
    setPageNo(1);
  }, [debouncedQuery, sortOption, filterOptions]);

  console.log(pageData, "page data")

  return (
    <div className="w-full h-full flex flex-col overflow-hidden">
      <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
        <Button
          colorType={"tertiary-gray"}
          onClick={() => {
            navigate(-1);
          }}
          size={"sm"}
          iconLeft={
            <div className="rotate-180">
              <TableArrowLeft />
            </div>
          }
        />

        <div className="flex items-center">
          <span className="text-2xl text-secondary-900 font-semibold ml-2">{t("product:rawMaterialList")}</span>
        </div>
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <div className="w-[320px] max-w-[320px] min-w-[320px] sm:hidden md:hidden">
            <SearchInput setSearchVal={setSearchVal} theme={"product"} />
          </div>
          <RawMaterialListSort sortValue={sortValue} setSortValue={setSortValue} ascDesc={ascDesc} setAscDesc={setAscDesc} />
          <TableOptions pageLimit={pageLimit} setPageLimit={setPageLimit} />
          <RawMaterialListFilter setFilterOptions={setFilterOptions} refetch={refetch} filterOptions={memoizedFilterOptions} />

          <div className="hidden sm:flex md:flex">
            <Button onClick={handleSearch} size="md" iconLeft={<SearchIcon />} colorType={"secondary-gray"} />
          </div>
          <Button
            colorType={"primary-product"}
            size={"md"}
            label={t("product:createRawMaterial")}
            iconLeft={<PlusIcon />}
            onClick={() => navigate("/app/product/mrp/stock/raw-material/create")}
          />
        </div>
      </div>
      {responsiveSearch && (
        <div className="w-full hidden sm:flex md:flex pb-4 px-1">
          <SearchInput setSearchVal={setSearchVal} />
        </div>
      )}
      {isLoading || isFetching ? (
        <div className="w-full h-[86vh] animate-pulse bg-gray-200 rounded" />
      ) : (
        <>
          {pageData?.length <= 0 ? (
            <div className="flex items-center justify-center w-full h-full">
              <NoData header={t("label:noData")} button={false} theme={"product"} />
            </div>
          ) : (
            <div className="flex-1 w-full mt-2 border rounded-xl flex flex-col overflow-hidden relative sm:px-11">
              <div
                className={`w-11 h-full hidden sm:flex ${isRightArrowHidden ? "hidden duration-1000" : ""
                  } z-[999] right-0 cursor-pointer flex absolute rounded-r-xl items-center justify-center group bg-gray-50`}
                onClick={() => {
                  sideScroll(document.getElementById("content-wrapper"), 25, 100, 20);
                }}
              >
                <ScrollChevronIcon />
              </div>

              <div
                className={`w-11 h-full hidden sm:flex ${isLeftArrowHidden ? "hidden duration-1000" : ""
                  } z-[999] left-0 absolute cursor-pointer flex rotate-180 items-center justify-center bg-gray-50 group`}
                onClick={() => {
                  sideScroll(document.getElementById("content-wrapper"), 25, 100, -20);
                }}
              >
                <ScrollChevronIcon />
              </div>

              {isLoading || isFetching ? (
                <div className="w-full h-[86vh] animate-pulse bg-gray-200 rounded" />
              ) : (
                <div id="content-wrapper" className="min-w-full max-w-full w-full h-[86vh] rounded-xl flex flex-col">
                  <div className="flex max-h-[44px] min-h-[44px] max-w-full min-w-full">
                    {tableHeadItems?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          scope="col"
                          className={` px-4 py-3 ${i === 0
                            ? "w-[22%] md:w-[43%] md:min-w-[43%]"
                            : i === 1
                              ? "w-[11%] md:w-[13%]"
                              : i === 2
                                ? "w-[15%]"
                                : i === 3
                                  ? "w-[11%] md:w-[9%] md:min-w-[9%]"
                                  : i === 4
                                    ? "w-[35%] md:w-[13.5%] md:min-w-[13.5%]"
                                    : i === 5
                                      ? "w-[6%] "
                                      : ""
                            } items-center text-center bg-secondary-50 border-gray-200 border-b border-r last:border-r-0`}
                        >
                          <span className={`text-center rounded flex text-xs font-medium justify-center md:line-clamp-2 items-center whitespace-nowrap`}>
                            {item?.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="w-full bg-white flex-1 flex flex-col overflow-y-auto overflow-x-hidden scrollbar-hide">
                    {pageData?.map((item, key) => {
                      const currencyIcon = () => {
                        switch ((item?.currency).toLowerCase()) {
                          case "usd":
                            return "$";
                          case "eur":
                            return "€";
                          case "gbp":
                            return "£";
                          case "rub":
                            return "₽";
                          case "uah":
                            return "₴";
                          case "try":
                            return "₺";
                          default:
                            return "₺";
                        }
                      };
                      return (
                        <div key={key} className={`flex w-full items-center border-b h-[128px] min-h-[128px] `}>
                          <div className=" border-r h-full px-3 py-2 flex items-center w-[22%] md:w-[43%] md:min-w-[43%]">
                            <RawMaterialListMaterialCard item={item} refetch={refetch} />
                          </div>
                          <div className=" border-r h-full p-4 flex items-center justify-center w-[11%] md:w-[13%] md:min-w-[13%]">
                            <p className="w-[90%] md:w-[100%] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-[#344054] dark:text-[#F5F5F5] font-medium text-center">
                              {item?.type === "lama" ? "Lama" : item?.type === "stick" ? "Çubuk" : item?.type === "pipe" ? "Boru" : "---"}
                            </p>
                          </div>
                          <div className="border-r h-full p-4 flex items-center justify-center w-[15%] md:w-[15%] md:min-w-[15%]">
                            <p className="w-[90%] line-clamp-2 text-sm text-[#344054] dark:text-[#F5F5F5] font-medium text-center">
                              {item?.supplierGroup?.name || "---"}
                            </p>
                          </div>
                          <div className="border-r h-full p-4 flex items-center w-[11%] md:w-[9%] md:min-w-[9%] flex-col justify-center text-center">
                            <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-[#344054] dark:text-[#F5F5F5] font-medium">
                              {currencyIcon()}
                              {item?.currentPrice}
                            </p>
                            <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-xs text-[#667085] dark:text-[#F5F5F5] font-normal">
                              {item?.unit}
                            </p>
                          </div>
                          <div className="h-full border-r justify-center flex text-start items-center w-[35%] md:w-[13.5%] md:min-w-[13.5%] p-3">
                            <p className="w-[95%] line-clamp-4 text-sm text-[#475467] dark:text-[#F5F5F5] font-normal">{item?.description || "---"}</p>
                          </div>
                          <div className="h-full justify-center flex text-start items-center w-[6%] p-3">
                            <span
                              onClick={() => {
                                navigate(`/app/product/mrp/stock/raw-material/detail/${item?.id}`);
                              }}
                              className="flex p-[10px] items-center justify-center cursor-pointer"
                            >
                              <DetailedIcon />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                    <div className="z-50 border-t border-[#D0D5DD] mt-auto bg-white rounded-b-xl">
                      <PaginationComponent pageNo={Number(pageNo) ? Number(pageNo) : 1} setPageNo={changePage} pageCount={pageCount} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
      <CustomModal
        isOpen={isNewRawMaterialModal}
        setIsOpen={setIsNewRawMaterialModal}
        modalTitle={t("product:createNewRawMaterial")}
        width="fit-content"
        children={<NewRawMaterialsModal t={t} refetch={refetch} update={onEdit} isOpen={isNewRawMaterialModal} setIsOpen={setIsNewRawMaterialModal} />}
      />
      <CustomModal
        isOpen={purchaseForm}
        setIsOpen={setPurchaseForm}
        onClose={() => {
          setPurchaseForm(false);
        }}
        modalTitle={t("product:purchaseRequestForm")}
        children={
          <TypeRawPurchaseRequestForm
            setIsOpen={purchaseForm}
            closeModal={() => {
              setPurchaseForm(false);
            }}
            purchaseData={purchase}
          />
        }
      />
    </div>
  );
};

export default RawMaterialList;

const DetailedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_18404_15024)">
        <path
          d="M9.99984 13.3334V10.0001M9.99984 6.66675H10.0082M18.3332 10.0001C18.3332 14.6025 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6025 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001Z"
          stroke="#475467"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18404_15024">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
