import React, { memo, useEffect, useState } from "react";
import { StepNoData } from "../step-navigation/components";
import { useTranslation } from "react-i18next";
import StepsMainSide from "../../components/steps/StepsMainSide";
import { CustomModal } from "../../components";
import { AddProcessModal } from "./index";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import { toast } from "react-toastify";
import { processService } from "../../services/process.service";
import OperationCard from "./components/OperationCard";
import { Loader } from "../../assets/icons/stepsIcons";
import { useStore } from "../../hooks/useStores";
import diacritics from "diacritics";

const Process = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { auth } = useStore();
  const [alertOpen, setAlertOpen] = useState(false);
  const [process, setProcess] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [temporaryProcesses, setTemporaryProcesses] = useState([]);
  const [deleteProcess, setDeleteProcess] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [selectedTab, setSelectedTab] = useState("process");
  const [loading, setLoading] = useState(false);

  const handleData = async () => {
    setLoading(true);
    processService.activeProcesses().then(({ data }) => {
      data.map((d) => {
        return {
          ...d,
          responsibleUser: d.responsibleUser.map((r) => r.id),
        };
      });
      setProcess(data);
      setTemporaryProcesses(data);
      setLoading(false);
    });
  };

  const handleDelete = (id) => {
    setDeleteProcess(id);
    setSelectedItem(id);
    setIsOpen(false);
  };

  const handleEdit = (id) => {
    setSelectedItem(id);
    setIsOpen(true);
  };

  const onDelete = async (id) => {
    await processService.deleteProcess(id).then((res) => {
      setAlertOpen(false);
      toast.success("success", t("addProcess:deletedProcessMessage"));
    });
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    if (searchValue) {
      const filteredProcesses = process.filter((e) => {
        const name = e.name ? diacritics.remove(e.name).toLowerCase() : "";
        return name.includes(searchValue.toLowerCase());
      });
      setTemporaryProcesses(filteredProcesses);
      setSearchValue(searchValue.toLowerCase());
    } else {
      setTemporaryProcesses(process);
    }
  }, [searchValue, process]);

  return (
    <>
      <div className="w-full min-w-full h-full xs:mt-[70px]">
        {process?.length != 0 ? (
          <StepsMainSide
            t={t}
            title={t("product:internalOperation")}
            length={
              temporaryProcesses?.length +
              "/" +
              auth?.user?.company?.limits.operation +
              " " +
              t("product:internalOperation")
            }
            isLimitReached={
              process?.length >= auth?.user?.company?.limits.operation
            }
            limitlabel={t("buttons:buyOperationCredits")}
            addlabel={t("product:addNewInternalOperation")}
            placeholder={t("addProcess:searchPlaceholder")}
            setIsOpen={setIsOpen}
            setSearchVal={setSearchValue}
            onClickSubmitButton={() => {
              setSelectedItem(null);
            }}
            children={
              <>
                {loading ? (
                  <div className="flex items-center mt-[120px] justify-center w-full h-full">
                    <Loader
                      currentColor="currentColor"
                      currentFill="currentFill"
                    />
                  </div>
                ) : (
                  <>
                    {temporaryProcesses?.map((e, i) => {
                      return (
                        <OperationCard
                          key={i}
                          t={t}
                          name={e?.name}
                          icon={e?.iconKey}
                          responsibleUser={e?.responsibleUser.map(
                            (r) => r?.name + " " + r?.lastName + " "
                          )}
                          editOnClick={() => {
                            handleEdit(e);
                          }}
                          deleteOnclick={() => {
                            handleDelete(e?.id);
                            setAlertOpen(true);
                          }}
                        />
                      );
                    })}
                  </>
                )}
              </>
            }
          />
        ) : (
          <StepNoData
            header={t("routes:addProcess")}
            text={t("addProcess:noTitle")}
            onClick={() => setIsOpen(true)}
          />
        )}
      </div>

      <CustomModal
        children={
          <AddProcessModal
            data={process}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedProcess={selectedItem}
            handleData={handleData}
          />
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        
        modalTitle={
          selectedItem
            ? t("addProcess:updateProcess")
            : t("buttons:createNewInternalOparation")
        }
      />

      <AlertModal
        title={t("equipments:deleteEquipment")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={alertOpen}
        setIsOpen={setAlertOpen}
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        deleteProcess={deleteProcess}
        applyButton={async () => {
          await onDelete(deleteProcess);
          await handleData();
          setIsAlert(false);
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default memo(Process);
