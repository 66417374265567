import React, { useEffect, useMemo, useState } from "react";
import { QualityHeader } from "../components";
import moment from "moment";
import QualityMain from "./QualityMain";
import { useStore } from "../../../../hooks/useStores";
import { useNavigate } from "react-router-dom";

const QualityDashboard = () => {
  const { auth } = useStore();
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const navigate = useNavigate();

  const MemoizedQualityMain = useMemo(() => <QualityMain date={date} />, [date]);

  return (
    <div className="flex w-full h-full flex-col">
      <QualityHeader date={date} setDate={setDate} />
      {/* <QualityMain date={date} /> */}
      {MemoizedQualityMain}
    </div>
  );
};

export default QualityDashboard;
