import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MaterialSupplyPlanModalRawCard from "../../../components/junction/MaterialSupplyPlanModalRawCard";
import { Button, Loading } from "../../../components";
import { Toast } from "../../../utils/toastify/toast";
import { purchaseService } from "../../../services/purchase.service";
import { instance } from "../../../libs/client";
import moment from "moment";
import * as yup from "yup";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import EndDatePicker from "../../person/components/EndDatePicker";
import { CalendarIcon } from "../../../assets/icons/PersonIcons";
import TextArea from "../../../components/inputs/TextArea";
import UnitPriceInput from "../../../components/inputs/UnitPriceInput";
import PersonnelAndEquipmentSelector from "../../../components/inputs/PersonnelAndEquipmentSelector";
import QtyInput from "../../../components/inputs/QtyInput";

const MaterialSupplyPlanModal = ({ selectedJunction, setIsOpen, refetch, groupId }) => {
  const { t } = useTranslation();
  const [suppliers, setSuppliers] = useState([]);
  const [date, setDate] = useState(moment(new Date()));
  const [visible, setVisible] = useState(false);
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("USD");

  const handleSupplier = async () => {
    await instance.get(`purchase/list-by-group/${groupId}`).then((res) => {
      setSuppliers(
        res?.data?.map((item) => {
          return { label: item?.name, value: item?.id, image: item?.image };
        })
      );
    });
  };

  const priceOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "RUB", value: "RUB" },
    { label: "UAH", value: "UAH" },
    { label: "TRY", value: "TRY" },
  ];

  const qtyOptions = [
    { label: "Kilogram", value: "kg" },
    { label: t("product:metricTon"), value: "ton" },
    { label: "Gram", value: "gr" },
    { label: "Miligram", value: "mg" },
    { label: "Microgram", value: "mcg" },
    { label: "Pound", value: "lb" },
    { label: t("product:ounce"), value: "oz" },
    { label: "Metre", value: "m" },
    { label: "Millilitre", value: "mm" },
    { label: t("product:gallon"), value: "gal" },
    { label: t("product:squareMetre"), value: "m2" },
    { label: t("product:squareMilimetre"), value: "mm2" },
    { label: t("product:squareInch"), value: "mm2" },
    { label: t("product:pieceModal"), value: "ad" },
    { label: t("product:package"), value: "pkg" },
    { label: t("product:box"), value: "box" },
  ];

  const schema = yup.object({
    supplier: yup.string().required("Tedarikçi seçimi zorunludur"),
    purchaseQuantity: yup.string().required("Satın alınacak miktar zorunludur"),
    description: yup.string().required("Açıklama zorunludur"),
    price: yup.string().required("Birim fiyat zorunludur"),
    currency: yup.string().required("Para birimi zorunludur"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      price: "",
      currency: "USD",
    },
  });

  useEffect(() => {
    handleSupplier();
  }, []);

  const purchaseQuantity = useWatch({
    control,
    name: "purchaseQuantity",
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (value) => {
    if (loading) return;
    setLoading(true);

    if (!date) {
      Toast("error", "Lütfen tahmini teslimat tarihi seçiniz");
      return;
    }

    const data = {
      price: Number(value.price),
      plannedUnit: Number(value?.purchaseQuantity),
      currency: value.currency,
      requestId: selectedJunction?.id,
      estimatedDeliveryDate: moment(date).format("YYYY-MM-DD"),
      description: value?.description,
      supplierId: value?.supplier,
    };

    try {
      const res = await purchaseService.createPlannedPurchaseJunction(data);
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        setIsOpen(false);
        refetch();
      } else {
        Toast("error", res?.data?.message);
      }
    } catch (error) {
      Toast("error", "Bir hata oluştu. Lütfen tekrar deneyin.");
    } finally {
      setLoading(false);
    }
  };

  const units = [
    { shortLabel: "kg", value: "kilogram" },
    { shortLabel: "ton", value: "ton" },
    { shortLabel: "gr", value: "gram" },
    { shortLabel: "mg", value: "miligram" },
    { shortLabel: "mcg", value: "mikrogram" },
    { shortLabel: "lb", value: "libre" },
    { shortLabel: "oz", value: "ons" },
    { shortLabel: "m", value: "metre" },
    { shortLabel: "mm", value: "milimetre" },
    { shortLabel: "gal", value: "galon" },
    { shortLabel: "m2", value: "metrekare" },
    { shortLabel: "mm2", value: "milimetrekare" },
    { shortLabel: "in2", value: "inçkare" },
    { shortLabel: "ad", value: "adet" },
    { shortLabel: "pkg", value: "paket" },
    { shortLabel: "box", value: "kutu" },
  ];

  const getUnitLabel = (unit) => {
    if (unit === undefined) {
      return "--";
    }
    const foundUnit = units.find((u) => u.value === unit);
    return foundUnit ? foundUnit.shortLabel : unit;
  };

  useEffect(() => {
    if (selectedJunction?.status === "active") {
      setPrice(selectedJunction?.unitCost);
      setCurrency(selectedJunction?.currency);
      setValue("price", selectedJunction?.unitCost);
      setValue("currency", selectedJunction?.currency);
      setValue("description", selectedJunction?.description);
      setValue("purchaseQuantity", selectedJunction?.plannedUnit);
      setValue("supplier", selectedJunction?.supplier?.id);
      setDate(selectedJunction?.estimatedDeliveryDate);
    }
  }, [selectedJunction]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-[766px]">
      <div className="flex flex-row items-center mt-5 border-b border-t p-3 w-full bg-[#F9FAFB]">
        <MaterialSupplyPlanModalRawCard item={selectedJunction} isModal={true} />
        <Button iconLeft={<ChevronRightIcon />} size={"sm"} colorType={"tertiary-gray"} />
        <div className="flex flex-row items-center ml-4 rounded-lg p-2 w-[350px]">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <PersonnelAndEquipmentSelector
                value={value}
                items={suppliers}
                icon={"machine"}
                theme={"product"}
                label={t("product:supplier")}
                onChange={onChange}
                onBlur={onBlur}
                validate={errors?.supplier ? "error" : ""}
                errorMessage={errors?.supplier ? errors?.supplier?.message : ""}
              />
            )}
            name="supplier"
          />
        </div>
      </div>
      <div className="flex flex-col items-start gap-y-4 w-full mt-3">
        <div className="flex flex-row gap-x-6 w-full">
          <div className="w-1/2">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <QtyInput
                  value={value}
                  theme={"product"}
                  items={qtyOptions}
                  qtyType={getUnitLabel(
                    selectedJunction?.rawMaterial
                      ? selectedJunction?.rawMaterial?.unitType
                      : selectedJunction?.purchaseRequest?.rawMaterial
                      ? selectedJunction?.purchaseRequest?.rawMaterial?.unitType
                      : t("product:pcs")
                  )}
                  label={t("product:quantityTobePurchased")}
                  onChange={onChange}
                  setValue={setValue}
                  onBlur={onBlur}
                  validate={errors?.purchaseQuantity ? "error" : ""}
                  errorMessage={errors?.purchaseQuantity ? errors?.purchaseQuantity?.message : ""}
                />
              )}
              name="purchaseQuantity"
            />
          </div>
          <div className="flex w-1/2"></div>
        </div>
        <div className="flex flex-row gap-x-6 w-full">
          <div className="w-1/2">
            <UnitPriceInput
              theme={"product"}
              items={priceOptions}
              setValue={(field, value) => {
                if (field === "price") {
                  setPrice(value);
                  setValue("price", value);
                } else {
                  setCurrency(value);
                  setValue("currency", value);
                }
                trigger(["price", "currency"]);
              }}
              label={t("product:unitPriceRawMaterial")}
              price={price}
              currency={currency}
              validate={errors?.price ? "error" : ""}
              errorMessage={errors?.price ? errors?.price?.message : ""}
            />
          </div>
          <div className="flex flex-col gap-2 w-1/2">
            <p className="text-sm font-medium text-secondary-700">{t("product:estimatedDeliveryDate")}</p>
            <EndDatePicker
              date={date}
              visible={visible}
              setDate={(e) => {
                setDate(e);
              }}
              setVisible={setVisible}
              buttonClassName={"flex flex-col items-center"}
              calendarClassName={"absolute top-12 rounded-lg shadow-xl z-[600]"}
            >
              <Button
                onClick={() => {
                  setVisible(!visible);
                }}
                iconLeft={<CalendarIcon />}
                size={"md"}
                colorType={"secondary-gray"}
                label={moment(date).format("DD.MM.YYYY")}
              />
            </EndDatePicker>
          </div>
        </div>
        <div className="flex flex-col gap-y-4 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextArea
                value={value}
                theme={"product"}
                height={80}
                label={t("product:description")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.description ? "error" : ""}
                errorMessage={errors?.description ? errors?.description?.message : ""}
              />
            )}
            name="description"
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-4 bg-[#F9FAFB] w-full border-y mt-6 px6 py-4 min-h-[104px]">
        <div className="flex w-full justify-between items-center">
          <p className="text-[#344054] text-md font-semibold">{t("product:quantityTobePurchased")}</p>
          <p className="text-[#475467] text-md">
            {purchaseQuantity || "0"}{" "}
            {getUnitLabel(
              selectedJunction?.rawMaterial
                ? selectedJunction?.rawMaterial?.unitType
                : selectedJunction?.purchaseRequest?.rawMaterial
                ? selectedJunction?.purchaseRequest?.rawMaterial?.unitType
                : t("product:pcs")
            )}
          </p>
        </div>
        <div className="flex w-full justify-between items-center">
          <p className="text-[#344054] text-md font-semibold">{t("product:totalPrice")}</p>
          <p className="text-[#475467] text-md">
            {purchaseQuantity * price || "0"} {currency}
          </p>
        </div>
      </div>
      <div className="w-[100%] flex items-center gap-x-3 mt-4">
        <Button
          onClick={() => !loading && setIsOpen(false)}
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          size={"md"}
          type={"button"}
          disabled={loading}
        />
        <Button
          type={"submit"}
          label={loading ? <Loading color="#fff" secondary="#fff" size={22} /> : t("buttons:save")}
          colorType={"primary-product"}
          size={"md"}
          disabled={loading}
        />
      </div>
    </form>
  );
};

export default MaterialSupplyPlanModal;

export const ChevronRightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export const UrgencyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M3 8.5H21M3 15.5H21" stroke="#98A2B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
