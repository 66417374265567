import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Button, Input, Loading, UploadProfile } from "../../../components";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextArea from "../../../components/inputs/TextArea";
import PurchaseFinishJunctionCard from "../../../components/new-cards/material-supply/PurchaseFinishJunctionCard";
import MaterialSupplyReceivedCard from "../../../components/new-cards/material-supply/MaterialSupplyReceivedCard";
import { purchaseService } from "../../../services/purchase.service";
import { Toast } from "../../../utils/toastify/toast";
import MaterialSupplyQualityAppliedCard from "../../../components/new-cards/material-supply/MaterialSupplyQualityAppliedCard";

const NewQualityControlForm = ({ refetch, setIsOpen, junctionData }) => {
  const { t } = useTranslation();
  // const [selectedStock, setSelectedStock] = useState([]);
  // const [selectedWip, setSelectedWip] = useState([]);
  const [selectedStock, setSelectedStock] = useState(false);
  const [selectedWip, setSelectedWip] = useState(false);
  const [incomingUnit, setIncomingUnit] = useState(0);
  const [rejectedUnit, setRejectedUnit] = useState(0);
  const [sendToStock, setSendToStock] = useState(0);
  const [sendWip, setSendWip] = useState(0);
  const [selectedCode, setSelectedCode] = useState("");

  const schema = yup.object({
    acceptedPiece: yup.number(),
    rejectedPiece: yup.number().notRequired(),
    sendToStock: yup.number().notRequired(),
    sendToProduction: yup.number().notRequired(),
    qualityDescription: yup.string().notRequired(),
    qualityControlFile: yup.string().notRequired(),
    qualityAcceptedFile: yup.string().notRequired(),
    qualityRejectedFile: yup.string().notRequired(),
    purchaseFile: yup.string().notRequired(),
    secondPurchaseFile: yup.string().notRequired(),
  });

  const wipQualityController = async () => {
    if (junctionData?.purchaseRequest?.productExternalOperation || junctionData?.purchaseRequest?.productInternalOperation) {
      await purchaseService
        .getActiveWipOperation(junctionData?.purchaseRequest?.productExternalOperation?.id || junctionData?.purchaseRequest?.productInternalOperation?.id)
        .then((res) => {
          setSelectedCode(res?.code);
        });
    } else {
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rejectedPiece: 0,
    },
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (value) => {
    if (loading) return;
    setLoading(true);

    const send = {
      rejectedUnit: Number(rejectedUnit) || 0,
      incomingUnit: Number(incomingUnit),
      qualityDescription: value?.qualityDescription,
      qualityAcceptedFile: value?.qualityAcceptedFile,
      qualityRejectedFile: value?.qualityRejectedFile,
      qualityControlFile: value?.qualityControlFile,
      stockUnit: Number(sendToStock) || 0,
      wipUnit: Number(sendWip) || 0,
    };

    try {
      if (junctionData?.purchaseRequest?.productInternalOperation || junctionData?.purchaseRequest?.productExternalOperation) {
        if (incomingUnit - rejectedUnit === Number(sendToStock) + Number(sendWip)) {
          const res = await purchaseService.purchaseQuality(junctionData?.id, send);
          if (res.data.code === 0) {
            setIsOpen(false);
            refetch();
            Toast("success", res?.data?.message);
          } else {
            Toast("error", res?.data?.message);
          }
        } else {
          Toast("error", `Girilen stok adedi ve üretim adedi toplamı en fazla ${incomingUnit - rejectedUnit} olmalıdır.`);
        }
      } else {
        const res = await purchaseService.purchaseQuality(junctionData?.id, send);
        if (res.data.code === 0) {
          setIsOpen(false);
          refetch();
          Toast("success", res?.data?.message);
        } else {
          Toast("error", res?.data?.message);
        }
      }
    } catch (error) {
      Toast("error", "Bir hata oluştu. Lütfen tekrar deneyin.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setIncomingUnit(junctionData?.incomingUnit);
    setRejectedUnit(junctionData?.rejectedUnit);
    setValue("qualityDescription", junctionData?.qualityDescription);
    setValue("qualityControlFile", junctionData?.qualityControlFile);
    setValue("qualityAcceptedFile", junctionData?.qualityAcceptedFile);
    setValue("qualityRejectedFile", junctionData?.qualityRejectedFile);

    setValue("purchaseFile", junctionData?.purchaseFile);
    setValue("secondPurchaseFile", junctionData?.secondPurchaseFile);
  }, [junctionData]);

  useEffect(() => {
    wipQualityController();
  }, [junctionData]);

  const handleStockCheckboxChange = () => {
    setSelectedStock(!selectedStock);
  };

  const handleWipCheckboxChange = () => {
    setSelectedWip(!selectedWip);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full h-full gap-y-8 mt-6">
      <div className="flex gap-x-6 w-full h-full">
        <div className="flex flex-col gap-y-6 min-w-[420px] max-w-[420px] w-[420px]">
          <div className="flex w-full">
            {junctionData?.quality === "applied" ? (
              <MaterialSupplyQualityAppliedCard data={junctionData} isModal={true} />
            ) : (
              <MaterialSupplyReceivedCard data={junctionData} isModal={true} />
            )}
          </div>
          <div className="flex flex-col w-full h-full gap-y-4">
            {/* <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  icon={<QuantityIcon />}
                  theme={"product"}
                  label={t("product:inComingQuantity")}
                  errorMessage={errors?.acceptedPiece?.message}
                  validate={errors?.acceptedPiece ? "error" : ""}
                />
              )}
              name="acceptedPiece"
            /> */}
            <Input
              onChange={({ target }) => {
                setIncomingUnit(target?.value);
              }}
              value={incomingUnit}
              icon={<QuantityIcon />}
              theme={"product"}
              label={t("product:inComingQuantity")}
            />

            <Input
              onChange={({ target }) => {
                setRejectedUnit(target?.value);
              }}
              value={rejectedUnit}
              icon={<QuantityDeclinedIcon />}
              theme={"product"}
              label={t("product:rejectedQuantity")}
            />

            {/* <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  icon={<QuantityDeclinedIcon />}
                  theme={"product"}
                  label={t("product:rejectedQuantity")}
                  errorMessage={errors?.rejectedPiece?.message}
                  validate={errors?.rejectedPiece ? "error" : ""}
                />
              )}
              name="rejectedPiece"
            /> */}
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <UploadProfile title={`${t("product:appendix")} 1`} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
              )}
              name="purchaseFile"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <UploadProfile title={`${t("product:appendix")} 2`} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
              )}
              name="secondPurchaseFile"
            />
          </div>
        </div>
        <div className="flex w-[1px] h-full bg-[#E4E7EC]"></div>
        <div className="flex flex-col gap-y-3 min-w-[420px] max-w-[420px] w-[420px]">
          <div className="flex">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextArea
                  label={t("product:descriptionOptionel")}
                  width={"100%"}
                  onBlur={onBlur}
                  onChange={onChange}
                  theme={"product"}
                  rows={junctionData?.quality === "waiting" ? 5 : 8}
                  value={value}
                />
              )}
              name="qualityDescription"
            />
          </div>
          <div className="flex flex-col gap-y-[18px]">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <UploadProfile title={t("product:controlForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
              )}
              name="qualityControlFile"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <UploadProfile title={t("product:approvalForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
              )}
              name="qualityAcceptedFile"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <UploadProfile title={t("product:rejectionForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
              )}
              name="qualityRejectedFile"
            />
          </div>
          {selectedCode === 0 && (
            <div className="flex flex-row gap-x-2 items-center">
              {/* Stock Section */}
              <div className="flex flex-col gap-y-2 w-full">
                <div className="flex flex-row items-center gap-x-1">
                  <span
                    className={`w-5 h-5 border rounded-md flex justify-center items-center mr-1 ${
                      selectedStock ? "bg-[#DC6803] border-[#DC6803]" : "border-[#D0D5DD]"
                    }`}
                    onClick={handleStockCheckboxChange}
                  >
                    {selectedStock && (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                  </span>
                  <p className="text-sm text-secondary-600 font-medium">Stoka Gönder</p>
                  <div className="w-5 h-5 min-w-5 min-h-5 flex items-center justify-center">
                    <InfoIcon />
                  </div>
                </div>
                {/* <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      icon={<QuantityIcon />}
                      theme={"product"}
                      errorMessage={errors?.sendToStock?.message}
                      validate={errors?.sendToStock ? "error" : ""}
                      disabled={junctionData?.quality === "waiting" ? false : true}
                    />
                  )}
                  name="sendToStock"
                /> */}
                <Input
                  onChange={({ target }) => {
                    setSendToStock(target?.value);
                  }}
                  value={sendToStock}
                  icon={<QuantityIcon />}
                  disabled={selectedStock === true ? false : true}
                  theme={"product"}
                />
              </div>

              {/* WIP Section */}
              <div className="flex flex-col gap-y-2 w-full">
                <div className="flex flex-row items-center gap-x-1">
                  <span
                    className={`w-5 h-5 border rounded-md flex justify-center items-center mr-2 ${
                      selectedWip ? "bg-[#DC6803] border-[#DC6803]" : "border-[#D0D5DD]"
                    }`}
                    onClick={handleWipCheckboxChange}
                  >
                    {selectedWip && (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                  </span>
                  <p className="text-sm text-secondary-600 font-medium">Üretime (WIP) Gönder</p>
                  <div className="w-5 h-5 min-w-5 min-h-5 flex items-center justify-center">
                    <InfoIcon />
                  </div>
                </div>

                <Input
                  onChange={({ target }) => {
                    setSendWip(target?.value);
                  }}
                  value={sendWip}
                  icon={<QuantityIcon />}
                  theme={"product"}
                  disabled={selectedWip === true ? false : true}
                />
                {/* <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      icon={<QuantityIcon />}
                      theme={"product"}
                      disabled={junctionData?.quality === "waiting" ? false : true}
                      errorMessage={errors?.sendToProduction?.message}
                      validate={errors?.sendToProduction ? "error" : ""}
                    />
                  )}
                  name="sendToProduction"
                /> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full gap-x-3 justify-end items-center">
        <span className="flex max-w-[160px] min-w-[160px] w-[160px] max-h-[44px]">
          <Button onClick={() => setIsOpen(false)} colorType={"secondary-gray"} label={t("buttons:stop")} size={"lg"} disabled={loading} />
        </span>
        <span className="flex max-w-[248px] min-w-[248px] w-[248px] max-h-[44px]">
          <Button
            colorType={"primary-product"}
            label={loading ? <Loading color="#fff" secondary="#fff" size={22} /> : t("buttons:approve")}
            size={"lg"}
            type={"submit"}
            disabled={loading}
          />
        </span>
      </div>
    </form>
  );
};

export default NewQualityControlForm;

const QuantityDeclinedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_19622_11535)">
        <path
          d="M6.87435 1.6665H4.16602C2.7853 1.6665 1.66602 2.78579 1.66602 4.1665V15.8332C1.66602 17.2139 2.78531 18.3332 4.16602 18.3332H8.1475M6.87435 1.6665V6.45222C6.87435 6.54928 6.97778 6.61134 7.06343 6.56566L9.99935 4.99984L12.9353 6.56566C13.0209 6.61134 13.1244 6.54928 13.1244 6.45222V1.6665M6.87435 1.6665H13.1244M13.1244 1.6665H15.8327C17.2134 1.6665 18.3327 2.78579 18.3327 4.1665V9.0119M12.2718 12.2351L14.8942 14.8904M17.5287 17.5021L14.8942 14.8904M14.8942 14.8904L17.5287 12.2351M14.8942 14.8904L12.2718 17.5021"
          stroke="#F04438"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_19622_11535">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_19622_11962)">
        <path
          d="M6.87435 1.6665H4.16602C2.7853 1.6665 1.66602 2.78579 1.66602 4.1665V15.8332C1.66602 17.2139 2.7853 18.3332 4.16602 18.3332H15.8327C17.2134 18.3332 18.3327 17.2139 18.3327 15.8332V4.1665C18.3327 2.78579 17.2134 1.6665 15.8327 1.6665H13.1244M6.87435 1.6665V6.45222C6.87435 6.54928 6.97778 6.61134 7.06343 6.56566L9.99935 4.99984L12.9353 6.56566C13.0209 6.61134 13.1244 6.54928 13.1244 6.45222V1.6665M6.87435 1.6665H13.1244"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_19622_11962">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const InfoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_20580_11950)">
        <path
          d="M6.06065 6C6.21739 5.55445 6.52675 5.17874 6.93395 4.93942C7.34116 4.70011 7.81991 4.61263 8.28543 4.69248C8.75096 4.77233 9.17319 5.01435 9.47737 5.37569C9.78154 5.73702 9.94802 6.19435 9.94732 6.66667C9.94732 8 7.94732 8.66667 7.94732 8.66667M8.00065 11.3333H8.00732M14.6673 8C14.6673 11.6819 11.6825 14.6667 8.00065 14.6667C4.31875 14.6667 1.33398 11.6819 1.33398 8C1.33398 4.3181 4.31875 1.33334 8.00065 1.33334C11.6825 1.33334 14.6673 4.3181 14.6673 8Z"
          stroke="#98A2B3"
          stroke-width="1.33"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_20580_11950">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
