import React from "react";
import { NewDotsIcon } from "../../../../components/new-cards/card-icons";
import { Tooltip } from "react-tooltip";
import { NoEquIcon } from "../../../../assets/icons/machineIcons";
import CustomImage from "../../../../components/CustomImage";
import { useTranslation } from "react-i18next";

const ProdCard = ({ item }) => {
  const { t } = useTranslation();
  return (
    <div
      data-tooltip-id={`${item?.id}`}
      data-tooltip-place={"right"}
      data-tooltip-content={`
  ${t("product:productName")} : ${item?.product?.name} 
   -
  ${t("product:stockCode")} : ${item?.product?.stockCode}
  `}
      className="flex bg-white flex-row  w-[324px] min-w-[324px] h-[112px] min-h-[112px] relative group pl-3 pr-3  pt-2 border border-[#D0D5DD] rounded-lg cursor-pointer "
    >
      <div className="flex flex-col w-[93%]">
        <div className="flex flex-row items-center gap-x-3">
          {item?.product?.image ? (
            <div>
              <CustomImage
                borderRadius={2}
                src={item?.product?.image}
                style={{
                  width: 64,
                  height: 48,
                  minWidth: 64,
                  minHeight: 48,
                  borderRadius: 4,
                  borderWidth: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          ) : (
            <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
              <NoEquIcon />
            </div>
          )}
          <div className="flex flex-col items-start">
            <div className="flex flex-row items-center gap-x-1 w-full">
              <p className="font-semibold text-secondary-900 text-base w-[185px] truncate">{item?.product?.name || "Product Name"}</p>
            </div>
            <div className="bg-[#FFFAEB] max-w-[198px] h-6 rounded px-[3px] py-1">
              <p className="text-xs font-medium max-w-[198px] truncate text-[#B54708]">
               {t("product:stock")}:{item?.product?.stockCode || "--"}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center gap-x-2 w-[100%]">
          <div className="flex flex-col h-[42px] w-1/2  mt-1.5 border border-[#E4E7EC] rounded">
            <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
              <p className="text-[10px] font-medium text-secondary-600">{t("Açılan İş Emri")}</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
              <p className="text-xs font-medium text-secondary-700">{"0" || "0"}</p>
            </div>
          </div>
          <div className="flex flex-col h-[42px] w-1/2  mt-1.5 border border-[#E4E7EC] rounded">
            <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
              <p className="text-[10px] font-medium text-secondary-600">{t("Kapatılan İş Emri")}</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
              <p className="text-xs font-medium text-secondary-700">{"0" || "0"}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`absolute right-0 bg-[#F2F4F7] bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
      >
        <p className={`text-xxs font-semibold text-secondary-600 -rotate-90 whitespace-nowrap`}>
          {t("person:product")} ({t("product:pcs")})
        </p>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
      >
        <NewDotsIcon />
      </div>
      <Tooltip className="z-[120]" id={`${item?.id}`} />
    </div>
  );
};

export default ProdCard;
