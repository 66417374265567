import React, { useState } from "react";
import { Button, CustomModal } from "../../../components";
import { FlipBackwardIcon } from "../../../assets/icons/machineIcons";
import { orderService } from "../../../services/order.service";
import { useQuery } from "@tanstack/react-query";
import NoAvatar from "../../../components/avatar/NoAvatar";
import generateFileUrl from "../../../utils/generateFileUrl";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Toast } from "../../../utils/toastify/toast";
import MoveWorkOrderModal from "./MoveWorkOrderModal";
import { DotsHorizontalIcon } from "../../../assets/icons/productIcons";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { useStore } from "../../../hooks/useStores";
import UpdateWorkOrderQuantityModal from "./UpdateWorkOrderQuantityModal";

const WorkOrderModal = ({ detailId, setIsOpenWorkOrderModal, setOpenWipExitModal, refetch, setIsFirstModal, isFirstModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { auth } = useStore();
  const { data } = useQuery({ queryFn: () => orderService.getOrderDetails(detailId), queryKey: ["workOrderModal"] });
  const [moveOrderData, setMoveOrderData] = useState(null);
  const [movePartialData, setMovePartialData] = useState(null);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [updateWorkOrderQuantityModal, setUpdateWorkOrderQuantityModal] = useState(false);
  const [updateWorkOrderData, setUpdateWorkOrderData] = useState(null);

  const toggleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const menuOptions = [
    {
      label: t("product:moveOrder"),
      value: "workOrderMove",
      icon: <ConvertIcon />,
    },
    {
      label: t("product:updateOrderQuantity"),
      value: "updateQuantityWorkOrder",
      icon: <ConvertIcon />,
    },
    {
      label: t("tasks:delete"),
      value: "delete",
      icon: <DeleteIcon />,
    },
  ];

  const tableHeadItems = [t("product:workOrderNo"), t("product:workOrderQuantity"), t("product:quantityProduced"), t("product:remainingOrders"), ""];

  const onDeleted = async (deleteId) => {
    await orderService.deletePartial(deleteId).then((res) => {
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        setIsOpenWorkOrderModal(false);
        refetch();
      } else {
        Toast("error", res?.data?.message);
      }
    });
  };

  return (
    <>
      {!isFirstModal ? (
        <>
          <MoveWorkOrderModal
            productId={data?.data?.product?.id}
            moveOrderData={moveOrderData}
            movePartialData={movePartialData}
            refetch={refetch}
            setIsOpenWorkOrderModal={setIsOpenWorkOrderModal}
            setIsFirstModal={setIsFirstModal}
            isFirstModal={isFirstModal}
          />
        </>
      ) : (
        <div className="flex flex-col pt-4">
          <div className="flex justify-between items-center w-full min-h-[56px] max-h-[56px] h-[56px] bg-[#F9FAFB] border-y border-[#E4E7EC]">
            <div className="flex gap-x-2 items-center pl-1">
              {data?.data?.order?.customer?.image || auth?.user?.company?.image ? (
                <img
                  className="w-[32px] h-[24px] rounded border border-[#0000001A] flex-none"
                  src={generateFileUrl(data?.data?.order?.customer?.image || auth?.user?.company?.image)}
                />
              ) : (
                <div className="w-[32px] h-[24px] min-w-[32px] min-h-[24px] rounded border border-secondary-200 overflow-hidden">
                  <NoAvatar name={data?.data?.order?.customer?.name || auth?.user?.company?.image || "--"} rounded={4} theme={"product"} />
                </div>
              )}
              <p className="text-[#101828] font-semibold text-md truncate max-w-[200px]">
                {data?.data?.order?.customer?.name || auth?.user?.company?.name || "Customer Name"}
              </p>
            </div>
            <div className="flex gap-x-2 items-center">
              <OrderNewIcon />
              <div className="flex gap-x-2 items-center">
                <p className="text-[#475467] text-sm mt-[1px]">{t("product:orderNo")}</p>
                <p className="text-[#B54708] font-semibold text-md">ORD-{data?.data?.order?.systemOrderNo || data?.data?.order?.systemStockOrderNo || "-"}</p>
              </div>
            </div>
            <div className="flex gap-x-2 items-center">
              <CalendarIcon />
              <div className="flex gap-x-2 items-center">
                <p className="text-[#475467] text-sm mt-[1px]">{t("product:orderGeneratedDate")}</p>
                <p className="text-[#B54708] font-semibold text-md">{moment(data?.data?.createdDate).format("DD.MM.YYYY")}</p>
              </div>
            </div>
          </div>
          <div className="flex w-full min-h-[88px] max-h-[88px] h-[88px] gap-x-6 py-5">
            <div className="flex w-[552px] gap-x-2">
              {data?.data?.product?.image ? (
                <img className="w-[64px] h-[48px] rounded border border-[#0000001A] flex-none" src={generateFileUrl(data?.data?.product?.image)} />
              ) : (
                <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden">
                  <NoAvatar name={data?.data?.product?.name || "--"} rounded={4} theme={"product"} />
                </div>
              )}
              <div className="flex flex-col w-0 flex-grow">
                <p className="text-[#101828] text-md font-semibold ml-1 truncate max-w-[350px]">{data?.data?.product?.name || "--"}</p>
                <span className="truncate text-[#B54708] font-medium text-xs px-1 py-[3px] bg-[#FFFAEB] rounded w-fit max-w-[350px]">
                  {t("product:stock")}: {data?.data?.product?.stockCode || "--"}
                </span>
              </div>
            </div>
            <div className="flex gap-x-3">
              <div className="flex flex-col gap-y-1 w-[132px]">
                <p className="text-[#475467] text-sm">{t("product:orderTotal")}</p>
                <p className="text-[#344054] font-semibold text-md">{data?.data?.orderedQuantity || "0"}</p>
              </div>
              <div className="flex flex-col gap-y-1 w-[132px]">
                <p className="text-[#475467] text-sm">{t("product:dueDate")}</p>
                <p className="text-[#344054] font-semibold text-md">{moment(data?.data?.deliveryDate).format("DD.MM.YYYY") || "--.--.----"}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col h-[444px] w-full overflow-y-auto overflow-x-hidden scrollbar-hide">
            <div className="flex max-h-[26px] min-h-[26px] w-full bg-[#F9FAFB] sticky">
              {tableHeadItems.map((item, i) => (
                <div
                  key={i}
                  className={`flex border-y px-3 py-1 border-r border-[#E4E7EC] items-center w-[${
                    i === 0 ? "276px" : i === 1 || i === 2 || i === 3 ? "167px" : "72px"
                  }] min-w-[${i === 0 ? "276px" : i === 1 || i === 2 || i === 3 ? "167px" : "72px"}] max-w-[${
                    i === 0 ? "276px" : i === 1 || i === 2 || i === 3 ? "167px" : "72px"
                  }] last:border-r-0 ${i === 0 ? "justify-start" : "justify-center"}`}
                >
                  <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
                </div>
              ))}
            </div>
            {data?.data?.partial?.map((item, index) => {
              const isDropdownOpen = openDropdownId === item.id; // Check if the current dropdown is open

              return (
                <div className="flex w-full items-center border-b h-[70px] min-h-[70px] ">
                  {/* tablo 1.kolon */}
                  <div className="flex items-center py-3 pr-6 pl-3 border-r h-full w-[276px] min-w-[276px] max-w-[276px] justify-start">
                    <p className="text-[#B54708] font-semibold text-md">
                      {item?.type === "order" ? `WO-${item?.workOrderNo}` : `SWO-${item?.stockWorkOrderNo}`}
                    </p>
                  </div>
                  {/* tablo 2.kolon */}
                  <div className="border-r flex flex-col h-full py-4 items-center justify-center min-w-[167px] max-w-[167px] w-[167px]">
                    <p className="text-[#344054] font-medium text-sm">{item?.desiredQuantity || 0}</p>
                    <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                  </div>
                  {/* tablo 3.kolon */}
                  <div className="border-r flex flex-col h-full py-4 items-center justify-center min-w-[167px] max-w-[167px] w-[167px]">
                    <p className="text-[#344054] font-medium text-sm">{item?.finishedQuantity || 0}</p>
                    <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                  </div>
                  {/* tablo 4.kolon */}
                  <div className="border-r flex flex-col h-full py-4 items-center justify-center min-w-[167px] max-w-[167px] w-[167px]">
                    <p className="text-[#344054] font-medium text-sm">{item?.desiredQuantity - item?.finishedQuantity || 0}</p>
                    <p className="text-[#667085] text-xs">{t("product:pcs")}</p>
                  </div>
                  {/* tablo 5.kolon */}
                  <div className="flex flex-col h-full py-4 items-center justify-center min-w-[72px] max-w-[72px] w-[72px]">
                    <span className="relative">
                      <Button
                        colorType={"tertiary-gray"}
                        size={"sm"}
                        iconLeft={<DotsHorizontalIcon />}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(item.id);
                        }}
                      />
                      {isDropdownOpen && (
                        <div className="absolute flex flex-col min-w-[240px] max-w-[240px] h-fit bg-white border top-[40px] right-[4px] z-[110] rounded-lg py-1">
                          {menuOptions.map((option, optionIndex) => (
                            <>
                              <span className="flex w-full px-[6px]">
                                <button
                                  key={optionIndex}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (option.value === "delete") {
                                      setIsAlert(true);
                                      setDeleteId(item.id);
                                    } else if (option.value === "workOrderMove") {
                                      setMovePartialData(item);
                                      setMoveOrderData(data?.data?.order);
                                      setIsFirstModal(!isFirstModal);
                                    } else if (option.value === "updateQuantityWorkOrder") {
                                      if ((item?.desiredQuantity - item?.finishedQuantity || 0) === 0) {
                                        Toast("error", "İş Emri miktarı tamamlanmıştır. Yeni iş emri oluşturunuz.");
                                      } else {
                                        setUpdateWorkOrderData(item);
                                        setUpdateWorkOrderQuantityModal(true);
                                      }
                                    }
                                    setOpenDropdownId(null);
                                  }}
                                  type="button"
                                  className={`w-full h-[38px] flex items-center px-2.5 rounded-md ${
                                    option?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                                  }`}
                                >
                                  {option.icon}
                                  <p className={`font-medium text-sm ml-1 ${option.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>
                                    {option?.label}
                                  </p>
                                </button>
                              </span>
                              {optionIndex === 0 ? (
                                <span className="flex w-full py-1">
                                  <hr className="w-full border-[#EAECF0]" />
                                </span>
                              ) : null}
                            </>
                          ))}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex pt-6 gap-x-3 w-full border-t border-[#E4E7EC]">
            <Button
              colorType={"secondary-gray"}
              size={"lg"}
              label={t("product:transferFrom")}
              iconLeft={<FlipBackwardIcon />}
              onClick={() => {
                setIsOpenWorkOrderModal(false);
                setOpenWipExitModal(true);
              }}
            />
            <Button
              colorType={"secondary-gray"}
              size={"lg"}
              label={t("product:sendToProductionWip")}
              iconLeft={<FlipForwardIcon />}
              onClick={() => navigate(`/app/product/mrp/orders/management/planning/${detailId}`)}
            />
          </div>
        </div>
      )}
      <AlertModal
        title={t("tasks:delete")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={updateWorkOrderQuantityModal}
        setIsOpen={setUpdateWorkOrderQuantityModal}
        modalTitle={t("product:updateOrderQuantityHeader")}
        subTitle={t("product:newQuantityTitle")}
        width={512}
        height={404}
        children={<UpdateWorkOrderQuantityModal updateWorkOrderData={updateWorkOrderData} setUpdateWorkOrderQuantityModal={setUpdateWorkOrderQuantityModal} />}
      />
    </>
  );
};

export default WorkOrderModal;

const OrderNewIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M17.1673 8.75002V5.66669C17.1673 4.26656 17.1673 3.56649 16.8948 3.03171C16.6552 2.56131 16.2727 2.17885 15.8023 1.93917C15.2675 1.66669 14.5674 1.66669 13.1673 1.66669H7.83398C6.43385 1.66669 5.73379 1.66669 5.19901 1.93917C4.7286 2.17885 4.34615 2.56131 4.10647 3.03171C3.83398 3.56649 3.83398 4.26656 3.83398 5.66669V14.3334C3.83398 15.7335 3.83398 16.4336 4.10647 16.9683C4.34615 17.4387 4.7286 17.8212 5.19901 18.0609C5.73379 18.3334 6.43385 18.3334 7.83398 18.3334M10.5007 7.50002H7.16732M12.1673 5.00002H7.16732M8.83398 10H7.16732M10.3377 12.6321L13.3562 10.9454C13.4984 10.866 13.5694 10.8263 13.6447 10.8107C13.7114 10.7969 13.7801 10.7968 13.8468 10.8105C13.9221 10.826 13.9933 10.8656 14.1355 10.9448L17.1673 12.6321M10.3377 12.6321L13.7457 14.5311M10.3377 12.6321V15.9548C10.3377 16.1255 10.3377 16.2108 10.3628 16.287C10.385 16.3543 10.4212 16.4162 10.4692 16.4685C10.5234 16.5276 10.5978 16.5693 10.7466 16.6527L13.7457 18.3334M17.1673 12.6321L13.7457 14.5311M17.1673 12.6321V15.9539C17.1673 16.1249 17.1673 16.2104 17.1422 16.2866C17.1199 16.3541 17.0835 16.4161 17.0355 16.4684C16.9811 16.5275 16.9065 16.5692 16.7572 16.6525L13.7457 18.3334M13.7457 14.5311V18.3334"
        stroke="#475467"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5 8.33335H2.5M13.3333 1.66669V5.00002M6.66667 1.66669V5.00002M6.5 18.3334H13.5C14.9001 18.3334 15.6002 18.3334 16.135 18.0609C16.6054 17.8212 16.9878 17.4387 17.2275 16.9683C17.5 16.4335 17.5 15.7335 17.5 14.3334V7.33335C17.5 5.93322 17.5 5.23316 17.2275 4.69838C16.9878 4.22797 16.6054 3.84552 16.135 3.60584C15.6002 3.33335 14.9001 3.33335 13.5 3.33335H6.5C5.09987 3.33335 4.3998 3.33335 3.86502 3.60584C3.39462 3.84552 3.01217 4.22797 2.77248 4.69838C2.5 5.23316 2.5 5.93322 2.5 7.33335V14.3334C2.5 15.7335 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8212 3.86502 18.0609C4.3998 18.3334 5.09987 18.3334 6.5 18.3334Z"
        stroke="#475467"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const FlipForwardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M18 7.5H6.75C4.67893 7.5 3 9.17893 3 11.25C3 13.3211 4.67893 15 6.75 15H10.5M18 7.5L14.6667 4.16667M18 7.5L14.6667 10.8333"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ConvertIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14 6.00004H5C3.34315 6.00004 2 7.34319 2 9.00004C2 10.6569 3.34315 12 5 12H8M14 6.00004L11.3333 3.33337M14 6.00004L11.3333 8.66671"
        stroke="#667085"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const DeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M10.6667 3.99998V3.46665C10.6667 2.71991 10.6667 2.34654 10.5213 2.06133C10.3935 1.81044 10.1895 1.60647 9.93865 1.47864C9.65344 1.33331 9.28007 1.33331 8.53333 1.33331H7.46667C6.71993 1.33331 6.34656 1.33331 6.06135 1.47864C5.81046 1.60647 5.60649 1.81044 5.47866 2.06133C5.33333 2.34654 5.33333 2.71991 5.33333 3.46665V3.99998M6.66667 7.66665V11M9.33333 7.66665V11M2 3.99998H14M12.6667 3.99998V11.4666C12.6667 12.5868 12.6667 13.1468 12.4487 13.5746C12.2569 13.951 11.951 14.2569 11.5746 14.4487C11.1468 14.6666 10.5868 14.6666 9.46667 14.6666H6.53333C5.41323 14.6666 4.85318 14.6666 4.42535 14.4487C4.04903 14.2569 3.74307 13.951 3.55132 13.5746C3.33333 13.1468 3.33333 12.5868 3.33333 11.4666V3.99998"
        stroke="#F04438"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
