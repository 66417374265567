import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CustomModal, Input, UploadProfile } from "../../components";
import { ArrowLeftIcon, FlexProductPlusIcon, PlusIcon } from "../../assets/icons/productIcons";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Badgets from "../../components/buttons/Badgets";
import { arrayMoveImmutable } from "array-move";
import { Toast } from "../../utils/toastify/toast";
import FlexProductSelectRawMaterialModal from "./modals/FlexProductSelectRawMaterialModal";
import { flexProductService } from "../../services/flex-product.service";
import _ from "lodash";
import CreateReceipeModal from "./modals/CreateReceipeModal";
import FlexProductRawMaterialCard from "./FlexProductRawMaterialCard";
import { processService } from "../../services";
import { externalOperationService } from "../../services/external-operation.service";
import CropperPdf from "../../components/inputs/CropperPdf";
import generateFileUrl from "../../utils/generateFileUrl";
import Preview from "../../components/modal/Preview";
import { instance } from "../../libs/client";

const SortableItem = SortableElement(
  ({
    value,
    setIsOpenAddOperationModal,
    number,
    setItems,
    oldItems,
    setStepOneData,
    setStepTwoData,
    setStepThreeData,
    externalProcess,
    internalProcess,
    t,
  }) => {
    const menuRef = useRef();

    const operationTypes = (type) => {
      switch (type) {
        case "conversion_operation":
          return "DÖN";
        case "supporter_operation":
          return "YAR";
        case "assembly_operation":
          return "MON";
        case "decomposition_operation":
          return "AYR";
        default:
          return "-";
      }
    };

    const deleteReceipeOperation = async (value) => {
      const formatToObj = oldItems?.flat();

      const values = formatToObj.filter((item) => item?.step !== value[0]?.step);

      let groupedOperations = _.groupBy(values, "step");
      let operationsNew = Object.keys(groupedOperations).map((key) => {
        return groupedOperations[key];
      });
      setItems(operationsNew);
    };

    const getOperations = async (type, processId, operations) => {
      if (type == "internal") {
        const filtered = internalProcess.filter((item) => item.value == processId);
        const filteredStations = filtered[0]?.stations;

        const mergeData = filteredStations?.filter((item) => operations?.some((op) => op.id === item.id));

        const data = mergeData?.map((item) => {
          const operation = operations.find((op) => op.id === item.id);
          return {
            ...item,
            ...operation,
          };
        });

        return data;
      } else {
        const filtered = externalProcess.filter((item) => item.value == processId);

        const filteredStations = filtered[0]?.supplier;

        const mergeData = filteredStations?.filter((item) => operations?.some((op) => op.id === item.id));

        const data = mergeData?.map((item) => {
          const operation = operations.find((op) => op.id === item.id);
          return {
            ...item,
            ...operation,
          };
        });

        return data;
      }
    };

    return (
      <div ref={menuRef} className="select-none">
        <div className="flex flex-col w-[300px] ">
          <div className="flex flex-row items-center w-full pb-2">
            <Badgets colorType={"fill-warning"} size={"sm"} label={number + ".Op"} />
            <div className="items-center gap-x-1 ml-auto hidden group-hover:flex">
              <Button
                colorType={"tertiary-gray"}
                iconLeft={<RecipeEdit />}
                onClick={async () => {
                  setIsOpenAddOperationModal(true);

                  setStepOneData({
                    id: value[0]?.id,
                    operationType: value[0]?.type,
                    operation: value[0]?.internalOperation?.id || value[0]?.externalOperation?.id || value[0]?.process?.id,
                    enabled: value[0]?.hasQualityControl,
                    qualityPercent: value[0]?.qualityPercent || value[0]?.qualityControlPercent,
                    description: value[0]?.description,
                    step: value[0]?.step,

                    operations: {
                      value: value[0]?.internalOperation?.id || value[0]?.externalOperation?.id || value[0]?.process?.id,
                      label: value[0]?.internalOperation?.name || value[0]?.externalOperation?.name || value[0]?.processName,
                      stations: await getOperations(
                        value[0]?.type,
                        value[0]?.internalOperation?.id || value[0]?.externalOperation?.id || value[0]?.process?.id,
                        value[0]?.plannableNodes || value[0]?.plannableSupplier
                      ),
                      supplier: await getOperations(
                        value[0]?.type,
                        value[0]?.internalOperation?.id || value[0]?.externalOperation?.id || value[0]?.process?.id,
                        value[0]?.plannableNodes || value[0]?.plannableSupplier
                      ),
                    },
                  });
                  setStepTwoData({
                    id: value[1]?.id,
                    operationType: value[1]?.type,
                    operation: value[1]?.internalOperation?.id || value[1]?.externalOperation?.id || value[1]?.process?.id,
                    enabled: value[1]?.hasQualityControl,
                    qualityPercent: value[1]?.qualityPercent || value[1]?.qualityControlPercent,
                    description: value[1]?.description,
                    step: value[0]?.step,

                    operations: {
                      value: value[1]?.internalOperation?.id || value[1]?.externalOperation?.id || value[1]?.process?.id,
                      label: value[1]?.internalOperation?.name || value[1]?.externalOperation?.name || value[1]?.processName,
                      stations: await getOperations(
                        value[1]?.type,
                        value[1]?.internalOperation?.id || value[1]?.externalOperation?.id || value[1]?.process?.id,
                        value[1]?.plannableNodes || value[1]?.plannableSupplier
                      ),
                      supplier: await getOperations(
                        value[0]?.type,
                        value[0]?.internalOperation?.id || value[0]?.externalOperation?.id || value[0]?.process?.id,
                        value[0]?.plannableNodes || value[0]?.plannableSupplier
                      ),
                    },
                  });
                  setStepThreeData({
                    id: value[2]?.id,
                    operationType: value[2]?.type,
                    operation: value[2]?.internalOperation?.id || value[2]?.externalOperation?.id || value[2]?.process?.id,
                    enabled: value[2]?.hasQualityControl,
                    qualityPercent: value[2]?.qualityPercent || value[2]?.qualityControlPercent,
                    description: value[2]?.description,
                    step: value[0]?.step,

                    operations: {
                      value: value[2]?.internalOperation?.id || value[2]?.externalOperation?.id || value[2]?.process?.id,
                      label: value[2]?.internalOperation?.name || value[2]?.externalOperation?.name || value[2]?.processName,
                      stations: await getOperations(
                        value[2]?.type,
                        value[2]?.internalOperation?.id || value[2]?.externalOperation?.id || value[2]?.process?.id,
                        value[2]?.plannableNodes || value[2]?.plannableSupplier
                      ),
                      supplier: await getOperations(
                        value[0]?.type,
                        value[0]?.internalOperation?.id || value[0]?.externalOperation?.id || value[0]?.process?.id,
                        value[0]?.plannableNodes || value[0]?.plannableSupplier
                      ),
                    },
                  });
                }}
              />
              <Button
                colorType={"tertiary-gray"}
                iconLeft={<RecipeClose />}
                onClick={() => {
                  deleteReceipeOperation(value);
                }}
              />
            </div>
          </div>
          <div className={`flex w-[310px] h-[112px] flex-col border items-start cursor-grap  rounded-lg border-secondary-300  bg-white relative group`}>
            {value?.length == 1 ? (
              <>
                {value?.map((b) => {
                  return (
                    <div className="flex flex-row items-center w-[100%]  justify-between pr-1 py-2 pl-3">
                      <div className="flex flex-row items-center gap-x-0.5 h-5 ">
                        {b?.type === "external" ? (
                          <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                            <p className="text-sm font-semibold text-secondary-700">{"HİZ"}</p>
                          </div>
                        ) : (
                          <div className="px-1 ml-1 border h-full w-fit rounded bg-[#F2F4F7]">
                            <p className="text-sm font-semibold text-secondary-700">{b?.processType ? operationTypes(b?.processType) : "-"}</p>
                          </div>
                        )}
                        <div className=" bg-white max-w-[230px] ml-1 rounded-r-[8px]">
                          <p className="font-semibold text-sm text-secondary-700  truncate">
                            {b?.internalOperation?.name || b?.externalOperation?.name || b?.processName}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="flex flex-row items-center gap-x-2 pr-1 py-1.5 pl-3 w-[290px] min-w-[290px] max-w-[290px] ">
                {value?.map((a, index) => {
                  return (
                    <div className={`flex border items-center w-1/3 h-6 gap-x-1 flex-row rounded`}>
                      <div className="px-1 bg-[#F2F4F7] w-fit border-r h-full flex items-center">
                        {a?.type === "external" ? (
                          <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
                        ) : (
                          <p className="text-xs font-semibold text-secondary-700">{a?.processType ? operationTypes(a?.processType) : "-"}</p>
                        )}
                      </div>
                      <div className="bg-white truncate px-1">
                        <p className="font-semibold text-xs text-secondary-700  truncate">
                          {a?.internalOperation?.name || a?.externalOperation?.name || a?.processName}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="flex flex-row items-start w-full border-b border-t bg-[#F9FAFB]">
              <div className="px-3 py-[3px] w-full">
                <p className="text-xs font-medium text-secondary-700">{t("product:cycleTimeStock")}</p>
              </div>
            </div>

            <div className="flex flex-col items-center px-2 pt-1 w-full">
              <div className="flex flex-row items-center w-full justify-between">
                <p className="text-xs font-normal text-secondary-500">{t("product:target")}</p>
                <p className="text-sm max-w-[220px] truncate font-medium text-secondary-600">
                  {value &&
                    value.length > 0 &&
                    (value
                      ?.reduce((sum, item) => {
                        const itemTotal = item?.avgEstimatedTime == null ? 0 : Number(item?.avgEstimatedTime)?.toFixed(0);
                        return sum + itemTotal / value?.length;
                      }, 0)
                      .toFixed(2) ||
                      "---")}
                </p>
              </div>
              <div className="flex flex-row items-center w-full justify-between">
                <p className="text-xs font-normal text-secondary-500">uygulanan</p>
                <p className="text-sm font-medium text-[#079455]">
                  {value && value.length > 0
                    ? value?.reduce((sum, item) => {
                        const itemTotal = item?.estimatedTime == null ? 0 : Number(item?.estimatedTime)?.toFixed(0);
                        return sum + itemTotal / value?.length;
                      }, 0)
                    : "---"}
                </p>
              </div>
            </div>
          </div>
          <div className="w-[30px] h-px bg-gray-300 absolute -right-[0px] bottom-10" />
        </div>
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({ setItems, items, setIsOpenAddOperationModal, setStepOneData, setStepTwoData, setStepThreeData, internalProcess, externalProcess, t }) => {
    return (
      <div className="w-full flex flex-row gap-y-8 h-full">
        {items
          ?.sort((a, b) => {
            return a[0]?.step - b[0]?.step;
          })
          ?.map((value, index) => {
            return (
              <div className="flex flex-col relative ">
                <div className="relative group w-[340px] flex">
                  <SortableItem
                    key={String(index)}
                    index={index}
                    value={value}
                    number={value[0]?.step}
                    setIsOpenAddOperationModal={setIsOpenAddOperationModal}
                    setItems={setItems}
                    oldItems={items}
                    setStepOneData={setStepOneData}
                    setStepTwoData={setStepTwoData}
                    setStepThreeData={setStepThreeData}
                    externalProcess={externalProcess}
                    internalProcess={internalProcess}
                    t={t}
                  />
                </div>
              </div>
            );
          })}
        <div
          className="h-[80px] flex my-auto"
          onClick={() => {
            setIsOpenAddOperationModal(true);
          }}
        >
          <div className="w-[300px] h-[80px] border border-gray-400 rounded-lg flex items-center justify-center cursor-pointer shadow border-dotted">
            <PlusIconNew />
          </div>
        </div>
      </div>
    );
  }
);

const NewFlexProduct = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { productId } = useParams();
  const [items, setItems] = useState([]);
  const [isOpenAddOperationModal, setIsOpenAddOperationModal] = useState(false);
  const [isOpenRecipeInfo, setIsOpenRecipeInfo] = useState(false);
  const [selectRawCardModal, setSelectRawCardModal] = useState(false);
  const [selectedRawMaterial, setSelectedRawMaterial] = useState(null);
  const [isOpenModalTab, setIsOpenModalTab] = useState(false);
  const [internalProcess, setInternalProcess] = useState([]);
  const [externalProcess, setExternalProcess] = useState([]);
  const [croppedImage, setCroppedImage] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [isCrop, setIsCrop] = useState(false);
  const [pdfDataCDN, setPdfDataCDN] = useState("");
  const [cropData, setCropData] = useState("");
  const [croppedDataCDN, setCroppedDataCDN] = useState("");

  const defaultStepData = {
    type: "",
    processId: "",
    processName: "Yeni Operasyon Ekle",
    hasQaulityControl: false,
    qualityPercent: "",
    description: "",
    step: "",
    estimatedTime: "",
    estimatedSettingsTime: "",
    costOfMinute: "",
    currency: "",
    process: {
      id: "",
    },
    plannableSupliers: [],
    plannableNodes: [],
  };

  const [stepOneData, setStepOneData] = useState(defaultStepData);
  const [stepTwoData, setStepTwoData] = useState(defaultStepData);
  const [stepThreeData, setStepThreeData] = useState(defaultStepData);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const Items = arrayMoveImmutable(items, oldIndex, newIndex);

    const updatedItems = Items.map((group, groupIndex) => {
      return group.map((item) => ({
        ...item,
        step: groupIndex + 1,
      }));
    });

    setItems(updatedItems);
  };

  const schema = yup.object({
    productPhoto: yup.string().notRequired(),
    productName: yup.string().required(`${t("validation:empty")}`),
    stockCode: yup.string().required(`${t("validation:empty")}`),
    technicalPhoto: yup.string().notRequired(),
    rawMaterialAmount: yup.number().required(`${t("validation:empty")}`),
    description: yup.string().required(`${t("validation:empty")}`),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getFlexProductDetail = async () => {
    const { data } = await flexProductService.getFlexProductById(productId);

    if (data) {
      setValue("productName", data?.name);
      setValue("stockCode", data?.stockCode);
      setValue("productPhoto", data?.image);
      setValue("technicalPhoto", data?.technicalDrawing);
      setValue("rawMaterialAmount", data?.quantityUsedPerPiece);
      setValue("description", data?.description);
      setSelectedRawMaterial(data?.flexRawMaterial);
      if (data?.technicalDrawingCrop) {
        setCroppedImage(generateFileUrl(data?.technicalDrawingCrop));
      } else {
        setCroppedImage(null);
      }

      const groupedOperations = _.groupBy(data?.operations, "step");
      const operationsNew = Object.keys(groupedOperations).map((key) => {
        return groupedOperations[key];
      });

      setItems(operationsNew);

      if (data.operations.length > 0) {
        setIsOpenRecipeInfo(true);
      }
    } else {
    }
  };

  useEffect(() => {
    productId != undefined && getFlexProductDetail();
  }, []);

  const onSubmit = async (value) => {
    const sendCreate = {
      image: value?.productPhoto,
      name: value?.productName,
      stockCode: value?.stockCode,
      technicalDrawing: value?.technicalPhoto,
      materialId: selectedRawMaterial?.id,
      operations: items?.flat(),
      quantityUsedPerPiece: value?.rawMaterialAmount,
      description: value?.description,
      technicalDrawing: pdfDataCDN,
      technicalDrawingCrop: croppedDataCDN,
    };
    const sendUpdate = {
      id: productId,
      image: value?.productPhoto,
      name: value?.productName,
      stockCode: value?.stockCode,
      technicalDrawing: value?.technicalPhoto,
      materialId: selectedRawMaterial?.id,
      operations: items?.flat(),
      quantityUsedPerPiece: value?.rawMaterialAmount,
      description: value?.description,
      technicalDrawing: pdfDataCDN,
      technicalDrawingCrop: croppedDataCDN,
    };
    productId != undefined
      ? await flexProductService.updateFlexProduct(productId, sendUpdate).then((res) => {
          if (res?.data?.code == 0) {
            Toast("success", res?.data?.message);
            navigate(-1);
          } else {
            Toast("error", res?.data?.message);
          }
        })
      : await flexProductService.createFlexProduct(sendCreate).then((res) => {
          if (res?.data?.code == 0) {
            Toast("success", res?.data?.message);
            navigate(-1);
          } else {
            Toast("error", res?.data?.message);
          }
        });
  };

  const handleInternalProcessUpdate = async () => {
    let datas = [];
    await processService.activeProcessesWithStations().then((res) => {
      const newTabsData = res?.data.map((item) => ({
        stations: item.stations,
        supplier: item.supplier,
        label: item.name,
        value: item.id,
        operationType: item.type,
      }));
      datas = newTabsData || [];
    });

    return await setInternalProcess(datas);
  };

  const handleExternalProcessUpdate = async (index) => {
    let datas = [];

    await externalOperationService.listOperation().then((res) => {
      const newTabsData = res?.data.map((item) => ({
        stations: item.stations,
        supplier: item.supplier,
        label: item.name,
        value: item.id,
      }));
      datas = newTabsData || [];
    });

    return await setExternalProcess(datas);
  };
  useEffect(() => {
    handleInternalProcessUpdate();
    handleExternalProcessUpdate();
  }, []);

  const uploadBase64 = async (file) => {
    const data = { imageBinary: file };
    const response = await instance({
      method: "post",
      url: "file/upload/base64",
      data: { data },
    });
    setPdfDataCDN(response.data?.file);
  };
  const uploadBase64Cropped = async (file) => {
    const data = { imageBinary: file };
    const response = await instance({
      method: "post",
      url: "file/upload/base64",
      data: { data },
    });
    setCroppedDataCDN(response?.data?.file);
  };
  useEffect(() => {
    pdfData && uploadBase64(pdfData);
    cropData && uploadBase64Cropped(cropData);
  }, [pdfData, cropData]);

  // useEffect(() => {
  //   if (!isOpenAddOperationModal) {
  //     setStepOneData(defaultStepData);
  //     setStepTwoData(defaultStepData);
  //     setStepThreeData(defaultStepData);
  //   }
  // }, [isOpenAddOperationModal]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full h-full gap-y-8 overflow-y-auto overflow-x-hidden scrollbar-hide">
        <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
          <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
          <p className="text-[#101828] font-semibold text-2xl">{t("product:createNewFlexible")}</p>
        </div>

        <div className="flex flex-col gap-y-6 w-full">
          <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] pb-5 justify-between border-b border-[#E4E7EC]">
            <div className="flex flex-col gap-y-1">
              <p className="text-[#101828] font-semibold text-lg">{t("product:productInformation")}</p>
              <p className="text-[#344054] font-normal text-sm">{t("product:generalInformationProduct")} </p>
            </div>
            <div className="flex gap-x-3">
              <span className="flex min-w-[96px] max-w-[96px] w-[96px]">
                <Button onClick={() => navigate(-1)} size={"md"} colorType={"secondary-gray"} label={t("buttons:stop")} />
              </span>
              <span className="flex min-w-[96px] max-w-[96px] w-[96px]">
                <Button size={"md"} colorType={"primary-product"} label={productId != undefined ? t("buttons:update") : t("buttons:create")} type={"submit"} />
              </span>
            </div>
          </div>
          <div className="flex flex-col w-full gap-y-5">
            <div className="flex w-full gap-x-8 items-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:productImages")}</p>
              </div>
              <div className="flex w-[75%] gap-x-[72px]">
                <div className="flex">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <UploadProfile
                        title={t("product:productImages")}
                        subTitle={t("fileInput:updatePicture")}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      />
                    )}
                    name="productPhoto"
                  />
                </div>
                <div className="flex">
                  <CropperPdf pdfData={croppedImage} setPdfData={setPdfData} setIsCrop={setIsCrop} pdfLink={generateFileUrl(pdfDataCDN)} />
                </div>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:productName")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        theme={"product"}
                        errorMessage={errors?.productName?.message}
                        validate={errors?.productName ? "error" : ""}
                      />
                    )}
                    name="productName"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:stockCode")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        theme={"product"}
                        errorMessage={errors?.stockCode?.message}
                        validate={errors?.stockCode ? "error" : ""}
                      />
                    )}
                    name="stockCode"
                  />
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:rawMaterial")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[70%] gap-x-8">
                  {selectedRawMaterial ? (
                    <div
                      onClick={() => {
                        setSelectRawCardModal(true);
                      }}
                      className="flex w-[324px] min-h-[90px] max-h-[90px] h-[90px] items-center cursor-pointer"
                    >
                      <FlexProductRawMaterialCard item={selectedRawMaterial} />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setSelectRawCardModal(true);
                      }}
                      className="flex w-[324px] min-h-[90px] max-h-[90px] h-[90px] border border-[#D0D5DD] border-dashed shadow-xs rounded-lg px-3 py-2 items-center justify-center cursor-pointer"
                    >
                      <FlexProductPlusIcon />
                    </div>
                  )}
                  <div className="flex w-[50%] ml-4">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("product:amountTobeUsed")}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          type={"number"}
                          theme={"product"}
                          errorMessage={errors?.rawMaterialAmount?.message}
                          validate={errors?.rawMaterialAmount ? "error" : ""}
                          step={"any"}
                        />
                      )}
                      name="rawMaterialAmount"
                    />
                  </div>
                </span>
              </div>
            </div>
            <div className="flex w-full min-h-[1px] max-h-[1px] h-[1px] bg-[#E4E7EC]" />
            <div className="flex w-full gap-x-8 items-start justify-start">
              <div className="flex w-[25%]">
                <p className="text-[#344054] font-medium text-sm">{t("product:description")}</p>
              </div>
              <div className="flex w-[75%]">
                <span className="flex w-[60%]">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        theme={"product"}
                        errorMessage={errors?.description?.message}
                        validate={errors?.description ? "error" : ""}
                      />
                    )}
                    name="description"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        {!isOpenRecipeInfo && (
          <div className="flex w-full items-center justify-start">
            <span className="flex max-w-[240px] min-w-[240px] w-[240px]">
              <Button
                colorType={"secondary-product"}
                label={t("product:enterRecipeInfor")}
                iconLeft={<PlusIcon />}
                onClick={() => {
                  setIsOpenRecipeInfo(true);
                }}
              />
            </span>
          </div>
        )}
        {isOpenRecipeInfo && (
          <div className="flex flex-col w-full min-h-[200px] max-h-[200px] h-[200px] gap-y-5">
            <div className="flex w-full justify-between items-start pb-5 border-b border-[#E4E7EC]">
              <div className="flex flex-col gap-y-1">
                <p className="text-[#101828] font-semibold text-lg">{t("product:receipe")}</p>
                <p className="text-[#344054] font-normal text-sm">{t("product:enterTheProduction")}</p>
              </div>
              <span className="flex">
                <Button
                  colorType={"secondary-product"}
                  label={t("product:removeAll")}
                  size={"sm"}
                  onClick={() => {
                    setItems([]);
                    setIsOpenRecipeInfo(false);
                  }}
                />
              </span>
            </div>

            <div className="flex min-h-[180px] max-h-[180px] h-[180px] w-full overflow-x-auto overflow-y-hidden scrollbar-hide">
              <SortableList
                axis="xy"
                items={items}
                onSortEnd={onSortEnd}
                distance={1}
                hasOrders={false}
                setIsOpenAddOperationModal={setIsOpenAddOperationModal}
                setItems={setItems}
                setStepOneData={setStepOneData}
                setStepTwoData={setStepTwoData}
                setStepThreeData={setStepThreeData}
                internalProcess={internalProcess}
                externalProcess={externalProcess}
                t={t}
              />
            </div>
          </div>
        )}
      </form>
      <CustomModal
        onClose={() => {
          setStepOneData(defaultStepData);
          setStepTwoData(defaultStepData);
          setStepThreeData(defaultStepData);
          setIsOpenAddOperationModal(false);
        }}
        width={900}
        isOpen={isOpenAddOperationModal}
        setIsOpen={setIsOpenAddOperationModal}
        modalTitle={t("product:newRecipeStep")}
        titleButton={false}
        buttonColorType={"tertiary-error"}
        buttonIcon={<PlusIcon />}
        buttonSize={"xl"}
        buttonOnClick={() => setIsOpenModalTab(true)}
        buttonClassName={"pt-0 pb-0 hover:bg-[#FFF]"}
        buttonLabel={t("product:createMultiple")}
        children={
          <CreateReceipeModal
            items={items}
            setItems={setItems}
            stepOneData={stepOneData}
            setStepOneData={setStepOneData}
            setStepTwoData={setStepTwoData}
            stepTwoData={stepTwoData}
            setStepThreeData={setStepThreeData}
            stepThreeData={stepThreeData}
            defaultStepData={defaultStepData}
            onClose={() => {
              setStepOneData(defaultStepData);
              setStepTwoData(defaultStepData);
              setStepThreeData(defaultStepData);
              setIsOpenAddOperationModal(false);
            }}
            setIsOpenModalTab={setIsOpenModalTab}
            isOpenModalTab={isOpenModalTab}
            prodType="flex"
          />
        }
      />
      <CustomModal
        isOpen={isCrop}
        setIsOpen={setIsCrop}
        modalTitle={t("product:pdfCropper")}
        children={<Preview file={pdfData} setCropData={setCropData} setIsCrop={setIsCrop} croppedImage={croppedImage} setCroppedImage={setCroppedImage} />}
      />
      <CustomModal
        isOpen={selectRawCardModal}
        setIsOpen={setSelectRawCardModal}
        modalTitle={t("product:addRawMaterial")}
        subTitle={t("product:addTheNecessary")}
        children={
          <FlexProductSelectRawMaterialModal
            setSelectedRawMaterial={setSelectedRawMaterial}
            selectedRawMaterial={selectedRawMaterial}
            setSelectRawCardModal={setSelectRawCardModal}
          />
        }
      />
    </>
  );
};

export default NewFlexProduct;

const PlusIconNew = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path d="M24 10V38M10 24H38" stroke="#98A2B3" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const RecipeEdit = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.39662 15.0965C2.43491 14.752 2.45405 14.5797 2.50618 14.4186C2.55243 14.2758 2.61778 14.1398 2.70045 14.0144C2.79363 13.8731 2.91621 13.7506 3.16136 13.5054L14.1666 2.50017C15.0871 1.5797 16.5795 1.5797 17.4999 2.50017C18.4204 3.42065 18.4204 4.91303 17.4999 5.83351L6.49469 16.8387C6.24954 17.0839 6.12696 17.2065 5.98566 17.2996C5.86029 17.3823 5.72433 17.4477 5.58146 17.4939C5.42042 17.546 5.24813 17.5652 4.90356 17.6035L2.08325 17.9168L2.39662 15.0965Z"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const RecipeClose = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M15 5L5 15M5 5L15 15" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
