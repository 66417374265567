import React, { useEffect, useState } from "react";
import { orderService } from "../../../services/order.service.js";
import TimeBreak from "./subpages/TimeBreak.js";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Toast } from "../../../utils/toastify/toast.js";
import { junctionService } from "../../../services/junction.service.js";
import { Button } from "../../../components/index.js";
import { useNavigate } from "react-router-dom";
import EditManuelJunctionMainForm from "./subpages/EditManuelJunctionMainForm.js";
import EditManuelJunctionQualityControlForm from "./subpages/EditManuelJunctionQualityControlForm.js";

const EditManuelJunctionMain = ({
  equipmentData,
  defaultStartForm,
  defaultEndForm,
  setSelectedDates,
  selectedDates,
  leftTab,
  setLeftTab,
  rightTab,
  setRightTab,
  breakTimes,
  setBreakTimes,
  newBreak,
  setNewBreak,
  dateChangeCount,
  workType,
  setWorkType,
  junctionNumber,
  selectedJunctionId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);
  const [validateError, setValidateError] = useState(false);
  const [endValidateError, setEndValidateError] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [workPiece, setWorkPiece] = useState(null);
  const [startDate, setStartDate] = useState(defaultStartForm);
  const [endDate, setEndDate] = useState(defaultEndForm);
  const [endBeforeStartError, setEndBeforeStartError] = useState(false);
  const [breakOverlapError, setBreakOverlapError] = useState(false);
  const [breakOutOfRangeError, setBreakOutOfRangeError] = useState(false);
  const [breakEndBeforeStartError, setBreakEndBeforeStartError] = useState(false);
  const [qualityFileFirst, setQualityFileFirst] = useState(null);
  const [qualityFileSecond, setQualityFileSecond] = useState(null);
  const [qualityFileThird, setQualityFileThird] = useState(null);
  const [qualityDescription, setQualityDescription] = useState(null);
  const [rejectedPiece, setRejectedPiece] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);

  const getJunctionDetail = async () => {
    if (selectedJunctionId) {
      await junctionService
        .getJunctionDetail(selectedJunctionId)
        .then((res) => {
          setSelectedProduct(res?.data?.job?.product);
          setSelectedOperation({ ...res?.data?.header, processName: res?.data?.internalOperation?.name });
          setQualityDescription(res?.data?.metadata?.qualityDescription);
          setQualityFileFirst(res?.data?.metadata?.qualityImage);
          setQualityFileSecond(res?.data?.metadata?.acceptedImage);
          setQualityFileThird(res?.data?.metadata?.rejectedImage);
          setRejectedPiece(res?.data?.metadata?.rejectedWorkCount);
          setWorkPiece(res?.data?.madeWorkCount || null);
          setWorkType(res?.data?.type || null);
          setEnabled(res?.data?.metadata?.qualityStatus == "accepted" ? true : false);
          setSelectedUser({ label: res?.data?.user?.name + " " + res?.data?.user?.lastName, value: res?.data?.user?.id, image: res?.data?.user?.avatar });
          setSelectedDates({
            start: moment(res?.data?.startDate).utc().add(3, "hours").format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(res?.data?.endDate).utc().add(3, "hours").format("YYYY-MM-DDTHH:mm:ss"),
          });
        })
        .catch((err) => {});
    }
  };

  const onSubmit = async () => {
    const send = {
      rejectedPiece: enabled ? Number(rejectedPiece) : null,
      acceptedPiece: enabled ? (Number(workPiece) - Number(rejectedPiece) == null ? Number(workPiece) : Number(workPiece) - Number(rejectedPiece)) : null,
      qualityDescription: enabled ? qualityDescription : null,
      qualityImage: enabled ? qualityFileFirst : null,
      acceptedImage: enabled ? qualityFileSecond : null,
      rejectedImage: enabled ? qualityFileThird : null,
      junctionId: selectedJunctionId,
      workPiece: Number(workPiece),
      startDate: moment(selectedDates?.start).utc().format(),
      endDate: moment(selectedDates?.end).utc().format(),
      type: workType,
      qualityControl: enabled,
      subEvents: breakTimes
        .filter((breakTime) => moment(breakTime.start).isValid() && moment(breakTime.end).isValid())
        .map((breakTime) => ({ startDate: moment(breakTime.start).utc().format(), endDate: moment(breakTime.end).utc().format(), id: breakTime?.id })),
    };
    await orderService.qualityForm(selectedJunctionId, send).then((res) => {
      if (res?.data?.code === 0) {
        Toast("success", res?.data?.message);
        navigate(-1);
      } else {
        Toast("error", res?.data?.message);
      }
    });
  };

  useEffect(() => {
    setStartDate(defaultStartForm);
    setEndDate(defaultEndForm);
  }, [defaultEndForm, defaultStartForm]);

  useEffect(() => {
    getJunctionDetail();
  }, []);
  return (
    <form className="flex flex-col w-full h-fit pr-6 gap-y-6">
      <div className="flex min-h-[40px] max-h-[40px] justify-between">
        <p className="text-[#101828] font-semibold text-lg">{t("product:junctionEditManually")}</p>
        <span className="flex h-full min-w-[300px] max-w-[300px] w-full gap-x-3">
          <Button
            onClick={() => {
              navigate(-dateChangeCount);
            }}
            colorType={"secondary-gray"}
            label={t("buttons:stop")}
            size={"lg"}
          />
          <Button colorType={"primary-machine"} label={t("buttons:save")} size={"lg"} onClick={() => onSubmit()} />
        </span>
      </div>
      <div className="flex w-full h-fit">
        <TimeBreak
          setRightTab={setRightTab}
          rightTab={rightTab}
          setEndValidateError={setEndValidateError}
          endValidateError={endValidateError}
          validateError={validateError}
          setValidateError={setValidateError}
          setBreakTimes={setBreakTimes}
          breakTimes={breakTimes}
          setNewBreak={setNewBreak}
          newBreak={newBreak}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
          defaultEndForm={defaultEndForm}
          defaultStartForm={defaultStartForm}
          setEndBeforeStartError={setEndBeforeStartError}
          endBeforeStartError={endBeforeStartError}
          breakOverlapError={breakOverlapError}
          setBreakOverlapError={setBreakOverlapError}
          breakOutOfRangeError={breakOutOfRangeError}
          setBreakOutOfRangeError={setBreakOutOfRangeError}
          breakEndBeforeStartError={breakEndBeforeStartError}
          setBreakEndBeforeStartError={setBreakEndBeforeStartError}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
        />
        <EditManuelJunctionMainForm
          selectedProduct={selectedProduct}
          selectedUser={selectedUser}
          setLeftTab={setLeftTab}
          setWorkType={setWorkType}
          workType={workType}
          selectedOperation={selectedOperation}
          equipmentData={equipmentData}
          startDate={startDate}
          endDate={endDate}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
          workPiece={workPiece}
          setWorkPiece={setWorkPiece}
          leftTab={leftTab}
          setRejectedPiece={setRejectedPiece}
          rejectedPiece={rejectedPiece}
          junctionNumber={junctionNumber}
          selectedJunctionId={selectedJunctionId}
        />
        <EditManuelJunctionQualityControlForm
          setQualityDescription={setQualityDescription}
          setQualityFileFirst={setQualityFileFirst}
          setQualityFileSecond={setQualityFileSecond}
          setQualityFileThird={setQualityFileThird}
          qualityDescription={qualityDescription}
          qualityFileFirst={qualityFileFirst}
          qualityFileSecond={qualityFileSecond}
          qualityFileThird={qualityFileThird}
          setRejectedPiece={setRejectedPiece}
          rejectedPiece={rejectedPiece}
          enabled={enabled}
          setEnabled={setEnabled}
        />
      </div>
    </form>
  );
};

export default EditManuelJunctionMain;
