import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { CustomModal } from '../../../../components';
import ResetKpiValueModal from '../../../settings/modal/ResetKpiValueModal';
import { avarageService } from '../../../../services/average.service';
import { ResetRefresh } from '../../../../assets/icons/machineIcons';
import { GaugeNoData } from '../../../../assets/illustrations';
import moment from 'moment';

const PersonnelDetailKpiCard = ({
    timeMass = 0,
    name,
    selectedProcess,
    gaugeData,
    item,
    selectedDate
}) => {
    const menuRef = useRef();
    const { t } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);
    const [view, setView] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [loading, setLoading] = useState(false);

    const data = { id: selectedProcess, name: name?.toLowerCase() };

    const getReportValue = (data) => {
        setLoading(true);
        avarageService.GetReportType(data).then((response) => {
            setSelectedValue(response.data);
        });
        setLoading(false);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setView(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div
                className='relative flex flex-col items-start border-[#E4E7EC]  w-full h-[115px] shadow-xs min-h-[115px] border rounded-lg cursor-pointer bg-[#fff]'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {timeMass >= 0 ? (
                    <>
                        <div className='px-3 pt-2 pb-1 w-full border-b border-b-[#E4E7EC]'>
                            <p className='text-xs font-semibold w-[130px]  truncate text-secondary-700'>{name || "Kpi Adı Bulunamadı"}</p>
                        </div>
                        <div className='flex  w-full bg-[#F9FAFB] border-b border-b-[#E4E7EC]'>
                            <div className='flex px-3 flex-row items-center py-1 w-full'>
                                <p className={`text-2xl font-semibold text-secondary-600`}>
                                    %{(item?.average ? (item?.average * 100)?.toFixed(0) : "0")}
                                </p>
                                <div className='flex flex-col items-start ml-2'>
                                    <p className='text-xxs text-[#667085]'>{t("product:avg")}</p>
                                    <p className='text-xs -mb-[2px] text-secondary-700'>
                                        {((item?.timeMass / 3600).toFixed(2) || 0) + ` ${t("product:hours")}`}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row items-center w-full'>
                            <div className='flex flex-col items-start w-[50%] border-r'>
                                <div className='flex flex-col items-start h-full py-1 pr-2 pl-3'>
                                    <p className='text-xxs text-secondary-600'>{selectedDate === moment(new Date())?.format("YYYY-MM-DD") ? t("product:prevDay") : t("product:selectedDay") }</p>
                                    <div className='flex flex-row items-center gap-x-1 w-full'>
                                        <PrevDayIcon />
                                        <p className='text-xs font-medium text-secondary-700'>
                                            %{(item?.actual * 100 || 0)?.toFixed(0)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col items-start w-[50%]'>
                                <div className='flex flex-col items-start h-full py-1 pr-2 pl-3'>
                                    <p className='text-xxs text-secondary-600'>{t("product:maximums")}</p>
                                    <div className='flex flex-row items-center gap-x-1 w-full'>
                                        <MaximumIcons />
                                        <p className='text-xs font-medium text-secondary-700'>
                                            %{parseInt(item?.average == 0 ? 0 : item?.kpi * 100) > 100 ? 100 : parseInt(item?.average == 0 ? 0 : item?.kpi * 100 || 0)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setView(!view);
                            }}
                            className="flex absolute right-1 top-[6px] cursor-pointer"
                        >
                            <DotsIcons />
                        </div>
                        {view === true && (
                            <div
                                ref={menuRef}
                                className="absolute w-[195px] z-50 flex flex-col py-1  top-[30px] right-[12px]  border-[1px]  border-secondary-200  shadow-lg bg-white rounded-lg cursor-pointer"
                            >
                                <div className="px-[6px] py-[1px] w-full flex-row items-center z-[100]">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            getReportValue(data);
                                            setIsOpen(true);
                                        }}
                                        type="button"
                                        className={`flex flex-row w-full items-center gap-2 hover:bg-secondary-50 bg-white px-[6px] py-[10px] min-w-[144px] min-h-[40px] cursor-pointer`}
                                    >
                                        <div className="w-4 h-4 flex items-center text-[#667085]">
                                            <ResetRefresh color={"#667085"} />
                                        </div>
                                        <p className="text-secondary-700 text-xs font-medium">{t("gauge:resetKpiValue")}</p>
                                    </button>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <img src={GaugeNoData} className="w-[124px] h-[66px] mx-auto mt-auto mb-auto" />
                )}
            </div>
            <CustomModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                modalTitle={t("gauge:resetKpiValue")}
                children={<ResetKpiValueModal gaugeData={gaugeData} selectedValue={selectedValue} setValue={setSelectedValue} loading={loading} />}
            />
        </>
    )
}

export default PersonnelDetailKpiCard

export const PrevDayIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M4.5 3.5H2.3C2.01997 3.5 1.87996 3.5 1.773 3.5545C1.67892 3.60243 1.60243 3.67892 1.5545 3.773C1.5 3.87996 1.5 4.01997 1.5 4.3V9.7C1.5 9.98003 1.5 10.12 1.5545 10.227C1.60243 10.3211 1.67892 10.3976 1.773 10.4455C1.87996 10.5 2.01997 10.5 2.3 10.5H4.5M4.5 10.5H7.5M4.5 10.5L4.5 2.3C4.5 2.01997 4.5 1.87996 4.5545 1.773C4.60243 1.67892 4.67892 1.60243 4.773 1.5545C4.87996 1.5 5.01997 1.5 5.3 1.5L6.7 1.5C6.98003 1.5 7.12004 1.5 7.227 1.5545C7.32108 1.60243 7.39757 1.67892 7.4455 1.773C7.5 1.87996 7.5 2.01997 7.5 2.3V10.5M7.5 5.5H9.7C9.98003 5.5 10.12 5.5 10.227 5.5545C10.3211 5.60243 10.3976 5.67892 10.4455 5.773C10.5 5.87996 10.5 6.01997 10.5 6.3V9.7C10.5 9.98003 10.5 10.12 10.4455 10.227C10.3976 10.3211 10.3211 10.3976 10.227 10.4455C10.12 10.5 9.98003 10.5 9.7 10.5H7.5" stroke="#667085" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
};

export const MaximumIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M10.5 1.5H1.5M6 10.5V3.5M6 3.5L2.5 7M6 3.5L9.5 7" stroke="#667085" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const DotsIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z" stroke="#98A2B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 4.5C9.41421 4.5 9.75 4.16421 9.75 3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75C8.25 4.16421 8.58579 4.5 9 4.5Z" stroke="#98A2B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 15C9.41421 15 9.75 14.6642 9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15Z" stroke="#98A2B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const ArrowIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 5V19M12 19L19 12M12 19L5 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};