import React from 'react'
import PersonelDetailGaugeChart from './chart/PersonelDetailGaugeChart'
import { useTranslation } from 'react-i18next'
import PersonnelDashboardGaugeChart from './chart/PersonnelDashboardGaugeChart'
import PersonnelDetailKpiCard from './chart/PersonnelDetailKpiCard'

const PersonnelDashboardDetailCharts = ({ gaugeData,selectedDate }) => {
  const { t } = useTranslation()
  return (
    <div className="flex w-full flex-col h-full gap-y-[14px]">
      {gaugeData?.map((item, index) => (
        <PersonnelDetailKpiCard
          key={index}
          gaugeData={gaugeData}
          name={t(`addProcess:${item?.type}`)}
          value={item?.actual ? item?.actual : 0}
          timeMass={item?.timeMass || 0}
          kpi1={item?.average?.toFixed(2) || 0}
          kpi2={item?.kpi || 0}
          item={item}
          selectedDate={selectedDate}
        // selectedProcess={data?.id}
        />
        // <PersonnelDashboardGaugeChart
        //   key={index}
        //   gaugeData={gaugeData}
        //   name={t(`addProcess:${item?.type}`)}
        //   value={item?.actual ? item?.actual : 0}
        //   timeMass={item?.timeMass || 0}
        //   kpi1={item?.average?.toFixed(2) || 0}
        //   kpi2={item?.kpi || 0}
        //   // selectedProcess={data?.id}
        // />
      ))}
    </div>
  )
}

export default PersonnelDashboardDetailCharts