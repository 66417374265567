import React, { useState, useEffect, useMemo } from "react";
import { Button, CustomModal, Loading } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { purchaseService } from "../../services/purchase.service";
import diacritics from "diacritics";
import MaterialSupplyEventCard from "../../components/new-cards/junction-card/MaterialSupplyEventCard";
import MaterialSupplyActiveEventCard from "../../components/new-cards/junction-card/MaterialSupplyActiveEventCard";
import { QualityTab } from "./quality/components";
import { Toast } from "../../utils/toastify/toast";
import NewOrderHeaderCard from "./components/order/NewOrderHeaderCard";
import useWindowDimensions from "../../hooks/useWindowDimension";
import NewQualityControlForm from "./modals/NewQualityControlForm";
import NewPurchaseFinishModal from "./modals/NewPurchaseFinishModal";
import MaterialSupplyPlanModal from "./modals/MaterialSupplyPlanModal";

import SearchInput from "../../components/inputs/SearchInput";
import MaterialSupplyHeaderCard from "./cards/material-supply/MaterialSupplyHeaderCard";
import MaterialSupplyTab from "./materialSupply/components/MaterialSupplyTab";
import MaterialSupplyPlanModalRawCard from "../../components/junction/MaterialSupplyPlanModalRawCard";
import MaterialSupplyEventCardPlannedTriple from "../../components/new-cards/material-supply/MaterialSupplyEventCardPlannedTriple";
import MaterialSupplyReceivedCard from "../../components/new-cards/material-supply/MaterialSupplyReceivedCard";
import MaterialSupplyQualityAppliedCard from "../../components/new-cards/material-supply/MaterialSupplyQualityAppliedCard";

import Badgets from "../../components/buttons/Badgets";
import { CloseIcon } from "../../assets/icons/departmentIcon";
const MaterialSupplyJunction = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { settings, auth } = useStore();
  const { height, width } = useWindowDimensions();
  let navigate = useNavigate();
  const [searchVal, setSearchVal] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [finishPurhcaseModal, setFinishPurhcaseModal] = useState(false);
  const [finishPurhcaseModalData, setFinishPurhcaseModalData] = useState(null);
  const [purchaseQualityForm, setPurchaseQualityForm] = useState(false);
  const [purchaseQualityFormData, setPurchaseQualityFormData] = useState(null);
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const [draggedJunction, setDraggedJunction] = useState(null);
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [isOpenPlanningModalData, setIsOpenPlanningModalData] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [selectedLeftCardId, setSelectedLeftCardId] = useState(null);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [lastSortedCardId, setLastSortedCardId] = useState(null);
  const [selectedMsNo, setSelectedMsNo] = useState(null);
  const [filteredPurchaseJunctionUnPlanned, setFilteredPurchaseJunctionUnPlanned] = useState([]);
  const [filteredPurchaseJunctionPastJunctionData, setFilteredPurchaseJunctionPastJunctionData] = useState([]);
  const [filteredPurchaseJunctionPendingJunctionData, setFilteredPurchaseJunctionPendingJunctionData] = useState([]);
  const [filteredPurchaseJunctionPlannedJunctionData, setFilteredPurchaseJunctionPlannedJunctionData] = useState([]);

  const headData = useQuery({
    queryKey: ["all-supplier-junction-group-junctions"],
    enabled: true,
    retry: 0,
    queryFn: async () => await purchaseService.listSupplierGroupForCompany(),
  });

  const {
    data: materialSupplyJunctions,
    refetch: refetchMaterialSupplyJunctions,
    isLoading: materialSupplyLoading,
  } = useQuery({
    queryKey: ["purchase-junctions-supply-planned", id, searchVal],

    retry: 0,
    queryFn: async () => {
      let res = await purchaseService.purchaseTransactionsPlanned(id, "follow", searchVal);
      navigate(`/app/product/mrp/supply/material/${id}/junction/${searchVal}`);
      await setFilteredPurchaseJunctionUnPlanned(
        res?.orderList
          ?.filter((d) => d?.status == "waiting" || d?.status == "started")
          ?.sort((a, b) => {
            const aLength = a?.plannedJunctions?.length || 0;
            const bLength = b?.plannedJunctions?.length || 0;
            return bLength - aLength;
          })
      );
      await setFilteredPurchaseJunctionPastJunctionData(res?.subJunctions?.filter((d) => d?.quality == "applied"));
      await setFilteredPurchaseJunctionPendingJunctionData(res?.subJunctions?.filter((d) => d.quality == "waiting"));
      await setFilteredPurchaseJunctionPlannedJunctionData(res?.plannedJunctions);
    },
    enabled: false,
  });

  const refetch = () => {
    refetchMaterialSupplyJunctions();
  };

  useEffect(() => {
    if (id != ":id" && id != "undefined" && id != auth.user.company.id) {
      refetchMaterialSupplyJunctions();
      setSelectedGroup(headData?.data?.find((item) => item?.id == id));
    } else if (id == auth.user.company.id) {
      navigate(`/app/product/mrp/supply/material/${headData?.data[0]?.id}/junction`);
    } else {
    }
  }, [id, searchVal]);

  useEffect(() => {
    if (headData?.data?.length > 0 && (id == ":id" || id == "undefined")) {
      navigate(`/app/product/mrp/supply/material/${headData?.data[0]?.id}/junction`);
    }
  }, [headData?.data]);

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };

  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handlePlannedOnDrop = () => {
    if (draggedJunction?.id) {
      if (draggedJunction?.status === "waiting" || draggedJunction?.status === "started") {
        setIsOpenPlanningModal(true);
        setIsOpenPlanningModalData(draggedJunction);
      } else {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
    }
  };

  const handleFinishedOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.quality === "waiting")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setPurchaseQualityForm(true);
      setPurchaseQualityFormData(draggedJunction);
    }
  };

  const handleWaitingOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.status === "active") || draggedJunction?.plannedUnit == draggedJunction?.acceptedUnit) {
        Toast("error", t("product:columnDragDropWarningMaterialPlannedCardSameUnit"));
        return;
      }
      setFinishPurhcaseModal(true);
      setFinishPurhcaseModalData(draggedJunction);
    }
  };

  const tabOptions = [
    { value: "list", label: t("product:list") },
    { value: "junction", label: t("product:track") },
    { value: "plan", label: t("product:planner") },
  ];

  const handleSortBySelectedCard = (items, type) => {
    if (!selectedCardId) return items;
    const isSorted = selectedCardId === lastSortedCardId;
    const sortedItems = items.sort((a, b) => {
      let aId, bId;

      if (type === "planned") {
        aId = a?.id;
        bId = b?.id;
      } else {
        aId = a?.plannedJunctionRef?.id;
        bId = b?.plannedJunctionRef?.id;
      }
      if (aId === selectedCardId && bId !== selectedCardId) return -1;
      if (aId !== selectedCardId && bId === selectedCardId) return 1;
      if (isSorted) return bId - aId;

      return 0;
    });
    return sortedItems?.map((item) => ({
      ...item,
      opacity: item?.plannedJunctionRef?.id === selectedCardId || item?.id === selectedCardId ? 1 : 0.25,
    }));
  };

  const handleCardClick = (cardId, msNo) => {
    if (selectedCardId === cardId) {
      // Aynı karta tekrar tıklanırsa sıralamayı ve msNo'yu sıfırla
      setSelectedCardId(null);
      setLastSortedCardId(null);
      setSelectedMsNo(null);
    } else {
      // Yeni bir kart seçildiyse, lastSortedCardId ve msNo'yu güncelle
      setSelectedCardId(cardId);
      setLastSortedCardId(cardId);
      setSelectedMsNo(msNo);
    }
  };

  const handleCardClickInfo = (cardId) => {
    setSelectedLeftCardId(cardId);
  };

  useEffect(() => {
    if (!hasInitialized && filteredPurchaseJunctionUnPlanned?.length > 0) {
      setHasInitialized(true);
    }
  }, [filteredPurchaseJunctionUnPlanned, hasInitialized]);

  useEffect(() => {
    //filteredPurchaseJunctionUnPlanned
    if (filteredPurchaseJunctionUnPlanned?.length > 0) {
      setSelectedLeftCardId(filteredPurchaseJunctionUnPlanned[0]?.id);
    }
  }, [materialSupplyJunctions, materialSupplyLoading]);

  return (
    <div className="w-full h-full flex flex-col overflow-hidden overflow-y-auto scrollbar-hide">
      <>
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
          </span>
          <div className="flex items-center">
            <span className="font-codecMedium text-2xl text-black">upu.</span>
            <p className="text-2xl font-codecMedium text-[#B54708]">supply</p>
            {!(headData?.isLoading || headData?.isFetching || materialSupplyJunctions?.isFetching || materialSupplyJunctions?.isLoading) && (
              <p className="text-xl font-normal ml-2 text-secondary-500">
                {selectedGroup && selectedGroup?.name ? `${t("product:materialSupply")} / ${selectedGroup?.name}` : t("product:materialSupply")}
              </p>
            )}
          </div>
          <span className="ml-auto">
            <Button
              colorType={"primary-product"}
              size={"md"}
              label={t("product:supplierList")}
              onClick={() => navigate("/app/product/mrp/supply/material/list")}
            />
          </span>
        </div>
        {headData?.isLoading || headData?.isFetching || materialSupplyJunctions?.isFetching || materialSupplyJunctions?.isLoading ? (
          <div className="w-full bg-gray-200 rounded-xl animate-pulse h-[96px] min-h-[96px] flex gap-5" />
        ) : (
          <div className="h-[44px] min-h-[44px] w-full overflow-x-auto flex ">
            {headData?.data
              ?.sort((a, b) => b?.requestCount - a?.requestCount)
              ?.map((item, index) => {
                return (
                  <div className={`min-w-[192px] ${index !== headData?.data?.length - 1 ? "border-b" : "border-b-0"} max-h-[40px] pr-5`}>
                    <MaterialSupplyHeaderCard
                      onClick={async () => {
                        navigate(`/app/product/mrp/supply/material/${item?.id}/junction`);
                      }}
                      name={item?.name}
                      selectedOperation={{
                        id: selectedGroup?.id,
                        name: item?.name,
                      }}
                      id={item?.id}
                      length={item?.requestCount}
                    />
                  </div>
                );
              })}
          </div>
        )}
        {headData?.isLoading || headData?.isFetching || materialSupplyJunctions?.isFetching || materialSupplyJunctions?.isLoading ? (
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-gray-200 p-[6px] mt-6 mb-4 rounded-xl animate-pulse flex gap-5" />
        ) : (
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-4 mb-4">
            {tabOptions?.map((option, index) => (
              <div
                className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                  "junction" == option?.value ? "bg-white shadow-sm" : ""
                }`}
                onClick={() => {
                  if (option?.value === "plan") navigate(`/app/product/mrp/supply/material/${id}/plan-view`);
                  if (option?.value === "list") navigate(`/app/product/mrp/supply/material/${id}/list`);
                }}
              >
                <p className={`text-md font-semibold ${"junction" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
              </div>
            ))}
          </div>
        )}
        {headData?.isLoading || headData?.isFetching || materialSupplyJunctions?.isFetching || materialSupplyJunctions?.isLoading ? (
          <div className="w-full h-full bg-gray-200 rounded-xl animate-pulse mt-4" />
        ) : (
          <div style={{ minHeight: height - 135 }} className="flex w-full h-full rounded-xl mt-4 relative border border-[#E4E7EC] bg-[#F9FAFB]">
            <div className="flex flex-col min-w-[356px] max-w-[356px] h-full overflow-y-auto scrollbar-hide w-[356px] bg-white rounded-tl-xl border-r border-[#E4E7EC]">
              <div className="flex w-full min-h-[76px] flex-col max-h-[76px] h-[76px] px-4 py-3  border-b border-[#E4E7EC]">
                <p className="text-lg font-semibold text-secondary-900">{t("product:materialServicePro")}</p>
                <p className="text-sm text-secondary-600 font-normal">{t("product:dragDropPlan")}</p>
              </div>
              <div className="flex flex-col w-full px-4 py-3 gap-y-4">
                <SearchInput setSearchVal={setSearchVal} theme={"product"} placeholder={t("chat:search")} />

                {materialSupplyLoading ? (
                  <div className="flex items-center justify-center mt-10">
                    {" "}
                    <Loading color={"#fff"} secondary={"rgb(0 0 0 / 0.4)"} size={36} />{" "}
                  </div>
                ) : (
                  filteredPurchaseJunctionUnPlanned?.map((item, index) => {
                    return (
                      <div
                        draggable
                        onDragStart={() => setDraggedJunction(item)}
                        onClick={() => handleCardClickInfo(item?.id)}
                        key={index}
                        className="min-h-[80px]"
                      >
                        <MaterialSupplyPlanModalRawCard item={item} selectedLeftCardId={selectedLeftCardId} />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <div style={{ minWidth: width - 475 }} className="flex flex-col h-full px-6 py-4">
              <div className="flex flex-row items-center justify-between w-full">
                {selectedCardId ? (
                  <div className="flex flex-row items-center ml-4 gap-x-2">
                    <FiltersIcon />
                    <p className="text-sm font-medium text-secondary-700">
                      MS-{selectedMsNo} {t("product:filtered")}
                    </p>
                    {selectedMsNo && (
                      <Badgets
                        iconLeft={<CloseIcon />}
                        size={"sm"}
                        colorType={"outline-warning"}
                        label={t("product:resetFilter")}
                        onClick={() => {
                          setSelectedCardId(null);
                          setLastSortedCardId(null);
                          setSelectedMsNo(null);
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <div className="flex flex-row items-center gap-x-2 ml-4">
                    <FiltersIcon />
                    <p className="text-sm text-secondary-700 font-medium">{t("product:materialDetail")}</p>
                  </div>
                )}

                <div className={`flex min-h-10 max-h-10 h-10 ${selectedCardId ? "" : "ml-auto"} items-center justify-end gap-x-3`}>
                  {/* <span className="flex w-[300px] min-w-[300px]">
                    <SearchInput setSearchVal={setSearchTerm} theme={"product"} />
                  </span> */}
                  {/* <Button colorType={"secondary-gray"} size={"md"} iconLeft={<SortIcon />} />
                <Button colorType={"secondary-gray"} size={"md"} iconLeft={<FilterIcon />} /> */}
                </div>
              </div>

              <div className="flex w-full h-full overflow-x-auto pb-6 overflow-y-hidden">
                <MaterialSupplyTab
                  length={filteredPurchaseJunctionPlannedJunctionData?.filter((item) => item?.purchaseRequest?.id === selectedLeftCardId)?.length || 0}
                  type={"planned"}
                  onDragOver={(e) => e.preventDefault()}
                  onDragEnd={() => {
                    setDraggedJunction(null);
                  }}
                  onDrop={() => {
                    handlePlannedOnDrop();
                  }}
                  children={
                    <PlannedJunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={handleSortBySelectedCard(
                        filteredPurchaseJunctionPlannedJunctionData?.filter((item) => item?.purchaseRequest?.id === selectedLeftCardId),
                        "planned"
                      )}
                      onClick={handleCardClick}
                      selectedCardId={selectedCardId}
                      refetch={refetch}
                      setFinishPurhcaseModal={setFinishPurhcaseModal}
                      setIsOpenPlanningModalData={setIsOpenPlanningModalData}
                      setIsOpenPlanningModal={setIsOpenPlanningModal}
                      setFinishPurhcaseModalData={setFinishPurhcaseModalData}
                    />
                  }
                />
                <MaterialSupplyTab
                  length={filteredPurchaseJunctionPendingJunctionData?.filter((item) => item?.purchaseRequest?.id === selectedLeftCardId)?.length || 0}
                  type={"received"}
                  onDragEnd={() => {
                    setDraggedJunction(null);
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleWaitingOnDrop();
                  }}
                  children={
                    <JunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={handleSortBySelectedCard(
                        filteredPurchaseJunctionPendingJunctionData?.filter((item) => item?.purchaseRequest?.id === selectedLeftCardId),
                        "received"
                      )}
                      onClick={handleCardClick}
                      refetch={refetch}
                      setFinishPurhcaseModalData={setFinishPurhcaseModalData}
                      setFinishPurhcaseModal={setFinishPurhcaseModal}
                      selectedCardId={selectedCardId}
                    />
                  }
                />
                <MaterialSupplyTab
                  length={filteredPurchaseJunctionPastJunctionData?.filter((item) => item?.purchaseRequest?.id === selectedLeftCardId)?.length || 0}
                  type={"complate"}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleFinishedOnDrop();
                  }}
                  children={
                    <QualityApliedController
                      setDraggedJunction={setDraggedJunction}
                      items={handleSortBySelectedCard(
                        filteredPurchaseJunctionPastJunctionData?.filter((item) => item?.purchaseRequest?.id === selectedLeftCardId),
                        "complate"
                      )}
                      onClick={handleCardClick}
                      refetch={refetch}
                      setPurchaseQualityForm={setPurchaseQualityForm}
                      setPurchaseQualityFormData={setPurchaseQualityFormData}
                      selectedCardId={selectedCardId}
                    />
                  }
                />
              </div>
            </div>

            {(materialSupplyJunctions?.isFetching || materialSupplyJunctions?.isLoading) && (
              <div className="w-full absolute bottom-0 p-2 px-3 flex items-center bg-white border-b border-secondary-200 flex-1 justify-center">
                <Loading size={20} primary={"#B54708"} secondary={"#fff"} />
              </div>
            )}
          </div>
        )}
      </>
      <CustomModal
        isOpen={finishPurhcaseModal}
        setIsOpen={setFinishPurhcaseModal}
        modalTitle={t("product:receiveDelivery")}
        // subTitle={t("product:addThePurchase")}
        children={<NewPurchaseFinishModal refetch={refetch} setIsOpen={setFinishPurhcaseModal} junctionData={finishPurhcaseModalData} />}
      />
      <CustomModal
        isOpen={purchaseQualityForm}
        setIsOpen={setPurchaseQualityForm}
        modalTitle={t("product:qualityControlForm")}
        children={<NewQualityControlForm refetch={refetch} setIsOpen={setPurchaseQualityForm} junctionData={purchaseQualityFormData} />}
      />
      <CustomModal
        isOpen={isOpenPlanningModal}
        setIsOpen={setIsOpenPlanningModal}
        onClose={() => {
          setIsOpenPlanningModal(false);
        }}
        modalTitle={t("product:planPurchase")}
        subTitle={t("product:specifyPlannings")}
        width="fit-content"
        children={<MaterialSupplyPlanModal setIsOpen={setIsOpenPlanningModal} refetch={refetch} selectedJunction={isOpenPlanningModalData} groupId={id} />}
      />
    </div>
  );
};

export default MaterialSupplyJunction;

const JunctionListController = ({ items, onClick, selectedCardId, refetch, setDraggedJunction, setFinishPurhcaseModalData, setFinishPurhcaseModal }) => {
  return items?.map((item, index) => (
    <div
      key={index}
      draggable
      onDragStart={() => setDraggedJunction(item)}
      className="min-h-[80px] mb-3"
      style={{
        opacity: item.opacity, // Opaklığı ayarla
      }}
    >
      <MaterialSupplyReceivedCard
        data={item}
        refetch={refetch}
        onClick={() => onClick(item?.plannedJunctionRef?.id, item?.plannedJunctionRef?.msNo)} // Kart tıklandığında `handleCardClick` fonksiyonunu çağır
        setFinishPurhcaseModalData={setFinishPurhcaseModalData}
        setFinishPurhcaseModal={setFinishPurhcaseModal}
        selectedCardId={selectedCardId}
      />
    </div>
  ));
};

const QualityApliedController = ({ items, onClick, setPurchaseQualityFormData, selectedCardId, setPurchaseQualityForm, refetch, setDraggedJunction }) => {
  return items?.map((item, index) => (
    <div
      key={index}
      draggable
      onDragStart={() => setDraggedJunction(item)}
      className="min-h-[80px] mb-3"
      style={{
        opacity: item.opacity, // Opaklığı ayarla
      }}
    >
      <MaterialSupplyQualityAppliedCard
        data={item}
        refetch={refetch}
        onClick={() => onClick(item?.plannedJunctionRef?.id, item?.plannedJunctionRef?.msNo)} // Kart tıklandığında `handleCardClick` fonksiyonunu çağır
        setPurchaseQualityForm={setPurchaseQualityForm}
        setPurchaseQualityFormData={setPurchaseQualityFormData}
        selectedCardId={selectedCardId}
      />
    </div>
  ));
};

const PlannedJunctionListController = ({
  items,
  onClick,
  setFinishPurhcaseModal,
  refetch,
  selectedCardId,
  setFinishPurhcaseModalData,
  setIsOpenPlanningModalData,
  setIsOpenPlanningModal,
  setDraggedJunction,
}) => {
  return items?.map((item, index) => (
    <div
      key={index}
      draggable
      onDragStart={() => setDraggedJunction(item)}
      className="min-h-[80px] cursor-pointer mb-3"
      style={{
        opacity: item.opacity, // Opaklığı ayarla
      }}
    >
      <MaterialSupplyEventCardPlannedTriple
        data={item}
        refetch={refetch}
        onClick={() => onClick(item?.id, item?.msNo)} // Kart tıklandığında `handleCardClick` fonksiyonunu çağır
        setFinishPurhcaseModal={setFinishPurhcaseModal}
        setFinishPurhcaseModalData={setFinishPurhcaseModalData}
        setIsOpenPlanningModalData={setIsOpenPlanningModalData}
        setIsOpenPlanningModal={setIsOpenPlanningModal}
        selectedCardId={selectedCardId}
      />
    </div>
  ));
};

export const FiltersIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M2.16699 3.83333C2.16699 3.36662 2.16699 3.13327 2.25782 2.95501C2.33771 2.79821 2.4652 2.67072 2.622 2.59083C2.80026 2.5 3.03361 2.5 3.50033 2.5H17.5003C17.967 2.5 18.2004 2.5 18.3787 2.59083C18.5355 2.67072 18.6629 2.79821 18.7428 2.95501C18.8337 3.13327 18.8337 3.36662 18.8337 3.83333V4.39116C18.8337 4.61516 18.8337 4.72716 18.8063 4.8313C18.782 4.92359 18.7421 5.01103 18.6882 5.0898C18.6275 5.17869 18.5428 5.25204 18.3736 5.39875L13.0438 10.0179C12.8745 10.1646 12.7898 10.238 12.7291 10.3269C12.6752 10.4056 12.6353 10.4931 12.611 10.5854C12.5837 10.6895 12.5837 10.8015 12.5837 11.0255V15.382C12.5837 15.5449 12.5837 15.6264 12.5574 15.6969C12.5342 15.7591 12.4964 15.8149 12.4472 15.8596C12.3916 15.9102 12.3159 15.9404 12.1646 16.001L9.33125 17.1343C9.02497 17.2568 8.87182 17.3181 8.74889 17.2925C8.64138 17.2702 8.54704 17.2063 8.48637 17.1148C8.41699 17.0101 8.41699 16.8452 8.41699 16.5153V11.0255C8.41699 10.8015 8.41699 10.6895 8.38962 10.5854C8.36536 10.4931 8.32544 10.4056 8.27158 10.3269C8.21081 10.238 8.12617 10.1646 7.9569 10.0179L2.62708 5.39875C2.45781 5.25204 2.37318 5.17869 2.3124 5.0898C2.25855 5.01103 2.21862 4.92359 2.19436 4.8313C2.16699 4.72716 2.16699 4.61516 2.16699 4.39116V3.83333Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
