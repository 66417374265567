import React, { memo, useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components";
import { PrinterIcon } from "../../../assets/icons/buttonIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { authStore } from "../../../stores/auth.store";
import { useReactToPrint } from "react-to-print";
import "./media.css";
import moment from "moment";
import { CalendarIcon2, OrderIcon, QtyIcon2, UpuLogo } from "../../../assets/icons/commonIcons";
import { he } from "date-fns/locale";
import { useStore } from "../../../hooks/useStores";
import { QRCodeSVG } from "qrcode.react";

const OrderQrModal = ({ orderId, order }) => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const printRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "P-" + " " + order?.product?.name + " " + "upu.product",
  });

  return (
    <div className="flex flex-col pt-4 w-[400px]">
      <div className="w-full h-[240px] flex flex-col relative border border-secondary-300  px-6 py-[18px]">
        <div className="flex flex-row items-center w-full gap-x-2  justify-between">
          <div className="flex-1 flex flex-col">
            <p className="text-lg font-semibold w-[260px]  truncate text-secondary-900">{order?.product?.name}</p>
            <p className="text-[15px] leading-6 text-secondary-600 w-[260px] truncate">
              {t("product:stock")}: {order?.product?.stockCode}
            </p>
          </div>
          <img
            className="w-[72px] min-w-[72px] min-h-[54px] h-[54px] rounded border border-secondary-300"
            src={generateFileUrl(auth?.user?.company?.image)}
          />
        </div>
        <div className="w-full flex flex-row justify-between items-center mt-auto">
          <img
            className="w-[180px] h-[135px] border border-secondary-300"
            src={generateFileUrl(order?.product?.image)}
          />
          <div className="w-[135px] min-w-[135px] max-w-[135px] max-h-[135px] min-h-[135px] h-[135px]  bg-[#B54708] flex items-center justify-center">
            <QRCode
              id="qr-code"
              fgColor="#fff"
              bgColor="#B54708"
              size={115}
              ecLevel="M"
              value={order?.product?.id || undefined}
              eyeRadius={[
                { outer: [2, 2, 2, 2], inner: [2, 2, 2, 2] },
                { outer: [2, 2, 2, 2], inner: [2, 2, 2, 2] },
                { outer: [2, 2, 2, 2], inner: [2, 2, 2, 2] },
              ]}
              qrStyle="squares"
            />
          </div>
        </div>
      </div>

      <div style={{ display: "none" }}>
        <div className="p-4" ref={printRef}>
          <div className="w-[253px] h-[160px] flex flex-col relative border border-secondary-300  px-4 py-3 ">
            <div className="flex flex-row items-center w-full gap-x-2  justify-between">
              <div className="flex-1 flex flex-col">
                <p className="text-xs font-semibold w-[160px] truncate text-secondary-900">{order?.product?.name}</p>
                <p className="text-xxs text-secondary-600 w-[160px] truncate">
                  {t("product:stock")}: {order?.product?.stockCode}
                </p>
              </div>
              <img
                className="w-[48px] min-w-[48px] min-h-[36px] h-[36px] rounded border border-secondary-300"
                src={generateFileUrl(auth?.user?.company?.image)}
              />
            </div>
            <div className="w-full flex flex-row justify-between items-center mt-auto">
              <img
                className="w-[120px] h-[90px] border border-secondary-300"
                src={generateFileUrl(order?.product?.image)}
              />
              <div className="w-[90px] min-w-[90px] max-w-[90px] max-h-[90px] min-h-[90px] h-[90px] bg-[#B54708] flex items-center justify-center">
                <QRCode
                  id="qr-code"
                  fgColor="#fff"
                  bgColor="#B54708"
                  size={58}
                  ecLevel="M"
                  value={orderId || undefined}
                  eyeRadius={[
                    { outer: [2, 2, 2, 2], inner: [2, 2, 2, 2] },
                    { outer: [2, 2, 2, 2], inner: [2, 2, 2, 2] },
                    { outer: [2, 2, 2, 2], inner: [2, 2, 2, 2] },
                  ]}
                  qrStyle="squares"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex items-center gap-4 justify-between mt-6">
        <Button
          onClick={handlePrint}
          iconLeft={<PrinterIcon />}
          label={t("embedded:print")}
          colorType={"secondary-gray"}
          size={"md"}
        />
      </div>
    </div>

  );
};

export default memo(OrderQrModal);
