import React, { useEffect, useState } from "react";
import { ArrowDownIcon } from "../../assets/icons/stepsIcons";
import { CheckIcon } from "../../assets/icons/stepsIcons";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const NewSelectInput = ({
  items,
  value,
  onChange,
  placeholder,
  icon,
  disabled,
  errorMessage,
  validate,
  theme,
  size,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleSelect = (item) => {
    const isSelected = selectedItems?.some(
      (selectedItem) => selectedItem?.value === item?.value
    );
    let updatedItems;
    if (isSelected) {
      updatedItems = selectedItems?.filter(
        (selectedItem) => selectedItem?.value !== item?.value
      );
    } else {
      updatedItems = [...selectedItems, item];
    }
    setSelectedItems(updatedItems);
    onChange(updatedItems?.map((selectedItem) => selectedItem?.value));
  };
  const sizeStyles = {
    xs: "40px",
    sm: "44px",
  };
  const inputSize = sizeStyles[size] || "40px";
  useEffect(() => {
    if (value) {
      setSelectedItems(value?.map((v) => items?.find((i) => i?.value === v)));
    }
  }, [value]);
  return (
    <div className="flex flex-col w-full relative">
      {placeholder && (
        <span className="text-sm mb-1.5 pl-0 text-secondary-700 dark:text-darkTrue-200 font-medium">
          {placeholder}
        </span>
      )}
      <div className={`relative group `}>
        <div
          style={{ height: inputSize }}
          onClick={handleToggle}
          className={`${size === "sm" ? "px-[14px] py-[10px]" : "px-3 py-2"}  ${
            theme === "person"
              ? ` ${
                  open && "outline outline-4 outline-[#C4EDE6] border-teal-300"
                } `
              : theme === "product"
              ? ` ${
                  open &&
                  "outline outline-4 outline-[#FDE3C1] border-warning-300"
                } `
              : theme === "embedded"
              ? ` ${
                  open &&
                  "outline outline-4 outline-[#D2D6E8] border-grayBlue-300"
                }`
              : ` ${
                  open &&
                  "outline outline-4 outline-[#E8DFFB] border-primary-300"
                }`
          } flex w-full border cursor-pointer overflow-hidden   truncate items-center  focus-within:ring-opacity-50 transition duration-300 rounded-lg dark:border-[#525252] dark:bg-[#292929]
            ${
              validate === "success"
                ? "border-validate-success"
                : validate === "warning"
                ? "border-validate-warning"
                : validate === "error"
                ? "border-validate-errorBorder"
                : "border-secondary-300"
            } text-secondary-500 text-md
              ${disabled ? "bg-[#F9FAFB]  mx-0" : "bg-white"} `}
        >
          {icon && icon}
          <span className="block truncate text-md font-medium text-secondary-900 dark:text-[#FAFAFA]">
            {selectedItems?.length > 0
              ? selectedItems?.map((item) => item?.label).join(", ")
              : ""}
          </span>
          <span className="flex items-center ml-auto">
            <ArrowDownIcon
              color={"#667085"}
              className={`h-5 w-5 dark:text-[#FAFAFA]  ${
                open ? "rotate-180" : ""
              }`}
              aria-hidden="true"
            />
          </span>
        </div>

        {open && (
          <div className="absolute z-[99999] w-full max-h-40 py-1 ring-opacity-5 overflow-auto mt-1 focus:outline-none rounded-lg shadow-lg bg-white border-secondary-200 border-t border dark:border-[#525252] dark:bg-[#292929]">
            {items &&
              items?.map((item) => (
                <div
                  key={item?.value}
                  onClick={() => handleSelect(item)}
                  className={classNames(
                    selectedItems?.some(
                      (selectedItem) => selectedItem?.value === item?.value
                    )
                      ? "bg-white text-secondary-900 dark:bg-darkTrue-800 dark:text-darkTrue-50"
                      : "text-secondary-900 dark:text-white",
                    "cursor-pointer select-none flex flex-row items-center relative z-50 hover:bg-secondary-50 dark:hover:bg-gray-500 dark:hover:text-darkTrue-50 rounded-md mx-1 p-[10px]  text-md"
                  )}
                >
                  <span
                    className={classNames(
                      "block truncate text-secondary-900",
                      selectedItems?.some(
                        (selectedItem) => selectedItem?.value === item?.value
                      )
                        ? "font-medium"
                        : "font-medium"
                    )}
                  >
                    {item?.label}
                  </span>

                  {selectedItems?.some(
                    (selectedItem) => selectedItem?.value === item?.value
                  ) && (
                    <div className="flex items-center ml-auto">
                      <CheckIcon
                        width={20}
                        height={20}
                        color={
                          theme === "person"
                            ? "#0E9384"
                            : theme === "product"
                            ? "#DC6803"
                            : theme === "embedded"
                            ? "#3E4784"
                            : "#7F56D9"
                        }
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
      {validate ? (
        <p
          className={`${
            validate === "success"
              ? "text-validate-success"
              : validate === "warning"
              ? "text-validate-warning"
              : validate === "error"
              ? "text-validate-error"
              : validate === "tooltip"
              ? "text-secondary-500 text-sm leading-5"
              : ""
          } text-sm mt-1.5 font-normal`}
        >
          {errorMessage || validate.toLocaleUpperCase()}
        </p>
      ) : <></>}
    </div>
  );
};

export default NewSelectInput;
