import React , {useRef} from "react";
import Button from "../../../components/buttons/Button";
import { QRCode } from "react-qrcode-logo";
import { PrinterIcon } from "../../../assets/icons/buttonIcons";
import { useTranslation } from "react-i18next";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useReactToPrint } from "react-to-print";
import { authStore } from "../../../stores/auth.store";
import "./media.css";
import { Canvg } from 'canvg';
import { QRCodeSVG } from "qrcode.react";

const UserQrModal = ({ userId, name, role, department }) => {
  const { t } = useTranslation();
  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "U-" + " " + name + " " + "upu.person",
  });

  const divStyle = {
    background: "linear-gradient(90deg, #FFF 0%, #F9FAFB 25%, #F9FAFB 50%, #F9FAFB 75%, #FFF 100%)",
  };

  const svgRef = useRef(null);

  const downloadSVG = () => {
   
    if (svgRef.current) {
      const svg = svgRef.current.outerHTML;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const v = Canvg.fromString(ctx, svg);
      v.render().then(() => {
        // Add user name to the canvas
        ctx.font = "16px Arial";
        ctx.fillStyle = "#000";
        ctx.fillText(name, 10, 20);

        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `${name}-qr-code.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url); // Release memory
        });
      });
    }
  };

  return (
    <div className="flex flex-col relative">
      <div ref={componentRef} className="print w-[256px] h-[486px] flex flex-col items-center gap-y-4 pt-6">
        <div className="flex w-full min-h-[120px] bg-[#107569]/50 rounded-b-md">
          <div className="flex w-full max-h-[95px] bg-[#107569] flex-col items-center justify-center">
            <img src={generateFileUrl(authStore.user?.company?.image)} className="w-[45px] h-[35px] rounded-sm border border-[#D0D5DD]" />
            <p className="font-medium text-[#FFFFFF] text-lg">{authStore.user?.company?.name}</p>
            <p className="text-[#F0FDF9] font-normal text-md">{t("chat:staffIdentityCard")}</p>
          </div>
        </div>

        <div className="flex flex-col w-full h-full gap-y-2 justify-end">
          <div style={divStyle} className="flex flex-col w-full items-center justify-center">
            <p className="text-[#344054] font-medium text-sm">{t("chat:nameSurnames")}</p>
            <p className="text-[#134E48] font-bold text-md">{name || "--"}</p>
          </div>
          <div style={divStyle} className="flex flex-col w-full items-center justify-center">
            <p className="text-[#344054] font-medium text-sm">{t("routes:department")}</p>
            <p className="text-[#134E48] font-bold text-md">{department || "--"}</p>
          </div>
          <div style={divStyle} className="flex flex-col w-full items-center justify-center">
            <p className="text-[#344054] font-medium text-sm">{t("chat:role")}</p>
            <p className="text-[#134E48] font-bold text-md">{role || "--"}</p>
          </div>
        </div>
        <div className="w-[171px] h-[171px] rounded-lg bg-white flex items-center justify-center">
          <QRCode
            id="qr-code"
            fgColor="#107569"
            bgColor="#FFF"
            size={150}
            ecLevel="M"
            value={userId || undefined}
            eyeRadius={[
              {
                outer: [2, 2, 2, 2],
                inner: [2, 2, 2, 2],
              },
              {
                outer: [2, 2, 2, 2],
                inner: [2, 2, 2, 2],
              },
              {
                outer: [2, 2, 2, 2],
                inner: [2, 2, 2, 2],
              },
            ]}
            qrStyle="squares"
          />
          
          <div className="hidden">
           <QRCodeSVG value={userId} size={256} level="H" marginSize={4} ref={svgRef} />
           </div>
        </div>
      </div>
      <div className="w-full flex items-center gap-4 justify-between mt-4">
        <Button onClick={handlePrint} iconLeft={<PrinterIcon />} label={t("embedded:print")} colorType={"secondary-gray"} size={"md"} />
      
      </div>
      <button className="text-[10px]" onClick={()=>{
        downloadSVG()
      }}>Download QR Code as SVG</button>
    </div>
  );
};

export default UserQrModal;

