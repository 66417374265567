import React, { useEffect, useMemo, useRef, useState } from "react";
import NewOrder from "./modals/NewOrder";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OrderSort from "./components/OrderSort";
import OrderFilterPage from "./OrderPageFilter";
import { debounce } from "../../utils/debounce";
import OrderQrModal from "./modals/OrderQrModal";
import { useStore } from "../../hooks/useStores";
import { useSearchParams } from "react-router-dom";
import SupplyFormModal from "./modals/SupplyFormModal";
import WipRawMaterialCard from "./cards/WipRawMaterialCard";
import { FilterIcon } from "../../assets/icons/commonIcons";
import { orderService } from "../../services/order.service";
import OrderWipProductCard from "./cards/OrderWipProductCard";
import OrderRawMaterialCard from "./cards/OrderRawMaterialCard";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import NoData from "../../steps/step-navigation/components/NoData";
import { DeadlineIconSort } from "../../assets/icons/equipmentIcon";
import { PlusIcon, QuantityIcon } from "../../assets/icons/stepsIcons";
import MrpMultiOperationCard from "./components/MrpMultiOperationCard";
import { CloseIconGray, SearchIcon } from "../../assets/icons/PersonIcons";
import FilterDrawerLast from "../../components/filter-drawer/FilterDrawer";
import { QuantityAcceptedIcon, QuantityDeclineIcon } from "../../assets/icons/productIcons";
import { Button, CustomModal, Loading, PaginationComponent, TableOptions } from "../../components";

const Order = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const outerDivRef = useRef(null);
  const mappedDivRefs = useRef([]);
  const { settings, auth } = useStore();
  const { height } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderData, setOrderData] = useState({});
  const [supplyForm, setSupplyForm] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isOpenQrModal, setIsOpenQrModal] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isNewOrderModal, setIsNewOrderModal] = useState(false);
  const [tableHeadItemsLength, setTableHeadItemsLength] = useState(0);
  const [page, setPage] = useState(Number(searchParams.get("pageNo")) || 1);
  const [sortValue, setSortValue] = useState(searchParams.get("sort") || null);
  const [searchVal, setSearchVal] = useState(searchParams.get("search") || "");
  const [ascDesc, setAscDesc] = useState(searchParams.get("ascDesc") === "true");
  const [pageLimit, setPageLimit] = useState(Number(searchParams.get("take")) || 10);

  const filterString = filterOptions && Object.keys(filterOptions).length > 0 ? encodeURIComponent(JSON.stringify(filterOptions)) : "";

  const fetchData = async (page, search, sortValue, ascDesc, filterString, pageLimit) => {
    const queryParams = new URLSearchParams();
    if (search) queryParams.set("search", search);
    if (sortValue) queryParams.set("sort", sortValue);
    if (page) queryParams.set("pageNo", page.toString());
    if (pageLimit !== 10) queryParams.set("take", pageLimit.toString());
    if (ascDesc !== undefined) queryParams.set("ascDesc", ascDesc.toString());
    setSearchParams(queryParams);

    return await orderService.getOrderListForGet({
      order: ascDesc ? "DESC" : "ASC",
      filter: [filterString],
      take: pageLimit,
      sort: sortValue,
      search: search,
      pageNo: page,
    });
  };

  const { isPending, data, isFetching, isLoading, refetch } = useQuery({
    queryKey: ["product-twin", page, debouncedSearch, sortValue, ascDesc, filterString, pageLimit],
    queryFn: () => fetchData(page, debouncedSearch, sortValue, ascDesc, filterString, pageLimit),
    enabled: !!debouncedSearch || debouncedSearch === "",
    placeholderData: keepPreviousData,
    retry: 1,
  });

  const items = data?.data;

  const _setHeadItems = () => {
    if (items?.length > 0) {
      const head = items?.map((d) => d?.operations?.length);
      setTableHeadItemsLength(Math.max(...head));
    }
  };

  const handleSearchChange = debounce((text) => {
    if (page != 1 && text) setPage(1);
    setDebouncedSearch(text);
  }, 1000);

  const handleScroll = (source, index = null) => {
    let currentScrollLeft;
    if (source === "outer") {
      currentScrollLeft = outerDivRef.current?.scrollLeft;
    } else if (source === "mapped") {
      currentScrollLeft = mappedDivRefs.current[index]?.scrollLeft;
    }
    if (outerDivRef.current && source !== "outer") {
      outerDivRef.current.scrollLeft = currentScrollLeft;
    }
    mappedDivRefs.current.forEach((div, idx) => {
      if (div && idx !== index) {
        div.scrollLeft = currentScrollLeft;
      }
    });
  };

  const tableHeadItems = useMemo(() => {
    const items = [];
    for (let i = 0; i < Math.max(1, tableHeadItemsLength); i++) {
      items.push({ title: `${i + 1}.${t("product:operation")}`, id: i });
    }
    return items;
  }, [tableHeadItemsLength, t]);

  useEffect(() => {
    handleSearchChange(searchVal);
    return () => {
      handleSearchChange.cancel();
    };
  }, [searchVal]);

  useEffect(() => {
    _setHeadItems();
  }, [items]);

  return (
    <>
      <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative scrollbar-hide">
        <div className={`flex w-full md:flex-col md:items-start border-b h-14 min-h-[58px] md:h-12 md:min-h-12  mb-4 items-center  `}>
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
            <div className="flex items-center">
              <span className="font-codecMedium text-2xl text-black">upu.</span>
              <p className="text-2xl font-codecMedium text-[#B54708]">order</p>
              <p className="text-xl font-normal ml-2 text-secondary-500">{t("product:workOrders")}</p>
            </div>
          </span>
          <div className="flex items-center md:hidden sm:hidden flex:hidden">
            <span className="font-codecMedium text-2xl text-black">upu.</span>
            <p className="text-2xl font-codecMedium text-[#B54708]">order</p>
            <p className="text-xl font-normal ml-2 text-secondary-500">{t("product:workOrders")}</p>
          </div>
          {isLoading ? (
            <div className="ml-auto flex h-[42px] w-[828px] bg-gray-200 rounded-xl animate-pulse" />
          ) : (
            <div className="ml-auto flex flex-row items-center gap-x-3 md:items-start md:ml-0 md:mt-6">
              <div className="w-[320px] max-w-[320px] min-w-[320px] md:w-[300px] md:min-w-[300px] md:max-w-[300px] sm:hidden md:flex step-2">
                <div
                  className={`min-h-[42px] max-h-[42px] focus-within:border-warning-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#FDE3C1] relative rounded-lg border border-secondary-300 flex items-center dark:border-[#424242] shadow-xs`}
                >
                  <div className="min-h-[42px] max-h-[42px] min-w-[42px] max-w-[42px] text-[#667085] dark:text-[#A3A3A3] justify-center flex items-center">
                    <SearchIcon width={20} height={20} />
                  </div>
                  <input
                    value={searchVal}
                    onChange={({ target }) => setSearchVal(target.value)}
                    className="h-full min-h-[40px] max-h-[40px] flex-1 flex placeholder:text-[#667085] placeholder:dark:text-[#737373] text-[#667085] dark:text-[#737373] text-md font-normal dark:bg-[#141414] rounded-lg focus:outline-none focus:border-none"
                    placeholder={t("chat:search")}
                  />
                  {searchVal && (
                    <div onClick={() => setSearchVal("")} className="w-8 h-8 items-center justify-center flex cursor-pointer">
                      <CloseIconGray />
                    </div>
                  )}
                </div>
              </div>
              <div className="hidden sm:flex md:hidden">
                <Button size="md" iconLeft={<SearchIcon />} colorType={"secondary-gray"} />
              </div>
              <OrderSort
                sortValue={sortValue}
                setSortValue={setSortValue}
                ascDesc={ascDesc}
                setAscDesc={setAscDesc}
                options={[
                  { label: t("product:orderNo"), value: "piece", icon: <QuantityIcon /> },
                  { label: t("product:finishedPiece"), value: "finishedPiece", icon: <QuantityAcceptedIcon /> },
                  { label: t("product:rejectedPieces"), value: "rejectedPiece", icon: <QuantityDeclineIcon /> },
                  { label: t("chat:deadLine"), value: "deliveryDate", icon: <DeadlineIconSort /> },
                ]}
              />
              <Button colorType={"secondary-gray"} label={"Filter"} iconLeft={<FilterIcon />} size={"md"} onClick={() => setIsFilterOpen(!isFilterOpen)} />
              <TableOptions pageLimit={pageLimit} setPageLimit={setPageLimit} />
              <Button
                colorType={"primary-product"}
                size={"md"}
                label={t("product:createNewOrder")}
                iconLeft={<PlusIcon />}
                onClick={() => navigate("/app/product/mrp/orders/new-order")}
              />
            </div>
          )}
        </div>
        {isPending || isLoading ? (
          <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse md:mt-16" />
        ) : (
          <div style={{ minHeight: height - 50 }} className="w-full border shadow-md rounded-xl border-[#D0D5DD] overflow-hidden md:mt-16 relative">
            {isFetching && (
              <div className="absolute inset-0 z-[99999] bg-[#0F0F0F] bg-opacity-30 backdrop-blur-[2px] flex items-center justify-center">
                <Loading secondary={"#0F0F0F50"} primary={"#fff"} size={20} />
                <p className="text-lg font-medium text-white ml-2">Yükleniyor...</p>
              </div>
            )}
            {items?.length <= 0 ? (
              <div className="flex w-full h-full justify-center items-center">
                <NoData header={t("label:noData")} button={false} />
              </div>
            ) : (
              <div className="w-full h-full flex flex-col relative overflow-hidden">
                <div className="flex flex-row w-full h-11 min-h-[44px] bg-[#F9FAFB] border-[#EAECF0] rounded-tl-xl rounded-tr-xl border-b">
                  <div className="flex items-center justify-center max-w-[356px] min-w-[356px] h-full border-r border-[#EAECF0] text-sm font-semibold text-[#344054]">
                    {t("routes:Product")}
                  </div>
                  <div ref={outerDivRef} onScroll={() => handleScroll("outer")} className="flex items-center w-full h-full overflow-x-auto overflow-y-hidden">
                    <div className="min-w-[356px] min-h-[44px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200">
                      {t("product:rawMaterial")}
                    </div>
                    {tableHeadItems?.map((item, index) => (
                      <div
                        key={index}
                        className="min-w-[346px] min-h-[44px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200"
                      >
                        {item?.title}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={`flex flex-col w-full flex-1 overflow-y-auto scrollbar-hide`}>
                  {items?.map((order, index) => {
                    const rawMaterialData = {
                      id: order?.rawMaterial?.id,
                      name: t("product:purchasing"),
                      status: order?.rawMaterial?.status,
                      materials: order?.rawMaterial?.materials,
                      source: order?.rawMaterial?.material?.source,
                    };
                    const filledOperations = [
                      rawMaterialData,
                      ...(order?.operations || []),
                      ...Array.from({ length: Math.max(tableHeadItemsLength - (order?.operations || [])?.length, 0) }, () => null),
                    ];
                    return (
                      <div key={index} style={{ height: "2000px" }} className="flex w-full h-[128px]">
                        <div className="max-w-[356px] min-w-[356px] h-full px-4 py-2 border-r ">
                          <OrderWipProductCard
                            item={order}
                            refetch={refetch}
                            qrFunction={(order) => {
                              setSelectedOrder(order);
                              setIsOpenQrModal(true);
                            }}
                          />
                        </div>
                        <div
                          ref={(el) => (mappedDivRefs.current[index] = el)}
                          onScroll={() => handleScroll("mapped", index)}
                          id="content-wrapper"
                          className="w-full overflow-x-auto scrollbar-hide flex border-b border-gray-200"
                        >
                          <div key={index} className="min-w-[356px] flex items-center py-2 px-4 border-r border-gray-200">
                            {order?.purchaseRequest?.length > 0 ? (
                              <>
                                {order?.purchaseRequest[0]?.source === "stock" ? (
                                  <WipRawMaterialCard item={order?.purchaseRequest[0]} order={order} />
                                ) : (
                                  <WipRawMaterialCard item={order?.purchaseRequest[0]} order={order} />
                                )}
                              </>
                            ) : (
                              <OrderRawMaterialCard item={order} />
                            )}
                          </div>
                          {filledOperations
                            ?.sort((a, b) => a?.step - b?.step)
                            ?.map((item, index) => {
                              if (index > 0)
                                return (
                                  <div key={index} className="min-w-[346px] flex items-center justify-center py-2 px-4 border-r border-gray-200">
                                    {item != null && (
                                      <MrpMultiOperationCard
                                        item={item}
                                        order={order}
                                        index={index}
                                        isLast={index + 1 === filledOperations?.length}
                                        onClick={() => navigate(`/app/product/mrp/work-in-process/detail/${item?.orderId}+${item?.step}`)}
                                      />
                                    )}
                                  </div>
                                );
                            })}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="z-50 border-t border-[#D0D5DD] bg-white rounded-b-xl">
                  <PaginationComponent pageNo={Number(page || 1)} setPageNo={setPage} pageCount={data?.meta?.pageCount} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <CustomModal
        width={900}
        isBadges={true}
        badgesSize={"md"}
        isOpen={isNewOrderModal}
        badgesColor={"fill-warning"}
        setIsOpen={setIsNewOrderModal}
        modalTitle={t("product:createNewOrder")}
        badgesValue={data?.meta?.itemCount + "/" + auth?.user?.company?.limits?.order}
        children={
          <NewOrder
            t={t}
            refresh={refetch}
            orderData={orderData}
            supplyForm={supplyForm}
            isOpen={isNewOrderModal}
            setOrderData={setOrderData}
            setSupplyForm={setSupplyForm}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            setIsNewOrderModal={setIsNewOrderModal}
          />
        }
      />
      <CustomModal
        isOpen={isOpenQrModal}
        setIsOpen={setIsOpenQrModal}
        modalTitle={t("embedded:getQr")}
        children={<OrderQrModal orderId={selectedOrder?.product?.id} order={selectedOrder} />}
      />
      <CustomModal
        isOpen={supplyForm}
        setIsOpen={setSupplyForm}
        modalTitle={t("product:supplyForm")}
        children={
          <SupplyFormModal
            refresh={refetch}
            orderData={orderData}
            supplyForm={supplyForm}
            setSupplyForm={setSupplyForm}
            selectedProduct={selectedProduct}
            onClose={() => setSupplyForm(false)}
          />
        }
      />
      <div style={{ zIndex: 99999 }} className="absolute top-3 right-0">
        <FilterDrawerLast
          isOpen={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
          width={400}
          children={<OrderFilterPage setFilterOptions={setFilterOptions} setIsFilterOpen={setIsFilterOpen} filterOptions={filterOptions} />}
        />
      </div>
    </>
  );
};

export default Order;
