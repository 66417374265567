import React from "react";
import CustomImage from "../CustomImage";
import { NoEquIcon } from "../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

const ServiceSupplyRawCard = ({ item, selectedLeftCardId }) => {
  const { t } = useTranslation();
  const units = [
    { shortLabel: "kg", value: "kilogram" },
    { shortLabel: "ton", value: "ton" },
    { shortLabel: "gr", value: "gram" },
    { shortLabel: "mg", value: "miligram" },
    { shortLabel: "mcg", value: "mikrogram" },
    { shortLabel: "lb", value: "libre" },
    { shortLabel: "oz", value: "ons" },
    { shortLabel: "m", value: "metre" },
    { shortLabel: "mm", value: "milimetre" },
    { shortLabel: "gal", value: "galon" },
    { shortLabel: "m2", value: "metrekare" },
    { shortLabel: "mm2", value: "milimetrekare" },
    { shortLabel: "in2", value: "inçkare" },
    { shortLabel: "ad", value: "adet" },
    { shortLabel: "pkg", value: "paket" },
    { shortLabel: "box", value: "kutu" },
  ];
  const getUnitLabel = (unit) => {
    if (unit === undefined) {
      return "--";
    }
    const foundUnit = units.find((u) => u.value === unit);
    return foundUnit ? foundUnit.shortLabel : unit;
  };
  const totalSsPlanned = item?.ssPlanedJunctions?.reduce((acc, curr) => acc + (curr?.targetedWorkCount || 0), 0);
  return (
    <div
      className={`flex bg-white flex-row mr-4 w-[324px] min-w-[324px] h-[112px] min-h-[112px] relative group pl-3 pr-3  pt-2 border ${
        selectedLeftCardId === item?.id ? "shadow-ring-product border-[#FEC84B]" : "border-[#D0D5DD]"
      }  rounded-lg cursor-grab `}
    >
      <div className="flex flex-col w-[93%]">
        <div className="flex flex-row items-center gap-x-3">
          {item?.flexProduct ? (
            <>
              {item?.flexProduct?.image ? (
                <div data-tooltip-id={`${item?.id}`} data-tooltip-place="right" data-tooltip-content={`${item?.flexProduct?.name}`}>
                  <CustomImage
                    borderRadius={2}
                    src={item?.flexProduct?.image}
                    style={{
                      width: 64,
                      height: 48,
                      minWidth: 64,
                      minHeight: 48,
                      borderRadius: 4,
                      borderWidth: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                      borderColor: "#0000001A",
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              ) : (
                <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                  <NoEquIcon />
                </div>
              )}
            </>
          ) : item?.externalOperation?.product ? (
            <>
              {item?.externalOperation?.product?.image ? (
                <div data-tooltip-id={`${item?.id}`} data-tooltip-place="right" data-tooltip-content={`${item?.externalOperation?.product?.name}`}>
                  <CustomImage
                    borderRadius={2}
                    src={item?.externalOperation?.product?.image}
                    style={{
                      width: 64,
                      height: 48,
                      minWidth: 64,
                      minHeight: 48,
                      borderRadius: 4,
                      borderWidth: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                      borderColor: "#0000001A",
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              ) : (
                <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                  <NoEquIcon />
                </div>
              )}
            </>
          ) : item?.job?.product ? (
            <>
              {item?.job?.product?.image ? (
                <div data-tooltip-id={`${item?.id}`} data-tooltip-place="right" data-tooltip-content={`${item?.job?.product?.name}`}>
                  <CustomImage
                    borderRadius={2}
                    src={item?.job?.product?.image}
                    style={{
                      width: 64,
                      height: 48,
                      minWidth: 64,
                      minHeight: 48,
                      borderRadius: 4,
                      borderWidth: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                      borderColor: "#0000001A",
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              ) : (
                <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                  <NoEquIcon />
                </div>
              )}
            </>
          ) : (
            <>
              {item?.image ? (
                <div data-tooltip-id={`${item?.id}`} data-tooltip-place="right" data-tooltip-content={`${item?.name}`}>
                  <CustomImage
                    borderRadius={2}
                    src={item?.image}
                    style={{
                      width: 64,
                      height: 48,
                      minWidth: 64,
                      minHeight: 48,
                      borderRadius: 4,
                      borderWidth: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                      borderColor: "#0000001A",
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              ) : (
                <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                  <NoEquIcon />
                </div>
              )}
            </>
          )}
          <div className="flex flex-col items-start">
            <div className="flex flex-row items-center gap-x-1  w-full">
              <p className="font-semibold w-[175px] text-secondary-900 text-base truncate">
                {/* {item?.flexProduct ? item?.flexProduct?.name || "Product Name" : item?.externalOperation ? item?.externalOperation?.product?.name || "Product Name" : item?.job?.product ? item?.job?.product?.name || "Product Name" : item?.name || "Product Name"} */}
                {item?.flexProduct?.name || item?.externalOperation?.product?.name || item?.job?.product?.name || item?.name || "Product Name"}
              </p>
            </div>
            <div
              className={`h-6 max-w-[190px] flex px-1 py-[3px] ml-[2px] rounded-md items-center justify-center bg-[${
                item?.flexProduct || item?.flexRawMaterial || item?.name ? "#F9F5FF" : "#FFFAEB"
              }] `}
            >
              <p
                className={`text-sm font-normal max-w-[195px] truncate ${
                  item?.flexProduct || item?.flexRawMaterial || item?.name ? "text-[#6941C6]" : "text-[#B54708]"
                }`}
              >
                {item?.flexProduct || item?.flexRawMaterial ? `${t("product:stock")} :` : ""} {""}
                {item?.flexProduct
                  ? item?.flexProduct?.stockCode
                  : item?.flexRawMaterial
                  ? item?.stockCode
                  : item?.job?.product
                  ? item?.job?.product?.stockCode
                  : item?.externalOperation?.product?.stockCode || "--"}{" "}
                {item?.job ? `(${item?.header?.step + ".Op"})` : item?.externalOperation && !item?.flexProduct && `(${item?.step + ".Op"})`}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center gap-x-1 w-[100%]">
          <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
            <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
              <p className="text-[10px] font-medium text-secondary-600">{t("product:inStock")}</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
              <p className="text-xs font-medium text-secondary-700">
                {item?.flexProduct ? item?.piece || "0" : item?.externalOperation ? item?.externalOperation?.activePiece || "0" : item?.qualityQuantity || "0"}
              </p>
            </div>
          </div>
          <div className="flex flex-col h-[42px] min-w-[28%] w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
            <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
              <p className="text-[10px] font-medium text-secondary-600">{t("product:activeRequest")}</p>
            </div>
            <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
              <p className="text-xs font-medium text-secondary-700">
                {item?.flexProduct ? totalSsPlanned : item?.header ? item?.header?.piece : item?.piece || "0"}
              </p>
            </div>
          </div>
          <div className="w-[44%] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
            <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
              <span className="text-xxs truncate text-[#475467] font-medium ">{"SS"}</span>
            </div>
            <div className="flex flex-col w-full items-start">
              <div className="flex flex-row items-center w-full border-b px-1">
                <p className="text-xxs font-normal text-secondary-600">{t("product:remainings")}</p>
                <p className="text-xs font-medium ml-auto text-secondary-600">
                  {item?.header ? item?.header?.piece - item?.header?.finishedPiece : item?.piece - item?.finishedPiece || "0"}
                </p>
              </div>
              <div className="flex flex-row items-center w-full px-1">
                <p className="text-xxs font-normal text-secondary-600">{t("product:plannedNew")}</p>
                <p className="text-xs font-medium ml-auto text-secondary-600">{item?.header ? item?.header?.finishedPiece : totalSsPlanned || "0"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`absolute right-0 bg-[${
          item?.flexProduct || item?.flexRawMaterial ? "#F9F5FF" : "#F2F4F7"
        }]  bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
      >
        <p
          className={`text-[10px] font-semibold whitespace-nowrap ${
            item?.flexProduct || item?.flexRawMaterial ? "text-[#7F56D9]" : "text-[#475467]"
          } -rotate-90`}
        >
          {item?.flexProduct ? item?.step + ".Flex" : item?.flexRawMaterial ? "Flex" : t("product:semiProduct")}
        </p>

        {/* {item?.externalOperation ? (
                    <p className="text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90">
                        {"Ara Mamül"}
                    </p>
                ) : item?.flexProduct ? (
                    <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
                        {item?.flexProduct ? item?.step + ".Flex" : item?.flexRawMaterial ? "Flex" : "Ara Mamül"}
                    </p>
                ) : item?.flexRawMaterial ? (
                    <p className={`text-[10px] font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>
                        {item?.flexProduct ? item?.step + ".Flex" : item?.flexRawMaterial ? "Flex" : "Ara Mamül"}

                    </p>
                ) : (
                    "--"
                )} */}
      </div>

      <Tooltip className="z-[120]" id={`${item?.id}`} />
    </div>
  );
};

export default ServiceSupplyRawCard;
