import React, { useEffect, useState } from "react";
import TextArea from "../../../components/inputs/TextArea";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, UploadProfile } from "../../../components";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "../../../components/inputs/PhoneInput";
import { rawMaterialService } from "../../../services/raw-material.service";
import { Toast } from "../../../utils/toastify/toast";
import { externalOperationService } from "../../../services/external-operation.service";
import NewSelectInput from "../../../components/inputs/NewSelectInput";
const SupplierModal = ({
  onClose,
  refetch,
  selected,
  isSelectTab,
  groupsData,
}) => {
  const { t } = useTranslation();
  const [group, setGroup] = useState([]);
  const [error, setError] = useState(null);
  const [validateError, setValidateError] = useState(false);
  const schema = yup.object({
    supplierImage: yup.string().notRequired(),
    companyName: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    authorization: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    email: yup
      .string()
      .email()
      .notRequired()
      // .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:email")}`),
    phone: yup
      .number()
      .notRequired()
      .typeError(`${t("validation:number")}`),
    // productType: yup
    //   .string()
    //   // .required(`${t("validation:empty")}`)
    //   .typeError(`${t("validation:string")}`),
    address: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (value) => {
    const send = {
      image: value?.supplierImage,
      name: value?.companyName,
      contactName: value?.authorization,
      contactEmail: value?.email,
      contactPhone: value?.phone,
      address: value?.address,
      group: group,
      productionType:
        isSelectTab == "material" ? value?.productType : undefined,
    };
    if (selected?.id) {
      if (isSelectTab == "material") {
        await rawMaterialService
          .updateSuplier(selected?.id, send)
          .then((res) => {
            if (res?.data?.code === 0) {
              Toast("success", res?.data?.message);
              onClose();
              refetch();
            } else {
              Toast("error", res?.data?.message);
              onClose();
            }
          });
      } else {
        await externalOperationService
          .editSupplier(selected.id, send)
          .then((response) => {
            if (response.data.code == 0) {
              Toast("success", response?.data?.message);
              onClose();
              refetch();
            } else {
              Toast("error", response?.data?.message);
              onClose();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      if (isSelectTab == "material") {
        if (group?.length === 0) {
          setError("Bir tedarikçi seçininiz.");
          setValidateError(true)
          return
        } else {
          setError(null);
          setValidateError(false)
        }
        await rawMaterialService.addSupplier(send).then((res) => {
          if (res?.code === 0) {
            Toast("success", res?.message);
            onClose();
            refetch();
          } else {
            Toast("error", res?.message);
            onClose();
            refetch();
          }
        });
      } else {
        if (group?.length === 0) {
          setError("Bir tedarikçi seçiniz.");
          setValidateError(true)
          return;
        } else {
          setError(null);
          setValidateError(false)
        }
        await externalOperationService
          .addSupplier(send)
          .then((response) => {
            if (response?.data?.code == 0) {
              refetch();
              onClose();
              Toast("success", t("product:addedSupplier"));
            } else if (response?.data?.code == 2) {
              onClose();
              Toast("error", t("product:noLimitSupplier"));
            } else {
              onClose();
              Toast("error", t("product:errors"));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  useEffect(() => {
    if (selected) {
      setValue("supplierImage", selected?.image);
      setValue("companyName", selected.name);
      setValue("authorization", selected?.contactName);
      setValue("email", selected?.contactEmail);
      setValue("phone", selected?.contactPhone);
      setValue("productType", selected?.productionType);
      setValue("address", selected?.address);
      setGroup(
        selected?.group?.map((el) => {
          return el?.id;
        })
      );
    }
  }, [selected]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-[632px] flex flex-col ">
      <p className="text-xl font-semibold text-secondary-900 dark:text-[#FAFAFA]">{selected ? t("product:editSupplier") : t("product:addSupplier")}</p>
      <div className="flex gap-x-6 flex-row w-full mt-6">
        <div className="flex flex-col  gap-4 w-[49.5%] ">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <UploadProfile
                title={t("product:uploadImage")}
                subTitle={t("fileInput:removePicture")}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
            name="supplierImage"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                theme={"product"}
                label={t("addCompany:companyName")}
                errorMessage={errors?.companyName?.message}
                validate={errors?.companyName ? "error" : ""}
              />
            )}
            name="companyName"
          />
          <NewSelectInput
            items={groupsData?.map((el) => {
              return { label: el.name, value: el.id };
            })}
            value={group}
            theme={"product"}
            placeholder={t("product:supplierGroup")}
            onChange={(e) => {
              setGroup(e);
              setValidateError(false)
              setError(null);
            }}
            validate={validateError ? "error" : ""}
            errorMessage={validateError ? error : ""}
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                theme={"product"}
                label={t("addCompany:contactName")}
                errorMessage={errors?.authorization?.message}
                validate={errors?.authorization ? "error" : ""}
              />
            )}
            name="authorization"
          />

        </div>
        <div className="h-[290px] w-px bg-gray-200 " />
        <div className="flex flex-col gap-4  w-[49.5%]">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <PhoneInput
                label={t("table:phoneNo")}
                disabled={false}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                theme={"product"}
                validate={errors?.phone ? "error" : ""}
                errorMessage={errors?.phone ? errors?.phone?.message : ""}
              />
            )}
            name="phone"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("product:email")}
                disabled={false}
                onBlur={onBlur}
                onChange={onChange}
                theme={"product"}
                value={value}
                validate={errors?.email ? "error" : ""}
                errorMessage={errors?.email ? errors?.email?.message : ""}
              />
            )}
            name="email"
          />
          {/* {isSelectTab == "material" && (
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("product:productionType")}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={onChange}
                  theme={"product"}
                  value={value}
                  validate={errors?.productType ? "error" : ""}
                  errorMessage={
                    errors?.productType ? errors?.productType?.message : ""
                  }
                />
              )}
              name="productType"
            />
          )} */}
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextArea
                label={t("routes:adress")}
                width={"100%"}
                onBlur={onBlur}
                onChange={onChange}
                theme={"product"}
                height={104}
                value={value}
              />
            )}
            name="address"
          />
        </div>
      </div>
      <div className="w-[50%] flex items-center ml-auto gap-3 mt-6">
        <Button
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          size={"md"}
          onClick={onClose}
        />
        <Button
          type={"submit"}
          label={t("buttons:save")}
          colorType={"primary-product"}
          size={"md"}
        />
      </div>
    </form>
  );
};

export default SupplierModal;
