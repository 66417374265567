import React from 'react'
import { Tooltip } from 'react-tooltip';
import { NoEquIcon } from '../../../assets/icons/machineIcons';
import { useTranslation } from 'react-i18next';
import CustomImage from '../../../components/CustomImage';

const RelatedProductCard = ({item}) => {
    const {t} = useTranslation()
  return (
    <div className="flex bg-white w-[312px] min-w-[312px] flex-row h-[112px] min-h-[112px] relative group pl-3  pt-2 border border-[#D0D5DD] rounded-lg cursor-grab ">
    <div className="flex flex-col w-[90%]">
      <div className="flex flex-row items-center gap-x-3">
        {item?.image ? (
          <div>
            <CustomImage
              borderRadius={2}
              src={item?.image}
              style={{
                width: 64,
                height: 48,
                minWidth: 64,
                minHeight: 48,
                borderRadius: 4,
                borderWidth: 1,
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
                borderColor: "#0000001A",
                backgroundColor: "#fff",
              }}
            />
          </div>
        ) : (
          <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
            <NoEquIcon />
          </div>
        )}
        <div className="flex flex-col items-start">
          <div className="flex flex-row items-center gap-x-1 w-full">
            <p className="font-semibold text-secondary-900 text-base w-[185px] truncate">{item?.revisionNo ? (item?.revisionNo != 0 ? `${item?.name} (Rev-${item?.revisionNo})` : item?.name) : item?.name}</p>
          </div>
          <div className='flex flex-row items-center gap-x-1'>
            <div className="bg-[#FFFAEB] max-w-[185px]   h-6 rounded px-[3px] py-1">
              <p className="text-xs font-medium max-w-[185px] truncate text-[#B54708]">
                {t("product:stock")} :{" "}
                {item?.revisionNo ? (item?.revisionNo != 0 ? `REV-${item?.revisionNo}/${item?.stockCode} ` : item?.stockCode) : item?.stockCode || "--"}
              </p>
            </div>

          </div>
        </div>
      </div>
      <div className='flex flex-row items-center gap-x-1.5 w-full'>
        <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
          <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
            <p className="text-[10px] font-medium text-secondary-600">{t("product:orderQty")}</p>
          </div>
          <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
            <p className="text-xs font-medium text-secondary-700">{item?.inOrder || "0"}</p>
          </div>
        </div>
        <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
          <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
            <p className="text-[10px] font-medium text-secondary-600">{t("product:inStocks")}</p>
          </div>
          <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
            <p className="text-xs font-medium text-secondary-700">{item?.qualityQuantity || "0"}</p>
          </div>
        </div>
        <div className="w-[44%] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
          <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
            <span className="text-xxs truncate text-[#475467] font-medium ">{t("product:workOrder")}</span>
          </div>
          <div className="flex flex-col w-full items-start">
            <div className="flex flex-row items-center w-full border-b px-1">
              <p className="text-xxs font-normal text-secondary-600">{t("product:production")}</p>
              <p className="text-xs font-medium max-w-[40px] truncate ml-auto text-secondary-600">{item?.openWorkOrder || "0"}</p>
            </div>
            <div className="flex flex-row items-center w-full px-1">
              <p className="text-xxs font-normal text-secondary-600">{t("product:ready")}</p>
              <p className="text-xs font-medium max-w-[40px] truncate ml-auto text-secondary-600">{item?.readyWorkOrder || "0"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className={`flex items-center bg-[#F2F4F7] justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0  border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}>
      <p className={`text-xxs font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>{t("product:productss")} ({t("product:pcs")})</p>
    </div>
   
 
    <Tooltip className="z-50" id={`${item?.id}`} />
  </div>
  )
}

export default RelatedProductCard