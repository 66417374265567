import React from "react";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../../components/CustomImage";
import { AcceptedIcon, EquCalculate, GreenAvgIcon, RedAvgIcon, RejectedIcon, TargetIcon } from "../../../components/new-cards/card-icons";
import { ClockIcon, PieceIcon } from "../../../assets/icons/commonIcons";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useStore } from "../../../hooks/useStores";
const OperationDetailLegacyCard = ({ data, operationType }) => {
  const { t } = useTranslation();

  const oneOrderTime = (start, end, count, type) => {
    const diff = moment(end).diff(moment(start), "seconds");
    const oneOrder = diff / count;
    return type === "setup" ? diff : `${oneOrder?.toFixed(0)}`;
  };

  const convertSeconds = (seconds) => {
    if (isNaN(seconds) || seconds == Infinity) return "---";

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Number(seconds % 60).toFixed(0);

    let formattedTime = "";

    if (minutes > 0) {
      formattedTime += minutes + " dk ";
    }

    if (remainingSeconds > 0) {
      formattedTime += remainingSeconds + " sn";
    }

    return formattedTime.trim();
  };

  const totalEstimatedTime = data?.productType === "flex" ? data?.flexHeader?.estimatedTime : data?.header?.internalOperation?.estimatedTime;
  const setupEstimatedTime =
    data?.productType === "flex" ? data?.flexHeader?.estimatedSettingsTime * 60 : data?.header?.internalOperation?.estimatedSettingsTime * 60;
  const formattedEstimatedOpTime =
    data?.type === "setup"
      ? convertSeconds(
          data?.productType === "flex" ? data?.flexHeader?.estimatedSettingsTime * 60 : data?.header?.internalOperation?.estimatedSettingsTime * 60
        )
      : convertSeconds(totalEstimatedTime);

  const startDate = data?.startDate;
  const endDate = data?.endDate;
  const madeWorkCount = data?.metadata?.madeWorkCount;
  const type = data?.type;
  const oneOrderTimeInSeconds = oneOrderTime(startDate, endDate, madeWorkCount, type);
  const formattedOneOrderTime = convertSeconds(oneOrderTimeInSeconds);
  const isRedOld = data?.type == "setup" ? setupEstimatedTime < oneOrderTimeInSeconds : totalEstimatedTime < oneOrderTimeInSeconds;

  const estimatedTimeGroup = data?.header
    ? data?.header?.internalOperation?.plannableNodes?.find((element) => {
        return element?.id == data?.station?.id;
      })
    : data?.flexHeader?.plannableNodes
    ? data?.flexHeader?.plannableNodes?.find((element) => {
        return element?.id == data?.station?.id;
      })
    : data?.flexHeader?.estimatedTime;

  const estimatedTimeInSeconds =
    data?.type === "setup"
      ? estimatedTimeGroup?.estimatedSettingsTime
        ? estimatedTimeGroup?.estimatedSettingsTime
        : estimatedTimeGroup
      : estimatedTimeGroup?.estimatedTime
      ? estimatedTimeGroup?.estimatedTime
      : estimatedTimeGroup;

  const isRed = estimatedTimeInSeconds < oneOrderTimeInSeconds;

  return (
    <div
      className={`flex w-full flex-col border border-t-4 ${
        data?.type === "work" ? "border-t-success-500" : data?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
      } items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
    >
      <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
        <div className="flex flex-row items-center gap-x-2 ">
          {data?.productType === "flex" ? (
            <>
              {data?.flexProduct?.image ? (
                <div>
                  <CustomImage
                    borderRadius={2}
                    src={data?.flexProduct?.image}
                    style={{
                      width: 64,
                      height: 48,
                      minWidth: 64,
                      minHeight: 48,
                      borderRadius: 4,
                      borderWidth: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                      borderColor: "#0000001A",
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              ) : (
                <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                  <NoEquIcon />
                </div>
              )}
            </>
          ) : (
            <>
              {operationType === "internal" ? (
                <>
                  {data?.station?.metadata?.image ? (
                    <div>
                      <CustomImage
                        borderRadius={2}
                        src={data?.station?.metadata?.image}
                        style={{
                          width: 64,
                          height: 48,
                          minWidth: 64,
                          minHeight: 48,
                          borderRadius: 4,
                          borderWidth: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          display: "flex",
                          borderColor: "#0000001A",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  ) : (
                    <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                      <NoEquIcon />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {data?.supplier?.image ? (
                    <div>
                      <CustomImage
                        borderRadius={2}
                        src={data?.supplier?.image}
                        style={{
                          width: 64,
                          height: 48,
                          minWidth: 64,
                          minHeight: 48,
                          borderRadius: 4,
                          borderWidth: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          display: "flex",
                          borderColor: "#0000001A",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  ) : (
                    <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                      <NoEquIcon />
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <div className="flex flex-col items-start gap-y-[6px]">
            <div className="flex items-center gap-y-1">
              <ClockIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">
                {data?.startDate ? moment(data?.startDate).format("HH:mm") : "--"} - {data?.endDate ? moment(data?.endDate).format("HH:mm") : "--"}
              </p>
              <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:hours")}</p>
            </div>
            <div className="flex items-center gap-y-1">
              <QuantityIcon />
              <p className="text-xs font-medium ml-1 text-secondary-600">{data?.madeWorkCount || "0"}</p>
              <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:pcs")}</p>
            </div>
          </div>
        </div>
        {data?.user?.avatar ? (
          <div className="ml-auto pr-24">
            <CustomImage
              src={data?.user?.avatar}
              style={{
                width: 48,
                height: 48,
                minWidth: 48,
                minHeight: 48,
                flexDirection: "column",
                borderRadius: 100,
                display: "flex",
                borderColor: "#0000001A",
                backgroundColor: "#fff",
              }}
            />
          </div>
        ) : (
          <div className="ml-auto pr-24">
            <NoAvatar size={48} fontSize={22} name={data?.user?.name} lastName={data?.user?.lastName} color={"#B54708"} />
          </div>
        )}
        <div className="flex flex-col z-[20] items-start rounded border-[0.5px] ml-auto absolute border-[#D0D5DD] w-[86px] min-w-[86px] bg-white top-[6px] right-[6px]">
          <div
            className={`flex flex-row items-center justify-center gap-x-1 h-5 min-h-5 px-[2px] py-[2px] ${
              data?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"
            }  w-full rounded-t-[4px] border-b-[0.5px] border-secondary-300 ml-auto`}
          >
            {data?.metadata?.qualityStatus === "waiting" ? (
              <p className="text-xxs text text-secondary-600 font-semibold">{t("product:applied")}</p>
            ) : (
              <>
                <p className="text-xxs text text-secondary-600 font-semibold">{t("product:qualityNew")}</p>
                <div className="h-[14px] min-h-[14px] flex items-center justify-center bg-[#B54708] px-1 py-[1px] rounded">
                  <p className="text-xxs font-semibold text-white">
                    %{data?.productType === "flex" ? data?.flexHeader?.qualityPercent || "--" : data?.header?.internalOperation?.qualityPercent || "--"}
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
            <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">{data?.metadata?.qualityStatus === "waiting" ? <PieceIcon /> : <AcceptedIcon />}</div>
            <p className={`text-xs font-medium ml-1 ${data?.metadata?.qualityStatus === "waiting" ? "text-secondary-600" : "text-[#079455]"} `}>
              {data?.metadata?.qualityStatus === "waiting" ? data?.madeWorkCount || "0" : data?.acceptedWorkCount || "0"}
            </p>
            <p className="text-[10px] font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
          </div>
          <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
            <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
              {data?.metadata?.qualityStatus === "waiting" ? <EquCalculate /> : <RejectedIcon />}
            </div>
            <p className={`text-xs font-medium ml-1 ${data?.metadata?.qualityStatus === "waiting" ? "text-[#6941C6]" : "text-error-600"} `}>
              {data?.metadata?.qualityStatus === "waiting" ? data?.metadata?.calculateWorkCount || "0" : data?.metadata?.rejectedWorkCount || "0"}
            </p>
            <p className="text-xxs font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full ${
          data?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"
        }  border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg`}
      >
        <div className="flex flex-row items-center">
          <TargetIcon />
          <p className="text-xs font-medium ml-1 text-secondary-600">
            {data?.header?.externalOperation
              ? data?.header?.externalOperation?.estimatedTime
              : data?.header?.internalOperation?.plannableNodes?.length == 0
              ? formattedEstimatedOpTime || "--"
              : data?.type === "setup"
              ? estimatedTimeGroup?.estimatedSettingsTime
                ? `${Math.floor(estimatedTimeGroup?.estimatedSettingsTime / 60 || 0)} dk ${(estimatedTimeGroup?.estimatedSettingsTime % 60 || 0).toFixed(
                    0
                  )} sn` || ""
                : `${Math.floor(estimatedTimeGroup / 60 || 0)} dk ${(estimatedTimeGroup % 60 || 0).toFixed(0)} sn` || ""
              : estimatedTimeGroup?.estimatedTime
              ? `${Math.floor(estimatedTimeGroup?.estimatedTime / 60 || 0)} dk ${(estimatedTimeGroup?.estimatedTime % 60 || 0).toFixed(0)} sn` || ""
              : `${Math.floor(estimatedTimeGroup / 60 || 0)} dk ${(estimatedTimeGroup % 60 || 0).toFixed(0)} sn` || ""}
          </p>
          {data?.header?.externalOperation ? (
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:day")}</p>
          ) : (
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:targetMinActualss")}</p>
          )}
        </div>
        <div className="flex flex-row items-center">
          {data?.header?.internalOperation?.plannableNodes?.length == 0 ? (
            isRedOld ? (
              <RedAvgIcon />
            ) : (
              <GreenAvgIcon />
            )
          ) : isRed ? (
            <RedAvgIcon />
          ) : (
            <GreenAvgIcon />
          )}
          <p
            className={`text-xs font-medium ml-1 ${
              data?.header?.internalOperation?.plannableNodes?.length == 0
                ? isRedOld
                  ? "text-[#D92D20]"
                  : "text-[#079455]"
                : isRed
                ? "text-[#D92D20]"
                : "text-[#079455]"
            }`}
          >
            {formattedOneOrderTime || "--"}
          </p>
          <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:average")}</p>
        </div>
      </div>
    </div>
  );
};

export default OperationDetailLegacyCard;

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M4.81234 1.16675H2.62484C1.81942 1.16675 1.1665 1.81967 1.1665 2.62508V11.3751C1.1665 12.1805 1.81942 12.8334 2.62484 12.8334H11.3748C12.1803 12.8334 12.8332 12.1805 12.8332 11.3751V2.62508C12.8332 1.81967 12.1803 1.16675 11.3748 1.16675H9.18734M4.81234 1.16675V4.54175C4.81234 4.59837 4.87267 4.63457 4.92263 4.60792L6.99984 3.50008L9.07704 4.60792C9.127 4.63457 9.18734 4.59837 9.18734 4.54175V1.16675M4.81234 1.16675H9.18734"
        stroke="#475467"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
