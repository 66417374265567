import React, { useEffect, useState } from "react";
import { externalOperationService } from "../../services/external-operation.service";
import { useTranslation } from "react-i18next";
import StepsMainSide from "../../components/steps/StepsMainSide";
import { Loader } from "three";
import OperationCard from "../process/components/OperationCard";
import { StepNoData } from "../step-navigation/components";
import { CustomModal } from "../../components";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import AddExternalOperationModal from "./components/AddExternalOperationModal";
import { useStore } from "../../hooks/useStores";
import diacritics from "diacritics";

export default function External() {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTab, setSelectedTab] = useState("process");
  const [alertOpen, setAlertOpen] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [externalOperation, setExternalOperation] = useState([]);

  const handleData = async () => {
    setLoading(true);
    await externalOperationService.listOperation().then(({ data }) => {
      data.map((d) => {
        return {
          ...d,
          responsibleUser: d.responsibleUser.map((r) => r.id),
        };
      });
      setExternalOperation(data);
      setLoading(false);
    });
  };

  const handleDelete = (id) => {
    // setDeleteProcess(id);
    setSelectedItem(id);
    setIsOpen(false);
  };

  const handleEdit = (id) => {
    setSelectedItem(id);
    setIsOpen(true);
  };

  useEffect(() => {
    handleData();
  }, []);

  const filteredUsers = searchValue
    ? externalOperation?.filter((d) =>
      `${diacritics.remove(d?.name)}`
        .toLowerCase()
        .includes(searchValue?.toLowerCase())
    )
    : externalOperation;

  return (
    <>
      <div className="w-full min-w-full h-full xs:mt-[70px]">
        {externalOperation ? (
          <StepsMainSide
            t={t}
            title={t("product:externalOperations")}
            length={
              filteredUsers?.length +
              "/" +
              auth?.user?.company?.limits?.externalOperation +
              " " +
              t("product:externalOperation")
            }
            addlabel={t("product:newExternal")}
            setIsOpen={setIsOpen}
            placeholder={t("addProcess:searchPlaceholder")}
            setSearchVal={setSearchValue}
            onClickSubmitButton={() => {
              setSelectedItem(null);
            }}
            children={
              <>
                {loading ? (
                 <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[270px]" />
                ) : (
                  <>
                    {filteredUsers?.map((e, i) => {
                      return (
                        <OperationCard
                          key={i}
                          t={t}
                          name={e?.name}
                          icon={e?.iconKey}
                          responsibleUser={e?.supplier.map((r) => r?.name)}
                          editOnClick={() => {
                            handleEdit(e);
                          }}
                          deleteOnclick={() => {
                            // handleDelete(e?.id);
                            // setAlertOpen(true);
                          }}
                          page={"external"}
                        />
                      );
                    })}
                  </>
                )}
              </>
            }
          />
        ) : (
          <StepNoData
            header={t("routes:external")}
            text={t("addProcess:externalStep")}
            onClick={() => setIsOpen(true)}
          />
        )}
      </div>

      <CustomModal
        children={
          <AddExternalOperationModal
            data={externalOperation}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedProcess={selectedItem}
            handleData={handleData}
          />
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalTitle={
          selectedItem
            ? t("addProcess:updateProcess")
            : t("settingTab:newCreateExternalOperation")
        }
      />

      <AlertModal
        title={t("equipments:deleteEquipment")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={alertOpen}
        setIsOpen={setAlertOpen}
        isAlert={isAlert}
        setIsAlert={setIsAlert}
      // deleteProcess={deleteProcess}
      // applyButton={async () => {
      //   await onDelete(deleteProcess);
      //   await handleData();
      //   setIsAlert(false);
      //   setIsOpen(false);
      // }}
      />
    </>
  );
}
