import React, { useEffect, useMemo, useState } from "react";
import { Button } from "../../components";
import { ArrowLeft } from "../../assets/icons/commonIcons";
import generateFileUrl from "../../utils/generateFileUrl";
import { PlusIcon } from "../../assets/icons/PersonIcons";
import SearchInput from "../../components/inputs/SearchInput";
import { DotsHorizontalIcon, FilterIcon, QuantityAcceptedIcon, QuantityDeclineIcon, SwitchIcon } from "../../assets/icons/productIcons";
import { CalendarIconLegacy, OrderIconLegacy } from "../../assets/icons/modalIcons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { orderService } from "../../services/order.service";
import NoAvatar from "../../components/avatar/NoAvatar";
import moment from "moment";
import { useTranslation } from "react-i18next";
import diacritics from "diacritics";
import OrderSort from "./components/OrderSort";
import { QuantityIcon } from "../../assets/icons/stepsIcons";
import { DeadlineIconSort } from "../../assets/icons/equipmentIcon";
import CustomerDetailTable from "./CustomerDetailTable";
import Badgets from "../../components/buttons/Badgets";
import NoData from "../../steps/step-navigation/components/NoData";

const OrderDetailLegacy = () => {
  const { t } = useTranslation();
  const { id, filterValue } = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageData, setPageData] = useState([]);
  const [searchVal, setSearchVal] = useState(searchParams.get("search") || "");
  const [sortValue, setSortValue] = useState(searchParams.get("sort") || null);
  const [ascDesc, setAscDesc] = useState(searchParams.get("ascDesc") === "true");
  const [openOrderIds, setOpenOrderIds] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  //new
  const [filterOptions, setFilterOptions] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);

  const filterString = filterOptions && Object.keys(filterOptions).length > 0 ? encodeURIComponent(JSON.stringify(filterOptions)) : "";

  const tableHeadItems = [
    t("product:products"),
    t("product:due"),
    t("product:orderQuantityss"),
    t("product:quantityInProduction"),
    t("product:totalDeliveredQuantity"),
    t("product:remainingOrders"),
    t("product:unitPricess"),
    t("product:totalPrice"),
    "",
  ];

  const { isLoading, refetch, isFetching } = useQuery({
    queryKey: ["orderCustomerDetail"],
    retry: 0,
    queryFn: useMemo(
      () => async () => {
        try {
          const res = await orderService.getCustomerDetails(
            id,
            filterValue,
            Number(pageNo),
            searchVal,
            sortValue,
            ascDesc ? "DESC" : "ASC",
            pageLimit,
            filterString ? [filterString] : []
          );
          setPageData(res[0]);
          setSearchVal(searchVal);

          const queryParams = new URLSearchParams();
          if (pageNo) queryParams.set("pageNo", pageNo.toString());
          if (searchVal) queryParams.set("search", searchVal);
          if (sortValue) queryParams.set("sort", sortValue);
          if (ascDesc != undefined) queryParams.set("ascDesc", ascDesc.toString());
          if (pageLimit !== 10) queryParams.set("take", pageLimit.toString());

          setSearchParams(queryParams);
          setIsFirstLoad(false);
          return res;
        } catch (error) {
          console.error("Error occurred while fetching data:", error);
          throw error;
        }
      },
      [searchVal, sortValue, ascDesc, pageNo, filterString, filterValue]
    ),
  });

  useEffect(() => {
    refetch();
  }, [sortValue, searchVal, pageNo, id, filterValue, ascDesc]);

  const headerItems = [
    { label: t("product:allNew"), value: "all" },
    { label: t("product:active"), value: "active" },
    { label: t("tasks:expired"), value: "expired" },
    { label: t("product:completedNew"), value: "finished" },
  ];

  const totalOrderAmount = pageData?.orders?.reduce((acc, item) => acc + (item.totalQuantity || 0), 0);
  const totalPrice = pageData?.orders?.reduce((acc, item) => acc + (item.totalPrice || 0), 0);

  const handleFilterClick = (value) => {
    navigate(`/app/product/mrp/orders/customer-detail/${id}/${value}`);
  };

  const toggleDetails = (orderId) => {
    setOpenOrderIds((prev) => {
      if (prev.includes(orderId)) {
        return prev.filter((id) => id !== orderId);
      } else {
        return [...prev, orderId];
      }
    });
  };
  return (
    <div className="flex flex-col w-full h-full">
      {isFirstLoad ? (
        <div className="w-full px-6">
          <div className="w-full h-[56px] min-h-[56px] bg-gray-200 rounded-xl animate-pulse" />
        </div>
      ) : (
        <div className="flex w-full min-h-[56px] max-h-[56px] h-14 justify-between items-center border-b border-[#E4E7EC] px-6">
          <div className="flex gap-x-2 items-center justify-center">
            <Button colorType={"tertiary-gray"} iconLeft={<ArrowLeft />} onClick={() => navigate(-1)} size={"md"} />
            {pageData?.customerImage ? (
              <img
                className="flex min-w-[48px] max-w-[48px] min-h-[36px] max-h-[36px] border border-[#0000001A] rounded"
                src={generateFileUrl(pageData?.customerImage)}
              />
            ) : (
              <div className="w-[48px] h-[36px] min-w-[48px] min-h-[36px] rounded border border-secondary-200">
                <NoAvatar name={pageData?.customerName} rounded={4} theme={"product"} minHeight="36px" minWidth="48px" />
              </div>
            )}

            <p className="text-[#101828] font-semibold text-xl ml-1">{pageData?.customerName || "--"}</p>
          </div>
          <span>
            <Button
              colorType={"primary-product"}
              label={t("product:createNewOrder")}
              iconLeft={<PlusIcon />}
              size={"md"}
              onClick={() => {
                navigate("/app/product/mrp/orders/new-order");
              }}
            />
          </span>
        </div>
      )}
      {isFirstLoad ? (
        <div className="w-full px-6">
          <div className="w-full h-[56px] min-h-[56px] bg-gray-200 mt-4 rounded-xl animate-pulse" />
        </div>
      ) : (
        <div className="flex w-full py-6 justify-between items-center border-b border-[#E4E7EC] px-6">
          <div className="flex border rounded-lg border-[#D0D5DD]">
            {headerItems.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleFilterClick(item.value)}
                  className={`flex justify-center items-center text-[#344054] font-semibold text-sm px-4 py-2 cursor-pointer ${
                    index === headerItems?.length - 1 ? "rounded-r-lg" : "border-r border-[#D0D5DD]"
                  } ${filterValue === item.value ? "bg-[#F9FAFB]" : ""} ${index === 0 ? "rounded-l-lg" : ""}`}
                >
                  {item.label}
                </div>
              );
            })}
          </div>
          <div className="flex gap-x-3">
            <span className="flex min-w-[220px] max-w-[220px] h-full items-center justify-center">
              <SearchInput theme={"product"} setSearchVal={setSearchVal} placeholder={t("chat:search")} />
            </span>
            {/* <Button colorType={"secondary-gray"} size={"md"} label={t("product:sorted")} iconLeft={<SwitchIcon />} /> */}
            <OrderSort
              sortValue={sortValue}
              setSortValue={setSortValue}
              ascDesc={ascDesc}
              setAscDesc={setAscDesc}
              options={[
                {
                  label: t("product:orderNo"),
                  value: "systemOrderNo",
                  icon: <QuantityIcon />,
                },
                {
                  label: t("product:quantity"),
                  value: "totalQuantity",
                  icon: <QuantityIcon />,
                },
                {
                  label: "Teslim edilmis Adet",
                  value: "deliveredQuantity",
                  icon: <QuantityAcceptedIcon />,
                },
                {
                  label: "Kalan Adet",
                  value: "remainingQuantity",
                  icon: <QuantityDeclineIcon />,
                },
                {
                  label: "Siparis Olusturulma Tarihi",
                  value: "createdDate",
                  icon: <QuantityIcon />,
                },
                {
                  label: "Siparis Guncellenme Tarihi",
                  value: "createdDate",
                  icon: <QuantityIcon />,
                },
              ]}
            />
            {/* <Button colorType={"secondary-gray"} size={"md"} label={t("product:filters")} iconLeft={<FilterIcon />} /> */}
          </div>
        </div>
      )}

      {isFetching ? (
        <div className="w-full px-6 h-full">
          <div className="w-full min-h-full bg-gray-200 mt-4 rounded-xl animate-pulse" />
        </div>
      ) : (
        <>
          {pageData?.orders?.length === 0 ? (
            <div className="flex w-full h-full items-center justify-center">
              <NoData theme={"product"} header={t("label:noData")} button={false} />
            </div>
          ) : (
            <div className="flex w-full min-h-full relative">
              <div className="flex flex-col gap-y-6 overflow-y-auto overflow-x-hidden scrollbar-hide w-full h-full bg-[#F9FAFB] px-6 py-4 pb-[250px]">
                {pageData?.orders?.map((order, index) => {
                  const isOpen = openOrderIds.includes(order.id);
                  return (
                    <div className="flex flex-col w-full h-fit border border-[#E4E7EC] bg-[#FFF] rounded-xl">
                      <div className="flex justify-between w-full px-4 py-3 items-center">
                        <div className="flex gap-x-4 items-center">
                          <Badgets colorType={"fill-warning"} size={"lg"} label={index + 1} />
                          <div className="flex gap-x-8 items-center">
                            <div className="flex gap-x-2 items-center justify-center">
                              <OrderIconLegacy />
                              <p className="text-[#475467] text-sm">{t("product:manufacturerOrderNo")}</p>
                              <p className="text-[#B54708] text-md font-semibold">
                                {(order?.productType === "order" ? order?.systemOrderNo : order?.systemStockOrderNo) || "--"}
                              </p>
                            </div>
                            {order?.customerOrderNo && (
                              <div className="flex gap-x-2 items-center justify-center">
                                <OrderIconLegacy />
                                <p className="text-[#475467] text-sm">{t("product:customerOrderNo")}</p>
                                <p className="text-[#B54708] text-md font-semibold">{order?.customerOrderNo}</p>
                              </div>
                            )}

                            <div className="flex gap-x-2 items-center justify-center">
                              <CalendarIconLegacy />
                              <p className="text-[#475467] text-sm">{t("product:orderGeneratedDate")}</p>
                              <p className="text-[#B54708] text-md font-semibold">{moment(order?.createdDate).format("DD.MM.YYYY") || "--"}</p>
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-x-3">
                          <Button
                            colorType={"secondary-gray"}
                            size={"sm"}
                            label={t("product:goToOrderDetail")}
                            iconLeft={<InfoCircleIcon />}
                            onClick={() => {
                              navigate(`/app/product/mrp/orders/order-detail/${order?.id}`);
                            }}
                          />
                          {isOpen ? (
                            <Button colorType={"tertiary-gray"} iconLeft={<ArrowUpIcon />} size={"md"} onClick={() => toggleDetails(order.id)} />
                          ) : (
                            <Button colorType={"tertiary-gray"} iconLeft={<ArrowDownIcon />} size={"md"} onClick={() => toggleDetails(order.id)} />
                          )}
                        </div>
                      </div>
                      {isOpen && (
                        <div className="flex flex-col w-full">
                          <div className="flex max-h-[26px] min-h-[26px] w-full bg-[#F9FAFB]">
                            {tableHeadItems.map((item, i) => (
                              <div
                                key={i}
                                className={`flex border-y px-3 py-1 border-r border-[#E4E7EC] items-center 
          ${i === 0 ? "basis-[320px] flex-none" : i === tableHeadItems.length - 1 ? "basis-[72px] flex-none" : "flex-1"} justify-center
          last:border-r-0`}
                              >
                                <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
                              </div>
                            ))}
                          </div>

                          {order?.details?.map((detail, index) => {
                            return <CustomerDetailTable detail={detail} refetch={refetch} />;
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {/* footer */}
              <div className="flex w-full px-6 absolute bottom-[170px]">
                <div className="w-full min-h-[72px] max-h-[72px] h-[72px] bg-[#B54708] shadow-xl bottom-[26px] rounded-xl">
                  <div className="flex w-full h-full justify-between">
                    <div className="flex h-full py-3 px-4 gap-x-4 items-center">
                      <div className="flex min-w-[64px] max-w-[64px] min-h-[48px] max-h-[48px] relative">
                        <img
                          className="absolute z-[10] top-[8px] left-[-2px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                          src={generateFileUrl("a0e89007-b7fc-422a-9171-bbb8eaed0bb6.png")}
                        />
                        <img
                          className="absolute z-[20] top-[-2px] left-[10px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                          src={generateFileUrl("a780ec08-3369-4929-be37-91b9360628a3")}
                        />
                        <img
                          className="absolute z-[30] bottom-[-2px] right-[-2px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                          src={generateFileUrl("a0e89007-b7fc-422a-9171-bbb8eaed0bb6.png")}
                        />
                      </div>
                      <div className="flex gap-x-1 items-center">
                        <p className="text-[#FFFFFF] font-semibold text-xl">{pageData?.orders?.length}</p>
                        <p className="text-[#FFFFFF] font-normal text-md">{t("product:totalOrder")}</p>
                      </div>
                    </div>
                    <div className="flex py-3 text-[#FFFFFF] font-semibold text-lg">
                      <div className="flex px-5 items-center justify-center border-r border-[#FFFFFF4D]">{t("product:totalAmount")}</div>
                      <div className="flex flex-col gap-y-[2px] items-center justify-center px-5 border-r border-[#FFFFFF4D]">
                        <p className="text-xs font-medium">{t("product:orderQuantity")}</p>
                        <p>{totalOrderAmount || "0"}</p>
                      </div>
                      <div className="flex flex-col gap-y-[2px] items-center justify-center px-5 border-r border-[#FFFFFF4D]">
                        <p className="text-xs font-medium">{t("product:operationCost")}</p>
                        <p>{"₺" + " " + "0"}</p>
                      </div>
                      <div className="flex flex-col gap-y-[2px] items-center justify-center px-5 border-r border-[#FFFFFF4D]">
                        <p className="text-xs font-medium">{t("product:totalPrice")}</p>
                        <p>{"₺" + " " + (totalPrice?.toFixed(2) || "0")}</p>
                      </div>
                      <div className="flex flex-col gap-y-[2px] items-center justify-center px-5">
                        <p className="text-xs font-medium">KDV</p>
                        <p>{"₺" + " " + ((totalPrice || "0") * 0.2)?.toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default OrderDetailLegacy;

// eski tablo
const OrderDetailLegacyTable = ({ detail, t }) => {
  const currencyIcon = () => {
    switch (detail?.currency || "TRY") {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      case "RUB":
        return "₽";
      case "UAH":
        return "₴";
      case "TRY":
        return "₺";
      default:
        return "₺";
    }
  };
  return (
    <div className="flex w-full gap-x-4">
      {/* card */}
      <div className="flex flex-col border border-[#E4E7EC] shadow-xs rounded-lg pt-3 gap-y-2 bg-[#fff] relative min-w-[320px] max-w-[320px]">
        <div className="flex gap-x-2 pl-3 pr-6">
          {detail?.product?.image ? (
            <img
              className="flex min-w-[64px] max-w-[64px] min-h-[48px] max-h-[48px] border border-[#0000001A] rounded"
              src={generateFileUrl(detail?.product?.image)}
            />
          ) : (
            <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200">
              <NoAvatar name={detail?.product?.name} rounded={4} theme={"product"} minHeight="48px" minWidth="64px" />
            </div>
          )}

          <div className="flex flex-col">
            <p className="text-[#101828] font-semibold text-md truncate w-[190px]">{detail?.product?.name || "--"}</p>
            <div className="flex w-fit bg-[#FFFAEB] px-1 py-[3px] text-[#B54708] font-medium text-xs rounded">
              {t("product:stock")}: {detail?.product?.code || "--"}
            </div>
          </div>
        </div>
        <span className="absolute right-3 top-2 cursor-pointer">
          <DotsHorizontalIcon />
        </span>
        <div className="flex flex-col">
          <div className="flex w-full min-h-[26px] max-h-[26px] bg-[#F2F4F7] border-y border-[#E4E7EC] text-[#475467] font-normal text-xs">
            <div className="w-1/2 flex items-center px-3 py-1 border-r border-[#E4E7EC]">{t("table:deliveryDate")}</div>
            <div className="w-1/2 flex items-center px-3 py-1">{t("product:orderQuantityss")}</div>
          </div>
          <div className="flex w-full min-h-[42px] max-h-[42px] bg-[#fff] text-[#101828] font-medium text-xs rounded-b-lg">
            <div className="w-1/2 flex items-center p-3 border-r border-[#E4E7EC]">{moment(detail?.deliveryDate).format("DD.MM.YYYY")}</div>
            <div className="w-1/2 flex items-center p-3 gap-x-1">
              <p className="text-[#101828] text-xs font-medium">{detail?.orderedQuantity || "--"}</p>
              <p className="text-[#475467] text-xs">{t("product:pcs")}</p>
            </div>
          </div>
        </div>
      </div>
      {/* orta tablo */}
      <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-[35%]">
        <div className="flex w-full min-h-[26px] max-h-[26px] bg-[#F2F4F7] border-b border-[#E4E7EC] text-[#475467] font-normal text-xs rounded-t-lg">
          <div className="w-1/2 flex items-center px-4 py-1 border-r border-[#E4E7EC]">{t("product:manufacturerStockCode")}</div>
          <div className="w-1/2 flex items-center px-4 py-1">{t("product:unitPrice")}</div>
        </div>
        <div className="flex w-full min-h-[42px] max-h-[42px] bg-[#fff] text-[#101828] font-medium text-xs rounded-b-lg">
          <div className="w-1/2 flex items-center px-4 py-3 border-r border-[#E4E7EC]">{detail?.product?.code || "--"}</div>
          <div className="w-1/2 flex items-center px-4 py-3">
            {currencyIcon()}
            {detail?.productPrice || "--"}
          </div>
        </div>
        <div className="flex w-full min-h-[26px] max-h-[26px] bg-[#F2F4F7] border-b border-[#E4E7EC] text-[#475467] font-normal text-xs border-t">
          <div className="w-1/2 flex items-center px-4 py-1 border-r border-[#E4E7EC]">{t("product:customerStockCodes")}</div>
          <div className="w-1/2 flex items-center px-4 py-1">{t("product:unitOperationCost")}</div>
        </div>
        <div className="flex w-full min-h-[42px] max-h-[42px] bg-[#fff] text-[#101828] font-medium text-xs rounded-b-lg">
          <div className="w-1/2 flex items-center px-4 py-3 border-r border-[#E4E7EC]">{detail?.customerStockCode || "--"}</div>
          <div className="w-1/2 flex items-center px-4 py-3">{"--"}</div>
        </div>
      </div>
      {/* son tablo */}
      <div className="flex flex-col border border-[#E4E7EC] rounded-lg w-[50%]">
        <div className="flex w-full min-h-[26px] max-h-[26px] bg-[#F2F4F7] border-b border-[#E4E7EC] text-[#475467] font-normal text-xs rounded-t-lg">
          <div className="w-1/5 flex items-center px-4 py-1 border-r border-[#E4E7EC]"></div>
          <div className="w-1/5 flex items-center px-4 py-1 border-r border-[#E4E7EC] whitespace-nowrap">{t("product:orderQuantity")}</div>
          <div className="w-1/5 flex items-center px-4 py-1 border-r border-[#E4E7EC] whitespace-nowrap">{t("product:operationCosts")}</div>
          <div className="w-1/5 flex items-center px-4 border-r border-[#E4E7EC] whitespace-nowrap">{t("product:salesPrice")}</div>
          <div className="w-1/5 flex items-center px-4">{t("product:taxAmount")}</div>
        </div>
        <div className="flex w-full min-h-[36.66px] max-h-[36.66px] bg-[#fff] border-b border-[#E4E7EC] text-[#079455] font-medium text-xs">
          <div className="w-1/5 flex items-center px-4 border-r border-[#E4E7EC] font-normal whitespace-nowrap">{t("product:delivered")}</div>
          <div className="w-1/5 flex items-center px-4 border-r border-[#E4E7EC] gap-x-1">
            {detail?.finishedQuantity || "0"}
            <p className="text-[#667085] font-normal">{t("product:pcs")}</p>
          </div>
          <div className="w-1/5 flex items-center px-4 border-r border-[#E4E7EC]">{"--"}</div>
          <div className="w-1/5 flex items-center px-4 border-r border-[#E4E7EC]">
            {currencyIcon()}
            {detail?.finishedQuantity * detail?.productPrice || "0"}
          </div>
          <div className="w-1/5 flex items-center px-4">
            {currencyIcon()} {(detail?.finishedQuantity * detail?.productPrice * 0.2)?.toFixed(2) || "0"}
          </div>
        </div>
        <div className="flex w-full min-h-[36.66px] max-h-[36.66px] bg-[#fff] text-[#475467] font-medium text-xs rounded-b-lg">
          <div className="w-1/5 flex items-center px-4 border-r border-[#E4E7EC]">{t("product:remaining")}</div>
          <div className="w-1/5 flex items-center px-4 border-r border-[#E4E7EC] gap-x-1 whitespace-nowrap">
            {detail?.orderedQuantity - detail?.finishedQuantity || "0"}
            <p className="text-[#667085] font-normal">{t("product:pcs")}</p>
          </div>
          <div className="w-1/5 flex items-center px-4 border-r border-[#E4E7EC] whitespace-nowrap">{"--"}</div>
          <div className="w-1/5 flex items-center px-4 border-r border-[#E4E7EC] whitespace-nowrap">
            {currencyIcon()}
            {(detail?.orderedQuantity * detail?.productPrice - detail?.finishedQuantity * detail?.productPrice)?.toFixed(2)}
          </div>
          <div className="w-1/5 flex items-center px-4 whitespace-nowrap">
            {currencyIcon()} {((detail?.orderedQuantity * detail?.productPrice - detail?.finishedQuantity * detail?.productPrice) * 0.2)?.toFixed(2) || "0"}
          </div>
        </div>
        <div className="flex w-full min-h-[36.66px] max-h-[36.66px] bg-[#fff] text-[#101828] font-semibold text-xs rounded-b-lg border-t border-[#E4E7EC]">
          <div className="w-1/5 flex items-center px-4 border-r border-[#E4E7EC]">{t("tasks:total")}</div>
          <div className="w-1/5 flex items-center px-4 border-r border-[#E4E7EC] gap-x-1">
            {detail?.orderedQuantity || "0"}
            <p className="text-[#667085] font-normal">{t("product:pcs")}</p>
          </div>
          <div className="w-1/5 flex items-center px-4 border-r border-[#E4E7EC] whitespace-nowrap">{"--"}</div>
          <div className="w-1/5 flex items-center px-4 border-r border-[#E4E7EC] whitespace-nowrap">
            {currencyIcon()}
            {(detail?.orderedQuantity * detail?.productPrice).toFixed(2)}
          </div>
          <div className="w-1/5 flex items-center px-4 whitespace-nowrap">
            {currencyIcon()} {(detail?.orderedQuantity * detail?.productPrice * 0.2).toFixed(2) || "0"}
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M10.4993 13.3334V10.0001M10.4993 6.66675H10.5077M18.8327 10.0001C18.8327 14.6025 15.1017 18.3334 10.4993 18.3334C5.89698 18.3334 2.16602 14.6025 2.16602 10.0001C2.16602 5.39771 5.89698 1.66675 10.4993 1.66675C15.1017 1.66675 18.8327 5.39771 18.8327 10.0001Z"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ArrowDownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path d="M5 8L10 13L15 8" stroke="#475467" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const ArrowUpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M15 12.5L10 7.5L5 12.5" stroke="#475467" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
