import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../assets/icons/departmentIcon';
import { OperationDetailIcon, TableArrowLeft } from '../../../assets/icons/machineIcons';

const DashboardKpiCard = ({ process,
    enabled,
    setSelectedProcess,
    selectedProcess,
    index,
    processId,
    setVisibleButton,
    setIsIntersecting,
    dataLength,
    setVisibleRightButton,
    handleEdit, }) => {

    const divRef = useRef();
    const menuRef = useRef();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [imageError, setImageError] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [view, setView] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };

    const getOperationDetails = useCallback(() => {
        navigate(
            `operation-detail/${processId}/${moment(new Date()).format("YYYY-MM-DD")}`
        );
    }, [navigate, processId]);

    useEffect(() => {
        const options = {
            root: null,
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (index === 0) {
                    if (entry.isIntersecting) {
                        setVisibleButton(false);
                        setIsIntersecting(entry.isIntersecting);
                    } else {
                        setVisibleButton(true);
                    }
                } else if (index === dataLength - 1) {
                    if (entry.isIntersecting) {
                        setVisibleRightButton(false);
                    } else {
                        setVisibleRightButton(true);
                    }
                }
            });
        }, options);

        if (divRef.current) {
            observer.observe(divRef.current);
        }
        return () => {
            if (divRef.current) {
                observer.unobserve(divRef.current);
            }
        };
    }, [
        index,
        dataLength,
        setVisibleButton,
        setIsIntersecting,
        setVisibleRightButton,
    ]);

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setView(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div
            ref={divRef}
            key={index}
            onDoubleClick={getOperationDetails}
            onClick={() => {
                if (selectedProcess?.id == process?.id) {
                    setSelectedProcess('all');
                }
                else { setSelectedProcess(process) }

            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`relative flex flex-col items-start w-[160px] min-w-[160px] h-[115px] shadow-xs min-h-[115px] border rounded-lg cursor-pointer bg-[#fff] ${selectedProcess?.id == process.id ? "border-primary-600" : "border-[#E4E7EC]"
                }`}>
            <div className='px-3 pt-2 pb-1 w-full border-b border-b-[#E4E7EC]'>
                <p className='text-xs font-semibold w-[130px]  truncate text-secondary-700'>{process?.name || "Process Name"}</p>
            </div>
            <div className='flex  w-full bg-[#F9FAFB] border-b border-b-[#E4E7EC]'>
                <div className='flex px-3 flex-row items-center py-1 w-full'>
                    {/* {process?.isIncreaseStatus === true ? (
                        <div className={` text-[#079455] dark:text-[#079455] -rotate-180`}>
                            {" "}
                            <ArrowIcon />
                        </div>
                    ) : (
                        <div
                            className={`${!process?.actual && !process?.average && !process.kpiValue ? "text-[#475467]" : "text-[#D92D20]"
                                }  dark:text-[#D92D20] `}
                        >
                            <ArrowIcon />
                        </div>
                    )} */}
                     <p className={`text-2xl font-semibold 
                    ${!process?.actual && !process?.average && !process.kpiValue ? "text-secondary-600" : "text-secondary-600"}`}>
                        %{(process?.average ? (process?.average * 100)?.toFixed(0) : "0")}
                    </p>
                    {/* <p className={`text-2xl font-semibold 
                    ${!process?.actual && !process?.average && !process.kpiValue ? "text-secondary-600" 
                        : process?.actual * 100 <= process?.average * 100
                            ? "text-[#D92D20]"
                            : process?.actual * 100 >= process?.average * 100 && process?.actual * 100 <= process?.kpiValue * 100
                                ? "text-[#DC6803]"
                                : process?.actual * 100 >= process?.kpiValue * 100 ? "text-[#079455]" : "text-secondary-600"}`}>
                        %{(process?.average ? (process?.average * 100)?.toFixed(0) : "0")}
                    </p> */}
                    <div className='flex flex-col items-start ml-2'>
                        <p className='text-xxs text-[#667085]'>{t("product:avg")}</p>
                        <p className='text-xs -mb-[2px] text-secondary-700'>{process?.timeMass ? (process.timeMass / 3600).toFixed(1) + ` ${t("product:hours")}` : `0 ${t("product:hours")}`}</p>
                    </div>
                </div>
            </div>
            <div className='flex flex-row items-center w-full'>
                <div className='flex flex-col items-start w-[50%] border-r'>
                    <div className='flex flex-col items-start h-full py-1 pr-2 pl-3'>
                        <p className='text-xxs text-secondary-600'>{t("product:prevDay")}</p>
                        <div className='flex flex-row items-center gap-x-1 w-full'>
                            <PrevDayIcon />
                            <p className='text-xs font-medium text-secondary-700'>
                                %{(process?.actual * 100 || 0)?.toFixed(0)}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col items-start w-[50%]'>
                    <div className='flex flex-col items-start h-full py-1 pr-2 pl-3'>
                        <p className='text-xxs text-secondary-600'>{t("product:maximums")}</p>
                        <div className='flex flex-row items-center gap-x-1 w-full'>
                            <MaximumIcons />
                            <p className='text-xs font-medium text-secondary-700'>
                                %{parseInt(process?.average == 0 ? 0 : process?.kpiValue * 100) > 100 ? 100 : parseInt(process?.average == 0 ? 0 : process?.kpiValue * 100 || 0)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    setView(!view);
                }}
                className="flex absolute right-1 top-[6px] cursor-pointer"
            >
                <DotsIcons />
            </div>
            {view === true && (
                <div
                    ref={menuRef}
                    className="absolute flex flex-col py-1  top-[24px] -right-1   border-[1px] border-secondary-200 z-50 shadow-lg bg-white rounded-lg cursor-pointer"
                >
                    <div className="px-1 py-[1px] w-full flex-row items-center">
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEdit(process?.id);
                            }}
                            type="button"
                            className={`flex flex-row items-center gap-1 hover:bg-secondary-50 bg-white px-[6px] py-[10px] min-w-[144px] min-h-[40px] cursor-pointer`}
                        >
                            <div className="w-3 h-3 flex items-center text-[#667085]">
                                <EditIcon />
                            </div>
                            <p className="text-secondary-700 text-xs font-semibold">
                                {t("addProcess:editOperation")}
                            </p>
                        </button>
                        <button
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                getOperationDetails();
                            }}
                            className={`flex flex-row items-center gap-1 hover:bg-secondary-50 bg-white px-[6px] py-[10px] min-w-[144px] min-h-[44px] cursor-pointer`}
                        >
                            <div className="w-3 h-3 flex items-center text-[#667085]">
                                <OperationDetailIcon />
                            </div>
                            <p className="text-secondary-700 text-xs font-semibold">
                                {t("addProcess:operationDetail")}
                            </p>
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DashboardKpiCard

export const PrevDayIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M4.5 3.5H2.3C2.01997 3.5 1.87996 3.5 1.773 3.5545C1.67892 3.60243 1.60243 3.67892 1.5545 3.773C1.5 3.87996 1.5 4.01997 1.5 4.3V9.7C1.5 9.98003 1.5 10.12 1.5545 10.227C1.60243 10.3211 1.67892 10.3976 1.773 10.4455C1.87996 10.5 2.01997 10.5 2.3 10.5H4.5M4.5 10.5H7.5M4.5 10.5L4.5 2.3C4.5 2.01997 4.5 1.87996 4.5545 1.773C4.60243 1.67892 4.67892 1.60243 4.773 1.5545C4.87996 1.5 5.01997 1.5 5.3 1.5L6.7 1.5C6.98003 1.5 7.12004 1.5 7.227 1.5545C7.32108 1.60243 7.39757 1.67892 7.4455 1.773C7.5 1.87996 7.5 2.01997 7.5 2.3V10.5M7.5 5.5H9.7C9.98003 5.5 10.12 5.5 10.227 5.5545C10.3211 5.60243 10.3976 5.67892 10.4455 5.773C10.5 5.87996 10.5 6.01997 10.5 6.3V9.7C10.5 9.98003 10.5 10.12 10.4455 10.227C10.3976 10.3211 10.3211 10.3976 10.227 10.4455C10.12 10.5 9.98003 10.5 9.7 10.5H7.5" stroke="#667085" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
};

export const MaximumIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path d="M10.5 1.5H1.5M6 10.5V3.5M6 3.5L2.5 7M6 3.5L9.5 7" stroke="#667085" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const DotsIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z" stroke="#98A2B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 4.5C9.41421 4.5 9.75 4.16421 9.75 3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75C8.25 4.16421 8.58579 4.5 9 4.5Z" stroke="#98A2B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 15C9.41421 15 9.75 14.6642 9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15Z" stroke="#98A2B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const ArrowIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 5V19M12 19L19 12M12 19L5 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

