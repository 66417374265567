import React from "react";
import { NoEquIcon } from "../../../../assets/icons/machineIcons";
import CustomImage from "../../../../components/CustomImage";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

const InternalPlannedStationCard = ({ filledData, a }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-start">
      <div
        data-tooltip-id={`${a?.id}`}
        data-tooltip-place={"bottom"}
        data-tooltip-content={`${a?.metadata?.brand + " " + a?.metadata?.model} `}
        className="flex items-center gap-x-2"
      >
        {a?.metadata?.warning && (
          <div className="animate-pulse">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.5001 7.50019V10.8335M10.5001 14.1669H10.5085M9.34622 3.24329L2.49215 15.0821C2.11198 15.7388 1.92189 16.0671 1.94999 16.3366C1.97449 16.5716 2.09763 16.7852 2.28876 16.9242C2.5079 17.0835 2.88728 17.0835 3.64605 17.0835L17.3542 17.0835C18.113 17.0835 18.4923 17.0835 18.7115 16.9242C18.9026 16.7852 19.0258 16.5716 19.0503 16.3366C19.0783 16.0671 18.8883 15.7388 18.5081 15.0821L11.654 3.24329C11.2752 2.58899 11.0858 2.26184 10.8387 2.15196C10.6232 2.05612 10.3771 2.05612 10.1615 2.15196C9.91443 2.26184 9.72503 2.58899 9.34622 3.24329Z"
                stroke="#D92D20"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        )}
        <p className={`text-sm font-semibold text-secondary-700 truncate ${a?.metadata?.warning ? "w-[140px]" : "w-[170px]"}`}>
          {a?.metadata?.brand + " " + a?.metadata?.model}
        </p>
      </div>
      <div className="flex flex-row items-center gap-x-3 mt-1">
        {a?.metadata?.image ? (
          <div className="cursor pointer">
            <div
              className={`w-[74px] min-w-[74px] min-h-[56px] h-[56px] rounded-[4px]  ${
                a?.metadata?.warning ? "ring-2 border-[2px] border-[#D92D20]  ring-opacity-25  animate-pulse ring-red-500" : ""
              }  flex items-center justify-center`}
            >
              <CustomImage
                borderRadius={2}
                src={a?.metadata?.image}
                style={{
                  width: 72,
                  height: 54,
                  minWidth: 72,
                  minHeight: 54,
                  borderRadius: 4,
                  borderWidth: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  display: "flex",
                  borderColor: "#0000001A",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          </div>
        ) : (
          <div className="w-[72px] min-w-[72px] min-h-[54px] h-[54px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
            <NoEquIcon />
          </div>
        )}
        <div className="flex flex-col items-start h-[54px] min-h-[54px] px-2 py-[6px] gap-y-1 rounded border border-[#E4E7EC]">
          <p className="text-xs font-normal text-secondary-600">{t("product:totalPlannedWorks")}</p>
          <p className="text-sm font-medium text-secondary-700">
            {Number(a?.metadata?.plannedWorkLoad / 86400).toFixed(1)} {t("product:day")}
          </p>
        </div>
      </div>
      <Tooltip className="z-[120]" id={`${a?.id}`} />
    </div>
  );
};

export default InternalPlannedStationCard;
