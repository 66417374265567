import React, { useState, useEffect } from "react";
import { Button, CustomModal, Loading } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { purchaseService } from "../../services/purchase.service";
import PurchaseJunctionFinish from "./modals/PurchaseJunctionFinish";
import RawQualityControlForm from "./modals/RawQualityControlForm";
import diacritics from "diacritics";
import MaterialSupplyEventCard from "../../components/new-cards/junction-card/MaterialSupplyEventCard";
import MaterialSupplyActiveEventCard from "../../components/new-cards/junction-card/MaterialSupplyActiveEventCard";
import { QualityTab } from "./quality/components";
import { Toast } from "../../utils/toastify/toast";
import NewOrderHeaderCard from "./components/order/NewOrderHeaderCard";
import useWindowDimensions from "../../hooks/useWindowDimension";
const MesMaterialSupplyJunction = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { settings, auth } = useStore();
  const { height } = useWindowDimensions();
  let navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [finishPurhcaseModal, setFinishPurhcaseModal] = useState(false);
  const [finishPurhcaseModalData, setFinishPurhcaseModalData] = useState(null);
  const [purchaseQualityForm, setPurchaseQualityForm] = useState(false);
  const [purchaseQualityFormData, setPurchaseQualityFormData] = useState(null);
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const [draggedJunction, setDraggedJunction] = useState(null);
  const headData = useQuery({
    queryKey: ["all-supplier-group-junctions"],
    enabled: false,
    retry: 0,
    queryFn: async () => await purchaseService.getPurchaseJunctionsForCompany(),
  });
  const planView = useQuery({
    queryKey: ["purchase-junctions-planned"],
    enabled: false,
    queryFn: async () => await purchaseService.purchaseTransactionsPlanned(id),
    retry: 0,
  });
  const refetch = () => {
    planView.refetch();
    headData.refetch();
  };
  useEffect(() => {
    headData.refetch();
    if (id != ":id" && id != "undefined") {
      planView.refetch();
      setSelectedGroup(headData?.data?.operations?.find((item) => item?.id == id));
    }
  }, [id]);
  useEffect(() => {
    if (headData?.data?.operations?.length > 0 && (id == ":id" || id == "undefined")) {
      navigate(`/app/product/mes/supply/material/${headData?.data?.operations[0]?.id}/junction`);
    }
  }, [headData?.data]);
  const filteredPurchaseJunctionActiveData = planView?.data?.junctions
    ?.filter((d) => d.status == "active" || d.type == "planned")
    ?.filter((d) => {
      const rawMaterialName = d?.purchaseRequest?.rawMaterial?.name || "";
      const normalizedRawMaterialName = diacritics.remove(rawMaterialName).toLowerCase();
      const normalizedSearchValue = diacritics.remove(searchTerm || "").toLowerCase();
      return normalizedRawMaterialName.includes(normalizedSearchValue);
    });
  const filteredPurchaseJunctionPastJunctionData = planView?.data?.junctions
    ?.filter((d) => d.status == "completed" && d.quality == "applied")
    ?.filter((d) => {
      const rawMaterialName = d?.purchaseRequest?.rawMaterial?.name || "";
      const normalizedRawMaterialName = diacritics.remove(rawMaterialName).toLowerCase();
      const normalizedSearchValue = diacritics.remove(searchTerm || "").toLowerCase();
      return normalizedRawMaterialName.includes(normalizedSearchValue);
    });
  const filteredPurchaseJunctionPendingJunctionData = planView?.data?.junctions
    ?.filter((d) => d.status == "completed" && d.quality == "waiting")
    ?.filter((d) => {
      const rawMaterialName = d?.purchaseRequest?.rawMaterial?.name || "";
      const normalizedRawMaterialName = diacritics.remove(rawMaterialName).toLowerCase();
      const normalizedSearchValue = diacritics.remove(searchTerm || "").toLowerCase();
      return normalizedRawMaterialName.includes(normalizedSearchValue);
    });
  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };
  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleFinishedOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.status === "completed")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setPurchaseQualityForm(true);
      setPurchaseQualityFormData(draggedJunction);
    }
  };

  const handleWaitingOnDrop = () => {
    if (draggedJunction?.id) {
      if (!(draggedJunction?.status === "active")) {
        Toast("error", t("product:columnDragDropWarning"));
        return;
      }
      setFinishPurhcaseModal(true);
      setFinishPurhcaseModalData(draggedJunction);
    }
  };

  const tabOptions = [
    { value: "junction", label: t("product:eventss") },
    { value: "plan", label: t("product:planner") },
  ];

  return (
    <div className="w-full h-full flex flex-col overflow-hidden overflow-y-auto scrollbar-hide">
      <>
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
          </span>
          <div className="flex items-center">
            <span className="font-codecMedium text-2xl text-black">upu.</span>
            <p className="text-2xl font-codecMedium text-[#B54708]">supply</p>
            {!(headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading) && (
              <p className="text-xl font-normal ml-2 text-secondary-500">
                {selectedGroup && selectedGroup?.name ? `${t("product:materialSupply")} / ${selectedGroup?.name}` : t("product:materialSupply")}
              </p>
            )}
          </div>
          <span className="ml-auto">
            <Button
              colorType={"primary-product"}
              size={"md"}
              label={t("product:supplierList")}
              onClick={() => navigate("/app/product/mes/supply/material/list")}
            />
          </span>
        </div>
        {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
          <div className="w-full bg-gray-200 rounded-xl animate-pulse h-[96px] min-h-[96px] flex gap-5" />
        ) : (
          <div className="h-[116px] min-h-[116px] w-full overflow-x-auto flex ">
            {headData?.data?.operations
              ?.sort((a, b) => b?.active + b?.past + b?.pending - (a?.active + a?.past + a?.pending))
              ?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`min-w-[192px] ${index !== headData?.data?.operations?.length - 1 ? "border-b" : "border-b-0"} max-h-[96px] pr-5`}
                  >
                    <NewOrderHeaderCard
                      onClick={async () => {
                        navigate(`/app/product/mes/supply/material/${item?.id}/junction`);
                      }}
                      icon={item?.iconKey}
                      name={item?.name}
                      planned={item?.plannedUnplanned?.planned}
                      unPlanned={item?.plannedUnplanned?.unPlanned}
                      selectedOperation={{
                        id: selectedGroup?.id,
                        name: item?.name,
                      }}
                      id={item?.id}
                    />
                  </div>
                );
              })}
          </div>
        )}
        {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-gray-200 p-[6px] mt-6 mb-4 rounded-xl animate-pulse flex gap-5" />
        ) : (
          <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-6 mb-4">
            {tabOptions?.map((option, index) => (
              <div
                className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                  "junction" == option?.value ? "bg-white shadow-sm" : ""
                }`}
                onClick={() => {
                  if (option?.value != "junction") navigate(`/app/product/mes/supply/material/${id}/plan-view`);
                }}
              >
                <p className={`text-md font-semibold ${"junction" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
              </div>
            ))}
          </div>
        )}
        {headData?.isLoading || headData?.isFetching || planView?.isFetching || planView?.isLoading ? (
          <div className="w-full h-full bg-gray-200 rounded-xl animate-pulse mt-4" />
        ) : (
          <div style={{ minHeight: height - 135 }} className="flex w-full flex-1 rounded-xl mt-4 flex-col overflow-hidden">
            <div className="w-full h-full rounded-xl flex flex-col relative">
              <div id="content-wrapper" className="flex w-full flex-1 md:overflow-x-auto overflow-hidden">
                <QualityTab
                  length={filteredPurchaseJunctionActiveData?.length || 0}
                  type={"active"}
                  onDragOver={(e) => e.preventDefault()}
                  onDragEnd={() => {
                    setDraggedJunction(null);
                  }}
                  children={
                    <ActivePurchaseJunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={filteredPurchaseJunctionActiveData}
                      navigate={navigate}
                      setFinishPurhcaseModal={setFinishPurhcaseModal}
                      setFinishPurhcaseModalData={setFinishPurhcaseModalData}
                    />
                  }
                />
                <QualityTab
                  length={filteredPurchaseJunctionPendingJunctionData?.length || 0}
                  type={"waiting"}
                  onDragEnd={() => {
                    setDraggedJunction(null);
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleWaitingOnDrop();
                  }}
                  children={
                    <JunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={filteredPurchaseJunctionPendingJunctionData}
                      setPurchaseQualityForm={setPurchaseQualityForm}
                      setPurchaseQualityFormData={setPurchaseQualityFormData}
                      navigate={navigate}
                    />
                  }
                />
                <QualityTab
                  length={filteredPurchaseJunctionPastJunctionData?.length || 0}
                  type={"finished"}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleFinishedOnDrop();
                  }}
                  children={
                    <JunctionListController
                      setDraggedJunction={setDraggedJunction}
                      items={filteredPurchaseJunctionPastJunctionData}
                      navigate={navigate}
                      setPurchaseQualityForm={setPurchaseQualityForm}
                      setPurchaseQualityFormData={setPurchaseQualityFormData}
                    />
                  }
                />
                {(planView?.isFetching || planView?.isLoading) && (
                  <div className="w-full absolute bottom-0 p-2 px-3 flex items-center bg-white border-b border-secondary-200 flex-1 justify-center">
                    <Loading size={20} primary={"#B54708"} secondary={"#fff"} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
      <CustomModal
        isOpen={finishPurhcaseModal}
        setIsOpen={setFinishPurhcaseModal}
        modalTitle={t("product:purchaseApproval")}
        children={
          <PurchaseJunctionFinish
            refetch={refetch}
            close={() => setFinishPurhcaseModal(false)}
            junctionData={finishPurhcaseModalData}
            rawData={{
              ...finishPurhcaseModalData?.purchaseRequest?.rawMaterial,
              deliveryDate: finishPurhcaseModalData?.estimatedDeliveryDate,
            }}
          />
        }
      />
      <CustomModal
        isOpen={purchaseQualityForm}
        setIsOpen={setPurchaseQualityForm}
        modalTitle={t("product:productRawMaterial")}
        children={
          <RawQualityControlForm
            refetch={refetch}
            close={() => setPurchaseQualityForm(false)}
            junctionData={purchaseQualityFormData}
            rawData={{
              ...purchaseQualityFormData?.purchaseRequest?.rawMaterial,
              deliveryDate: purchaseQualityFormData?.estimatedDeliveryDate,
            }}
          />
        }
      />
    </div>
  );
};

export default MesMaterialSupplyJunction;

const JunctionListController = ({ items, setPurchaseQualityFormData, setPurchaseQualityForm, setDraggedJunction }) => {
  return items?.map((item, index) => {
    return (
      <div draggable onDragStart={() => setDraggedJunction(item)} key={index} className="min-h-[80px] mb-3">
        <MaterialSupplyEventCard data={item} setPurchaseQualityForm={setPurchaseQualityForm} setPurchaseQualityFormData={setPurchaseQualityFormData} />
      </div>
    );
  });
};

const ActivePurchaseJunctionListController = ({ items, setFinishPurhcaseModal, setFinishPurhcaseModalData, setDraggedJunction }) => {
  return items?.map((item, index) => {
    return (
      <div draggable onDragStart={() => setDraggedJunction(item)} key={index} className="min-h-[80px] cursor-pointer mb-3">
        <MaterialSupplyActiveEventCard data={item} setFinishPurhcaseModal={setFinishPurhcaseModal} setFinishPurhcaseModalData={setFinishPurhcaseModalData} />
      </div>
    );
  });
};
