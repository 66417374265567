import React, { useEffect, useState } from "react";
import { junctionService } from "../../services/junction.service";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import NoAvatar from "../avatar/NoAvatar";
import Input from "../inputs/Input";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import UploadProfile from "../ImageUploader/UploadProfile";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../buttons/Button";
import { Toast } from "../../utils/toastify/toast";
import { orderService } from "../../services/order.service";
import { useTranslation } from "react-i18next";
import TextArea from "../inputs/TextArea";
import { Tooltip } from "react-tooltip";
import Loading from "../loading/Loading";
import CustomModal from "./CustomModal";
import SplitOrderModal from "../../pages/product/modals/SplitOrderModal";
import { useStore } from "../../hooks/useStores";
import Selector from "../inputs/Selector";
import DateTimeInput from "../inputs/DateTimeInput";
import { CalendarIcon, ClockIcon, QtyIcon } from "../../assets/icons/commonIcons";
import { flexJunctionService } from "../../services/flex-junction.service";
import { Loader } from "../../assets/icons/stepsIcons";

const QualityControlForm = ({ junctionId, onClose, refetch, junctionData, productType }) => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isSplit, setIsSplit] = useState(false);
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss"));

  const [endDate, setEndDate] = useState(moment(data?.endDate).format("YYYY-MM-DDTHH:mm:ss"));
  useState(false);
  const handleData = async () => {
    setLoading(true);
    const data = await junctionService.getQualityJunctionDetail(junctionId).catch((err) => Toast("error", String(err)));
    setData(data);
    setLoading(false);
  };
  const dayStartHour = moment(auth?.user?.company?.dayStartHour, "HH:mm:ss").format("HH:mm:ss");
  const junctionTypes = [
    { label: t("product:work"), value: "work" },
    { label: t("product:setup"), value: "setup" },
  ];
  const schema = yup.object({
    qualityImage: yup.string().notRequired(),
    acceptedImage: yup.string().notRequired(),
    rejectedImage: yup.string().notRequired(),
    qualityDescription: yup.string().notRequired(),
    rejectedPiece: yup.number(),
    workPiece: yup.number(),
    acceptedPiece: yup.number(),
    type: yup.string().required(`${t("validation:empty")}`),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rejectedPiece: 0,
    },
  });

  const onSubmit = async (value) => {
    setSubmitLoading(true);
    const send = {
      rejectedPiece: value?.rejectedPiece,
      acceptedPiece: value?.acceptedPiece,
      qualityDescription: value?.qualityDescription || "",
      qualityImage: value?.qualityImage || "",
      acceptedImage: value?.acceptedImage || "",
      rejectedImage: value?.rejectedImage || "",
      junctionId: junctionId,
      workPiece: value?.workPiece,
      startDate: moment(startDate).utc().format(),
      endDate: moment(endDate).utc().format(),
      type: value?.type,
    };

    const totalPieces = Number(value?.acceptedPiece) + Number(value?.rejectedPiece);
    if (totalPieces !== Number(value?.workPiece)) {
      setSubmitLoading(false);
      Toast("error", t("product:acceptedAndRejected"));
      return;
    }

    const startDateUnix = moment(startDate).unix();
    const endDateUnix = moment(endDate).unix();
    if (startDateUnix > endDateUnix) {
      console.log("denememe");
      setSubmitLoading(false);
      Toast("error", t("product:qualityUpdateError"));
      return;
    }

    try {
      let res;
      if (productType === "flex") {
        res = await flexJunctionService.qualityFlexJunction(data?.metadata?.id, send);
      } else {
        res = await orderService.qualityForm(data?.id, send);
      }

      if (res.data.code === 0) {
        refetch();
        onClose();
        Toast("success", res?.data?.message);
      } else {
        Toast("error", res?.data?.message);
      }
    } catch (error) {
      Toast("error", error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    setValue("qualityImage", data?.metadata?.qualityImage);
    setValue("rejectedImage", data?.metadata?.rejectedImage);
    setValue("acceptedImage", data?.metadata?.acceptedImage);
    setValue("qualityDescription", data?.metadata?.qualityDescription);
    setValue("rejectedPiece", data?.metadata?.rejectedWorkCount);
    setValue("acceptedPiece", data?.metadata?.acceptedWorkCount > 0 ? data?.metadata?.acceptedWorkCount : data?.metadata?.madeWorkCount);
    setValue("workPiece", data?.madeWorkCount);
    setValue("type", data?.type);
    setEndDate(data?.endDate);
    setStartDate(data?.startDate);
  }, [data]);

  return (
    <>
      {loading ? (
        <div className="flex items-center w-[770px] justify-center  h-[500px]">
          <Loading size={44} primary={"#B54708"} secondary={"#fff"} />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="w-[786px] flex flex-col mt-6">
          <div className="w-full flex gap-6 h-fit">
            <div className="w-[60%] flex flex-col gap-4">
              <div className="w-full flex items-center h-[72px] gap-3">
                {data?.productType === "flex" ? (
                  <img src={generateFileUrl(data?.flexProduct?.image)} className="w-[96px] h-[72px] rounded border border-gray-300" />
                ) : (
                  <img src={generateFileUrl(data?.job?.product?.image)} className="w-[96px] h-[72px] rounded border border-gray-300" />
                )}

                <div
                  data-tooltip-id={`${data?.id}`}
                  data-tooltip-place={"bottom"}
                  data-tooltip-content={`${t("product:productName")}: ${data?.productType === "flex" ? data?.flexProduct?.name : data?.job?.product?.name} `}
                  className="flex flex-col justify-between"
                >
                  {data?.productType === "flex" ? (
                    <p className="font-semibold text-gray-900 w-[250px] truncate">{data?.flexProduct?.name || "--"}</p>
                  ) : (
                    <p className="font-semibold text-gray-900 w-[250px] truncate">{data?.job?.product?.name || "--"}</p>
                  )}
                  {data?.productType === "flex" ? (
                    <p className="text-sm text-gray-600">
                      {t("product:stockCode")}: {data?.flexProduct?.stockCode}
                    </p>
                  ) : (
                    <p className="text-sm text-gray-600">
                      {t("product:orderNo")}: {data?.job?.orderNo}
                    </p>
                  )}

                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-1">
                      <QtyIcon />
                      {data?.productType === "flex" ? (
                        <p className="text-sm text-gray-600">
                          {data?.metadata?.madeWorkCount} / {data?.flexHeader?.quantity}
                        </p>
                      ) : (
                        <p className="text-sm text-gray-600">
                          {data?.metadata?.madeWorkCount} / {data?.job?.piece}
                        </p>
                      )}
                    </div>
                    <div className="flex items-center gap-1">
                      <ClockIcon />
                      <p className="text-sm text-gray-600">{moment(data?.endDate).diff(data?.startDate, "minutes").toFixed(1) + "dk"}</p>
                    </div>

                    <Tooltip id={`${data?.id}`} />
                  </div>
                  <div
                    data-tooltip-id={`${data?.id}`}
                    data-tooltip-place={"top"}
                    data-tooltip-content={"Bitiş tarihi ve saati"}
                    className="flex items-center gap-1"
                  >
                    <CalendarIcon />
                    <p className="text-sm text-gray-600">{moment(data?.endDate).format("DD-MM-YYYY HH:mm")}</p>
                  </div>
                </div>
              </div>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Selector
                    value={value}
                    items={junctionTypes}
                    theme={"product"}
                    label={t("product:junctionType")}
                    onChange={onChange}
                    onBlur={onBlur}
                    validate={errors?.source ? "error" : ""}
                    errorMessage={errors?.source ? errors?.source?.message : ""}
                  />
                )}
                name="type"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    theme={"product"}
                    label={t("product:numberOfProducts")}
                    errorMessage="Bu alan zorunludur"
                    validate={errors?.workPiece ? "error" : ""}
                  />
                )}
                name="workPiece"
              />
              <div className="flex flex-row items-center gap-x-3 w-full">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      theme={"product"}
                      label={t("product:approvedProductCount")}
                      errorMessage="Bu alan zorunludur"
                      validate={errors?.acceptedPiece ? "error" : ""}
                    />
                  )}
                  name="acceptedPiece"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      theme={"product"}
                      label={t("product:numberOfRejected")}
                      errorMessage="Bu alan zorunludur"
                      validate={errors?.rejectedPiece ? "error" : ""}
                    />
                  )}
                  name="rejectedPiece"
                />
              </div>
              <div className="flex flex-row items-center gap-x-3 w-full">
                {data?.internalOperation === null ? (
                  <>
                    <DateTimeInput
                      label={t("product:jobStartDate")}
                      theme={"product"}
                      initialValue={moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss")}
                      onChange={(e) => {
                        setStartDate(e);
                      }}
                    />
                    <DateTimeInput
                      label={t("product:jobEndDate")}
                      theme={"product"}
                      initialValue={moment(data?.endDate).format("YYYY-MM-DDTHH:mm:ss")}
                      onChange={(e) => {
                        setEndDate(e);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <DateTimeInput
                      minDate={
                        moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss") < moment(data?.startDate).format("YYYY-MM-DD") + "T" + dayStartHour
                          ? moment(data?.startDate).add(-1, "day").format("YYYY-MM-DD") + "T" + dayStartHour
                          : moment(data?.startDate).format("YYYY-MM-DD") + "T" + dayStartHour
                      }
                      maxDate={moment(data?.startDate).add(1, "day").format("YYYY-MM-DD") + "T" + dayStartHour}
                      label={t("product:jobStartDate")}
                      theme={"product"}
                      initialValue={moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss")}
                      onChange={(e) => {
                        setStartDate(e);
                      }}
                    />
                    <DateTimeInput
                      label={t("product:jobEndDate")}
                      theme={"product"}
                      minDate={
                        moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss") < moment(data?.startDate).format("YYYY-MM-DD") + "T" + dayStartHour
                          ? moment(data?.startDate).add(-1, "day").format("YYYY-MM-DD") + "T" + dayStartHour
                          : moment(data?.startDate).format("YYYY-MM-DD") + "T" + dayStartHour
                      }
                      maxDate={moment(data?.startDate).add(1, "day").format("YYYY-MM-DD") + "T" + dayStartHour}
                      initialValue={moment(data?.endDate).format("YYYY-MM-DDTHH:mm:ss")}
                      onChange={(e) => {
                        setEndDate(e);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="w-px border-l flex-1 bg-gray-300" />
            <div className="w-[40%] flex flex-col gap-3">
              <div className="w-full h-[48px] flex items-center">
                {data?.user?.avatar ? (
                  <img src={generateFileUrl(data?.user?.avatar)} className="w-12 min-w-[48px] h-12 rounded-full border border-gray-300" />
                ) : (
                  <NoAvatar name={data?.user?.name} lastName={data?.user?.lastName} size={48} />
                )}
                <p className="text-secondary-700 font-semibold w-[80%] truncate ml-3">{data?.user?.name + " " + data?.user?.lastName}</p>
              </div>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <UploadProfile title={t("product:controlForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
                )}
                name="qualityImage"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <UploadProfile title={t("product:approvalForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
                )}
                name="acceptedImage"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <UploadProfile title={t("product:rejectionForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
                )}
                name="rejectedImage"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextArea
                    label={t("product:descriptionOptionel")}
                    width={"100%"}
                    onBlur={onBlur}
                    onChange={onChange}
                    theme={"product"}
                    rows={3}
                    value={value}
                  />
                )}
                name="qualityDescription"
              />
            </div>
          </div>
          <div className="w-full flex flex-row items-center">
            {/* <div
              onClick={(e) => {
                setIsSplit(true);
              }}
              className="mt-10 font-semibold cursor-pointer text-[#B54708] text-md"
            >
              {t("product:splitJob")}
            </div> */}
            <div className="w-[50%] flex items-center ml-auto justify-end gap-3 mt-8">
              <Button label={t("buttons:stop")} colorType={"secondary-gray"} size={"md"} onClick={onClose} disabled={submitLoading} />
              {submitLoading ? (
                <div className="flex items-center justify-center bg-[#DC6803]  rounded-lg w-full h-[42px] max-h-[42px]">
                  <Loader currentColor="#DC6803" currentFill="currentFill" />
                </div>
              ) : (
                <Button type={"submit"} label={t("product:sendQualityForm")} colorType={"primary-product"} size={"md"} disabled={submitLoading} />
              )}
            </div>
          </div>
        </form>
      )}
      <CustomModal
        isOpen={isSplit}
        setIsOpen={setIsSplit}
        onClose={() => {
          setIsSplit(false);
        }}
        modalTitle={t("product:splitJob")}
        children={
          <SplitOrderModal
            handleData={refetch}
            setIsOpen={setIsSplit}
            closeModal={() => {
              setIsSplit(false);
            }}
            id={junctionId}
            dataJunction={junctionData}
          />
        }
      />
    </>
  );
};

export default QualityControlForm;
