import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Arrow, Loader } from "../../../assets/icons/stepsIcons";
import moment from "moment";
import { CalendarIcon } from "../quality/assets/qualitySvg";
import ProductTwinCalendar from "./components/ProductTwinCalendar";
import { Button, CustomModal } from "../../../components";
import { DownloadIcon } from "../../../assets/icons/buttonIcons";
import LegacyOperationDetailModal from "./components/LegacyOperationDetailModal";
import Badgets from "../../../components/buttons/Badgets";
import SearchInput from "../../../components/inputs/SearchInput";
import { SwitchIcon } from "../../../assets/icons/productIcons";
import ProdCard from "./cards/ProdCard";
import RawMaterialCard from "./cards/RawMaterialCard";
import OperationCard from "./cards/OperationCard";
import { useQuery } from "@tanstack/react-query";
import { orderService } from "../../../services/order.service";
import diacritics from "diacritics";
import InfiniteScroll from "react-infinite-scroll-component";
import { FlipBackwardIcon, SaveIcon } from "../../../assets/icons/machineIcons";
import { ChevronDown } from "../../../assets/icons/modalIcons";
import ProductTwinMonthlyChart from "./components/ProductTwinMonthlyChart";
import { externalOperationService } from "../../../services/external-operation.service";
import { rawMaterialService } from "../../../services/raw-material.service";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { useStore } from "../../../hooks/useStores";

const NewProductTwin = () => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpenOperationDetailModal, setIsOpenOperationDetailModal] = useState(false);
  const [isModalData, setIsModalData] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [dateType, setDateType] = useState(searchParams.get("tab") || "daily");
  const [date, setDate] = useState(searchParams.get("date") ? new Date(searchParams.get("date")) : new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);

  ///
  const [pageNo, setPageNo] = useState(Number(searchParams.get("pageNo")) || 1);
  const [searchVal, setSearchVal] = useState(searchParams.get("search") || "");
  const [sortValue, setSortValue] = useState(searchParams.get("sort") || null);
  const [ascDesc, setAscDesc] = useState(searchParams.get("ascDesc") === "true");
  const [pageLimit, setPageLimit] = useState(Number(searchParams.get("take")) || 5);
  const [filterOptions, setFilterOptions] = useState({});
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const ITEM_HEIGHT = 152;

  const calculateInitialItemCount = () => {
    const viewHeight = window.innerHeight;
    return Math.ceil(viewHeight / ITEM_HEIGHT) + 5;
  };

  const scrollableRefs = {
    header: useRef(null),
  };

  const [rowRefs, setRowRefs] = useState([]);

  useEffect(() => {
    setRowRefs((prevRefs) => pageData.map((_, index) => prevRefs[index] || React.createRef()));
  }, [pageData]);

  const handleScroll = (scrollingElement) => {
    const scrollLeft = scrollingElement.scrollLeft;

    if (scrollableRefs.header.current && scrollableRefs.header.current !== scrollingElement) {
      scrollableRefs.header.current.scrollLeft = scrollLeft;
    }

    rowRefs.forEach((ref) => {
      if (ref.current && ref.current !== scrollingElement) {
        ref.current.scrollLeft = scrollLeft;
      }
    });
  };

  const handlePrevious = () => {
    const newDate = new Date(date);

    switch (dateType) {
      case "daily":
        newDate.setDate(newDate.getDate() - 1);
        break;
      case "weekly":
        newDate.setDate(newDate.getDate() - 7);
        break;
      case "monthly":
        newDate.setMonth(newDate.getMonth() - 1, 1);
        break;
    }
    setDate(newDate);
  };

  const handleNext = () => {
    const newDate = new Date(date);
    const today = new Date();

    switch (dateType) {
      case "daily":
        newDate.setDate(newDate.getDate() + 1);
        break;
      case "weekly":
        newDate.setDate(newDate.getDate() + 7);
        break;
      case "monthly":
        newDate.setMonth(newDate.getMonth() + 1, 1);
        break;
    }

    if (newDate <= today) {
      setDate(newDate);
    }
  };

  const getDateRange = () => {
    if (dateType === "daily") {
      return {
        start: moment(date).format("DD.MM.YYYY"),
        end: moment(date).add(1, "day").format("DD.MM.YYYY"),
      };
    } else if (dateType === "weekly") {
      return {
        start: moment(date).format("DD.MM.YYYY"),
        end: moment(date).add(6, "days").format("DD.MM.YYYY"),
      };
    } else {
      return {
        start: moment(date).startOf("month").format("DD.MM.YYYY"),
        end: moment(date).endOf("month").format("DD.MM.YYYY"),
      };
    }
  };

  const dateRange = getDateRange();

  const handleSearch = (input) => {
    const normalizedInput = diacritics.remove(input);
    setSearchVal(normalizedInput);
  };

  const initialTake = calculateInitialItemCount();
  const dayStartHour = auth?.user?.company?.dayStartHour;
  const [hour, minute, second] = dayStartHour.split(":").map(Number);
  const formattedStartDate = moment(dateRange?.start, "DD.MM.YYYY").set({ hour, minute, second, millisecond: 0 }).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
  const formattedEndDate = moment(dateRange?.end, "DD.MM.YYYY").set({ hour, minute, second, millisecond: 0 }).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");

  const filterString = filterOptions && Object.keys(filterOptions).length > 0 ? encodeURIComponent(JSON.stringify(filterOptions)) : "";

  const { isLoading, refetch, isFetching } = useQuery({
    retry: 1,
    queryKey: ["productTwinLegacy", searchVal, sortValue, ascDesc, initialTake, filterOptions, dateType, date],
    queryFn: useMemo(
      () => async () => {
        try {
          let newPageNo = 1;
          if (pageData?.length === 0 || date || dateType) {
            setPageNo(1);
            setPageData([]);
          } else {
            newPageNo = pageNo;
          }

          const res = await orderService.getProductTwin(
            Number(newPageNo),
            formattedStartDate,
            formattedEndDate,
            searchVal,
            sortValue,
            ascDesc ? "DESC" : "ASC",
            initialTake,
            filterString ? [filterString] : []
          );

          setTotalCount(res?.meta?.itemCount);
          setPageData(res?.data);

          const params = new URLSearchParams();

          if (newPageNo) params.set("pageNo", newPageNo.toString());
          if (searchVal) params.set("search", searchVal);
          if (sortValue) params.set("sort", sortValue);
          params.set("ascDesc", ascDesc.toString());
          params.set("take", initialTake.toString());

          if (dateType === "daily") {
            params.set("startDate", moment(date).format("YYYY-MM-DD"));
            params.set("endDate", moment(date).add(1, "day").format("YYYY-MM-DD"));
          } else if (dateType === "weekly") {
            params.set("startDate", moment(date).format("YYYY-MM-DD"));
            params.set("endDate", moment(date).add(6, "days").format("YYYY-MM-DD"));
          } else if (dateType === "monthly") {
            params.set("startDate", moment(date).startOf("month").format("YYYY-MM-DD"));
            params.set("endDate", moment(date).endOf("month").format("YYYY-MM-DD"));
          }

          setSearchParams(params);
          setIsFirstLoad(false);
          return res;
        } catch (error) {
          console.error("Error occurred while fetching data:", error);
          throw error;
        }
      },
      [searchVal, sortValue, ascDesc, initialTake, filterOptions, dateType, date]
    ),
  });

  const fetchMoreData = async () => {
    let currentPage = pageNo;

    if (pageData?.length === 0) {
      currentPage = 1;
      setPageNo(1);
    } else {
      currentPage = pageNo + 1;
      setPageNo(currentPage);
    }

    try {
      const res = await orderService.getProductTwin(
        currentPage,
        formattedStartDate,
        formattedEndDate,
        searchVal,
        sortValue,
        ascDesc ? "DESC" : "ASC",
        5,
        filterString ? [filterString] : []
      );

      const newData = res?.data || [];
      setPageData((prevData) => [...prevData, ...newData]);
      setTotalCount(res?.meta?.itemCount);
    } catch (error) {
      console.error("Error occurred while fetching more data:", error);
    }
  };

  const hasMore = pageData?.length < totalCount;

  const maxOperations = pageData?.length ? Math.max(...pageData.map((item) => item.operations?.length || 0)) : 0;

  const scrollableDivRef = useRef(null);
  const [shouldShowHasMore, setShouldShowHasMore] = useState(hasMore);

  useEffect(() => {
    const checkScroll = () => {
      if (scrollableDivRef.current) {
        const { scrollHeight, clientHeight } = scrollableDivRef.current;
        setShouldShowHasMore(scrollHeight > clientHeight);
      }
    };

    checkScroll();
    window.addEventListener("resize", checkScroll);

    return () => {
      window.removeEventListener("resize", checkScroll);
    };
  }, [pageData, hasMore]);

  const { data: denemeData, refetch: refetchDeneme } = useQuery({
    queryKey: ["getRawMaterialDetail"],
    queryFn: async () => await rawMaterialService.getPurchaseList("f4dadd3e-e290-41b0-8a66-a5096b482b3b"),
    retry: 0,
  });
  return (
    <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative scrollbar-hide gap-y-4">
      <div className={`flex w-full md:flex-col md:items-start border-b h-14 min-h-[58px] md:h-12 md:min-h-12 items-center  `}>
        <div className="flex items-center">
          <p className="text-2xl font-codecMedium text-[#B54708]">product.</p>
          <span className="font-codecMedium text-2xl text-black">twin</span>
        </div>
      </div>

      <div className="flex items-center justify-between w-full h-10 max-h-10">
        <div className="flex border h-full border-[#D0D5DD] rounded-lg whitespace-nowrap text-[#344054] font-semibold text-sm cursor-pointer">
          <div
            onClick={() => {
              setDateType("daily");
            }}
            className={`flex px-4 py-2 border-r hover:bg-[#F2F4F7] border-[#D0D5DD] rounded-l-lg ${dateType === "daily" && "bg-[#F2F4F7]"}`}
          >
            <p>{t("buttons:daily")}</p>
          </div>
          <div
            onClick={() => {
              setDateType("weekly");
            }}
            className={`flex px-4 py-2 border-r hover:bg-[#F2F4F7] border-[#D0D5DD] ${dateType === "weekly" && "bg-[#F2F4F7]"}`}
          >
            <p>{t("buttons:weekly")}</p>
          </div>
          <div
            onClick={() => {
              setDateType("monthly");
            }}
            className={`flex px-4 py-2 rounded-r-lg hover:bg-[#F2F4F7] ${dateType === "monthly" && "bg-[#F2F4F7]"}`}
          >
            <p>{t("buttons:monthly")}</p>
          </div>
        </div>

        <div className="flex items-center gap-x-3">
          <Button
            colorType={"tertiary-gray"}
            size={"md"}
            iconLeft={
              <span className="rotate-90">
                <Arrow />
              </span>
            }
            onClick={handlePrevious}
          />

          <ProductTwinCalendar
            mode="single"
            date={date}
            dateType={dateType}
            visibleFooter
            setDate={setDate}
            visible={showDatePicker}
            setVisible={setShowDatePicker}
            buttonClassName={"flex flex-col items-center"}
            calendarClassName={"rounded-xl absolute top-11"}
          >
            <div
              onClick={() => setShowDatePicker(!showDatePicker)}
              className={`h-[38px] gap-2 border hover:bg-secondary-50 border-secondary-300 shadow-sm hover:shadow-none rounded-lg cursor-pointer flex items-center justify-between px-3 active:ring-4 ring-secondary-100 select-none ${
                showDatePicker ? "bg-secondary-50 ring-2 ring-secondary-200 shadow-none" : ""
              }`}
            >
              <CalendarIcon color={"#344054"} />
              <p className="text-sm font-medium text-secondary-800">{dateRange.start}</p>-
              <p className="text-sm font-medium text-secondary-800">{dateRange.end}</p>
            </div>
          </ProductTwinCalendar>
          <Button
            colorType={"tertiary-gray"}
            size={"md"}
            iconLeft={
              <span className="-rotate-90">
                <Arrow />
              </span>
            }
            onClick={handleNext}
          />
        </div>

        <Button
          colorType={"primary-product"}
          size={"md"}
          label={t("buttons:exportXlsx")}
          className=" font-semibold px-4 py-2 rounded-lg max-w-[200px] text-white"
          iconRight={<DownloadIcon color="white" />}
          disabled={true}
        />
      </div>
      <div className="flex flex-col w-full h-full gap-y-4">
        {isDropdownOpen ? (
          <div className="flex min-h-[330px] max-h-[330px] h-[330px] border border-[#E4E7EC] shadow-xs rounded-xl">
            <ProductTwinMonthlyChart
              defaultLegends={denemeData?.monthlyData?.legend}
              data={denemeData?.monthlyData}
              end={denemeData?.monthlyData?.endDate}
              start={denemeData?.monthlyData?.startDate}
              selectedDate={moment(timeFormatter(new Date())?.formatted).format("YYYY-MM-DD")}
              selectedEquipmentId={denemeData?.id || denemeData?.nodeId}
              refetch={refetchDeneme}
              unitType={denemeData?.unitType}
              currency={denemeData?.currency}
              setIsDropdownOpen={setIsDropdownOpen}
              isDropdownOpen={isDropdownOpen}
              dateType={dateType}
              setDateType={setDateType}
            />
          </div>
        ) : (
          <div className="flex w-full pl-6 p-2 items-center justify-between border border-[#E4E7EC] shadow-xs rounded-xl min-h-[56px] max-h-[56px] h-[56px]">
            <div className="flex gap-x-4 items-center">
              <p className="text-[#101828] font-semibold text-lg">Grafik Görünümü</p>
            </div>
            <div className="flex gap-x-4">
              <Button colorType={"tertiary-gray"} size={"md"} iconLeft={<ListIcon />} />
              <Button colorType={"tertiary-gray"} size={"md"} iconLeft={<DownloadIcon />} />
              <Button colorType={"tertiary-gray"} size={"md"} iconLeft={<ChevronDown />} onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
            </div>
          </div>
        )}

        <InfiniteScroll dataLength={pageData?.length || 0} next={fetchMoreData} hasMore={hasMore} scrollableTarget="scrollableDiv">
          <div
            id="scrollableDiv"
            ref={scrollableDivRef}
            className="flex flex-col w-full border border-[#E4E7EC] shadow-xs rounded-xl h-[93vh] overflow-y-auto overflow-x-hidden scrollbar-hide"
          >
            <div className="flex gap-x-4 items-center w-full min-h-[72px] max-h-[72px] h-[72px] border-b border-[#E4E7EC] px-6 py-4 sticky top-0 bg-white z-40">
              <div className="flex items-center gap-x-2 w-full">
                <p className="text-[#101828] font-semibold text-lg">Ürünler</p>
                <Badgets colorType={"fill-warning"} size={"md"} label={totalCount || "--"} />
              </div>
              <span className="flex w-[240px] min-w-[240px] max-w-[240px]">
                <SearchInput setSearchVal={handleSearch} theme={"product"} placeholder={t("chat:search")} />
              </span>
              <span className="flex min-w-[94px] max-w-[94px] w-[94px]">
                <Button colorType={"secondary-gray"} size={"md"} label={"Sırala"} iconLeft={<SwitchIcon />} />
              </span>
              <span className="flex min-w-[94px] max-w-[94px] w-[94px]">
                <Button colorType={"secondary-gray"} size={"md"} label={"Filtre"} iconLeft={<SwitchIcon />} />
              </span>
            </div>
            <div className="flex w-full sticky top-[72px] bg-white z-40 gap-x-8 px-6">
              <div className="flex min-w-[324px] max-w-[324px] w-[324px]"></div>
              <div
                className="flex w-full overflow-x-auto overflow-y-hidden min-h-[18px] max-h-[18px] h-[18px] gap-x-4"
                ref={scrollableRefs?.header}
                onScroll={(e) => handleScroll(e.target)}
              >
                {[...Array(maxOperations), 1].map((item, index) => {
                  return <div key={index} className="flex w-[312px] min-w-[312px] max-w-[312px] h-full"></div>;
                })}
              </div>
            </div>
            <div className="flex flex-col w-full">
              {pageData?.map((item, index) => {
                const operationCount = item.operations?.length || 0;
                const emptySlots = maxOperations - operationCount;
                return (
                  <div key={index} className="flex w-full min-h-[153px] max-h-[153px] h-[153px] px-6 py-5 gap-x-8 border-b border-[#E4E7EC]">
                    <div className="flex w-[324px] min-w-[324px] max-w-[324px] h-full">
                      <ProdCard item={item} />
                    </div>
                    <div
                      className="flex w-full gap-x-4 overflow-x-auto overflow-y-hidden h-full scrollbar-hide"
                      ref={rowRefs[index]}
                      onScroll={(e) => handleScroll(e.target)}
                    >
                      <RawMaterialCard item={item?.rawMaterial} data={item}/>
                      {item?.operations?.map((operation, index) => {
                        return (
                          <div
                            onClick={() => {
                              setIsOpenOperationDetailModal(true);
                              setIsModalData({ operation: operation, product: item });
                            }}
                            key={index}
                            className="flex w-[312px] min-w-[312px] max-w-[312px] h-full"
                          >
                            <OperationCard item={operation?.operations} times={operation.times[0]} />
                          </div>
                        );
                      })}
                      {[...Array(emptySlots)].map((_, emptyIndex) => (
                        <div key={`empty-${index}-${emptyIndex}`} className="flex w-[312px] min-w-[312px] max-w-[312px] h-full"></div>
                      ))}
                    </div>
                  </div>
                );
              })}
              {shouldShowHasMore && hasMore && (
                <div className="flex w-full justify-center items-center border-b h-[72px] min-h-[72px]">
                  <Loader currentColor="#DC6803" currentFill="currentFill" />
                </div>
              )}
            </div>
          </div>
        </InfiniteScroll>
      </div>
      <CustomModal
        isOpen={isOpenOperationDetailModal}
        setIsOpen={setIsOpenOperationDetailModal}
        modalTitle={"Operasyon Detay"}
        subTitle={
          isModalData?.operation?.operations?.[0]?.step +
          ".Operasyon" +
          " - " +
          (isModalData?.operation?.operations?.[0]?.internalOperation?.process?.name ||
            isModalData?.operation?.operations?.[0]?.externalOperation?.process?.name) +
          " " +
          "(" +
          (isModalData?.operation?.operations?.[0]?.internalOperation?.process?.code || "--") +
          ")"
        }
        width={835}
        children={<LegacyOperationDetailModal data={isModalData} />}
      />
    </div>
  );
};

export default NewProductTwin;

const ListIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5 10.0001L7.5 10.0001M17.5 5.00008L7.5 5.00008M17.5 15.0001L7.5 15.0001M4.16667 10.0001C4.16667 10.4603 3.79357 10.8334 3.33333 10.8334C2.8731 10.8334 2.5 10.4603 2.5 10.0001C2.5 9.53984 2.8731 9.16675 3.33333 9.16675C3.79357 9.16675 4.16667 9.53984 4.16667 10.0001ZM4.16667 5.00008C4.16667 5.46032 3.79357 5.83341 3.33333 5.83341C2.8731 5.83341 2.5 5.46032 2.5 5.00008C2.5 4.53984 2.8731 4.16675 3.33333 4.16675C3.79357 4.16675 4.16667 4.53984 4.16667 5.00008ZM4.16667 15.0001C4.16667 15.4603 3.79357 15.8334 3.33333 15.8334C2.8731 15.8334 2.5 15.4603 2.5 15.0001C2.5 14.5398 2.8731 14.1667 3.33333 14.1667C3.79357 14.1667 4.16667 14.5398 4.16667 15.0001Z"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
