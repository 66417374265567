import React, { useState } from "react";
import { EquipmentDetailIcon, NoEquIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../../components/CustomImage";
import {
  AcceptedIcon,
  DotsVerticalIcon,
  EquCalculate,
  FlexProductIcon,
  GreenAvgIcon,
  MadePlayIcon,
  PersonnelDetailIcon,
  RedAvgIcon,
  RejectedIcon,
  TargetIcon,
} from "../../../components/new-cards/card-icons";
import { ClockIcon, PieceIcon } from "../../../assets/icons/commonIcons";
import NoAvatar from "../../../components/avatar/NoAvatar";
import Badgets from "../../../components/buttons/Badgets";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useStore } from "../../../hooks/useStores";
import { getFormattedNavigateDate } from "../../../utils/getFomattedNavigateDate";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
const StockDetailRecipeQualityCard = ({ data, isModal }) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const { auth } = useStore();
  const navigate = useNavigate();
  const menuOptions = [
    {
      label: t("product:personnelDetail"),
      value: "personnelDetail",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetail",
      icon: <EquipmentDetailIcon />,
    },

    // {
    //   label: t("product:productDetail"),
    //   value: "productDetail",
    //   icon: <ProductDetailIcon />,
    // },
  ];

  const oneOrderTime = (start, end, count, type) => {
    const diff = moment(end).diff(moment(start), "seconds");
    const oneOrder = diff / count;
    return type === "setup" ? diff : `${oneOrder?.toFixed(0)}`;
  };

  const convertSeconds = (seconds) => {
    if (isNaN(seconds) || seconds == Infinity) return "---";

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Number(seconds % 60).toFixed(0);

    let formattedTime = "";

    if (minutes > 0) {
      formattedTime += minutes + " dk ";
    }

    if (remainingSeconds > 0) {
      formattedTime += remainingSeconds + " sn";
    }

    return formattedTime.trim();
  };

  // let formattedTime = convertSeconds(data?.header?.totalEstimatedTime / data?.header?.piece);
  // const formattedEstimatedOpTime = formattedTime
  // const formattedOneOrderTime = (convertSeconds(oneOrderTime(data?.startDate, data?.endDate, data?.metadata?.madeWorkCount, data?.type)));

  const totalEstimatedTime =
    data?.junction?.productType === "flex" ? data?.junction?.flexHeader?.estimatedTime : data?.junction?.header?.internalOperation?.estimatedTime;
  const setupEstimatedTime =
    data?.junction?.productType === "flex"
      ? data?.junction?.flexHeader?.estimatedSettingsTime * 60
      : data?.junction?.header?.internalOperation?.estimatedSettingsTime * 60;
  const formattedEstimatedOpTime =
    data?.junction?.type === "setup"
      ? convertSeconds(
          data?.junction?.productType === "flex"
            ? data?.junction?.flexHeader?.estimatedSettingsTime * 60
            : data?.junction?.header?.internalOperation?.estimatedSettingsTime * 60
        )
      : convertSeconds(totalEstimatedTime);

  const startDate = data?.junction?.startDate;
  const endDate = data?.junction?.endDate;
  const madeWorkCount = data?.junction?.metadata?.junction?.madeWorkCount;
  const type = data?.junction?.type;
  const oneOrderTimeInSeconds = oneOrderTime(startDate, endDate, madeWorkCount, type);
  const formattedOneOrderTime = convertSeconds(oneOrderTimeInSeconds);
  const isRedOld = data?.junction?.type == "setup" ? setupEstimatedTime < oneOrderTimeInSeconds : totalEstimatedTime < oneOrderTimeInSeconds;

  const estimatedTimeGroup = data?.junction?.header
    ? data?.junction?.header?.internalOperation?.plannableNodes?.find((element) => {
        return element?.id == data?.junction?.station?.id;
      })
    : data?.junction?.flexHeader?.plannableNodes
    ? data?.junction?.flexHeader?.plannableNodes?.find((element) => {
        return element?.id == data?.junction?.station?.id;
      })
    : data?.junction?.flexHeader?.estimatedTime;

  const estimatedTimeInSeconds =
    data?.junction?.type === "setup"
      ? estimatedTimeGroup?.estimatedSettingsTime
        ? estimatedTimeGroup?.estimatedSettingsTime
        : estimatedTimeGroup
      : estimatedTimeGroup?.estimatedTime
      ? estimatedTimeGroup?.estimatedTime
      : estimatedTimeGroup;

  // Perform the comparison in seconds
  const isRed = estimatedTimeInSeconds < oneOrderTimeInSeconds;

  return (
    <div
      className={`flex w-full flex-col border border-t-4 ${
        data?.junction?.type === "work" ? "border-t-success-500" : data?.junction?.type === "setup" ? "border-t-[#EAAA08]" : "border-t-[#98A2B3]"
      } items-start rounded-t-[4px] rounded-b-lg border-secondary-300 bg-white relative group`}
    >
      <div className="flex flex-row items-center w-full gap-x-[6px] pt-[6px] px-2 pb-[6px]">
        <div className="flex flex-row items-center gap-x-2 ">
          {data?.junction?.productType === "flex" ? (
            <>
              {data?.junction?.flexProduct?.image ? (
                <div>
                  <CustomImage
                    borderRadius={2}
                    src={data?.junction?.flexProduct?.image}
                    style={{
                      width: 64,
                      height: 48,
                      minWidth: 64,
                      minHeight: 48,
                      borderRadius: 4,
                      borderWidth: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                      borderColor: "#0000001A",
                      backgroundColor: "#fff",
                    }}
                  />
                </div>
              ) : (
                <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                  <NoEquIcon />
                </div>
              )}
            </>
          ) : (
            <>
              {data?.junction?.internalOperation ? (
                <>
                  {data?.junction?.station?.metadata?.image ? (
                    <div
                      data-tooltip-id={`${data?.junction?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`${data?.junction?.station?.metadata?.brand + "" + data?.junction?.station?.metadata?.model} `}
                    >
                      <CustomImage
                        borderRadius={2}
                        src={data?.junction?.station?.metadata?.image}
                        style={{
                          width: 64,
                          height: 48,
                          minWidth: 64,
                          minHeight: 48,
                          borderRadius: 4,
                          borderWidth: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          display: "flex",
                          borderColor: "#0000001A",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      data-tooltip-id={`${data?.junction?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`${data?.junction?.station?.metadata?.brand + "" + data?.junction?.station?.metadata?.model} `}
                      className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                    >
                      <NoEquIcon />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {data?.junction?.supplier?.image ? (
                    <div
                      data-tooltip-id={`${data?.junction?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`${t("product:supplierName")} : ${data?.junction?.supplier?.name} `}
                    >
                      <CustomImage
                        borderRadius={2}
                        src={data?.junction?.supplier?.image}
                        style={{
                          width: 64,
                          height: 48,
                          minWidth: 64,
                          minHeight: 48,
                          borderRadius: 4,
                          borderWidth: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          display: "flex",
                          borderColor: "#0000001A",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      data-tooltip-id={`${data?.junction?.id}`}
                      data-tooltip-place={"right"}
                      data-tooltip-content={`${data?.junction?.supplier?.name} `}
                      className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                    >
                      <NoEquIcon />
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <div className="flex flex-col items-start gap-y-1">
            {/* <div className="flex flex-row items-center gap-x-1 w-full">
                            {data?.productType === "flex" ? (
                                <>
                                    <FlexProductIcon />
                                    <p className="text-sm font-semibold text-secondary-900 truncate w-[184px]">{data?.flexProduct?.name || "--"}</p>
                                </>
                            ) : (
                                <p className="text-sm font-semibold text-secondary-900 truncate w-[200px]">{data?.job?.product?.name || "--"}</p>
                            )}
                        </div> */}

            <div className="flex flex-col items-start gap-y-1">
              <div className="flex flex-row items-center">
                <ClockIcon />
                <p className="text-xs font-medium ml-1 text-secondary-600">
                  {moment(data?.junction?.startDate).format("HH:mm") || "--"} - {moment(data?.junction?.endDate).format("HH:mm") || "--"}
                </p>
                <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:hours")}</p>
              </div>
              {/* <div className="flex flex-row items-center">
                                <PieceIcon />
                                <p className="text-xs font-medium ml-1 text-secondary-600">{data?.acceptedWorkCount || "--"}</p>
                                <p className="text-xxs font-normal mt-[1px] ml-[2px] text-secondary-600">{t("product:pcs")}</p>
                            </div> */}
            </div>
          </div>
        </div>
        {data?.junction?.user?.avatar ? (
          <div
            data-tooltip-id={`${data?.junction?.id}`}
            data-tooltip-place={"left"}
            data-tooltip-content={`${data?.junction?.user?.name} ${data?.junction?.user?.lastName}`}
            className="ml-auto pr-24"
          >
            <CustomImage
              src={data?.junction?.user?.avatar}
              style={{
                width: 48,
                height: 48,
                minWidth: 48,
                minHeight: 48,
                flexDirection: "column",
                borderRadius: 100,
                display: "flex",
                borderColor: "#0000001A",
                backgroundColor: "#fff",
              }}
            />
          </div>
        ) : (
          <div
            data-tooltip-id={`${data?.junction?.id}`}
            data-tooltip-place={"left"}
            data-tooltip-content={`${data?.junction?.user?.name} ${data?.junction?.user?.lastName}`}
            className="ml-auto pr-24"
          >
            <NoAvatar size={48} fontSize={22} name={data?.junction?.user?.name} lastName={data?.junction?.user?.lastName} color={"#B54708"} />
          </div>
        )}
        <div className="flex flex-col z-[20] items-start rounded border-[0.5px] ml-auto absolute border-[#D0D5DD] w-[86px] min-w-[86px] bg-white top-[6px] right-[6px]">
          <div
            className={`flex flex-row items-center justify-center gap-x-1 h-5 min-h-5 px-[2px] py-[2px] ${
              data?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"
            }  w-full rounded-t-[4px] border-b-[0.5px] border-secondary-300 ml-auto`}
          >
            {data?.junction?.metadata?.qualityStatus === "waiting" ? (
              <p className="text-xxs text text-secondary-600 font-semibold">{t("product:applied")}</p>
            ) : (
              <>
                <p className="text-xxs text text-secondary-600 font-semibold">{t("product:qualityNew")}</p>
                <div className="h-[14px] min-h-[14px] flex items-center justify-center bg-[#B54708] px-1 py-[1px] rounded">
                  <p className="text-xxs font-semibold text-white">
                    {" "}
                    %
                    {data?.junction?.productType === "flex"
                      ? data?.junction?.flexHeader?.qualityPercent || "--"
                      : data?.junction?.header?.internalOperation?.qualityPercent || "--"}
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
            <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
              {data?.junction?.metadata?.qualityStatus === "waiting" ? <PieceIcon /> : <AcceptedIcon />}
            </div>
            <p className={`text-xs font-medium ml-1 ${data?.metadata?.qualityStatus === "waiting" ? "text-secondary-600" : "text-[#079455]"} `}>
              {data?.junction?.metadata?.qualityStatus === "waiting" ? data?.junction?.madeWorkCount || "0" : data?.acceptedWorkCount || "0"}
            </p>
            <p className="text-[10px] font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
          </div>
          <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
            <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
              {data?.junction?.metadata?.qualityStatus === "waiting" ? <EquCalculate /> : <RejectedIcon />}
            </div>
            <p className={`text-xs font-medium ml-1 ${data?.junction?.metadata?.qualityStatus === "waiting" ? "text-[#6941C6]" : "text-error-600"} `}>
              {data?.junction?.metadata?.qualityStatus === "waiting"
                ? data?.junction?.metadata?.calculateWorkCount || "0"
                : data?.junction?.metadata?.rejectedWorkCount || "0"}
            </p>
            <p className="text-xxs font-normal ml-0.5 text-secondary-500">{t("product:pcs")}</p>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-row items-center gap-x-4 h-[26px] min-h-[26px] w-full ${
          data?.junction?.productType === "flex" ? "bg-[#F9F5FF]" : "bg-[#F9FAFB]"
        }  border-t-[0.5px] border-t-secondary-300 pb-[3px] pl-2 pt-[3px] pr-[3px] rounded-b-lg`}
      >
        <div className="flex flex-row items-center">
          <TargetIcon />
          <p className="text-xs font-medium ml-1 text-secondary-600">
            {data?.junction?.header?.externalOperation
              ? data?.junction?.header?.externalOperation?.estimatedTime
              : data?.junction?.header?.internalOperation?.plannableNodes?.length == 0
              ? formattedEstimatedOpTime || "--"
              : data?.junction?.type === "setup"
              ? estimatedTimeGroup?.estimatedSettingsTime
                ? `${Math.floor(estimatedTimeGroup?.estimatedSettingsTime / 60 || 0)} dk ${(estimatedTimeGroup?.estimatedSettingsTime % 60 || 0).toFixed(
                    0
                  )} sn` || ""
                : `${Math.floor(estimatedTimeGroup / 60 || 0)} dk ${(estimatedTimeGroup % 60 || 0).toFixed(0)} sn` || ""
              : estimatedTimeGroup?.estimatedTime
              ? `${Math.floor(estimatedTimeGroup?.estimatedTime / 60 || 0)} dk ${(estimatedTimeGroup?.estimatedTime % 60 || 0).toFixed(0)} sn` || ""
              : `${Math.floor(estimatedTimeGroup / 60 || 0)} dk ${(estimatedTimeGroup % 60 || 0).toFixed(0)} sn` || ""}
          </p>
          {data?.junction?.header?.externalOperation ? (
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:day")}</p>
          ) : (
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:targetMinActualss")}</p>
          )}
        </div>
        <div className="flex flex-row items-center">
          {data?.junction?.header?.internalOperation?.plannableNodes?.length == 0 ? (
            isRedOld ? (
              <RedAvgIcon />
            ) : (
              <GreenAvgIcon />
            )
          ) : isRed ? (
            <RedAvgIcon />
          ) : (
            <GreenAvgIcon />
          )}
          <p
            className={`text-xs font-medium ml-1 ${
              data?.junction?.header?.internalOperation?.plannableNodes?.length == 0
                ? isRedOld
                  ? "text-[#D92D20]"
                  : "text-[#079455]"
                : isRed
                ? "text-[#D92D20]"
                : "text-[#079455]"
            }`}
          >
            {formattedOneOrderTime || "--"}
          </p>
          <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:average")}</p>
        </div>
        {/* backendde yapilinca eklenecek 12.06.2024 */}
        {/* <div className="flex flex-row items-center">
            <MadePlayIcon />
            <p className="text-xs font-medium ml-1 text-[#CA8504]">{data?.madeTime || "--"}</p>
            <p className="text-xxs font-normal ml-[2px] mt-[1px] text-secondary-600">{t("product:doneMin")}</p>
          </div> */}
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowDropDown(!showDropDown);
        }}
        className={`hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white ${
          isModal === true ? "group-hover:hidden" : "group-hover:flex"
        }  right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer`}
      >
        <DotsVerticalIcon />
      </div>

      {showDropDown && (
        <div
          className={`hidden flex-col absolute min-w-[200px] top-[32px] right-[3px] max-w-[200px] h-fit bg-white border z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
        >
          {menuOptions?.map((item, index) => {
            const formattedDate = getFormattedNavigateDate(data?.junction?.startDate, auth.user.company.dayStartHour);
            return (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (item.value === "personnelDetail") {
                    navigate(`/app/machine/digital-twin/user-detail/${data?.junction?.user?.id}/${formattedDate}`);
                  }
                  if (item.value === "equipmentDetail") {
                    navigate(
                      `/app/machine/digital-twin/equipment-details/${data?.junction?.station?.deviceId}-${data?.junction?.station?.id}/${formattedDate}`
                    );
                  }
                  setShowDropDown(false);
                }}
                key={index}
                type="button"
                className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                  item?.value === "delete" || item.value === "finishJunction" ? "hover:bg-error-50" : "hover:bg-secondary-100"
                }`}
              >
                {item?.icon}
                <p className={`font-medium text-sm  ${item?.value === "delete" || item.value === "finishJunction" ? "text-[#D92D20]" : "text-secondary-700"}`}>
                  {item.label}
                </p>
              </button>
            );
          })}
        </div>
      )}

      {(data?.junction?.header?.step || data?.junction?.flexHeader?.step) === undefined ? null : (
        <span className="absolute left-1 top-1">
          <Badgets
            colorType={"fill-gray"}
            label={data?.junction?.productType === "flex" ? data?.junction?.flexHeader?.step + ".op" : data?.junction?.header?.step + ".op"}
            size={"sm"}
          />
        </span>
      )}
      <Tooltip className="z-[120]" id={`${data?.junction?.id}`} />
    </div>
  );
};

export default StockDetailRecipeQualityCard;
