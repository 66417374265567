import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import generateFileUrl from "../../../../utils/generateFileUrl";
import { Tooltip } from "react-tooltip";
import { DotsVerticalIcon } from "../../../../assets/icons/commonIcons";
import { NoEquIcon } from "../../../../assets/icons/machineIcons";

const InternalPlanViewPlannedCard = ({ item, statusColor, setDraggedProduct, orderCount = "0", totalCount = "50.000" }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let image = item?.internalOperation?.product?.image
    ? item?.internalOperation?.product?.image
    : item?.flexProduct?.image
      ? item?.flexProduct?.image
      : item?.image
  return (
    <>
      <div
        onClick={() => {
          if (item?.flexProduct) {
            navigate(`/app/product/mes/flex/operation/detail/${item?.flexProduct?.id}+${item?.step}`);
          } else if (item?.flexRawMaterial || item?.name) {
            navigate(`/app/product/mrp/work-in-process/flex/create/${item?.id}`);
          } else {
            navigate(`/app/product/mrp/work-in-process/detail/${item?.orderData?.id}+${item?.step}`);
          }
        }}
        data-tooltip-id={`${item?.id}`}
        data-tooltip-content={`${t("product:productName")}: ${item?.internalOperation?.product?.name ? item?.internalOperation?.product?.name : item?.flexProduct?.name ? item?.flexProduct?.name : item?.name
          } - ${t("product:stock")}: ${item?.internalOperation?.product?.stockCode
            ? item?.internalOperation?.product?.stockCode
            : item?.flexProduct?.stockCode
              ? item?.flexProduct?.stockCode
              : item?.stockCode
          }`}
        data-tooltip-place="right"
        key={item?.id}
        draggable
        // onDragStart={() => setDraggedProduct(item)}
        className="flex bg-white flex-row  w-[300px] min-w-[300px] h-[90px] mr-4 min-h-[90px] relative group pl-2  pt-2  border border-[#D0D5DD] rounded-lg cursor-grab "
      >
        <div className="flex flex-col w-[88%]">
          <div className="flex flex-row items-center gap-x-2">
            {image ? <img
              onClick={(e) => {
                e.stopPropagation();
                if (item?.flexProduct) {
                  navigate(`/app/product/mes/flex/operation/detail/${item?.flexProduct?.id}+${item?.step}`);
                } else if (item?.flexRawMaterial || item?.name) {
                  navigate(`/app/product/mes/flex/create/raw-material`);
                } else {
                  navigate(`/app/product/mrp/work-in-process/detail/${item?.orderData?.id}+${item?.step}`);
                }
              }}
              className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-300 select-none"
              src={generateFileUrl(
                item?.internalOperation?.product?.image
                  ? item?.internalOperation?.product?.image
                  : item?.flexProduct?.image
                    ? item?.flexProduct?.image
                    : item?.image
              )}
            /> : <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-300 select-none bg-secondary-50 flex items-center justify-center">
              <NoEquIcon />
            </div>}
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1 ml-1 w-full">
                {item?.flexProduct || item?.flexRawMaterial || item?.name ? (
                  <>
                    <p className="font-semibold text-secondary-900 text-base w-[165px] truncate">
                      {item?.internalOperation?.product?.name
                        ? item?.internalOperation?.product?.name
                        : item?.flexProduct?.name
                          ? item?.flexProduct?.name
                          : item?.name || "Product Name"}
                    </p>
                  </>
                ) : (
                  <p className="font-semibold text-secondary-900 text-base w-[170px] truncate">{item?.internalOperation?.product?.name || "Product Name"}</p>
                )}
              </div>
              <div
                className={`h-6 max-w-[170px] flex px-1 py-[3px] ml-[2px] items-center justify-center bg-[${item?.flexProduct || item?.flexRawMaterial || item?.name ? "#F9F5FF" : "#FFFAEB"
                  }]`}
              >
                <p
                  className={`text-sm font-normal  max-w-[170px] truncate ${item?.flexProduct || item?.flexRawMaterial || item?.name ? "text-[#6941C6]" : "text-[#B54708]"}`}
                >
                  {item?.flexProduct || item?.flexRawMaterial || item?.name ? t("product:flex") : t("product:stock")} :{" "}
                  {item?.internalOperation?.product?.stockCode
                    ? item?.internalOperation?.product?.stockCode || "--"
                    : item?.flexProduct?.stockCode
                      ? item?.flexProduct?.stockCode || "--"
                      : item?.stockCode || "--"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-[6px]">
            {item?.flexProduct ? (<>
              <div className="flex flex-row items-center mt-1.5">
                <PieceFlexIcon color={"#7F56D9"} />
                <p className="text-sm font-medium ml-1 text-[#6941C6]">{item?.finishedPiece || "0"}</p>
                <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-500">{t("product:qtyMade")}</p>
              </div>
            </>) : item?.flexRawMaterial || item?.name ? (
              <>
                <div className="flex flex-row items-center mt-1.5">
                  <PieceFlexIcon color={"#7F56D9"} />
                  <p className="text-sm font-medium ml-1 text-[#6941C6]">{item?.qualityQuantity || "0"}</p>
                  <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-500">{t("product:qtyMade")}</p>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-row items-center mt-1.5">
                  <PieceFlexIcon color={"#475467"} />
                  <p className="text-sm font-medium ml-1 text-secondary-700">{item?.finishedPiece || "0"}</p>
                  <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-600">{t("product:qtyMade")}</p>
                </div>
                <div className="flex flex-row items-center mt-1.5">
                  <PieceFlexIcon color={"#DC6803"} />
                  <p className="text-sm font-medium ml-1 text-[#B54708]">{item?.piece - item?.finishedPiece || "0"}</p>
                  <p className="text-[10px] ml-0.5 mt-[3px] font-normal text-secondary-600">{t("product:qtyRemaining")}</p>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 bg-[${item?.flexProduct || item?.flexRawMaterial || item?.name ? "#F9F5FF" : statusColor()
            }] border-secondary-300 h-[70px] min-h-[70px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
        >
          <p
            className={`text-[10px] font-medium ${item?.status === "started"
              ? "text-[#A15C07]"
              : item?.status === "rejected"
                ? "text-white"
                : item?.status === "finished"
                  ? "text-white"
                  : item?.flexProduct || item?.flexRawMaterial || item?.name
                    ? "text-[#6941C6]"
                    : "text-secondary-500"
              } -rotate-90`}
          >
            {item?.step ? item?.step + ".Op" : (item?.flexProduct || item?.flexRawMaterial || item?.name) ? (item?.step ? item?.step + ".Flex" : "Flex") : item?.internalOperation?.step + ".Op"}
          </p>
          {item?.flexProduct || item?.flexRawMaterial || item?.name ? (
            <></>
          ) : (
            <span className="w-3 min-w-3 h-3 min-h-3">
              <ArrowIcon
                color={item?.status === "started" ? "#A15C07" : item?.status === "rejected" ? "#fff" : item?.status === "finished" ? "#fff" : "#475467"}
              />
            </span>
          )}
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex absolute min-h-[24px] rotate-90 max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
      </div>
      {item?.id && <Tooltip delayShow={1000} className="z-[999]" id={`${item?.id}`} />}
    </>
  );
};

export default InternalPlanViewPlannedCard;

const ArrowIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M6 9.5L6 2.5M6 2.5L2.5 6M6 2.5L9.5 6" stroke={color} strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const AcceptIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.4987 1.33301H2.58203C1.89168 1.33301 1.33203 1.89265 1.33203 2.58301V13.4163C1.33203 14.1067 1.89168 14.6663 2.58203 14.6663H6.51722M5.4987 1.33301V5.22586C5.4987 5.2744 5.55042 5.30543 5.59324 5.28259L7.9987 3.99967L10.4042 5.28259C10.447 5.30543 10.4987 5.2744 10.4987 5.22586V1.33301M5.4987 1.33301H10.4987M10.4987 1.33301H13.4154C14.1057 1.33301 14.6654 1.89265 14.6654 2.58301V7.20932M8.57914 12.1222L10.4701 14.0023L14.6679 9.80986"
        stroke="#17B26A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const RejectIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.50065 1.33301H2.58398C1.89363 1.33301 1.33398 1.89265 1.33398 2.58301V13.4163C1.33398 14.1067 1.89363 14.6663 2.58399 14.6663H6.51917M5.50065 1.33301V5.22586C5.50065 5.2744 5.55237 5.30543 5.59519 5.28259L8.00065 3.99967L10.4061 5.28259C10.4489 5.30543 10.5007 5.2744 10.5007 5.22586V1.33301M5.50065 1.33301H10.5007M10.5007 1.33301H13.4173C14.1077 1.33301 14.6673 1.89265 14.6673 2.58301V7.20932M9.81857 9.78788L11.9165 11.9122M14.0241 14.0014L11.9165 11.9122M11.9165 11.9122L14.0241 9.78788M11.9165 11.9122L9.81857 14.0014"
        stroke="#F04438"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PieceFlexIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_17841_63067)">
        <path
          d="M5.49967 1.3335H2.99967C2.0792 1.3335 1.33301 2.07969 1.33301 3.00016V13.0002C1.33301 13.9206 2.0792 14.6668 2.99967 14.6668H12.9997C13.9201 14.6668 14.6663 13.9206 14.6663 13.0002V3.00016C14.6663 2.07969 13.9201 1.3335 12.9997 1.3335H10.4997M5.49967 1.3335V5.19064C5.49967 5.25535 5.56863 5.29672 5.62573 5.26627L7.99967 4.00016L10.3736 5.26627C10.4307 5.29672 10.4997 5.25535 10.4997 5.19064V1.3335M5.49967 1.3335H10.4997"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17841_63067">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
