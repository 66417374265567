import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { KVAhIcon, KVAicon } from "../../../../assets/icons/machineIcons";
import EquipmentDetailGaugeChart from "../chart/EquipmentDetailGaugeChart";
import { CcountIcon } from "../../../../assets/icons/leftMenuIcons";
import { UpuPointIcon } from "../../../../assets/icons/equipmentIcon";
import { observer } from "mobx-react-lite";
import EquipmentAndOperationDetailKpiCard from "../chart/EquipmentAndOperationDetailKpiCard";

const OperationDetailInfo = observer(({ data, gaugeData, energyUsageData,selectedDate }) => {
  const machineGaugeData = gaugeData.slice(0, 2);
  const productGaugeData = gaugeData.slice(2);
  const { t } = useTranslation();

  return (
    <div className="flex w-full min-h-[210px] max-h-[210px] gap-x-6 mt-6">
      <div className="flex flex-col h-full border rounded-xl shadow-xs border-[#E4E7EC] w-[40%]">
        <div className="flex justify-between px-4 pt-3 pb-1 items-center">
          <span className="font-codec text-xl text-[#141414]">
            upu.<span className="text-[#7F56D9]">machine</span>
          </span>
          <div className="flex border min-w-[160px] max-w-[160px] min-h-[48px] max-h-[48px] shadow-xs rounded-lg border-[#D6BBFB]">
            <div className="flex bg-[#F9F5FF] border-r border-[#D6BBFB] max-h-[47px] min-w-[48px] max-w-[48px] p-2 rounded-l-lg text-[#6941C6]">
              <CcountIcon />
            </div>
            <div className="flex flex-col w-full h-full px-3 py-1">
              <span className="text-[#475467] font-normal text-xs">c-count (kgs)</span>
              <span className="text-[#6941C6] font-semibold text-sm truncate w-[70%]">{energyUsageData?.cCount?.toFixed(2) || "-- "}</span>
            </div>
          </div>
        </div>

        <div className="flex w-full h-full px-4 pb-4 pt-2 gap-x-4">
          {machineGaugeData.map((item, index) => (
            <EquipmentAndOperationDetailKpiCard
              key={index}
              gaugeData={gaugeData}
              name={t(`addProcess:${item.type}`)}
              value={item.actual ? item.actual : 0}
              timeMass={item.timeMass || 0}
              kpi1={item.average?.toFixed(2) || 0}
              kpi2={item.kpi || 0}
              selectedProcess={data?.id}
              item={item}
              selectedDate={selectedDate}
            />
            // <EquipmentDetailGaugeChart
            //   key={index}
            //   gaugeData={gaugeData}
            //   name={t(`addProcess:${item.type}`)}
            //   value={item.actual ? item.actual : 0}
            //   timeMass={item.timeMass || 0}
            //   kpi1={item.average?.toFixed(2) || 0}
            //   kpi2={item.kpi || 0}
            //   selectedProcess={data?.id}
            // />
          ))}
          <div className="flex flex-col w-1/3 h-full gap-y-2">
            <div className="flex flex-col border h-[115px]  min-h-[115px] w-full rounded-lg border-[#D5D9EB]">
              <div className="flex p-2 gap-x-1 w-full items-center justify-center border-b border-[#D5D9EB] bg-[#F8F9FC] rounded-t-lg">
                <KVAicon />
                <span className="text-[#475467] font-normal text-xs">Consumption</span>
              </div>
              <div className="flex flex-row justify-center items-center">
                <div className="flex text-[#4E5BA6] items-center justify-center p-2 font-semibold text-base">{energyUsageData?.kw?.toFixed(2) || "-- "}</div>
                <p className="text-xs text-secondary-700 mt-0.5">kVA</p>
              </div>
              <div className="flex flex-row items-center justify-center gap-x-1">
                <div className="flex text-[#4E5BA6] items-center justify-center  font-semibold text-base">{energyUsageData?.kwh?.toFixed(2) || "-- "}</div>
                <p className="text-xs text-secondary-700 mt-0.5">kVAh</p>
              </div>
            </div>
            {/* <div className="flex flex-col border h-[50%] w-full rounded-lg border-[#D5D9EB]">
              <div className="flex p-2 gap-x-1 w-full items-center justify-center border-b border-[#D5D9EB] bg-[#F8F9FC] rounded-t-lg">
                <span className="flex min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px] items-center justify-center">
                  <KVAhIcon />
                </span>
                <span className="text-[#475467] font-normal text-xs">kVAh</span>
              </div>
              <div className="flex text-[#4E5BA6] items-center justify-center p-2 font-semibold text-base">{energyUsageData?.kwh?.toFixed(2) || "-- "}</div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full border rounded-xl shadow-xs border-[#E4E7EC] w-[60%]">
        <div className="flex justify-between px-4 pt-3 pb-1 items-center">
          <span className="font-codec text-xl text-[#141414]">
            upu.<span className="text-[#B54708]">product</span>
          </span>
          <div className="flex border min-w-[160px] max-w-[160px] min-h-[48px] max-h-[48px] shadow-xs rounded-lg border-[#F8DA64]">
            <div className="flex bg-[#FEFAE9] border-r border-[#F8DA64] max-h-[47px] items-center justify-center min-w-[48px] max-w-[48px] p-2 rounded-l-lg text-[#CFAA01]">
              <UpuPointIcon />
            </div>
            <div className="flex flex-col w-full h-full px-3 py-1">
              <span className="text-[#475467] font-normal text-xs">upu.point</span>
              <span className="text-[#9B7D07] font-semibold text-sm truncate w-[70%]">{data?.upuPoint || "--"}</span>
            </div>
          </div>
        </div>
        <div className="flex w-full h-full px-4 pb-4 pt-2 gap-x-4">
          {productGaugeData.map((item, index) => (
            <EquipmentAndOperationDetailKpiCard
              key={index}
              gaugeData={gaugeData}
              name={t(`addProcess:${item.type}`)}
              value={item.actual ? item.actual : 0}
              timeMass={item.timeMass || 0}
              kpi1={item.average?.toFixed(2) || 0}
              kpi2={item.kpi || 0}
              selectedProcess={data?.id}
              item={item}
              selectedDate={selectedDate}
            />
            // <EquipmentDetailGaugeChart
            //   key={index}
            //   gaugeData={gaugeData}
            //   name={t(`addProcess:${item.type}`)}
            //   value={item.actual ? item.actual : 0}
            //   timeMass={item.timeMass || 0}
            //   kpi1={item.average?.toFixed(2) || 0}
            //   kpi2={item.kpi || 0}
            //   selectedProcess={data?.id}
            // />
          ))}
        </div>
      </div>
    </div>
  );
});

export default OperationDetailInfo;
