import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon, FilterIcon, MinusIcon, PlusIcon } from "../../assets/icons/productIcons";
import { Button, Input, Loading, TimeInput } from "../../components";
import Selector from "../../components/inputs/Selector";
import Badgets from "../../components/buttons/Badgets";
import SearchInput from "../../components/inputs/SearchInput";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import { customerService } from "../../services/customer.service";
import { useQuery } from "@tanstack/react-query";
import { productService } from "../../services/product.service";
import { Toast } from "../../utils/toastify/toast";
import diacritics from "diacritics";
import { orderService } from "../../services/order.service";
import UnitPriceInput from "../../components/inputs/UnitPriceInput";
import NewOrderWipCard from "./cards/NewOrderWipCard";

const NewFlexOrder = ({}) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [isAddedProducts, setIsAddedProducts] = useState([]);
  const [isOpenNewPorductModal, setIsOpenNewPorductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [customer, setCustomer] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [customerOrderNo, setCustomerOrderNo] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  console.log("customer", customer);

  const [selectedRadio, setSelectedRadio] = useState("order");
  const [orderNo, setOrderNo] = useState("");

  const customerList = useQuery({
    queryKey: ["customer-list"],
    queryFn: async () => await customerService.getCustomerList(),
    retry: 0,
  });

  const getSystemOrderNo = async () => {
    const data = await orderService.getSystemOrderNo(selectedRadio);
    setOrderNo(data?.data);
  };

  const productList = useQuery({
    queryKey: ["list-product"],
    queryFn: async () => {
      return await productService.getAllListProduct();
    },
    retry: 0,
  });

  const handleRemoveProduct = (index) => {
    setIsAddedProducts((prevProducts) => prevProducts.filter((_, i) => i !== index));
  };

  const totalOrderAmount = isAddedProducts?.reduce((acc, item) => acc + (item.orderedQuantity || 0), 0);
  const totalCost = isAddedProducts?.reduce((acc, item) => acc + item.productPrice * (item.orderedQuantity || 0), 0);

  const onSubmit = () => {
    if (buttonLoading) return;
    setButtonLoading(true);
    if (selectedRadio == "order" && !customer) {
      Toast("error", "Müşteri seçiniz");
      return;
    }
    if (!customerOrderNo) {
      Toast("error", "Müşteri sipariş kodu giriniz");
      return;
    }
    if (isAddedProducts.length === 0) {
      Toast("error", "Lütfen en az bir ürün ekleyiniz");
      return;
    }
    const sendData = {
      customer: customer,
      selectedProducts: isAddedProducts,
      customerOrderNo: customerOrderNo,
      productType: selectedRadio,
      systemOrderNo: orderNo,
    };
    console.log("sendData", sendData);
    sessionStorage.setItem("firstPageData", JSON.stringify(sendData));
    navigate("/app/product/mrp/orders/new-order/second-page");
    setButtonLoading(false);
  };

  useEffect(() => {
    if (sessionStorage.getItem("firstPageData")) {
      const data = JSON.parse(sessionStorage.getItem("firstPageData"));
      console.log("data", data);
      setCustomer(data?.customer);
      setCustomerOrderNo(data?.customerOrderNo);
      setSelectedRadio(data?.productType);
      setIsAddedProducts(data?.selectedProducts);
    }
  }, [sessionStorage.getItem("firstPageData")]);

  const FilteredProducts = productList?.data?.data?.filter(
    (item) =>
      diacritics.remove(`${item?.stockCode}`).toLowerCase().includes(diacritics.remove(searchQuery.toLowerCase())) ||
      diacritics.remove(`${item?.name}`).toLowerCase().includes(diacritics.remove(searchQuery).toLowerCase())
  );

  const tableHeadItems = [
    t("product:productsAndMaterialss"),
    t("product:orderQuantityss"),
    t("product:delivery"),
    t("product:unitSale"),
    t("product:totalSales"),
    t("product:remove"),
  ];

  const currencyIcon = (currency) => {
    switch (currency || "TRY") {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      case "RUB":
        return "₽";
      case "UAH":
        return "₴";
      case "TRY":
        return "₺";
      default:
        return "₺";
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("firstPageData")) {
      const data = JSON.parse(sessionStorage.getItem("firstPageData"));
      if (selectedRadio === "stock") {
        getSystemOrderNo();
        setCustomer(null);
      } else if (selectedRadio === "order") {
        getSystemOrderNo();
        if (data?.customer) {
          setCustomer(data?.customer);
        } else {
          setCustomer(null);
        }
      }
    } else {
      getSystemOrderNo();
      setCustomer(null);
    }
  }, [selectedRadio]);

  const priceOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "RUB", value: "RUB" },
    { label: "UAH", value: "UAH" },
    { label: "TRY", value: "TRY" },
  ];

  return (
    <>
      <div className="flex flex-col w-full gap-y-8 h-[95vh]">
        {customerList?.isLoading || customerList?.isFetching ? (
          <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[56px]" />
        ) : (
          <div className="flex min-h-[56px] max-h-[56px] h-[56px] w-full items-center justify-start gap-x-2 sticky top-0 z-[50] bg-white">
            <Button size={"md"} iconLeft={<ArrowLeftIcon />} colorType={"tertiary-gray"} onClick={() => navigate(-1)} type={"button"} />
            <p className="text-[#101828] font-semibold text-2xl">{t("product:createNewOrder")}</p>
          </div>
        )}

        <div className="flex flex-col gap-y-6 w-full h-full">
          {customerList?.isLoading || customerList?.isFetching ? (
            <div className="bg-gray-200 animate-pulse rounded-lg w-full  min-h-[72px]" />
          ) : (
            <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] pb-5 justify-between border-b border-[#E4E7EC] ">
              <div className="flex flex-col gap-y-1">
                <p className="text-[#101828] font-semibold text-lg">{t("product:orderProducts")}</p>
                <p className="text-[#344054] font-normal text-sm">{t("product:productRelated")}</p>
              </div>
              <div className="flex gap-x-3 max-h-[40px] min-h-[40px] h-10">
                <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                  <Button
                    size={"md"}
                    colorType={"secondary-gray"}
                    label={t("buttons:stop")}
                    onClick={() => {
                      sessionStorage.removeItem("firstPageData");
                      sessionStorage.removeItem("secondPageData");
                      navigate("/app/product/mrp/orders");
                    }}
                  />
                </span>
                <span className="flex min-w-[144px] max-w-[144px] w-[144px]">
                  <Button
                    disabled={buttonLoading ? true : false}
                    size={"md"}
                    colorType={"primary-product"}
                    label={buttonLoading ? <Loading color={"#fff"} secondary={"#fff"} size={22} /> : t("buttons:save")}
                    onClick={onSubmit}
                  />
                </span>
              </div>
            </div>
          )}
          {customerList?.isLoading || customerList?.isFetching ? (
            <div className="bg-gray-200 animate-pulse rounded-lg w-full  min-h-[86px]" />
          ) : (
            <div className="flex w-full max-h-5 min-h-5 h-5 items-center gap-x-6">
              <div
                className="flex items-center gap-x-2"
                onClick={() => {
                  setSelectedRadio("order");
                }}
              >
                <div
                  className={`w-5 h-5 items-center flex justify-center rounded-full ${
                    selectedRadio == "order" ? "bg-[#DC6803]" : "bg-white border-[#D0D5DD] border"
                  }`}
                >
                  {selectedRadio == "order" && <div className="w-2  h-2 rounded-full bg-white"></div>}
                </div>
                <p className="text-sm text-secondary-700 font-medium">{t("product:produceForOrder")}</p>
              </div>
              <div
                className="flex items-center gap-x-2"
                onClick={() => {
                  setSelectedRadio("stock");
                }}
              >
                <div
                  className={`w-5 h-5 items-center flex justify-center rounded-full ${
                    selectedRadio == "stock" ? "bg-[#DC6803]" : "bg-white border-[#D0D5DD] border"
                  }`}
                >
                  {selectedRadio == "stock" && <div className="w-2  h-2 rounded-full bg-white"></div>}
                </div>
                <p className="text-sm text-secondary-700 font-medium">{t("product:produceForStock")}</p>
              </div>
            </div>
          )}
          {customerList?.isLoading || customerList?.isFetching ? (
            <div className="bg-gray-200 animate-pulse rounded-lg w-full  min-h-[86px]" />
          ) : (
            <div className="flex flex-col w-full ">
              <div className="flex w-full min-h-[86px] max-h-[86px] border-b border-[#E4E7EC] pb-5 gap-x-8">
                <span className="flex w-[33%]">
                  <Input label={t("product:orderCode")} theme={"product"} disabled={true} value={`${selectedRadio == "stock" ? "STK" : "ORD"}-${orderNo}`} />
                </span>
                <span className="flex w-[33%]">
                  <Selector
                    label={t("product:customer")}
                    items={customerList?.data?.data?.map((item) => {
                      return { label: item?.name, value: item?.id };
                    })}
                    theme={"product"}
                    placeholder={t("product:selectCustomer")}
                    onChange={(e) => {
                      setCustomer(customerList?.data?.data?.find((item) => item?.id === e));
                    }}
                    disabled={selectedRadio != "order" ? true : false}
                    value={customer?.id}
                  />
                </span>
                <span className="flex w-[33%]">
                  <Input
                    label={t("product:customerOrderCode")}
                    theme={"product"}
                    onChange={(e) => {
                      setCustomerOrderNo(e.target.value);
                    }}
                    value={customerOrderNo}
                  />
                </span>
              </div>
            </div>
          )}

          {productList?.isLoading || productList?.isFetching ? (
            <div className="bg-gray-200 animate-pulse rounded-lg w-full h-full" />
          ) : (
            <div className="flex w-full h-[55vh] border  border-[#E4E7EC] rounded-t-xl">
              <div className="flex flex-col h-full w-[401px] min-w-[401px] max-w-[401px] border-r border-[#EAECF0]">
                <div className="flex min-h-[56px] max-h-[56px] h-[56px] rounded-tl-xl border-b border-[#EAECF0] py-4 px-6 gap-x-2 items-center">
                  <p className="text-[#101828] font-semibold text-sm">{t("product:allProduct")}</p>
                  <Badgets colorType={"outline-warning"} size={"sm"} label={productList?.data?.data?.length || 0} />
                </div>
                <div className="flex flex-col gap-y-3 px-6 py-4 w-full">
                  <div className="flex w-full">
                    <Button
                      colorType={"secondary-gray"}
                      label={t("product:createNewProduct")}
                      iconLeft={<PlusIcon />}
                      onClick={() => {
                        navigate("/app/product/mrp/stock/create");
                      }}
                    />
                  </div>
                  <div className="flex gap-x-3 w-full">
                    <SearchInput setSearchVal={setSearchQuery} theme={"product"} />
                    <Button iconLeft={<FilterIcon />} colorType={"secondary-gray"} size={"md"} />
                  </div>
                </div>
                <div className="flex flex-col  w-full h-full overflow-y-auto overflow-x-hidden scrollbar-hide rounded-bl-xl">
                  {FilteredProducts?.map((item, index) => {
                    return (
                      <div
                        className={`flex w-full min-h-[136px] max-h-[136px] h-[136px] pl-6 p-3 gap-x-4 border-b border-[#E4E7EC] ${
                          index % 2 === 0 ? "bg-[#F9FAFB]" : "bg-white"
                        }`}
                      >
                        <NewOrderWipCard item={item} />
                        <div className="flex items-center justify-center min-w-[36px] max-w-[36px] w-[36px]">
                          <Button
                            iconLeft={<PlusIcon />}
                            colorType={"secondary-gray"}
                            size={"sm"}
                            onClick={() => {
                              const hasSameDeliveryDate = isAddedProducts.some(
                                (product) => product?.id === item?.id && product?.deliveryDate === moment().format("YYYY-MM-DD")
                              );

                              if (hasSameDeliveryDate) {
                                Toast("error", "Bu ürün için aynı teslim tarihine sahip kayıt zaten mevcut");
                                return;
                              }

                              const newProduct = {
                                ...item,
                                orderedQuantity: "",
                                productPrice: "",
                                currency: "TRY",
                                deliveryDate: moment().format("YYYY-MM-DD"),
                                product: item,
                              };
                              setIsAddedProducts([...isAddedProducts, newProduct]);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col min-h-full w-full relative">
                <div className="flex min-h-[56px] max-h-[56px] h-[56px] rounded-tr-xl border-b border-[#EAECF0] py-4 px-6 gap-x-2 items-center">
                  <p className="text-[#101828] font-semibold text-md">{t("product:addedProducts")}</p>
                  <Badgets colorType={"outline-warning"} size={"sm"} label={isAddedProducts?.length} />
                </div>
                <div className="flex flex-col w-full min-h-[calc(50vh-96px)] max-h-[calc(50vh-96px)] overflow-y-auto overflow-x-hidden scrollbar-hide pb-6 ">
                  {/* table header */}
                  <div className="flex max-h-[34px] min-h-[34px] h-[34px] w-full sticky top-0 bg-[#F9FAFB] z-50">
                    {tableHeadItems.map((item, i) => (
                      <div
                        key={i}
                        className={`flex border-b border-r border-[#E4E7EC] items-center py-2 ${
                          i === 0 || i === 1 || i === 2 ? "px-6" : i === 3 ? "px-4" : i === 4 ? "px-2" : ""
                        } w-[${i === 0 ? "30%" : i === 1 || i === 2 ? "16%" : i === 3 ? "17%" : i === 4 ? "13%" : i === 5 ? "8%" : ""}] ${
                          i === 5 ? "border-r-0" : ""
                        } ${i === 0 ? "justify-start" : "justify-center"}`}
                      >
                        <span className="text-center text-xs font-medium cursor-pointer whitespace-nowrap">{item}</span>
                      </div>
                    ))}
                  </div>
                  {isAddedProducts?.map((item, index) => {
                    return (
                      <div className="flex w-full items-center border-b h-[80px] min-h-[80px]">
                        {/* tablo 1.kolon */}
                        <div className="flex gap-x-3 h-full items-center pr-[30px] w-[30%] pl-4 border-r border-[#E4E7EC] relative">
                          {/* Ürün Resmi */}
                          <img
                            className="min-w-[64px] min-h-[48px] max-w-[64px] max-h-[48px] w-[64px] h-[48px] rounded border border-[#0000001A]"
                            src={generateFileUrl(item?.product?.image)}
                          />

                          {/* Ürün Bilgileri */}
                          <div className="flex flex-col justify-center min-w-0">
                            <p className="text-[#101828] font-semibold text-md truncate">{item?.product?.name}</p>
                            <span className="bg-[#FFFAEB] text-[#B54708] text-xs font-medium truncate px-1 py-[3px] w-fit">
                              {t("product:stock")}: {item?.product?.stockCode}
                            </span>
                          </div>

                          {/* Dikey Yazı Alanı */}
                          <div className="flex items-center justify-center min-w-[26px] w-[26px] h-[80px] absolute right-0 bg-[#F9FAFB] border border-r-0">
                            <span className="text-xxs font-semibold text-[#475467] rotate-180" style={{ writingMode: "vertical-rl" }}>
                              {t("person:product")} ({t("product:pcs")})
                            </span>
                          </div>
                        </div>
                        {/* tablo 2.kolon */}
                        <div className="flex flex-col h-full p-3 items-center justify-center w-[16%] border-r border-[#E4E7EC]">
                          <Input
                            type="number"
                            value={item?.orderedQuantity}
                            onChange={(e) => {
                              const updatedProducts = isAddedProducts.map((product) =>
                                product?.id === item?.id && product?.deliveryDate === item?.deliveryDate
                                  ? {
                                      ...product,
                                      orderedQuantity: Number(e.target.value),
                                    }
                                  : product
                              );
                              setIsAddedProducts(updatedProducts);
                            }}
                            theme={"product"}
                            validate={!item?.orderedQuantity ? "error" : null}
                            errorMessage="Bu alan zorunludur"
                          />
                        </div>
                        {/* tablo 3.kolon */}
                        <div className="flex h-full p-3 items-center justify-center w-[16%] border-r border-[#E4E7EC]">
                          <TimeInput
                            value={moment(item?.deliveryDate).format("YYYY-MM-DD")}
                            onChange={(value) => {
                              const hasSameDeliveryDate = isAddedProducts.some(
                                (product) => product?.id === item?.id && product?.deliveryDate === value && product !== item
                              );

                              if (hasSameDeliveryDate) {
                                Toast("error", "Bu ürün için seçilen teslim tarihine sahip kayıt zaten mevcut");
                                return;
                              }

                              const updatedProducts = isAddedProducts.map((product) =>
                                product?.id === item?.id &&
                                moment(product?.deliveryDate).format("YYYY-MM-DD") === moment(item?.deliveryDate).format("YYYY-MM-DD")
                                  ? { ...product, deliveryDate: value }
                                  : product
                              );
                              setIsAddedProducts(updatedProducts);
                            }}
                            theme={"product"}
                            minDate={moment().format("YYYY-MM-DD")}
                            validate={!item?.deliveryDate ? "error" : null}
                            errorMessage="Bu alan zorunludur"
                          />
                        </div>
                        {/* tablo 4.kolon */}
                        <div className="flex w-[17%] items-center justify-center h-full p-3 border-r border-[#E4E7EC]">
                          <UnitPriceInput
                            items={priceOptions}
                            price={item?.productPrice}
                            currency={item?.currency}
                            size={"sm"}
                            setValue={(field, value) => {
                              const updatedProducts = isAddedProducts.map((product) =>
                                product?.id === item?.id && product?.deliveryDate === item?.deliveryDate
                                  ? {
                                      ...product,
                                      [field === "price" ? "productPrice" : "currency"]: field === "price" ? Number(value) : value,
                                    }
                                  : product
                              );
                              setIsAddedProducts(updatedProducts);
                            }}
                            theme={"product"}
                            validate={!item?.productPrice ? "error" : null}
                            errorMessage="Bu alan zorunludur"
                          />
                        </div>
                        {/* tablo 5.kolon */}
                        <div className="flex w-[13%] items-center justify-center h-full p-3 border-r border-[#E4E7EC]">
                          <p className="text-[#344054] font-medium text-sm">
                            {currencyIcon(item?.currency)} {item?.productPrice * item?.orderedQuantity}
                          </p>
                        </div>
                        {/* tablo 6.kolon */}
                        <div className="flex w-[8%] h-full p-3 justify-center items-center">
                          <Button colorType={"secondary-product"} iconLeft={<MinusIcon />} onClick={() => handleRemoveProduct(index)} size={"sm"} />
                        </div>
                      </div>
                    );
                  })}
                  {isAddedProducts?.length > 0 && (
                    <div className="flex absolute bottom-6 right-0 min-h-[72px] max-h-[72px] h-[72px] px-6 w-full">
                      <div className="flex w-full min-h-[72px] max-h-[72px] h-[72px] bg-[#B54708] shadow-lg rounded-xl justify-between items-center">
                        <div className="flex h-full py-3 px-4 gap-x-4 items-center">
                          <div className="flex min-w-[64px] max-w-[64px] min-h-[48px] max-h-[48px] relative">
                            <img
                              className="absolute z-[10] top-[8px] left-[-2px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                              src={generateFileUrl(isAddedProducts?.[0]?.image)}
                            />
                            <img
                              className="absolute z-[20] top-[-2px] left-[10px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                              src={generateFileUrl(isAddedProducts?.[1]?.image)}
                            />
                            <img
                              className="absolute z-[30] bottom-[-2px] right-[-2px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] border-[2px] border-[#FFFFFFCC] rounded"
                              src={generateFileUrl(isAddedProducts?.[2]?.image)}
                            />
                          </div>
                          <div className="flex gap-x-1 items-center">
                            <p className="text-[#FFFFFF] font-semibold text-xl">{isAddedProducts?.length}</p>
                            <p className="text-[#FFFFFF] font-normal text-md">{t("product:productsss")}</p>
                          </div>
                        </div>
                        <div className="flex py-3 text-[#FFFFFF] font-semibold text-lg">
                          <div className="flex px-5 items-center justify-center border-r border-[#FFFFFF4D]">{t("product:totalAmount")}</div>
                          <div className="flex flex-col gap-y-[2px] items-center justify-center px-5 border-r border-[#FFFFFF4D]">
                            <p className="text-xs font-medium">{t("product:orderQuantity")}</p>
                            <p>{totalOrderAmount}</p>
                          </div>
                          <div className="flex flex-col gap-y-[2px] items-center justify-center px-5 border-r border-[#FFFFFF4D]">
                            <p className="text-xs font-medium">{t("product:totalSales")}</p>
                            <p>
                              {currencyIcon(isAddedProducts?.[0]?.currency)} {totalCost}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NewFlexOrder;
