import React, { useState, useEffect, useRef } from "react";
import { Button } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { purchaseService } from "../../services/purchase.service";
import useWindowDimensions from "../../hooks/useWindowDimension";
import NewOrderHeaderCard from "./components/order/NewOrderHeaderCard";
import { ArrowRight } from "../../assets/icons/equipmentIcon";
import MaterialSupplyHeaderCard from "./cards/material-supply/MaterialSupplyHeaderCard";
import CustomImage from "../../components/CustomImage";
import { NoEquIcon } from "../../assets/icons/machineIcons";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../assets/icons/stepsIcons";

const MaterialSupplyListPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { settings, auth } = useStore();
  let navigate = useNavigate();
  const { height } = useWindowDimensions();
  const [selectedGroup, setSelectedGroup] = useState("all");

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const ITEM_HEIGHT = 72;

  const calculateInitialItemCount = () => {
    const viewHeight = window.innerHeight;
    return Math.ceil(viewHeight / ITEM_HEIGHT) + 5;
  };

  const headData = useQuery({
    queryKey: ["all-supplier-list-group-junctions"],
    enabled: false,
    retry: 0,
    queryFn: async () => await purchaseService.listSupplierGroupForCompany(true),
  });

  const fetchMoreData = async () => {
    const nextPage = page + 1;

    const response = await purchaseService.purchaseTransactionsPlannedScroll(id, "list", nextPage, 10);

    const newData = response?.data || [];
    setData((prevData) => [...prevData, ...newData]);

    setTotalCount(response?.meta?.itemCount || 0);

    setPage(nextPage);
  };

  const hasMore = data.length < totalCount;

  useEffect(() => {
    if (id !== ":id" && id !== "undefined") {
      const loadData = async () => {
        await headData.refetch().then(() => {
          const foundGroup = headData?.data?.find((item) => item?.id === id);
          setSelectedGroup(foundGroup);
        });

        const initialTake = calculateInitialItemCount();
        const response = await purchaseService.purchaseTransactionsPlannedScroll(id, "list", 1, initialTake);

        const initialData = response?.data || [];
        setData(initialData);

        setTotalCount(response?.meta?.itemCount || 0);

        setPage(1);
      };

      loadData();
    }
  }, [id]);

  useEffect(() => {
    if (headData?.data?.length > 0 && (id === ":id" || id === "undefined")) {
      navigate(`/app/product/mrp/supply/material/${headData?.data[0]?.id}/list`);
    }
  }, [headData?.data]);

  const tabOptions = [
    { value: "list", label: t("product:list") },
    { value: "junction", label: t("product:track") },
    { value: "plan", label: t("product:planner") },
  ];

  const tableHeadItems = [
    { name: t("product:materials") },
    { name: t("product:inStock") },
    { name: t("product:activeRequests") },
    { name: t("product:remainingRequest") },
    { name: t("product:msPlanned") },
    { name: t("product:msPlannedQuantity") },
    { name: "" },
  ];

  const units = [
    { shortLabel: "kg", value: "kilogram" },
    { shortLabel: "ton", value: "ton" },
    { shortLabel: "gr", value: "gram" },
    { shortLabel: "mg", value: "miligram" },
    { shortLabel: "mcg", value: "mikrogram" },
    { shortLabel: "lb", value: "libre" },
    { shortLabel: "oz", value: "ons" },
    { shortLabel: "m", value: "metre" },
    { shortLabel: "mm", value: "milimetre" },
    { shortLabel: "gal", value: "galon" },
    { shortLabel: "m2", value: "metrekare" },
    { shortLabel: "mm2", value: "milimetrekare" },
    { shortLabel: "in2", value: "inçkare" },
    { shortLabel: "ad", value: "adet" },
    { shortLabel: "pkg", value: "paket" },
    { shortLabel: "box", value: "kutu" },
  ];

  const getUnitLabel = (unit) => {
    if (unit === undefined) {
      return "--";
    }
    const foundUnit = units.find((u) => u.value === unit);
    return foundUnit ? foundUnit.shortLabel : unit;
  };

  return (
    <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden relative step-1 scrollbar-hide">
      <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
        <span className="mr-3 md:flex sm:flex xs:flex hidden">
          <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
        </span>
        <div className="flex items-center">
          <span className="font-codecMedium text-2xl text-black">upu.</span>
          <p className="text-2xl font-codecMedium text-[#B54708]">supply</p>
          {!(headData?.isLoading || headData?.isFetching) && (
            <p className="text-xl font-normal ml-2 text-secondary-500">
              {selectedGroup && selectedGroup?.name ? `${t("product:materialSupply")} / ${selectedGroup?.name}` : t("product:materialSupply")}
            </p>
          )}
        </div>
        <span className="ml-auto">
          <Button
            colorType={"primary-product"}
            size={"md"}
            label={t("product:supplierList")}
            onClick={() => navigate("/app/product/mrp/supply/material/list")}
          />
        </span>
      </div>
      {headData?.isLoading || headData?.isFetching ? (
        <div className="w-full bg-gray-200 rounded-xl animate-pulse h-[96px] min-h-[96px] flex gap-5" />
      ) : (
        <div className="h-[48px] min-h-[48px] w-full overflow-x-auto flex  overflow-y-hidden">
          {headData?.data
            ?.sort((a, b) => b?.requestCount - a?.requestCount)
            ?.map((item, index) => {
              return (
                <div className={`min-w-[192px] ${index !== headData?.data?.length - 1 ? "border-b" : "border-b-0"} max-h-[40px] pr-5`}>
                  <MaterialSupplyHeaderCard
                    onClick={async () => {
                      navigate(`/app/product/mrp/supply/material/${item?.id}/list`);
                    }}
                    name={item?.name}
                    selectedOperation={{
                      id: id,
                      name: item?.name,
                    }}
                    id={item?.id}
                    length={item?.requestCount}
                  />
                </div>
              );
            })}
        </div>
      )}
      {headData?.isLoading || headData?.isFetching ? (
        <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-gray-200 p-[6px] mt-6 mb-4 rounded-xl animate-pulse flex gap-5" />
      ) : (
        <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-4 mb-4">
          {tabOptions?.map((option, index) => (
            <div
              className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
                "list" == option?.value ? "bg-white shadow-sm" : ""
              }`}
              onClick={() => {
                if (option?.value === "plan") navigate(`/app/product/mrp/supply/material/${id}/plan-view`);
                if (option?.value === "junction") navigate(`/app/product/mrp/supply/material/${id}/junction/:cardId`);
              }}
            >
              <p className={`text-md font-semibold ${"list" == option?.value ? "text-[#344054]" : "text-[#667085]"}`}>{option?.label}</p>
            </div>
          ))}
        </div>
      )}
      {headData?.isLoading || headData?.isFetching ? (
        <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse" />
      ) : (
        <>
          <div style={{ minHeight: height - 50 }} className="flex flex-col w-full border rounded-xl">
            <div className="flex max-h-[44px] min-h-[44px] max-w-full min-w-full">
              {tableHeadItems?.map((item, i) => {
                return (
                  <div
                    key={i}
                    scope="col"
                    className={` px-4 py-3 ${
                      i === 0 ? "min-w-[336px] max-w-[336px] rounded-tl-xl" : i === 6 ? "min-w-[72px] max-w-[72px] w-[72px] rounded-tr-xl" : "w-full"
                    } items-center text-center bg-secondary-50 border-gray-200 border-b border-r last:border-r-0`}
                  >
                    <span className={`text-center rounded flex text-xs font-medium justify-center md:line-clamp-2 items-center whitespace-nowrap`}>
                      {item?.name}
                    </span>
                  </div>
                );
              })}
            </div>
            <InfiniteScroll dataLength={data.length} next={fetchMoreData} hasMore={hasMore} scrollableTarget="scrollableDiv">
              <div id="scrollableDiv" className="w-full bg-white h-[88vh] flex flex-col overflow-y-auto overflow-x-hidden scrollbar-hide rounded-xl ">
                {data?.map((item, index) => {
                  const totalPlannedUnits = item?.plannedJunctions.reduce(
                    (acc, junction) => acc + (junction?.status === "active" ? junction?.plannedUnit || 0 : 0),
                    0
                  );
                  return (
                    <div className={`flex w-full items-center border-b h-[72px] min-h-[72px]`}>
                      <div className="flex items-center h-full border-r min-w-[336px] max-w-[336px]">
                        <div className="flex w-full h-full px-6 py-3 gap-x-[6px]">
                          {item?.type === "semi_internal_product" ? (
                            <>
                              {item?.productInternalOperation ? (
                                <>
                                  {item?.productInternalOperation?.product?.image ? (
                                    <div
                                      data-tooltip-id={`${item?.id}`}
                                      data-tooltip-place="right"
                                      data-tooltip-content={`${t("product:productName")}: ${item?.productInternalOperation?.product?.name} - ${t(
                                        "product:stockCode"
                                      )}: ${item?.productInternalOperation?.product?.stockCode}`}
                                    >
                                      <CustomImage
                                        borderRadius={2}
                                        src={item?.productInternalOperation?.product?.image}
                                        style={{
                                          width: 64,
                                          height: 48,
                                          minWidth: 64,
                                          minHeight: 48,
                                          borderRadius: 4,
                                          borderWidth: 1,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          display: "flex",
                                          borderColor: "#0000001A",
                                          backgroundColor: "#fff",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      // data-tooltip-id={`${item?.id}`}
                                      // data-tooltip-place="right"
                                      // data-tooltip-content={`${t("product:productName")}: ${item?.productInternalOperation?.product?.name} - ${t("product:stockCode")}: ${item?.productInternalOperation?.product?.stockCode
                                      //   }`}
                                      className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                                    >
                                      <NoEquIcon />
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {item?.productExternalOperation?.product?.image ? (
                                    <div
                                    // data-tooltip-id={`${item?.id}`}
                                    // data-tooltip-place="right"
                                    // data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stockCode")}: ${item?.productExternalOperation?.product?.stockCode
                                    //   }`}
                                    >
                                      <CustomImage
                                        borderRadius={2}
                                        src={item?.productExternalOperation?.product?.image}
                                        style={{
                                          width: 64,
                                          height: 48,
                                          minWidth: 64,
                                          minHeight: 48,
                                          borderRadius: 4,
                                          borderWidth: 1,
                                          alignItems: "center",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          display: "flex",
                                          borderColor: "#0000001A",
                                          backgroundColor: "#fff",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      // data-tooltip-id={`${item?.id}`}
                                      // data-tooltip-place="right"
                                      // data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stockCode")}: ${item?.productExternalOperation?.product?.stockCode
                                      //   }`}
                                      className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                                    >
                                      <NoEquIcon />
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          ) : item?.rawMaterial ? (
                            <>
                              {item?.rawMaterial?.image ? (
                                <div
                                // data-tooltip-id={`${item?.id}`}
                                // data-tooltip-place="right"
                                // data-tooltip-content={`${t("product:productName")}: ${item?.rawMaterial?.name} - ${t("product:stockCode")}: ${item?.rawMaterial?.stockCode}`}
                                >
                                  <CustomImage
                                    borderRadius={2}
                                    src={item?.rawMaterial?.image}
                                    style={{
                                      width: 64,
                                      height: 48,
                                      minWidth: 64,
                                      minHeight: 48,
                                      borderRadius: 4,
                                      borderWidth: 1,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      display: "flex",
                                      borderColor: "#0000001A",
                                      backgroundColor: "#fff",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  // data-tooltip-id={`${item?.id}`}
                                  // data-tooltip-place="right"
                                  // data-tooltip-content={`${t("product:productName")}: ${item?.rawMaterial?.name} - ${t("product:stockCode")}: ${item?.rawMaterial?.stockCode}`}
                                  className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                                >
                                  <NoEquIcon />
                                </div>
                              )}
                            </>
                          ) : item?.product?.image ? (
                            <div
                            // data-tooltip-id={`${item?.id}`}
                            // data-tooltip-place="right"
                            // data-tooltip-content={`${t("product:productName")}: ${item?.product?.name} - ${t("product:stockCode")}: ${item?.product?.stockCode}`}
                            >
                              <CustomImage
                                borderRadius={2}
                                src={item?.product?.image}
                                style={{
                                  width: 64,
                                  height: 48,
                                  minWidth: 64,
                                  minHeight: 48,
                                  borderRadius: 4,
                                  borderWidth: 1,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  display: "flex",
                                  borderColor: "#0000001A",
                                  backgroundColor: "#fff",
                                }}
                              />
                            </div>
                          ) : item?.productExternalOperation ? (
                            <>
                              {item?.productExternalOperation?.product?.image ? (
                                <div
                                // data-tooltip-id={`${item?.id}`}
                                // data-tooltip-place="right"
                                // data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stockCode")}: ${item?.productExternalOperation?.product?.stockCode
                                //   }`}
                                >
                                  <CustomImage
                                    borderRadius={2}
                                    src={item?.productExternalOperation?.product?.image}
                                    style={{
                                      width: 64,
                                      height: 48,
                                      minWidth: 64,
                                      minHeight: 48,
                                      borderRadius: 4,
                                      borderWidth: 1,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      display: "flex",
                                      borderColor: "#0000001A",
                                      backgroundColor: "#fff",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  // data-tooltip-id={`${item?.id}`}
                                  // data-tooltip-place="right"
                                  // data-tooltip-content={`${t("product:productName")}: ${item?.productExternalOperation?.product?.name} - ${t("product:stockCode")}: ${item?.productExternalOperation?.product?.stockCode
                                  //   }`}
                                  className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                                >
                                  <NoEquIcon />
                                </div>
                              )}
                            </>
                          ) : (
                            <div
                              data-tooltip-id={`${item?.id}`}
                              data-tooltip-place="right"
                              data-tooltip-content={`${t("product:productName")}: ${item?.product?.name} - ${t("product:stockCode")}: ${
                                item?.product?.stockCode
                              }`}
                              className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center"
                            >
                              <NoEquIcon />
                            </div>
                          )}
                          <div className="flex flex-col w-full justify-center">
                            <p className="text-[#101828] text-md font-semibold px-1 max-w-[190px]  truncate">
                              {item?.productInternalOperation
                                ? item?.productInternalOperation?.product?.name || "Product Name"
                                : item?.productExternalOperation
                                ? item?.productExternalOperation?.product?.name || "Product Name"
                                : item?.product
                                ? item?.product?.name
                                : item?.rawMaterial
                                ? item?.rawMaterial?.name
                                : "Product Name"}
                            </p>
                            <p className="px-1 py-[3px] max-w-[190px] w-fit truncate rounded bg-[#FFFAEB] text-[#B54708] text-xs font-medium">
                              {t("product:stock")} :{" "}
                              {item?.productInternalOperation
                                ? item?.productInternalOperation?.product?.stockCode || "--"
                                : item?.productExternalOperation
                                ? item?.productExternalOperation?.product?.stockCode || "--"
                                : item?.product
                                ? item?.product?.stockCode
                                : item?.rawMaterial
                                ? item?.rawMaterial?.stockCode
                                : "--"}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center justify-center h-full min-w-6 max-w-6 w-6 p-[3px] bg-[#F2F4F7] border-l border-[#E4E7EC]">
                          <p className="text-[#475467] text-xxs font-semibold -rotate-90 whitespace-nowrap">
                            {item?.productInternalOperation ? (
                              <p className="text-xxs font-semibold whitespace-nowrap text-[#667085]">{t("product:semiProduct")}</p>
                            ) : item?.productExternalOperation ? (
                              <p className="text-xxs font-semibold whitespace-nowrap text-[#667085] ">{t("product:semiProduct")}</p>
                            ) : item?.product ? (
                              <p className={`text-xxs font-semibold whitespace-nowrap text-[#667085]`}>{t("product:rMaterial")}</p>
                            ) : item?.rawMaterial ? (
                              <p className={`text-xxs font-semibold whitespace-nowrap text-[#667085]`}>{t("product:rMaterial")}</p>
                            ) : (
                              "--"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                        <p className="text-[#344054] text-sm font-medium">
                          {item?.rawMaterial
                            ? item?.rawMaterial?.quantity % 1 === 0
                              ? item?.rawMaterial?.quantity
                              : item?.rawMaterial?.quantity.toFixed(2)
                            : item?.product
                            ? item?.product?.qualityQuantity % 1 === 0
                              ? item?.product?.qualityQuantity
                              : item?.product?.qualityQuantity.toFixed(2)
                            : item?.productInternalOperation
                            ? item?.productInternalOperation?.activePiece % 1 === 0
                              ? item?.productInternalOperation?.activePiece || "--"
                              : item?.productInternalOperation?.activePiece.toFixed(2) || "--"
                            : item?.productExternalOperation?.activePiece % 1 === 0
                            ? item?.productExternalOperation?.activePiece || "--"
                            : item?.productExternalOperation?.activePiece?.toFixed(2) || "--"}
                        </p>
                        <p className="text-[#667085] text-xs">
                          {item?.productInternalOperation ? (
                            <p className="text-xs font-semibold whitespace-nowrap text-[#667085]">
                              {/* {"Ara Mamül "}  */}
                              {t("product:pcs")}
                            </p>
                          ) : item?.productExternalOperation ? (
                            <p className="text-xs font-semibold whitespace-nowrap text-[#667085] ">
                              {/* {"Ara Mamül"}  */}

                              {t("product:pcs")}
                            </p>
                          ) : item?.product ? (
                            <p className={`text-xs font-semibold whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.rawMaterial?.unitType)}</p>
                          ) : item?.rawMaterial ? (
                            <p className={`text-xs font-semibold whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.rawMaterial?.unitType)}</p>
                          ) : (
                            "--"
                          )}
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                        <p className="text-[#344054] text-sm font-medium">
                          {item?.orderedUnit ? (item?.orderedUnit % 1 === 0 ? item?.orderedUnit : item?.orderedUnit.toFixed(2)) : "0"}
                        </p>
                        <p className="text-[#667085] text-xs">
                          {item?.productInternalOperation ? (
                            <p className="text-xs font-semibold whitespace-nowrap text-[#667085]">
                              {/* {"Ara Mamül "}  */}
                              {t("product:pcs")}
                            </p>
                          ) : item?.productExternalOperation ? (
                            <p className="text-xs font-semibold whitespace-nowrap text-[#667085] ">
                              {/* {"Ara Mamül"}  */}

                              {t("product:pcs")}
                            </p>
                          ) : item?.product ? (
                            <p className={`text-xs font-semibold whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.rawMaterial?.unitType)}</p>
                          ) : item?.rawMaterial ? (
                            <p className={`text-xs font-semibold whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.rawMaterial?.unitType)}</p>
                          ) : (
                            "--"
                          )}
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                        <p className="text-[#344054] text-sm font-medium">
                          {item?.orderedUnit - totalPlannedUnits
                            ? (item?.orderedUnit - totalPlannedUnits) % 1 === 0
                              ? item?.orderedUnit - totalPlannedUnits
                              : (item?.orderedUnit - totalPlannedUnits).toFixed(2)
                            : "0"}
                        </p>
                        <p className="text-[#667085] text-xs">
                          {" "}
                          {item?.productInternalOperation ? (
                            <p className="text-xs font-semibold whitespace-nowrap text-[#667085]">
                              {/* {"Ara Mamül "}  */}
                              {t("product:pcs")}
                            </p>
                          ) : item?.productExternalOperation ? (
                            <p className="text-xs font-semibold whitespace-nowrap text-[#667085] ">
                              {/* {"Ara Mamül"}  */}

                              {t("product:pcs")}
                            </p>
                          ) : item?.product ? (
                            <p className={`text-xs font-semibold whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.rawMaterial?.unitType)}</p>
                          ) : item?.rawMaterial ? (
                            <p className={`text-xs font-semibold whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.rawMaterial?.unitType)}</p>
                          ) : (
                            "--"
                          )}
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                        <p className="text-[#344054] text-sm font-medium">
                          {totalPlannedUnits ? (totalPlannedUnits % 1 === 0 ? totalPlannedUnits : totalPlannedUnits.toFixed(2)) : "0"}
                        </p>
                        <p className="text-[#667085] text-xs">
                          {" "}
                          {item?.productInternalOperation ? (
                            <p className="text-xs font-semibold whitespace-nowrap text-[#667085]">
                              {/* {"Ara Mamül "}  */}
                              {t("product:pcs")}
                            </p>
                          ) : item?.productExternalOperation ? (
                            <p className="text-xs font-semibold whitespace-nowrap text-[#667085] ">
                              {/* {"Ara Mamül"}  */}

                              {t("product:pcs")}
                            </p>
                          ) : item?.product ? (
                            <p className={`text-xs font-semibold whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.rawMaterial?.unitType)}</p>
                          ) : item?.rawMaterial ? (
                            <p className={`text-xs font-semibold whitespace-nowrap text-[#667085]`}>{getUnitLabel(item?.rawMaterial?.unitType)}</p>
                          ) : (
                            "--"
                          )}
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center h-full border-r w-full py-4">
                        <p className="text-[#344054] text-sm font-medium">{item?.plannedJunctions?.length || "0"}</p>
                      </div>
                      <div className="flex justify-center items-center h-full  min-w-[72px] max-w-[72px] w-[72px]">
                        <Button
                          colorType={"tertiary-gray"}
                          iconLeft={<ArrowRight />}
                          size={"md"}
                          onClick={() => navigate(`/app/product/mrp/supply/material/purchase-detail/${item?.id}/${headData?.data[0]?.id}`)}
                        />
                      </div>
                    </div>
                  );
                })}
                {hasMore && (
                  <div className="flex w-full justify-center items-center border-b h-[72px] min-h-[72px]">
                    <Loader currentColor="#DC6803" currentFill="currentFill" />
                  </div>
                )}
                {/* {!hasMore && data.length > 0 && (
                  <div className="flex w-full justify-center items-center h-[72px]">
                    <p>No more data</p>
                  </div>
                )} */}
              </div>
            </InfiniteScroll>
          </div>
        </>
      )}
    </div>
  );
};

export default MaterialSupplyListPage;
