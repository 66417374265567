import React, { useState } from "react";
import { Button, Input, TimeInput } from "../../../components";
import StockProductCard from "../cards/StockProductCard";
import { useTranslation } from "react-i18next";
import UnitPriceInput from "../../../components/inputs/UnitPriceInput";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { rawMaterialService } from "../../../services/raw-material.service";
import { productService } from "../../../services/product.service";
import { Toast } from "../../../utils/toastify/toast";
import StockOperationCard from "../cards/StockOperationCard";
import NewStockExternalOperationCard from "../cards/NewStockExternalOperationCard";

const EditStockQuantityModal = ({ onClose, item, isStock, isOperation, legacyRefetch }) => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("TRY");
  const [errors, setErrors] = useState({});
  const [deliveryDate, setDeliveryDate] = useState("");
  const [selectedOption, setSelectedOption] = useState("buy");
  const handleInputChange = (event) => {
    setQuantity(event.target.value);
  };

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ["getRawMaterialDetail-modal"],
    queryFn: async () => await rawMaterialService.getPurchaseList(item?.id),
    retry: 0,
  });

  const handleSetValue = (field, value) => {
    if (field === "price") {
      setPrice(value);
    } else {
      setCurrency(value);
    }
  };

  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };

  const onSubmit = async () => {
    let isValid = true;
    const type = selectedOption === "buy" || selectedOption === "increase" ? "increase" : "decrease";

    if (!quantity) {
      setErrors({
        workPiece: { message: "Bu alan zorunludur. 1" },
      });
      isValid = false;
    }
    if ((selectedOption === "increase" && !price) || !currency) {
      setErrors({
        price: { message: "Miktar ve fiyat zorunludur" },
      });
      isValid = false;
    }
    if (selectedOption === "buy" && !deliveryDate) {
      setErrors({
        deliveryDate: { message: "Teslim tarihi zorunludur" },
      });
      isValid = false;
    }
    if (isValid) {
      let sendData = {};
      if (selectedOption === "buy") {
        sendData = {
          quantity: parseInt(quantity),
          deliveryDate: deliveryDate,
          source: "supply",
        };
      } else if (selectedOption === "decrease") {
        sendData = {
          quantity: parseInt(quantity),
          source: "stock",
        };
      } else {
        sendData = {
          quantity: parseInt(quantity),
          unitCost: price,
          currency: currency,
          source: "stock",
        };
      }
      setErrors({});
      if (isOperation) {
        await productService.setManualQuantityOperation(isOperation?.type, type, isOperation?.id, sendData).then((res) => {
          if (res?.data?.code === 0) {
            Toast("success", res?.data?.message);
            onClose();
          } else {
            Toast("error", res?.data?.message);
          }
        });
      } else if (isStock) {
        await productService.setManualQuantity(type, item?.id, sendData).then((res) => {
          if (res?.data?.code === 0) {
            Toast("success", res?.data?.message);
            onClose();
          } else {
            Toast("error", res?.data?.message);
          }
        });
      } else {
        await rawMaterialService.setManualRawMaterialEdit(type, item?.id, sendData).then((res) => {
          if (res?.data?.code === 0) {
            Toast("success", res?.data?.message);
            onClose();
          } else {
            Toast("error", res?.data?.message);
          }
        });
      }
    }
  };

  const priceOptions = [
    { label: "TRY", value: "TRY" },
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "GBP", value: "GBP" },
    { label: "RUB", value: "RUB" },
    { label: "UAH", value: "UAH" },
  ];

  return (
    <div className="flex flex-col w-full h-full gap-y-6 pt-6">
      <div className="max-w-[350px] min-w-[350px] h-full">
        <StockProductCard item={isStock ? item : data} disableMenu />
      </div>

      {isOperation && (
        <div className="w-[350px] min-w-[350px] max-w-[350px] h-full">
          <StockOperationCard item={isOperation} disableMenu />
        </div>
      )}
      <div className="flex flex-col w-full gap-y-2 items-start">
        <div className="flex w-full gap-x-6 items-center">
          <div className="flex gap-x-2 w-1/2 cursor-pointer items-center justify-start" onClick={() => handleRadioChange("decrease")}>
            <div
              className={`flex min-w-5 max-w-5 w-5 min-h-5 max-h-5 h-5 rounded-full items-center justify-center
              ${selectedOption === "decrease" ? "bg-[#DC6803]" : "border border-[#D0D5DD] "}`}
            >
              {selectedOption === "decrease" && <div className="flex min-w-2 max-w-2 w-2 min-h-2 max-h-2 h-2 bg-white rounded-full"></div>}
            </div>
            <p className="text-[#344054] font-medium text-sm">{t("product:decreaseManually")}</p>
          </div>
          <div className="flex gap-x-2 w-1/2 cursor-pointer items-center justify-start" onClick={() => handleRadioChange("increase")}>
            <div
              className={`flex min-w-5 max-w-5 w-5 min-h-5 max-h-5 h-5 rounded-full items-center justify-center
              ${selectedOption === "increase" ? "bg-[#DC6803]" : "border border-[#D0D5DD] "}`}
            >
              {selectedOption === "increase" && <div className="flex min-w-2 max-w-2 w-2 min-h-2 max-h-2 h-2 bg-white rounded-full"></div>}
            </div>
            <p className="text-[#344054] font-medium text-sm">{t("product:increaseManually")}</p>
          </div>
        </div>
        <div className="flex gap-x-2 w-full cursor-pointer items-center justify-start" onClick={() => handleRadioChange("buy")}>
          <div
            className={`flex min-w-5 max-w-5 w-5 min-h-5 max-h-5 h-5 rounded-full items-center justify-center
              ${selectedOption === "buy" ? "bg-[#DC6803]" : "border border-[#D0D5DD] "}`}
          >
            {selectedOption === "buy" && <div className="flex min-w-2 max-w-2 w-2 min-h-2 max-h-2 h-2 bg-white rounded-full"></div>}
          </div>
          <p className="text-[#344054] font-medium text-sm">{t("product:increaseWithPurchase")}</p>
        </div>
      </div>
      <div className="flex flex-col w-full gap-y-4">
        <Input
          onChange={handleInputChange}
          value={quantity}
          theme={"product"}
          label={t("product:quantitys")}
          errorMessage={errors?.workPiece?.message}
          validate={errors?.workPiece ? "error" : ""}
        />
        {selectedOption === "buy" && (
          <TimeInput
            label={t("table:deliveryDate")}
            theme={"product"}
            value={moment(deliveryDate).format("YYYY-MM-DD")}
            onChange={(v) => {
              setDeliveryDate(v);
            }}
            minDate={moment().format("YYYY-MM-DD")}
            errorMessage={errors?.deliveryDate ? errors?.deliveryDate?.message : ""}
            validate={errors?.deliveryDate ? "error" : ""}
          />
        )}
        {(selectedOption === "increase" || selectedOption === "decrease") && (
          <UnitPriceInput
            theme={"product"}
            items={priceOptions}
            label={t("product:perUnitCost")}
            setValue={handleSetValue}
            price={selectedOption === "decrease" ? 0 : price}
            currency={currency}
            validate={errors?.price ? "error" : ""}
            errorMessage={errors?.price?.message}
            disabled={selectedOption === "decrease"}
          />
        )}
      </div>
      <div className="flex w-full gap-x-3">
        <Button colorType={"secondary-gray"} label={t("buttons:stop")} onClick={onClose} />
        <Button colorType={"primary-product"} label={t("buttons:save")} onClick={onSubmit} />
      </div>
    </div>
  );
};

export default EditStockQuantityModal;
