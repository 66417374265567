import React, { useState } from "react";
import { EquipmentEditIcon, NewDotsIcon, NewRejectedIcon, PieceIcon, ScrapIcons } from "../../../components/new-cards/card-icons";
import { legacyFormatSeconds } from "../../../utils/secondToHoursAndMinute";
import { PurchaseIcon } from "../../../assets/icons/commonIcons";
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components";
import StockCardOperationModal from "../modals/StockCardOperationModal";
import ExternalInternalRequestForm from "../modals/ExternalInternalRequestForm";
import EditStockQuantityModal from "../modals/EditStockQuantityModal";
import { useNavigate } from "react-router-dom";

const StockOperationCard = ({ item, occurrences,isLast, onClick, product, refetch, stockData, disableMenu }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [stockEditModal, setStockEditModal] = useState(false);
  const [openStockQuantityEdit, setOpenStockQuantityEdit] = useState(false);

  const operationTypes = (type) => {
    switch (type) {
      case "conversion_operation":
        return "DÖN";
      case "supporter_operation":
        return "YAR";
      case "assembly_operation":
        return "MON";
      case "decomposition_operation":
        return "AYR";
      default:
        return "DÖN";
    }
  };
  let times = item?.times?.length > 0 ? item?.times?.find((a) => a?.avgEstimatedTime > 0) || item?.times[0] : null
  const menuOptionsSelected = [{ label: t("product:editStockQuantity"), value: "manuel_stock", icon: <EquipmentEditIcon /> },];
  return (
    <>
      <div className={`flex h-[112px] flex-col border items-start cursor-pointer  rounded-lg border-secondary-300  bg-white relative group `}>
        <div className="flex flex-row items-center w-[91%] min-w-[91%] max-w-[91%] justify-between">
          {item?.name?.length == 1 ? (
            <>
              {item?.name?.map((b) => {
                return (
                  <div className="flex flex-row items-center w-[100%]  justify-between pr-1 py-2 pl-3">
                    <div className="flex flex-row items-center gap-x-0.5 h-5 ">
                      {b?.processType == "external" ? (
                        <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                          <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
                        </div>
                      ) : (
                        <div className="px-1  border h-full w-fit rounded bg-[#F2F4F7]">
                          <p className="text-xs font-semibold text-secondary-700">{item?.processType ? operationTypes(b?.processType) : "DÖN"}</p>
                        </div>
                      )}
                      <div className=" bg-white max-w-[230px] ml-1 rounded-r-[8px]">
                        <p className="font-semibold text-sm text-secondary-700  truncate">{b?.name}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="flex flex-row items-center gap-x-2 pr-1 py-1.5 pl-2 w-[270px] min-w-[270px] max-w-[270px] ">
              {item?.name?.map((a, index) => {
                return (
                  <div className={`flex border items-center ${item?.name?.length == 2 ? "w-1/2 " : "w-1/3"}  h-6 gap-x-1 flex-row rounded`}>
                    <div className="px-1 bg-[#F2F4F7] w-fit border-r h-full flex items-center">
                      {a.processType === "external" ? (
                        <p className="text-xs font-semibold text-secondary-700">{"HİZ"}</p>
                      ) : (
                        <p className="text-xs font-semibold text-secondary-700">{a?.processType ? operationTypes(a?.processType) : "--"}</p>
                      )}
                    </div>
                    <div className="bg-white truncate px-1">
                      <p className="font-semibold text-xs text-secondary-700  truncate">{a?.name}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="flex flex-row items-center w-full">
          <div className="flex flex-row items-center w-[100%] border-l-0 border border-r-0 border-b-0 border-[#E4E7EC]">
            <div className="flex flex-col items-start border-r w-1/2 border-r-[#E4E7EC]">
              <div className="px-1.5 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                <p className="text-xs font-medium text-secondary-700">{times?.type === "external" ? t("product:cycleTimes") : t("product:cycleTime")}</p>
              </div>
              <div className="flex px-1.5 py-[3px] flex-row items-center w-full justify-between">
                <p className="text-[10px] text-secondary-500 font-normal">{t("product:target")}</p>
                <p className="font-medium max-w-[90px] truncate text-secondary-600 text-xs">{times?.type == 'internal' ? legacyFormatSeconds(times?.estimatedTime) : `${times?.estimatedTime} `}</p>
              </div>
              <div className="flex px-1.5 py-[1.5px] flex-row items-center w-full justify-between">
                <p className="text-[10px] text-secondary-500 font-normal">{t("product:applieds")}</p>
                <p className={`font-medium  ml-auto truncate text-xs ${(times?.type == 'internal' ? times?.estimatedTime < times?.avgEstimatedTime :
                  times?.estimatedTime * 86400 < times?.avgEstimatedTime)
                  ? "text-[#F04438]"
                  : "text-[#079455] "
                  }`}>{times?.avgEstimatedTime ? times?.type == 'internal' ? legacyFormatSeconds(times?.avgEstimatedTime?.toFixed(0)) : `${Math.ceil(times?.avgEstimatedTime / 60 / 60 / 24)} ` : '--'}</p>
              </div>
            </div>

            <div className="flex flex-col items-center  w-1/2">
              <div className="px-1.5 py-[3px] flex items-center rounded-tl-[4px] justify-center border-b w-full bg-[#F9FAFB]">
                <p className="text-xs font-medium text-secondary-700">{t("product:intermediateStock")}</p>
              </div>
              <div className="flex px-1.5 py-[3px] flex-row items-center  gap-x-0.5">
                <PieceIcon />
                <p className="font-medium text-secondary-600 text-xs">{item?.finishedPiece || "0"}</p>
              </div>
              <div className="flex px-1.5 py-[1.5px] flex-row items-center gap-x-0.5">
                <ScrapIcons />
                <p className="font-medium text-[#475467] text-xs">{item?.rejectedPiece || "0"}</p>
              </div>
            </div>
          </div>
        </div>
        {!disableMenu && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
            className={`absolute top-2 w-[20px] min-h-[24px] max-h-[24px] min-w-[20px] right-1 h-[24px] cursor-pointer`}
          >
            <NewDotsIcon />
          </div>
        )}
        {isOpen && (
          <div className="hidden flex-col absolute min-w-[225px] max-w-[225px] h-fit bg-white border top-[28px] right-[8px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
            {[...menuOptionsSelected, ...item?.times?.map((a) => {
              return {
                label: `${isLast ? t("product:productDetail") : `${a?.operation} Details`} `,
                value: "detail",
                icon: <PurchaseIcon />,
                type: a?.type,
                id: a?.id,
              }
            })]?.map((items, index) => {
              return (
                <button
                  onClick={() => {
                    if (items.value === "details") {
                      onClick();
                    }
                    if (items.value === "detail") {
                      if (isLast) {
                        navigate(`detail/general-info/${product?.id}`);
                      } else {
                        navigate(`/app/product/mrp/stock/operation-detail/${items?.type}/${items?.id}`)
                      }
                      
                    }
                    // if (items.value === "stockEdit") {
                    //   setStockEditModal(true);
                    //   setIsOpen(false);
                    // }
                    if (items.value === "manuel_stock") {
                      setOpenStockQuantityEdit(true);
                    }
                    setIsOpen(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${items?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                    }`}
                >
                  {items?.icon}
                  <p className={`font-medium text-sm  ${items?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{items.label}</p>
                </button>
              );
            })}
          </div>
        )}
      </div>
      <CustomModal
        isOpen={isPurchased}
        setIsOpen={setIsPurchased}
        // onClose={() => {
        //   setIsPurchased(false);
        // }}
        modalTitle={t("product:purchaseRequestForm")}
        children={
          <ExternalInternalRequestForm
            setIsOpen={isPurchased}
            closeModal={() => {
              setIsPurchased(false);
            }}
            purchaseData={item}
            product={product}
          />
        }
      />
      <CustomModal
        isOpen={stockEditModal}
        setIsOpen={setStockEditModal}
        modalTitle={t("product:editStock")}
        children={
          <StockCardOperationModal
            setIsOpen={setStockEditModal}
            closeModal={() => {
              setStockEditModal(false);
            }}
            refetch={refetch}
            product={product}
            purchaseData={item}
          />
        }
      />

      <CustomModal
        isOpen={openStockQuantityEdit}
        setIsOpen={setOpenStockQuantityEdit}
        onClose={() => {
          setOpenStockQuantityEdit(false);
        }}
        modalTitle={t("product:editStockQuantity")}
        width={400}
        children={
          <EditStockQuantityModal
            legacyRefetch={refetch}
            onClose={() => {
              setOpenStockQuantityEdit(false);
            }}
            item={stockData}
            isOperation={{...item,...times}}
            isStock={true}
          />
        }
      />
    </>
  );
};

export default StockOperationCard;
