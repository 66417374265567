import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, Loading, UploadProfile } from "../../../components";
import TextArea from "../../../components/inputs/TextArea";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ServiceSupplyReceivedEventCard from "../../../components/new-cards/service-supply/components/ServiceSupplyReceivedEventCard";
import { serviceSupplyService } from "../../../services/service-supply.service";
import { Toast } from "../../../utils/toastify/toast";
import ServiceSupplierQualityApliedEventCard from "../../../components/new-cards/service-supply/components/ServiceSupplierQualityApliedEventCard";

const ServiceSupplyQualityControlForm = ({ refetch, setIsOpen, junctionData }) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const schema = yup.object({
    acceptedPiece: yup.number(),
    rejectedPiece: yup.number().notRequired(),
    qualityDescription: yup.string().notRequired(),
    qualityImage: yup.string().notRequired(),
    acceptedImage: yup.string().notRequired(),
    rejectedImage: yup.string().notRequired(),
    purchaseFile: yup.string().notRequired(),
    secondPurchaseFile: yup.string().notRequired(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rejectedPiece: 0,
    },
  });

  const onSubmit = async (value) => {
    if (loading) return;
    setLoading(true);

    const send = {
      rejectedUnit: value?.rejectedPiece || 0,
      piece: value?.acceptedPiece,
      qualityDescription: value?.qualityDescription,
      qualityImage: value?.qualityImage,
      rejectedImage: value?.rejectedImage,
      acceptedImage: value?.acceptedImage,
    };
    try {
      await serviceSupplyService.serviceSupplierQualityApplied(junctionData?.id, send).then((res) => {
        if (res?.data?.code === 0) {
          setIsOpen(false);
          refetch();
          Toast("success", res?.data?.message);
        } else {
          Toast("error", res?.data?.message);
        }
      });
    } catch (error) {
      Toast("error", "Bir hata oluştu. Lütfen tekrar deneyin.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (junctionData?.status === "completed") {
      setValue("acceptedPiece", junctionData?.metadata?.targetedWorkCount);
      setValue("rejectedPiece", junctionData?.metadata?.rejectedWorkCount);
      setValue("purchaseFile", junctionData?.purchaseFile);
      setValue("secondPurchaseFile", junctionData?.secondPurchaseFile);
      setValue("qualityDescription", junctionData?.description);
      setValue("qualityImage", junctionData?.metadata?.qualityImage);
      setValue("acceptedImage", junctionData?.metadata?.acceptedImage);
      setValue("rejectedImage", junctionData?.metadata?.rejectedImage);
    } else {
      setValue("acceptedPiece", junctionData?.metadata?.targetedWorkCount || junctionData?.targetedWorkCount);
      setValue("rejectedPiece", junctionData?.rejectedUnit);
      setValue("purchaseFile", junctionData?.purchaseFile);
      setValue("secondPurchaseFile", junctionData?.secondPurchaseFile);
    }
  }, [junctionData]);

  // useEffect(() => {
  //     setValue("acceptedPiece", junctionData?.incomingUnit);
  //     setValue("rejectedPiece", junctionData?.rejectedUnit);

  //     setValue("qualityDescription", junctionData?.qualityDescription);
  //     setValue("qualityControlFile", junctionData?.qualityControlFile);
  //     setValue("qualityAcceptedFile", junctionData?.qualityAcceptedFile);
  //     setValue("qualityRejectedFile", junctionData?.qualityRejectedFile);

  //     setValue("purchaseFile", junctionData?.purchaseFile);
  //     setValue("secondPurchaseFile", junctionData?.secondPurchaseFile);
  //   }, [junctionData]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full h-full gap-y-8 mt-6">
      <div className="flex gap-x-6 w-full h-full">
        <div className="flex flex-col gap-y-6 min-w-[385px] max-w-[385px] w-[385px]">
          <div className="flex w-full">
            {junctionData?.status === "completed" ? (
              <ServiceSupplierQualityApliedEventCard refetch={refetch} data={junctionData} isModal={true} />
            ) : (
              <ServiceSupplyReceivedEventCard data={junctionData} isModal={true} />
            )}
          </div>
          <div className="flex flex-col w-full h-full gap-y-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  icon={<QuantityIcon />}
                  theme={"product"}
                  label={t("product:numberOfQuality")}
                  errorMessage={value > junctionData?.targetedWorkCount ? errors?.acceptedPiece?.message : ""}
                  validate={value > junctionData?.targetedWorkCount ? `Değer teslim alınandan büyük olamaz.` : ""}
                />
              )}
              name="acceptedPiece"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  icon={<QuantityDeclinedIcon />}
                  theme={"product"}
                  label={t("product:rejectedQuantity")}
                  errorMessage={errors?.rejectedPiece?.message}
                  validate={errors?.rejectedPiece ? "error" : ""}
                />
              )}
              name="rejectedPiece"
            />
            <div className="flex min-h-[66px] max-h-[66px] h-[66px] w-full">
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <UploadProfile title={`${t("product:appendix")} 1`} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
                )}
                name="purchaseFile"
              />
            </div>
            <div className="flex min-h-[66px] max-h-[66px] h-[66px] w-full">
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <UploadProfile title={`${t("product:appendix")} 2`} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
                )}
                name="secondPurchaseFile"
              />
            </div>
          </div>
        </div>
        <div className="flex w-[1px] h-full bg-[#E4E7EC]"></div>
        <div className="flex flex-col gap-y-3 min-w-[312px] max-w-[312px] w-[312px]">
          <div className="flex">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextArea
                  label={t("product:descriptionOptionel")}
                  width={"100%"}
                  onBlur={onBlur}
                  onChange={onChange}
                  theme={"product"}
                  rows={8}
                  value={value}
                />
              )}
              name="qualityDescription"
            />
          </div>
          <div className="flex flex-col gap-y-[18px]">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <UploadProfile title={t("product:controlForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
              )}
              name="qualityImage"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <UploadProfile title={t("product:approvalForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
              )}
              name="acceptedImage"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <UploadProfile title={t("product:rejectionForm")} subTitle={"Remove Picture"} onChange={onChange} onBlur={onBlur} value={value} />
              )}
              name="rejectedImage"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-x-3 justify-end items-center">
        <span className="flex max-w-[152px] min-w-[152px] w-[152px] max-h-[44px]">
          <Button onClick={() => setIsOpen(false)} colorType={"secondary-gray"} label={t("buttons:stop")} size={"lg"} disabled={loading} />
        </span>
        <span className="flex max-w-[200px] min-w-[200px] w-[200px] max-h-[44px]">
          <Button
            colorType={"primary-product"}
            label={loading ? <Loading color="#fff" secondary="#fff" size={22} /> : t("buttons:approve")}
            size={"lg"}
            type={"submit"}
            disabled={loading}
          />
        </span>
      </div>
    </form>
  );
};

export default ServiceSupplyQualityControlForm;

const QuantityDeclinedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_19622_11535)">
        <path
          d="M6.87435 1.6665H4.16602C2.7853 1.6665 1.66602 2.78579 1.66602 4.1665V15.8332C1.66602 17.2139 2.78531 18.3332 4.16602 18.3332H8.1475M6.87435 1.6665V6.45222C6.87435 6.54928 6.97778 6.61134 7.06343 6.56566L9.99935 4.99984L12.9353 6.56566C13.0209 6.61134 13.1244 6.54928 13.1244 6.45222V1.6665M6.87435 1.6665H13.1244M13.1244 1.6665H15.8327C17.2134 1.6665 18.3327 2.78579 18.3327 4.1665V9.0119M12.2718 12.2351L14.8942 14.8904M17.5287 17.5021L14.8942 14.8904M14.8942 14.8904L17.5287 12.2351M14.8942 14.8904L12.2718 17.5021"
          stroke="#F04438"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_19622_11535">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const QuantityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_19622_11962)">
        <path
          d="M6.87435 1.6665H4.16602C2.7853 1.6665 1.66602 2.78579 1.66602 4.1665V15.8332C1.66602 17.2139 2.7853 18.3332 4.16602 18.3332H15.8327C17.2134 18.3332 18.3327 17.2139 18.3327 15.8332V4.1665C18.3327 2.78579 17.2134 1.6665 15.8327 1.6665H13.1244M6.87435 1.6665V6.45222C6.87435 6.54928 6.97778 6.61134 7.06343 6.56566L9.99935 4.99984L12.9353 6.56566C13.0209 6.61134 13.1244 6.54928 13.1244 6.45222V1.6665M6.87435 1.6665H13.1244"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_19622_11962">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
