import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CalendarIcons } from "../../../assets/icons/productIcons";
import { DecraceStockIcon, EquipmentEditIcon, FreeStockIcon, NewDotsIcon, RezervStockIcon } from "../../../components/new-cards/card-icons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { DetailedIcon, PurchaseIcon } from "../../../assets/icons/commonIcons";
import { Tooltip } from "react-tooltip";
import { useStore } from "../../../hooks/useStores";
import { useLocation, useNavigate } from "react-router-dom";
import SupplyFormModal from "../modals/SupplyFormModal";
import { CustomModal } from "../../../components";
import PurchaseRequestForm from "../modals/PurchaseRequestForm";
import StockEditModal from "../modals/StockEditModal";
import NewPurchasedRequestForm from "../modals/NewPurchasedRequestForm";
import { EquipmentDeleteIcon, NoEquIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../../components/CustomImage";
import EditStockQuantityModal from "../modals/EditStockQuantityModal";
import { productService } from "../../../services/product.service";
import { Toast } from "../../../utils/toastify/toast";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";

const StockProductCard = ({ item, refetch, disableMenu, operations }) => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const pathname = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [supplyForm, setSupplyForm] = useState(false);
  const [requestForm, setRequestForm] = useState(false);
  const [stockEditModal, setStockEditModal] = useState(false);
  const [openStockQuantityEdit, setOpenStockQuantityEdit] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const menuOptions = [
    { label: t("product:editStockQuantity"), value: "manuel_stock", icon: <EquipmentEditIcon /> },
    { label: t("product:details"), value: "details", icon: <DetailedIcon /> },
    // {
    //   label: t("product:purchaseRequestCreation"),
    //   value: "form",
    //   icon: <PurchaseIcon />,
    // },
    {
      label: t("product:stockEdit"),
      value: "stockEdit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const onDeleted = async () => {
    await productService.deleteProduct(item?.id).then((res) => {
      if (res?.data?.code == 0) {
        Toast("success", "Ürün başarıyla silindi");
        refetch();
      } else {
        Toast("error", "Ürün silinirken bir hata oluştu");
      }
    });
  };

  return (
    <>
      <div data-tooltip-id={`${item?.id}`}
        data-tooltip-content={`
    ${t("product:productName")} : ${item?.revisionNo ? (item?.revisionNo != 0 ? `${item?.name} (Rev-${item?.revisionNo})` : item?.name) : item?.name}
     -
    ${t("product:stock")} : ${item?.revisionNo ? (item?.revisionNo != 0 ? `REV-${item?.revisionNo}/${item?.stockCode} ` : item?.stockCode) : item?.stockCode || "--"
          }
    `}
        data-tooltip-place="right"
        className="flex bg-white flex-row h-[112px] min-h-[112px] relative group pl-3  pt-2 border border-[#D0D5DD] rounded-lg cursor-grab ">
        <div className="flex flex-col w-[90%]">
          <div className="flex flex-row items-center gap-x-3">
            {item?.image ? (
              <div>
                <CustomImage
                  borderRadius={2}
                  src={item?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1 w-full">
                <p className="font-semibold text-secondary-900 text-base w-[185px] truncate">{item?.revisionNo ? (item?.revisionNo != 0 ? `${item?.name} (Rev-${item?.revisionNo})` : item?.name) : item?.name}</p>
              </div>
              <div className='flex flex-row items-center gap-x-1'>
                <div className="bg-[#FFFAEB] max-w-[185px]   h-6 rounded px-[3px] py-1">
                  <p className="text-xs font-medium max-w-[185px] truncate text-[#B54708]">
                    {t("product:stock")} :{" "}
                    {item?.revisionNo ? (item?.revisionNo != 0 ? `REV-${item?.revisionNo}/${item?.stockCode} ` : item?.stockCode) : item?.stockCode || "--"}
                  </p>
                </div>

              </div>
            </div>
          </div>
          <div className='flex flex-row items-center gap-x-1.5 w-full'>
            <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">{t("product:orderQty")}</p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                <p className="text-xs font-medium text-secondary-700">{item?.inOrder || "0"}</p>
              </div>
            </div>
            <div className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">{t("product:inStocks")}</p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                <p className="text-xs font-medium text-secondary-700">{item?.qualityQuantity || "0"}</p>
              </div>
            </div>
            <div className="w-[44%] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden ">
              <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
                <span className="text-xxs truncate text-[#475467] font-medium ">{t("product:workOrder")}</span>
              </div>
              <div className="flex flex-col w-full items-start">
                <div className="flex flex-row items-center w-full border-b px-1">
                  <p className="text-xxs font-normal text-secondary-600">{t("product:production")}</p>
                  <p className="text-xs font-medium max-w-[40px] truncate ml-auto text-secondary-600">{item?.openWorkOrder || "0"}</p>
                </div>
                <div className="flex flex-row items-center w-full px-1">
                  <p className="text-xxs font-normal text-secondary-600">{t("product:ready")}</p>
                  <p className="text-xs font-medium max-w-[40px] truncate ml-auto text-secondary-600">{item?.readyWorkOrder || "0"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className={`flex items-center bg-[#F2F4F7] justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0  border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}>
          <p className={`text-xxs font-semibold whitespace-nowrap text-[#475467] -rotate-90`}>{t("product:productss")} ({t("product:pcs")})</p>
        </div>
        {!disableMenu && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (pathname?.pathname?.includes("raw-material/list")) {
              } else {
                setIsOpen(!isOpen);
              }
            }}
            className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
          >
            <NewDotsIcon />
          </div>
        )}
        {isOpen && (
          <div className="hidden flex-col absolute min-w-[225px] max-w-[225px] h-fit bg-white border top-[12px] right-[32px] rounded-lg px-[6px] py-1 group-hover:flex z-[100]">
            {menuOptions?.map((items, index) => {
              return (
                <button
                  onClick={async () => {
                    if (items.value === "details") {
                      navigate(`detail/general-info/${item?.id}`);
                    }
                    if (items.value === "form") {
                      setIsPurchased(true);
                    }
                    if (items.value === "stockEdit") {
                      navigate(`edit/${item?.id}`);
                    }
                    if (items.value === "manuel_stock") {
                      setOpenStockQuantityEdit(true);
                    }
                    if (items.value === "delete") {
                      setIsAlert(true);
                    }
                    setIsOpen(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${items?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                    }`}
                >
                  {items?.icon}
                  <p className={`font-medium text-sm  ${items?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{items?.label}</p>
                </button>
              );
            })}
          </div>
        )}
        <Tooltip className="z-50" id={`${item?.id}`} />
      </div>
      {/* <div
        data-tooltip-id={`${item?.id}`}
        data-tooltip-content={`
    ${t("product:productName")} : ${item?.revisionNo ? (item?.revisionNo != 0 ? `${item?.name} (Rev-${item?.revisionNo})` : item?.name) : item?.name}
     -
    ${t("product:stock")} : ${
      item?.revisionNo ? (item?.revisionNo != 0 ? `REV-${item?.revisionNo}/${item?.stockCode} ` : item?.stockCode) : item?.stockCode || "--"
    }
    `}
        data-tooltip-place="right"
        className="flex bg-white flex-row w-full h-[112px] min-h-[112px] relative group pl-2 pt-2 border border-[#D0D5DD] rounded-lg cursor-grab"
      >
        <div className="flex flex-col w-[100%]">
          <div className="flex flex-row items-center gap-x-3">
            {item?.image ? (
              <div>
                <CustomImage
                  borderRadius={2}
                  src={item?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}

            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1 w-full">
                <p className="font-semibold text-secondary-900 text-base w-[185px] truncate">
                  {item?.revisionNo ? (item?.revisionNo != 0 ? `${item?.name} (Rev-${item?.revisionNo})` : item?.name) : item?.name}
                </p>
              </div>

              <div className="bg-[#FFFAEB] max-w-[185px] h-6 rounded px-[3px] py-1">
                <p className="text-xs font-medium max-w-[185px] truncate text-[#B54708]">
                  {t("product:stock")} :{" "}
                  {item?.revisionNo ? (item?.revisionNo != 0 ? `REV-${item?.revisionNo}/${item?.stockCode} ` : item?.stockCode) : item?.stockCode || "--"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-2 w-[100%]">
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center mt-1.5">
                <PieceIconss />
                <p className="text-sm font-medium ml-1 text-secondary-700">{item?.qualityQuantity || "--"}</p>
                <p className="text-[10px] ml-0.5 mt-0.5 font-normal text-secondary-500">{t("product:qtyCurrentStock")}</p>
              </div>
              <div className="flex flex-row items-center mt-0.5">
                <SupplyIconss />
                <p className="text-sm font-medium ml-1 text-secondary-700">{"0" || "--"}</p>
                <p className="text-[10px] ml-0.5 mt-0.5 font-normal text-secondary-500">{t("product:qtySupply")}</p>
              </div>
            </div>
            <div className="w-[111px] h-[42px] flex items-center mt-1.5 self-stretch border  border-[#E4E7EC] rounded ml-auto mr-2 overflow-hidden ">
              <div className="w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
                <span className="text-xxs truncate text-[#475467] font-medium ">{t("product:lastProcess")}</span>
              </div>
              <div className="flex flex-col items-start p-1">
                <div className="flex flex-row items-center ">
                  <DecraceStockIcon />
                  <p className="text-xs font-medium ml-1 text-secondary-600">{"0" || "--"}</p>
                  <p className="text-xxs ml-0.5 font-normal text-secondary-600">{t("product:pcs")}</p>
                </div>
                <div className="flex flex-row items-center ">
                  <CalendarIcons />
                  <p className="text-xs font-medium ml-1 text-secondary-600">{"--" || "--"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 bg-[#F2F4F7] border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px]`}
        >
          <p className={`text-xxs font-semibold text-secondary-600 -rotate-90`}>{t("product:productss")}</p>
        </div>
        {!disableMenu && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (pathname?.pathname?.includes("raw-material/list")) {
              } else {
                setIsOpen(!isOpen);
              }
            }}
            className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
          >
            <NewDotsIcon />
          </div>
        )}
        {isOpen && (
          <div className="hidden flex-col absolute min-w-[225px] max-w-[225px] h-fit bg-white border top-[12px] right-[32px] rounded-lg px-[6px] py-1 group-hover:flex z-[100]">
            {menuOptions?.map((items, index) => {
              return (
                <button
                  onClick={() => {
                    if (items.value === "details") {
                      navigate(`detail/general-info/${item?.id}`);
                    }
                    if (items.value === "form") {
                      setIsPurchased(true);
                    }
                    if (items.value === "stockEdit") {
                      navigate(`edit/${item?.id}`);
                    }
                    if (items.value === "manuel_stock") {
                      setOpenStockQuantityEdit(true);
                    }
                    setIsOpen(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    items?.value === "delete" ? "hover:bg-[#FECDCA]" : "hover:bg-secondary-100"
                  }`}
                >
                  {items?.icon}
                  <p className={`font-medium text-sm  ${items?.value === "delete" ? "text-[#D92D20]" : "text-secondary-700"}`}>{items?.label}</p>
                </button>
              );
            })}
          </div>
        )}
        <Tooltip className="z-50" id={`${item?.id}`} />
      </div> */}
      <AlertModal
        title={"Ürünü Sil"}
        subTitle={"Ürüne ait tüm veriler silinecektir. Silmek istediğinize emin misiniz?"}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(item?.id);
        }}
      />
      <CustomModal
        isOpen={isPurchased}
        setIsOpen={setIsPurchased}
        modalTitle={t("product:purchaseRequestForm")}
        children={
          <NewPurchasedRequestForm
            setIsOpen={isPurchased}
            closeModal={() => {
              setIsPurchased(false);
            }}
            purchaseData={item}
          />
        }
      />
      <CustomModal
        isOpen={supplyForm}
        setIsOpen={setSupplyForm}
        modalTitle={t("product:supplyForm")}
        children={
          <SupplyFormModal
            setIsOpen={setSupplyForm}
            onClose={() => {
              setSupplyForm(false);
            }}
            supplierData={item}
          />
        }
      />
      <CustomModal
        isOpen={requestForm}
        setIsOpen={setRequestForm}
        modalTitle={t("product:purchaseRequestForm")}
        children={
          <PurchaseRequestForm
            setIsOpen={setRequestForm}
            closeModal={() => {
              setRequestForm(false);
            }}
            supplierData={item}
          />
        }
      />
      <CustomModal
        isOpen={stockEditModal}
        setIsOpen={setStockEditModal}
        modalTitle={t("product:editStock")}
        children={
          <StockEditModal
            setIsOpen={setStockEditModal}
            closeModal={() => {
              setStockEditModal(false);
            }}
            refetch={refetch}
            product={item}
          />
        }
      />

      <CustomModal
        isOpen={openStockQuantityEdit}
        setIsOpen={setOpenStockQuantityEdit}
        onClose={() => {
          setOpenStockQuantityEdit(false);
        }}
        modalTitle={t("product:editStockQuantity")}
        width={400}
        children={
          <EditStockQuantityModal
            legacyRefetch={refetch}
            onClose={() => {
              setOpenStockQuantityEdit(false);
            }}
            item={item}
            isStock={true}
          />
        }
      />
    </>
  );
};

export default StockProductCard;

export const PieceIconss = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_17757_65421)">
        <path
          d="M5.49967 1.33325H2.99967C2.0792 1.33325 1.33301 2.07944 1.33301 2.99992V12.9999C1.33301 13.9204 2.0792 14.6666 2.99967 14.6666H12.9997C13.9201 14.6666 14.6663 13.9204 14.6663 12.9999V2.99992C14.6663 2.07944 13.9201 1.33325 12.9997 1.33325H10.4997M5.49967 1.33325V5.19039C5.49967 5.2551 5.56863 5.29648 5.62573 5.26602L7.99967 3.99992L10.3736 5.26602C10.4307 5.29648 10.4997 5.2551 10.4997 5.19039V1.33325M5.49967 1.33325H10.4997"
          stroke="#475467"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17757_65421">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SupplyIconss = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M15.1543 8.00049C15.1543 7.72435 14.9304 7.50049 14.6543 7.50049C14.3781 7.50049 14.1543 7.72435 14.1543 8.00049H15.1543ZM14.1543 8.00049C14.1543 11.4024 11.3965 14.1602 7.99455 14.1602V15.1602C11.9488 15.1602 15.1543 11.9547 15.1543 8.00049H14.1543ZM7.99455 14.1602C6.10588 14.1602 4.41608 13.3108 3.28539 11.9714L2.52125 12.6164C3.83382 14.1713 5.79896 15.1602 7.99455 15.1602V14.1602Z"
        fill="#475467"
      />
      <path
        d="M0.834961 8.00054C0.834961 8.27668 1.05882 8.50054 1.33496 8.50054C1.6111 8.50054 1.83496 8.27668 1.83496 8.00054H0.834961ZM1.83496 8.00054C1.83496 4.59861 4.59275 1.84082 7.99468 1.84082V0.84082C4.04046 0.84082 0.834961 4.04633 0.834961 8.00054H1.83496ZM7.99468 1.84082C9.85821 1.84082 11.528 2.66773 12.6584 3.97639L13.4151 3.32273C12.103 1.80352 10.161 0.84082 7.99468 0.84082V1.84082Z"
        fill="#475467"
      />
      <path
        d="M2.28608 12.5216C2.8119 12.5216 3.23817 12.0953 3.23817 11.5695C3.23817 11.0437 2.8119 10.6174 2.28608 10.6174C1.76025 10.6174 1.33398 11.0437 1.33398 11.5695C1.33398 12.0953 1.76025 12.5216 2.28608 12.5216Z"
        stroke="#475467"
        strokeMiterlimit="10"
      />
      <path
        d="M13.7148 5.26966C14.2406 5.26966 14.6669 4.84339 14.6669 4.31757C14.6669 3.79174 14.2406 3.36548 13.7148 3.36548C13.189 3.36548 12.7627 3.79174 12.7627 4.31757C12.7627 4.84339 13.189 5.26966 13.7148 5.26966Z"
        stroke="#475467"
        strokeMiterlimit="10"
      />
      <path
        d="M10.6128 9.85203C10.7954 9.74662 10.9078 9.55181 10.9078 9.34099V6.65938C10.9078 6.44856 10.7954 6.25375 10.6128 6.14834L8.29048 4.80755C8.1079 4.70214 7.88296 4.70214 7.70038 4.80755L5.37806 6.14834C5.19548 6.25375 5.08301 6.44856 5.08301 6.65938V9.34099C5.08301 9.55181 5.19548 9.74662 5.37806 9.85203L7.70038 11.1928C7.88296 11.2982 8.1079 11.2982 8.29048 11.1928L10.6128 9.85203Z"
        stroke="#475467"
        strokeMiterlimit="10"
      />
      <path d="M5.08301 6.3186L7.40533 7.65941C7.77048 7.87024 8.22038 7.87024 8.58554 7.65941L10.9078 6.3186" stroke="#475467" strokeMiterlimit="10" />
      <path d="M7.99512 8.00024V11.3632" stroke="#475467" strokeMiterlimit="10" />
    </svg>
  );
};
