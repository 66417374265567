import React, { useCallback, useEffect, useRef, useState } from "react";
import { NewDotsIcon } from "../../../components/new-cards/card-icons";
import { useTranslation } from "react-i18next";
import { orderService } from "../../../services/order.service";
import { useNavigate } from "react-router-dom";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { Tooltip } from "react-tooltip";
import { CustomModal, Loading } from "../../../components";
import OrderEditModal from "../modals/OrderEditModal";
import { Toast } from "../../../utils/toastify/toast";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import CustomImage from "../../../components/CustomImage";
import WipExitModal from "../modals/WipExitModal";
import OrderWipProductCardTable from "./OrderWipProductCardTable.js";
import TechnicalDrawingModal from "../modals/TechnicalDrawingModal.js";
import InOrderCardTable from "./InOrderCardTable.js";

const OrderWipProductCard = ({ item, addStock = false, refetch, qrFunction }) => {
  const { t } = useTranslation();
  const menuRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState("");
  const [openWipExitModal, setOpenWipExitModal] = useState(false);
  const [isCardClick, setIsCardClick] = useState(false);
  const [isCardClickLegacy, setIsCardClickLegacy] = useState(false);
  const [selectedCard, setSelectedCard] = useState([]);
  const [selectedCardLegacy, setSelectedCardLegacy] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingLegacy, setLoadingLegacy] = useState(false);
  const [openTechnicalModal, setOpenTechnicalModal] = useState(false);
  const [technicalProductData, setTechnicalProductData] = useState([]);
  const menuOptions = [
    { label: t("product:getQrCodes"), value: "qr", icon: <QrCodeIcon /> },
    { label: t("product:technicalDrawing"), value: "view-technical", icon: <RulerIcon /> },
    {
      label: t("product:productDetail"),
      value: "details",
      icon: <DetailIcon />,
    },
    { label: t("product:sendToOrder"), value: "wipExit", icon: <FlipForwardIcon /> },
  ];

  const onDeleted = async () => {
    await orderService.orderDelete(deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId("");
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  const handleClickOutside = useCallback((event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsCardClick(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const orderWipProduct = async (id) => {
    setLoading(true);
    await orderService.getOrderWip(id).then((res) => {
      setSelectedCard(res?.data);
      setLoading(false);
    });
  };

  const orderWipProductLegacy = async (id) => {
    setLoadingLegacy(true);
    await orderService.getOrderDetailsByProduct(id).then((res) => {
      setSelectedCardLegacy(res?.data);
      setLoadingLegacy(false);
    });
  };

  return (
    <>
      <div
        ref={menuRef}
        data-tooltip-id={`${item?.id}`}
        data-tooltip-place={"right"}
        data-tooltip-content={`
      ${t("product:productName")} : ${item?.product?.name} 
       -
      ${t("product:stockCode")} : ${item?.product?.stockCode}
      `}
        className="flex bg-white flex-row  w-[324px] min-w-[324px] h-[112px] min-h-[112px] relative group pl-3 pr-3  pt-2 border border-[#D0D5DD] rounded-lg cursor-pointer "
      >
        <div className="flex flex-col w-[93%]">
          <div className="flex flex-row items-center gap-x-3">
            {item?.product?.image ? (
              <div>
                <CustomImage
                  borderRadius={2}
                  src={item?.product?.image}
                  style={{
                    width: 64,
                    height: 48,
                    minWidth: 64,
                    minHeight: 48,
                    borderRadius: 4,
                    borderWidth: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                    borderColor: "#0000001A",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded-[2px] border border-secondary-300 bg-secondary-50 flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-x-1 w-full">
                <p className="font-semibold text-secondary-900 text-base w-[185px] truncate">{item?.product?.name || "Product Name"}</p>
              </div>
              {addStock === true ? (
                <div className="bg-[#FFFAEB] max-w-[185px] h-6 rounded px-[3px] py-1">
                  <div className="flex flex-row items-center ">
                    <p className="text-xs font-medium max-w-[185px] truncate text-[#B54708]">
                      {t("product:stock")} : {item?.product?.stockCode || "--"} +
                    </p>
                    <div className="flex flex-row items-center ml-1">
                      <PieceIconProduct />
                      <p className="text-xs font-medium ml-1 max-w-[185px] truncate text-[#B54708]">1500</p>
                      <p className="text-[10px] font-normal ml-0.5 text-[#B54708]">ad.</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-[#FFFAEB] max-w-[198px] h-6 rounded px-[3px] py-1">
                  <p className="text-xs font-medium max-w-[198px] truncate text-[#B54708]">
                    {t("product:stock")} : {item?.product?.stockCode || "--"}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-1 w-[100%]">
            <div
              onClick={() => {
                setIsCardClickLegacy(true && !isCardClickLegacy);
                setIsCardClick(false);
                setIsOpen(false);
                if (!isCardClickLegacy) {
                  orderWipProductLegacy(item?.product?.id);
                }
              }}
              className="flex flex-col h-[42px] w-[28%] min-w-[28%] mt-1.5 hover:bg-[#F9FAFB] self-stretch border border-[#E4E7EC] rounded"
            >
              {loadingLegacy ? (
                <div className="flex w-full h-full items-center justify-center">
                  <Loading size={18} secondary={"#B54708"} />
                </div>
              ) : (
                <>
                  <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                    <p className="text-[10px] font-medium text-secondary-600">{t("product:orderQty")}</p>
                  </div>
                  <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                    <p className="text-xs font-medium text-secondary-700">{(item?.product?.inOrder ? item?.product?.inOrder : item?.piece) || "0"}</p>
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col h-[42px] min-w-[28%] w-[28%] mt-1.5 border border-[#E4E7EC] rounded">
              <div className="px-[6px] py-[1px] border-b border-b-[#E4E7EC] bg-[#F9FAFB] rounded-tr-[4px] rounded-tl-[4px] w-full flex items-center justify-center">
                <p className="text-[10px] font-medium text-secondary-600">{t("product:inStock")}</p>
              </div>
              <div className="flex flex-row items-center gap-x-1 px-2 py-[2px] justify-center">
                <p className="text-xs font-medium text-secondary-700">{item?.product?.qualityQuantity || "0"}</p>
              </div>
            </div>

            <div
              onClick={() => {
                setIsCardClick(true && !isCardClick);
                setIsCardClickLegacy(false);
                setIsOpen(false);
                if (!isCardClick) {
                  orderWipProduct(item?.id);
                }
              }}
              className="w-[44%] h-[42px] flex items-center mt-1.5 hover:bg-[#F9FAFB] self-stretch border  border-[#E4E7EC] rounded ml-auto overflow-hidden "
            >
              {loading ? (
                <div className="flex ml-[45px] items-center justify-center">
                  <Loading size={18} secondary={"#B54708"} />
                </div>
              ) : (
                <>
                  <div className="w-[42px] min-w-[42px] h-[18px] px-[1px] py-1 -ml-[11.5px] -mr-3 -rotate-90 justify-center items-center flex gap-2 border-b  border-[#E4E7EC] bg-[#F9FAFB]">
                    <span className="text-xxs truncate text-[#475467] font-medium ">{t("product:workOrder")}</span>
                  </div>
                  <div className="flex flex-col w-full items-start">
                    <div className="flex flex-row items-center w-full border-b px-1">
                      <p className="text-xxs font-normal text-secondary-600">{t("product:production")}</p>
                      <p className="text-xs font-medium ml-auto text-secondary-600">{(item?.product?.openWorkOrder ? item?.product?.openWorkOrder : item?.wipPiece) || "0"}</p>
                    </div>
                    <div className="flex flex-row items-center w-full px-1">
                      <p className="text-xxs font-normal text-secondary-600">{t("product:ready")}</p>
                      <p className="text-xs font-medium ml-auto text-secondary-600">{(item?.finishedPiece ? item?.finishedPiece : item?.product?.readyWorkOrder) || "0"}</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className={`absolute right-0 bg-[#F2F4F7] bottom-0 flex items-center justify-center flex-col gap-y-2 border-l border-t border-b-0 p-[3px] ml-auto mt-auto border-r-0 border-secondary-300 h-[92px] min-h-[92px] w-[24px] min-w-[24px] rounded-tl-[4px] rounded-bl-[0px] rounded-br-[6px] text-secondary-600`}
        >
          <p className={`text-xxs font-semibold text-secondary-600 -rotate-90 whitespace-nowrap`}>
            {t("person:product")} ({t("product:pcs")})
          </p>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          className=" absolute min-h-[24px]  max-h-[24px] min-w-[24px] max-w-[24px] p-[2px]  right-[3px] -top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <NewDotsIcon />
        </div>
        {isOpen && (
          <div className="absolute w-[220px] bg-white right-3 top-[16px] border border-secondary-200 rounded-lg shadow-sm flex-col z-[160] hidden group-hover:flex py-1">
            {menuOptions?.map((items, index) => {
              return (
                <>
                  <span className="flex w-full px-[6px]">
                    <button
                      onClick={() => {
                        if (items.value === "delete") {
                          setIsAlert(true);
                          setDeleteId(item?.id);
                        } else if (items.value === "details") {
                          navigate(`/app/product/mrp/stock/detail/general-info/${item?.product?.id}`);
                        } else if (items.value === "qr") {
                          qrFunction(item);
                        } else if (items.value === "edit") {
                          setEditModal(true);
                        } else if (items.value === "wipExit") {
                          setOpenWipExitModal(true);
                        } else if (items.value === "view-technical") {
                          if (item?.product?.technicalDrawing) {
                            setTechnicalProductData(item?.product);
                            setOpenTechnicalModal(true);
                          } else {
                            Toast("error", "Teknik resim bulunamadı.");
                          }
                        }
                        setIsOpen(false);
                      }}
                      key={index}
                      type="button"
                      className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
                    >
                      {items?.icon}
                      <p className={`font-medium text-sm text-secondary-700 ml-1`}>{items?.label}</p>
                    </button>
                  </span>
                  {index === 1 ? (
                    <span className="flex w-full py-1">
                      <hr className="w-full border-[#EAECF0]" />
                    </span>
                  ) : null}
                </>
              );
            })}
          </div>
        )}
        {!isOpen && <Tooltip className="z-[120]" id={`${item?.id}`} />}
        {isCardClick === true && (
          <div className="z-[140] absolute top-[122px]  -left-1 items-start w-[328px]">
            <OrderWipProductCardTable setIsCardClick={setIsCardClick} loading={loading} selectedCard={selectedCard} />
          </div>
        )}
        {isCardClickLegacy === true && (
          <div className="z-[140] absolute top-[122px]  -left-1 items-start w-[328px]">
            <InOrderCardTable setIsCardClick={setIsCardClickLegacy} loading={loading} selectedCard={selectedCardLegacy} />
          </div>
        )}
      </div>

      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={openWipExitModal}
        setIsOpen={setOpenWipExitModal}
        modalTitle={t("product:transitionWipOrder")}
        subTitle={t("product:sendToCompletedOrder")}
        width={900}
        padding={true}
        children={<WipExitModal item={item} setIsOpen={setOpenWipExitModal} refetch={refetch} />}
      />
      <CustomModal
        isOpen={openTechnicalModal}
        setIsOpen={setOpenTechnicalModal}
        modalTitle={t("product:technicalDrawing")}
        width={"70%"}
        height={"85%"}
        children={<TechnicalDrawingModal setIsOpen={setOpenTechnicalModal} product={technicalProductData} />}
      />

      <CustomModal
        isOpen={editModal}
        setIsOpen={setEditModal}
        onClose={() => {
          setEditModal(false);
        }}
        modalTitle={t("product:createNewOrder")}
        children={<OrderEditModal selectedProduct={item?.product} setIsOpen={setEditModal} orderData={item} refresh={refetch} editData={item} t={t} />}
      />
    </>
  );
};

export default OrderWipProductCard;

export const OrdersIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M10 5.25V3.4C10 2.55992 10 2.13988 9.83651 1.81901C9.6927 1.53677 9.46323 1.3073 9.18099 1.16349C8.86012 1 8.44008 1 7.6 1H4.4C3.55992 1 3.13988 1 2.81901 1.16349C2.53677 1.3073 2.3073 1.53677 2.16349 1.81901C2 2.13988 2 2.55992 2 3.4V8.6C2 9.44008 2 9.86012 2.16349 10.181C2.3073 10.4632 2.53677 10.6927 2.81901 10.8365C3.13988 11 3.55992 11 4.4 11M6 4.5H4M7 3H4M5 6H4M5.90224 7.57926L7.7523 6.54548C7.82336 6.50578 7.85889 6.48592 7.89654 6.47812C7.92987 6.47122 7.96425 6.47119 7.99758 6.47803C8.03525 6.48577 8.07081 6.50557 8.14194 6.54515L10 7.57926M5.90224 7.57926L7.94703 8.71863M5.90224 7.57926V9.61976C5.90224 9.70508 5.90224 9.74774 5.91478 9.78582C5.92587 9.81951 5.944 9.85045 5.96797 9.8766C5.99506 9.90615 6.03227 9.927 6.1067 9.96871L7.94703 11M10 7.57926L7.94703 8.71863M10 7.57926V9.61931C10 9.70478 10 9.74752 9.98743 9.78566C9.9763 9.81939 9.95811 9.85037 9.93408 9.87653C9.90691 9.9061 9.86959 9.92693 9.79495 9.96858L7.94703 11M7.94703 8.71863V11"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PieceIconProduct = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g clipPath="url(#clip0_17757_65581)">
        <path
          d="M4.125 1H2.25C1.55964 1 1 1.55964 1 2.25V9.75C1 10.4404 1.55964 11 2.25 11H9.75C10.4404 11 11 10.4404 11 9.75V2.25C11 1.55964 10.4404 1 9.75 1H7.875M4.125 1V3.89286C4.125 3.94139 4.17672 3.97242 4.21954 3.94958L6 3L7.78046 3.94958C7.82328 3.97242 7.875 3.94139 7.875 3.89286V1M4.125 1H7.875"
          stroke="#DC6803"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17757_65581">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const QrCodeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M4.33333 4.33333H4.34M11.6667 4.33333H11.6733M4.33333 11.6667H4.34M8.66667 8.66667H8.67333M11.6667 11.6667H11.6733M11.3333 14H14V11.3333M9.33333 11V14M14 9.33333H11M10.4 6.66667H12.9333C13.3067 6.66667 13.4934 6.66667 13.636 6.594C13.7614 6.53009 13.8634 6.4281 13.9273 6.30266C14 6.16005 14 5.97337 14 5.6V3.06667C14 2.6933 14 2.50661 13.9273 2.36401C13.8634 2.23856 13.7614 2.13658 13.636 2.07266C13.4934 2 13.3067 2 12.9333 2H10.4C10.0266 2 9.83995 2 9.69734 2.07266C9.5719 2.13658 9.46991 2.23856 9.406 2.36401C9.33333 2.50661 9.33333 2.6933 9.33333 3.06667V5.6C9.33333 5.97337 9.33333 6.16005 9.406 6.30266C9.46991 6.4281 9.5719 6.53009 9.69734 6.594C9.83995 6.66667 10.0266 6.66667 10.4 6.66667ZM3.06667 6.66667H5.6C5.97337 6.66667 6.16005 6.66667 6.30266 6.594C6.4281 6.53009 6.53009 6.4281 6.594 6.30266C6.66667 6.16005 6.66667 5.97337 6.66667 5.6V3.06667C6.66667 2.6933 6.66667 2.50661 6.594 2.36401C6.53009 2.23856 6.4281 2.13658 6.30266 2.07266C6.16005 2 5.97337 2 5.6 2H3.06667C2.6933 2 2.50661 2 2.36401 2.07266C2.23856 2.13658 2.13658 2.23856 2.07266 2.36401C2 2.50661 2 2.6933 2 3.06667V5.6C2 5.97337 2 6.16005 2.07266 6.30266C2.13658 6.4281 2.23856 6.53009 2.36401 6.594C2.50661 6.66667 2.6933 6.66667 3.06667 6.66667ZM3.06667 14H5.6C5.97337 14 6.16005 14 6.30266 13.9273C6.4281 13.8634 6.53009 13.7614 6.594 13.636C6.66667 13.4934 6.66667 13.3067 6.66667 12.9333V10.4C6.66667 10.0266 6.66667 9.83995 6.594 9.69734C6.53009 9.5719 6.4281 9.46991 6.30266 9.406C6.16005 9.33333 5.97337 9.33333 5.6 9.33333H3.06667C2.6933 9.33333 2.50661 9.33333 2.36401 9.406C2.23856 9.46991 2.13658 9.5719 2.07266 9.69734C2 9.83995 2 10.0266 2 10.4V12.9333C2 13.3067 2 13.4934 2.07266 13.636C2.13658 13.7614 2.23856 13.8634 2.36401 13.9273C2.50661 14 2.6933 14 3.06667 14Z"
        stroke="#667085"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const RulerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_21775_13059)">
        <path
          d="M9.66648 3.66667L10.6665 4.66667M7.66648 5.66667L8.66648 6.66667M5.66648 7.66667L6.66648 8.66667M3.66648 9.66667L4.66648 10.6667M1.71024 11.7105L4.28933 14.2896C4.42134 14.4216 4.48734 14.4876 4.56345 14.5123C4.6304 14.5341 4.70251 14.5341 4.76946 14.5123C4.84557 14.4876 4.91157 14.4216 5.04358 14.2896L14.2893 5.04382C14.4213 4.91182 14.4873 4.84581 14.5121 4.7697C14.5338 4.70276 14.5338 4.63064 14.5121 4.56369C14.4873 4.48758 14.4213 4.42158 14.2893 4.28957L11.7102 1.71049C11.5782 1.57848 11.5122 1.51248 11.4361 1.48775C11.3692 1.466 11.2971 1.466 11.2301 1.48775C11.154 1.51248 11.088 1.57848 10.956 1.71049L1.71024 10.9562C1.57824 11.0882 1.51224 11.1542 1.48751 11.2304C1.46575 11.2973 1.46575 11.3694 1.48751 11.4364C1.51224 11.5125 1.57824 11.5785 1.71024 11.7105Z"
          stroke="#667085"
          stroke-width="1.33"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_21775_13059">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DetailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.75 2H4.5C3.11929 2 2 3.11929 2 4.5V11.5C2 12.8807 3.11929 14 4.5 14H11.5C12.8807 14 14 12.8807 14 11.5V4.5C14 3.11929 12.8807 2 11.5 2H10.25M5.75 2V5.38571C5.75 5.48278 5.85343 5.54484 5.93908 5.49916L8 4.4L10.0609 5.49916C10.1466 5.54484 10.25 5.48278 10.25 5.38571V2M5.75 2H10.25"
        stroke="#667085"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const FlipForwardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14 5.99998H5C3.34315 5.99998 2 7.34313 2 8.99998C2 10.6568 3.34315 12 5 12H8M14 5.99998L11.3333 3.33331M14 5.99998L11.3333 8.66665"
        stroke="#667085"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
