import React from 'react'
import { UpuPointLogo } from '../../../../assets/icons/PersonIcons'
import PersonnelDashboardDetailCharts from '../PersonnelDashboardDetailCharts'
import { useTranslation } from 'react-i18next'
import Badgets from '../../../../components/buttons/Badgets'
import { UpuPointIcon } from '../../../../assets/icons/equipmentIcon'

const PersonnelDashboardDetailInfo = ({ data, gaugeData,selectedDate }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col min-w-[180px] max-w-[180px] w-[180px] h-full gap-y-4">
      <div className='flex flex-col items-center gap-y-2 '>
      <div className="flex border w-full min-h-[48px] max-h-[48px] shadow-xs rounded-lg border-[#F8DA64]">
          <div className="flex bg-[#FEFAE9] border-r border-[#F8DA64] max-h-[47px] items-center justify-center min-w-[48px] max-w-[48px] p-2 rounded-l-lg text-[#CFAA01]">
            <UpuPointIcon />
          </div>
          <div className='flex flex-row w-full items-center gap-x-2'>
          <div className="flex flex-col w-1/2 h-full px-3 py-1">
            <span className="text-[#475467] font-normal text-xs">upu.point</span>
            <span className="text-[#9B7D07] font-semibold text-sm truncate w-[70%]">{data?.upuPoint ? (data?.upuPoint).toFixed(2) : "0"}</span>
          </div>
          </div>
        </div>
       
      </div>
      {/* <div className="flex w-full h-[124px] max-h-[124px] min-h-[124px] border border-[#F8DA64] rounded-lg shadow-xs">
        <div className="flex w-11 bg-[#FEFAE9] p-2 border-r border-[#F8DA64] rounded-l-lg items-center justify-center">
          <span className="flex min-w-[28px] max-w-[28px] min-h-[28px] max-h-[28px] items-start justify-end">
            <UpuPointLogo />
          </span>
        </div>
        
        <div className="flex w-full h-full items-center">
        <div className="flex w-1/2 h-full p-4 flex-col gap-y-3">
          <div className="flex w-full gap-x-2 items-center">
            <span className="text-[#475467] font-medium text-sm">{t("chat:today")}</span>
            
          </div>
          <div className="flex w-full flex-col h-full">
            <span className="text-[#9B7D07] font-semibold text-3xl">{data?.upuPoint ? (data?.upuPoint).toFixed(2) : "0"}</span>
            <span className="font-codec text-sm">
              upu.<span className="text-[#CFAA01]">point</span>
            </span>
          </div>
        </div>
        <div className="flex w-[1px] min-w-[1px] max-w-[1px] h-full bg-[#E4E7EC] max-h-[100px]"></div>
        <div className="flex w-1/2 h-full p-4 flex-col gap-y-3">
          <div className="flex w-full gap-x-2 items-center">
            <span className="text-[#475467] font-medium text-sm">{t("chat:thisMonth")}</span>
            <Badgets size={"sm"} colorType={"fill-success"} label={"1th"} />
          </div>
          <div className="flex w-full flex-col h-full">
            <span className="text-[#9B7D07] font-semibold text-3xl">
              {data?.monthlyUpuPoint ? (data?.monthlyUpuPoint).toFixed(2) : "0"}
            </span>
            <span className="font-codec text-sm">
              upu.<span className="text-[#CFAA01]">point</span>
            </span>
          </div>
        </div>
      </div>
      </div> */}
      <PersonnelDashboardDetailCharts gaugeData={gaugeData} selectedDate={selectedDate}/>
    </div>
  )
}

export default PersonnelDashboardDetailInfo