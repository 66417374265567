import React, { useEffect, useState } from "react";
import PersonelDetailHeader from "./PersonelDetailHeader";
import moment from "moment";
import PersonelDetailCharts from "./PersonelDetailCharts";
import MothlyDetailChart from "./components/MothlyDetailChart";
import SelectJunctionTable from "./components/SelectJunctionTable";
import WorkCountCard from "../components/WorkCountCard";
import Badgets from "../../../components/buttons/Badgets";
import PersonalJunctions from "./components/PersonalJunctions";
import { useQuery } from "@tanstack/react-query";
import { reportService } from "../../../services/report.service";
import { useNavigate, useParams } from "react-router-dom";
import { timeFormatter } from "../../../utils/timezoneFormatter";

import PersonalMonthlyChart from "./components/PersonalMonthlyChart";
import { UpuTimeline } from "../../../components/upuTimeline";
import PersonelDateSelector from "./PersonelDateSelector.js";

import UpuTimelinePersonelPages from "../../../components/upuTimeline/UpuTimelinePersonelPages";

import BarChart from "./components/BarChart";
import EquipmentLosses from "./components/EquipmentLosses";
import UpuTimelinePersonelPagesSaved from "../../../components/upuTimeline/UpuTimelinePersonelSavedReport";
import { authStore } from "../../../stores/auth.store";
import PersonalDetailInfo from "./components/PersonalDetailInfo.js";

const PersonelDetail = () => {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedJunction, setSelectedJunction] = useState("");
  const [gaugeData, setGaugeData] = useState([]);
  const [barValues, setBarValues] = useState([]);
  const navigate = useNavigate();
  const [dateChangeCount, setDateChangeCount] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [defaultStartTime, setDefaultStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [defaultEndTime, setDefaultEndTime] = useState(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState(null);
  const [lossesData, setLossesData] = useState();

  //grouped dasta
  const [groupedDatas, setGroupedDatas] = useState([]);

  let { userId, dates } = useParams();
  const url = userId;
  const regex = /([a-f0-9-]+):(\d{4}-\d{2}-\d{2})/;

  const match = url?.match(regex);

  const id = match ? match[1] : "";
  const start = match ? match[2] : moment()?.format("YYYY-MM-DD");

  const formattedStartDate = moment(dates).format("YYYY-MM-DD");

  let datas = {
    date: formattedStartDate,
  };

  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["user-detail"],
    queryFn: async () => reportService.getPersonelConsole(userId, datas),
    // queryFn: async () =>
    //   reportService.getPersonelConsole(
    //     // "6a4bb292-3c39-41e2-ac91-3c6dc1a403d6",
    //     id,
    //     datas
    //   ),
  });

  // useEffect(() => {
  //   setSelectedJunction(null);
  // }, [isFetching]);

  const selectedJunctionData = data?.additionalJunctionData?.find((obj) => obj?.junctionId === selectedJunction?.id);

  const selectedJunctionWorkCount = selectedJunctionData ? selectedJunctionData.workCount : [];

  const shiftlyDatas = async (data) => {
    if (data?.code == 2) {
      await data?.shiftlyTimelineData?.reduce((acc, item) => {
        const key = item?.shiftId;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key]?.push(item);
        setGroupedDatas(acc);
        return acc;
      }, {});
    } else {
      await data?.timeline?.shiftlyTimelineData?.reduce((acc, item) => {
        const key = item?.shiftId;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key]?.push(item);
        setGroupedDatas(acc);
        return acc;
      }, {});
    }
  };

  const [legendSelected, setLegendSelected] = useState({
    Performance: true,
    Quality: true,
    upuPoint: true,
  });

  const handleDateChange = (newDate) => {
    navigate(`/app/machine/digital-twin/user-detail/${userId}/${newDate}`);
  };

  useEffect(() => {
    setDate(dates);
  }, []);

  useEffect(() => {
    // if (data?.dayStart) {
    //   setDate(moment(data?.dayStart).format('YYYY-MM-DD'))
    // }

    // calculateDataBar
    setGaugeData(
      [
        { ...data?.performance, type: "Performance" },
        { ...data?.quality, type: "qualityRate" },
      ] || [{}, {}, {}]
    );
    if (data?.code == 2) {
      setGaugeData(
        [
          { ...data?.performance, type: "Performance" },
          { ...data?.quality, type: "qualityRate" },
        ] || [{}, {}, {}]
      );

      setStartTime(moment(timeFormatter(data?.startDate).formatted).valueOf());
      setDefaultStartTime(moment(timeFormatter(data?.startDate)?.formatted).valueOf());
      setEndTime(moment(timeFormatter(data?.startDate)?.formatted).valueOf() + 86400000);
      setDefaultEndTime(moment(timeFormatter(data?.startDate)?.formatted).valueOf() + 86400000);
      //  setDate(moment(data?.startDate).format('YYYY-MM-DD'))

      shiftlyDatas(data);
    } else {
      setStartTime(moment(timeFormatter(data?.dayStart)?.formatted).valueOf());
      setDefaultStartTime(moment(timeFormatter(data?.dayStart)?.formatted).valueOf());
      setEndTime(moment(timeFormatter(data?.dayStart)?.formatted).valueOf() + 86400000);
      setDefaultEndTime(moment(timeFormatter(data?.dayStart)?.formatted).valueOf() + 86400000);
      // setDate(data?.dayStart)
      shiftlyDatas(data);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [dates]);

  useEffect(() => {
    if (moment(date).format("YYYY-MM-DD") === moment(dates).format("YYYY-MM-DD")) {
    } else {
      handleDateChange(moment(date).format("YYYY-MM-DD"));
    }
  }, [date]);

  useEffect(() => {
    setDateChangeCount((prevCount) => prevCount + 1);
  }, [dates]);

  return (
    <div className="flex w-full h-full flex-col  scrollbar-hide   ">
      <div className="w-full h-full overflow-y-auto overflow-x-hidden scrollbar-hide">
        {isLoading || isFetching ? (
          <div className="bg-gray-200 animate-pulse rounded-lg w-full h-[56px] mt-4 mb-4 min-h-[56px] max-h-[56px]" />
        ) : (
          <div className="sticky top-0 z-50 bg-white">
            <PersonelDetailHeader user={data?.user} dateChangeCount={dateChangeCount} />
          </div>
        )}

        {isLoading || isFetching ? (
          <div className="bg-gray-200 animate-pulse rounded-lg w-full h-[308px] mt-4 mb-4 min-h-[308px] max-h-[308px]" />
        ) : (
          <div className="flex items-start gap-x-4">
            <PersonalDetailInfo data={data} gaugeData={gaugeData} selectedDate={dates}/>
            <PersonalMonthlyChart
              data={data?.monthlyData}
              user={data?.user}
              legendSelected={legendSelected}
              setLegendSelected={setLegendSelected}
              setDate={setDate}
              start={moment(date).format("YYYY/MM/DD")}
              end={moment(data?.endDate).format("YYYY/MM/DD")}
              selectedDate={dates}
              defaultLegends={data?.legends}
            />
          </div>
        )}

        {isLoading || isFetching ? (
          <div className="bg-gray-200 animate-pulse rounded-lg w-full h-[40px] mt-4 mb-4 min-h-[40px] max-h-[40px]" />
        ) : (
          <div className="flex w-full h-10 min-h-[40px] max-h-[40px] my-4 ">
            <PersonelDateSelector dates={dates} date={date} setDate={setDate} loading={isLoading} />
          </div>
        )}

        

        <div className="flex w-full border-1 border-gray-400">
          {data?.code != 2 ? (
            <>
              {isLoading || isFetching ? (
                <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[400px] mt-4" />
              ) : (
                <UpuTimelinePersonelPages
                  data={data}
                  selectedJunction={selectedJunction}
                  setSelectedJunction={setSelectedJunction}
                  startTime={startTime}
                  endTime={endTime}
                  date={date}
                  refetch={refetch}
                  setStartTime={setStartTime}
                  setEndTime={setEndTime}
                  defaultStartTime={defaultStartTime}
                  defaultEndTime={defaultEndTime}
                  selectedTimeRange={selectedTimeRange}
                  setSelectedTimeRange={setSelectedTimeRange}
                  equipmentData={data?.timeline}
                  groupedDatas={groupedDatas}
                  junctionData={data?.junctions}
                  svgHeight={data?.timelineData?.timelineData?.length * 117}
                />
              )}
            </>
          ) : (
            <>
              {isLoading || isFetching ? (
                <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[400px]" />
              ) : (
                <UpuTimelinePersonelPagesSaved
                  data={data}
                  selectedJunction={selectedJunction}
                  setSelectedJunction={setSelectedJunction}
                  startTime={startTime}
                  endTime={endTime}
                  setStartTime={setStartTime}
                  refetch={refetch}
                  setEndTime={setEndTime}
                  defaultStartTime={defaultStartTime}
                  defaultEndTime={defaultEndTime}
                  selectedTimeRange={selectedTimeRange}
                  setSelectedTimeRange={setSelectedTimeRange}
                  groupedDatas={groupedDatas}
                  equipmentData={data?.timeline}
                  junctionData={data?.junctionData}
                  svgHeight={data?.timelineData?.length * 130}
                  date={date}
                />
              )}
            </>
          )}
        </div>
        {isLoading || isFetching ? (
          <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[72px] mt-3 mb-3" />
        ) : selectedJunctionWorkCount.length > 0 ? (
          <WorkCountCard workCount={selectedJunctionWorkCount} />
        ) : null}

        <div className={`flex flex-col h-fit `}>
          {isLoading || isFetching ? (
            <div className="bg-gray-200 animate-pulse rounded-lg w-full min-h-[400px]" />
          ) : (
            <PersonalJunctions
              setSelectedJunction={setSelectedJunction}
              selectedJunction={selectedJunction}
              junctionData={data?.junctionData || data?.junctions}
              user={data?.user}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              refetch={refetch}
              defaultEndTime={defaultEndTime}
              defaultStartTime={defaultStartTime}
              date={date}
              equipmentData={data?.timeline}
              activeLength={data?.activeJunctionLength}
            />
          )}

          {/* {!selectedJunction && <div className="flex min-h-[50px] max-h-[50px]"></div>}
          {selectedJunction && <SelectJunctionTable selectedJunction={selectedJunction} setSelectedJunction={setSelectedJunction} />} */}
        </div>
        {/* <div className="flex max-h-[140px] min-h-[140px]">
          <EquipmentLosses
            date={date}
            // lossesData={lossesData}
          />
        </div> */}
      </div>
    </div>
  );
};

export default PersonelDetail;
